import { Plugin } from 'vue'
import { EventEmitter } from 'events'

export interface IFormEmitterHelpers {
  setFieldTouched(): void
}

const plugin: Plugin = {
  install: (app) => {
    const emitter = new EventEmitter()
    emitter.setMaxListeners(10000)

    function setFieldTouched() {
      emitter.emit('setFieldTouched')
    }
    app.provide('form-emitter', emitter)
    app.provide<IFormEmitterHelpers>('form-emitter-helpers', {
      setFieldTouched,
    })
  },
}

export default plugin
