<script lang="ts" setup>
import useTextFilters from '@/plugins/TextFilters'
import { computed, ref } from 'vue'
import useFileDownload from '@/use/useFileDownload'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { ElNotification } from 'element-plus'
import { IFile } from '@/entityes/invoice/file/file.type'

interface IProps {
  isResult: boolean
  file: { id: number; name: string }
  type?: 'exante_virtual_files'
}
const props = defineProps<IProps>()
const invoiceStore = useInvoiceStore()
const { subStringFileName } = useTextFilters()
const name = computed(() => subStringFileName(props.file.name, 20))
const isIndicating = ref(false)
const { downloadResultFileRequest, downloadFileRequest } = useFileDownload()

function request(file: IFile) {
  isIndicating.value = true
  if (props.isResult) {
    downloadResultFileRequest({
      fileId: file.id,
      name: `${file.name}`,
    })
      .catch((err) => {
        ElNotification({
          title: `Ошибка`,
          message: `скачивания файлов ${err}`,
          type: 'error',
        })
      })
      .finally(() => (isIndicating.value = false))
  } else {
    if (invoiceStore.invoice) {
      downloadFileRequest({
        invoiceId: invoiceStore.invoice?.id,
        fileId: file.id,
        fileType: props.type || '',
        name: `${file.name}`,
      })
    }
  }
}
</script>

<template>
  <div v-if="file.id">
    <el-tooltip effect="dark" :content="`Скачать ${file?.name}`" :disabled="file?.name?.length < 20" placement="right">
      <a @click="request(file)" class="clickable-text-link">
        <div class="mb-4 flex items-center cursor-point">
          <el-icon size="large" class="is-loading mr-2" v-if="isIndicating"><Loading /></el-icon>
          <img class="mr-2" v-else src="../../../assets/doc_icon.svg" alt="doc_icon" />
          {{ name }}
        </div>
      </a>
    </el-tooltip>
  </div>
</template>

<style scoped lang="sass"></style>
