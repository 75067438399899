import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "padding--top_bot_10 bg-green margin-v-xs border",
  style: {"padding-left":"14px"}
}
const _hoisted_2 = { class: "flex-box flex-box--align-c" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = ["src"]

import { useInvoiceMenu } from '@/controllers/useInvoiceMenu'
import usePolitic from '@/use/usePolitic'
import useWindowWidth from '@/use/useWindowWidth'
import { onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

interface Props {
  invoiceId: string | number
  showLoader: boolean
  invoicePayed: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceSidebarMenu',
  props: {
    invoiceId: {},
    showLoader: { type: Boolean },
    invoicePayed: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const mobileStepperIsOpen = ref(false)

const openMobileMenu = () => {
  mobileStepperIsOpen.value = true
}
const closeMobileMenu = () => {
  mobileStepperIsOpen.value = false
}

const { screenLessValue } = useWindowWidth()
onBeforeMount(() => {
  mobileStepperIsOpen.value = !screenLessValue(992)
})
const route = useRoute()
const { isAdmin, isPartner } = usePolitic()
const { getInvoiceRoutes } = useInvoiceMenu()

return (_ctx: any,_cache: any) => {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(isAdmin) || _unref(isPartner) ? 'left-menu-admin' : 'left-menu-slot')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (props.showLoader)
          ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              size: 18,
              class: "is-loading margin-h--right-10",
              color: "#FF9A9D"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Loading)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["circle margin-h--right-10", props.invoicePayed ? 'circle-success' : 'circle-fail'])
            }, null, 2)),
        _createElementVNode("h3", _hoisted_3, "Заказ #" + _toDisplayString(props.invoiceId), 1)
      ])
    ]),
    (mobileStepperIsOpen.value)
      ? (_openBlock(), _createBlock(_component_el_menu, {
          key: 0,
          style: {"border":"none"},
          class: "bg-gray"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getInvoiceRoutes), (item) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                class: _normalizeClass(["flex-box flex-box--align-c clickable margin-v-xs bg-gray cursor-point", _unref(route).name === item.tag ? 'bg-purple font-color--white font-weight--semi' : '']),
                key: item.tag,
                to: {
          name: item.tag,
          params: {
            id: _ctx.invoiceId,
            good_id: item?.good_id?.value || '',
          },
        }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: item?.icon,
                    alt: "icon",
                    class: _normalizeClass(["margin-h--right-10", _unref(route).name === item.tag ? 'img-white' : 'img']),
                    width: "18"
                  }, null, 10, _hoisted_4),
                  _createTextVNode(" " + _toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["class", "to"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}
}

})