<script setup lang="ts">
import { ElMessageBox } from 'element-plus'
import { reactive, ref } from 'vue'
import useFileBase64Converter from '../lib/useFileBase64Converter'
import { PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'

interface IProps {
  fileFormat: RegExp
  text: string
}
const props = withDefaults(defineProps<IProps>(), {
  text: 'Загрузите файл',
})
interface IEmits {
  (e: 'readedFiles', value: PreProcessingFiles): void
}
const emit = defineEmits<IEmits>()
const draggable = ref(false)

const { fileTransform } = useFileBase64Converter()

const files = reactive<File[]>([])
const fileWatch = (value: File[]) => {
  value.forEach((item) => {
    if (!props.fileFormat.test(item.name)) {
      ElMessageBox.alert('Вы пытаетесь загрузить файлы неверного формата', 'Ошибка', {
        confirmButtonText: 'Понятно',
        type: 'error',
      })
      return files.splice(0, files.length)
    }
  })
  fileTransform(value)
    .then((transformedFiles) => {
      // Структура данных
      const result: PreProcessingFiles = {
        file_names: [],
        files: [],
      }
      transformedFiles.forEach((elem) => {
        result.file_names.push(elem.name)
        result.files.push(elem.base || '')
      })
      emit('readedFiles', result)
    })
    .catch((err) => {
      console.log(err)
      ElMessageBox.alert('Ошибка чтения фалов')
    })
}
function onChange(event: Event) {
  if (!event.target) return
  files.push(...((<HTMLInputElement>event.target).files || []))
  fileWatch(files)
  files.splice(0, files.length)
}
const ondrop = (event: DragEvent) => {
  draggable.value = false
  if (!event.target || !event.dataTransfer) return
  const value = event.dataTransfer.files
  files.push(...value)
  fileWatch(files)
  files.splice(0, files.length)
}
const ondragover = () => {
  draggable.value = true
  // enableBoolValue()
}
const ondragleave = () => {
  draggable.value = false
  // disableBoolValue()
}
</script>

<template>
  <label>
    <div
      class="rb-upload flex-box flex-box--center flex-box--align-c"
      :class="{ draggable }"
      @drop.prevent="ondrop"
      @dragover.prevent="ondragover"
      @dragleave.prevent="ondragleave"
    >
      <p class="font-weight--extra font-color--gray">
        {{ props.text }}
      </p>
    </div>
    <input class="rb-input" type="file" multiple @change="onChange" />
  </label>
</template>

<style lang="sass" scoped>
.rb-upload
  background: #FFFFFF
  border: 2px dashed transparentize(#243EC8, .6)
  box-sizing: border-box
  border-radius: 6px
  padding: 36px
  cursor: pointer
  height: 140px
  margin-top: 20px
  &.draggable
    opacity: .5
.rb-input
    display: none
.file-card
  border: 2px solid #EFEFEF
  padding: 4px
  border-radius: 4px
</style>
