<script setup lang="ts">
import { CircleCloseFilled, View } from '@element-plus/icons-vue'
import { ref } from 'vue'

defineSlots<{
  left(): any
  right(): any
  bottom(): any
}>()
interface IProps {
  goodName: string
}
interface IEmits {
  (e: 'show-files', value: { show: boolean, goodName: string }): void
}
const props = defineProps<IProps>()
const emit = defineEmits<IEmits>()
const filesBoxIsOpen = ref(false)
function showFiles() {
  filesBoxIsOpen.value = true
  emit('show-files', { show: true, goodName: props.goodName })
}
function hideFiles() {
  filesBoxIsOpen.value = false
  emit('show-files', { show: false, goodName: props.goodName })
}
</script>

<template>
  <el-row>
    <el-col :xs="24" :sm="24" :md="12" :lg="12">
      <div class="p-4">
        <slot name="left"></slot>
      </div>
    </el-col>
    <el-col :xs="24" :sm="24" :md="12" :lg="12" class="bg-gray">
      <div class="flex justify-between p-4">
        <div>
          <h3>Итоговые файлы</h3>
        </div>
        <div>
          <el-button :icon="filesBoxIsOpen ? CircleCloseFilled : View"
                     :type="filesBoxIsOpen ? 'danger' : 'primary'"
                     @click="() => { filesBoxIsOpen ? hideFiles() : showFiles() }">
            {{ filesBoxIsOpen ? 'скрыть' : 'посмотреть' }}
          </el-button>
        </div>
      </div>
      <slot name="right" class="p-4"></slot>
    </el-col>
  </el-row>
  <div>
    <slot name="bottom"></slot>
  </div>
</template>

<style scoped lang="sass">

</style>
