import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import { UnwrapNestedRefs } from 'vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
}

export default /*@__PURE__*/_defineComponent({
  __name: 'OtherCard',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { align: "middle" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        lg: 6,
        md: 12
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "Тип", -1),
          _createElementVNode("p", null, "Стандартный вычет", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_el_col, {
        lg: 6,
        md: 12
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "Сумма", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item.sum), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        lg: 6,
        md: 12
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "Код вычета", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item?.other_attributes?.refund_code || 'Код дохода не определен'), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        lg: 6,
        md: 12
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "Файл", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item?.other_attributes?.file_name || 'Файл не определен'), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})