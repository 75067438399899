import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-box flex-box--align-c flex-box--end" }
const _hoisted_2 = { class: "font-weight--extra" }
const _hoisted_3 = {
  key: 0,
  class: "font-color--green"
}
const _hoisted_4 = {
  key: 1,
  class: "font-color--red"
}
const _hoisted_5 = { class: "flex-box flex-box--align-c flex-box--end" }

import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { UnwrapNestedRefs } from 'vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
}
interface IEmits {
  (e: 'remove'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialLossesCard',
  props: {
    item: {}
  },
  emits: ["remove"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { align: "middle" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        sm: 6,
        md: 6,
        lg: 6,
        class: "margin-v-xs"
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "ЦБ", -1)),
          _createElementVNode("p", null, _toDisplayString(props.item.item?.other_attributes?.cb || 0) + " руб.", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        sm: 4,
        md: 4,
        lg: 4,
        class: "margin-v-xs"
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "ПФИ", -1)),
          _createElementVNode("p", null, _toDisplayString(props.item.item?.other_attributes?.pfi || 0) + " руб.", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        sm: 8,
        md: 8,
        lg: 8,
        class: "margin-v-xs"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, [
              (props.item?.valueFromServer && !props.item.fieldWasEdited)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Вычет сохранен"))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, "Вычет не сохранен"))
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        sm: 4,
        md: 4,
        lg: 4,
        class: "b-margin-top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(EditSaveCardButtonGroup, {
              onToggle: _ctx.item.toggleEdit,
              onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove')))
            }, null, 8, ["onToggle"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})