import { DeclarationAction } from '@/entityes/invoice/declaration/declaration.type'
import { IInvoice, InvoiceStatuses } from '@/entityes/invoice/invoice.type'
import usePolitic from '@/use/usePolitic'
import { computed } from 'vue'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'

export function useStepper() {
  const router = useRouter()
  const politics = usePolitic()
  const steps: Record<string, () => void> = {
    loadFile: () => {
      console.log('load file')

      router.push({
        name: 'file_in_invoice',
        replace: true,
      })
    },
    prePayment: () => {
      console.log('pre payment')

      router.push({
        name: 'pre_add_deductions',
        replace: true,
      })
    },
    goodSelect: () => {
      console.log('good select')

      router.push({
        name: 'good_select',
        replace: true,
      })
    },
    tms: () => {
      console.log('tms')

      router.push({
        name: 'tms',
        replace: true,
      })
    },
    show: () => {
      console.log('show')

      router.push({
        name: 'invoice show',
        replace: true,
      })
    },
  }
  function selectStep(invoice_status: InvoiceStatuses, has_tms = false) {
    if (invoice_status === 'payed' && has_tms && !politics.isAdmin.value) return steps.tms()
    if (invoice_status === 'new') return steps.loadFile()
    if (invoice_status === 'file_uploaded') return steps.prePayment()
    if (invoice_status === 'pre_add_services_selected') return steps.goodSelect()
    // return steps.show()
  }
  function checkingForTms(actions: DeclarationAction[]) {
    const resolvedTms = ['Missing_resolved', 'Transfer_resolved', 'Short_resolved']
    return actions.some((item) => resolvedTms.includes(item))
  }
  function checkingForTmsFromInvoice(actions: IInvoice['tms']) {
    if (!actions) return false
    return Object.values(actions).some((item) => item)
  }
  return {
    selectStep,
    checkingForTms,
    checkingForTmsFromInvoice,
  }
}
