import {useRequestService} from '@/use/useRequestService'
import {useTaxPlanStore} from '@/entityes/invoice/tax_plan/tax_plan.store'
import {IDataSource, ITaxPlan, ITaxUpdate} from '@/entityes/invoice/tax_plan/tax_plan.type'

export function useTaxPlanRepository() {
  const { fetch } = useRequestService()
  const taxPlanStore = useTaxPlanStore()

  function getTaxPlanList(profile_id: number) {
    return new Promise((resolve, reject) => {
      fetch<{ tax_plans: ITaxPlan[] }>(`/profiles/${profile_id}/tax_plans`).then((res) => {
        resolve(res)
        taxPlanStore.taxPlans = res.tax_plans
      }).catch((err) => {
        reject(err)
      })
    })
  }
  function getTaxPlan(profile_id: number, taxPlanId: number | string) {
    return new Promise((resolve, reject) => {
      fetch<ITaxPlan>(`/profiles/${profile_id}/tax_plans/${taxPlanId}`).then((res) => {
        taxPlanStore.taxPlan = res
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
  function updateTaxPlan(profile_id: number | string, taxPlanId: number | string, body: ITaxUpdate) {
    return fetch<ITaxPlan>(`/profiles/${profile_id}/tax_plans/${taxPlanId}`, {
      method: 'patch',
      body
    })
  }
  function getDataSources(profile_id: number) {
    return new Promise((resolve, reject) => {
      fetch<{ data_sources: IDataSource[] }>(`/profiles/${profile_id}/data_sources`)
        .then((res) => {
          taxPlanStore.dataSources = res.data_sources
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  function saveDataSource(profile_id: number | string, body: {file_type: string, file_name: string, file: string}) {
    return fetch(`/profiles/${profile_id}/data_sources`, {
      method: 'post',
      body
    })
  }
  function createNewTaxPlan(profile_id: number | string, body: { name: string }) {
    return fetch<ITaxPlan>(`/profiles/${profile_id}/tax_plans`, {
      method: 'post',
      body
    })
  }
  return {
    getTaxPlanList,
    getTaxPlan,
    updateTaxPlan,
    getDataSources,
    saveDataSource,
    createNewTaxPlan
  }
}
