import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

interface IProps {
  dataSource: any
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DataSourceCard',
  props: {
    dataSource: {}
  },
  setup(__props: any) {

const props = __props
const fileTypeFilter = (type: string) => {
  return type?.split('::')[1]
}

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { class: "p-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 4 }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, [
            _cache[0] || (_cache[0] = _createTextVNode(" id: ")),
            _createElementVNode("span", null, _toDisplayString(_ctx.dataSource.id), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 10 }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, "Number: " + _toDisplayString(_ctx.dataSource.account_number), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 7 }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, "FileType: " + _toDisplayString(fileTypeFilter(_ctx.dataSource?.datable_type)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})