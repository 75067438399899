<script setup lang="ts">
import { useFileStore } from '@/entityes/invoice/file/file.store'
import { useFileAdminRepository } from '@/entityes/invoice/file/file.admin.repository'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useFileDownload from '@/use/useFileDownload'
import AdminRenderFiles from './AdminRenderFiles.vue'
import { ref } from 'vue'

const invoiceStore = useInvoiceStore()
const fileStore = useFileStore()
const fileAdminRepository = useFileAdminRepository()
const { downloadFileRequest } = useFileDownload()
const tp = ref(0)
const cp = ref(1)

function changePage(page: number) {
  if (!invoiceStore.invoice) return
  fileAdminRepository.getAllFilesInInvoice(invoiceStore.invoice.id, 1).then((res) => {
    tp.value = res.total_pages
    cp.value = page
  })
}
function init() {
  if (!invoiceStore.invoice) return
  fileAdminRepository.getAllFilesInInvoice(invoiceStore.invoice.id, 1).then((res) => {
    tp.value = res.total_pages
    cp.value = res.current_page
  })
}
init()
</script>

<template>
  <div v-if="fileStore.filesAdmin !== null && invoiceStore.invoice !== null">
    <el-pagination
      class="mb-8"
      :hide-on-single-page="tp === 1"
      :current-page="cp"
      :total="tp"
      layout="prev, pager, next"
      :page-size="1"
      @current-change="changePage"
      background
    />
    <el-scrollbar max-height="400">
      <div class="flex items-center flex-wrap">
        <div
          v-for="file in fileStore.filesAdmin.files"
          :key="file.id"
          @click="
            downloadFileRequest({
              invoiceId: invoiceStore.invoice.id,
              fileType: file.tag,
              fileId: file.id,
              name: file.name,
            })
          "
          class="mb-4 mr-4"
        >
          <admin-render-files :file="file" />
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<style lang="sass" scoped></style>
