// import CheckAdminRoleMiddleware from '@/router/middlewares/CheckAdminRoleMiddleware'
// import LoadPromocodeMiddleware from '@/router/Admin/LoadPromocodeMiddleware'
import { CheckAdminRoleMiddleware } from '@/router/middlewares/CheckAdminRoleMiddleware'
const Index = () => import('@/components/Admin/Promocode/Index.vue')
const Show = () => import('@/components/Admin/Promocode/Show.vue')

export default [
  {
    path: '/promocodes',
    name: 'promo index',
    component: Index,
    meta: {
      middleware: [CheckAdminRoleMiddleware],
    },
  },
  {
    path: '/promocodes/:id',
    name: 'promo show',
    component: Show,
    props: true,
    meta: {
      middleware: [CheckAdminRoleMiddleware],
    },
  },
]
