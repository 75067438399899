import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "font-color--deep-gray font-weight--extra mb-2" }
const _hoisted_3 = { class: "font-weight--semi" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = {
  key: 0,
  class: "bg-gray border p-2"
}
const _hoisted_6 = { class: "flex items-center justify-between" }
const _hoisted_7 = { class: "flex" }

import { ref } from 'vue'
import { useUpdateDeclarationVersion } from '@/05-Features/DeclarationVersionSet/api/updateDeclarationVersion.request'
import { IDeclaration } from '@/entityes/invoice/declaration/declaration.type'
import { ElNotification } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'

interface IProps {
  declaration: IDeclaration
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DeclarationCorrectionNumberInput',
  props: {
    declaration: {}
  },
  setup(__props: any) {

const props = __props
const { updateDeclarationVersion } = useUpdateDeclarationVersion()
const showEditor = ref(false)
const isLoading = ref(false)
const correctionNumber = ref(props.declaration?.correction_number || 0)
function onSubmit() {
  isLoading.value = true
  const body = { correction_number: correctionNumber.value }
  // if(props.declaration?.id) {
    updateDeclarationVersion(props.declaration?.id, body).then(() => {
      isLoading.value = false
    }).catch((err) => {
      isLoading.value = false
      ElNotification({
        title: `Ошибка`,
        message: `обновления версии декларации ${err}`,
        type: 'error',
      })
    })
  // }
}

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _cache[2] || (_cache[2] = _createTextVNode(" Версия уточненной декларации: ")),
      _createElementVNode("span", _hoisted_3, _toDisplayString(correctionNumber.value > 0 ? `уточненная (версия - ${correctionNumber.value})` : `первичная (версия - ${correctionNumber.value})`), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_checkbox, {
        modelValue: showEditor.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showEditor).value = $event)),
        type: "primary",
        class: "font-weight--extra"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" Подать уточненную декларацию ")
        ])),
        _: 1
      }, 8, ["modelValue"])
    ]),
    (showEditor.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "mr-2" }, "Версия уточненной декларации", -1)),
              _createVNode(_component_el_input_number, {
                class: "mr-6",
                style: {"width":"86px !important","height":"26px"},
                size: "small",
                min: 0,
                modelValue: correctionNumber.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((correctionNumber).value = $event)),
                disabled: isLoading.value
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _createVNode(_component_el_button, {
              icon: _unref(UploadFilled),
              size: "small",
              type: "primary",
              onClick: onSubmit,
              loading: isLoading.value
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Сохранить")
              ])),
              _: 1
            }, 8, ["icon", "loading"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})