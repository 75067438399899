import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-weight--extra font-color--gray" }

import { ElMessageBox } from 'element-plus'
import { reactive, ref } from 'vue'
import useFileBase64Converter from '../lib/useFileBase64Converter'
import { PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'

interface IProps {
  fileFormat: RegExp
  text: string
}
interface IEmits {
  (e: 'readedFiles', value: PreProcessingFiles): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DragAndDrop',
  props: {
    fileFormat: {},
    text: { default: 'Загрузите файл' }
  },
  emits: ["readedFiles"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const draggable = ref(false)

const { fileTransform } = useFileBase64Converter()

const files = reactive<File[]>([])
const fileWatch = (value: File[]) => {
  value.forEach((item) => {
    if (!props.fileFormat.test(item.name)) {
      ElMessageBox.alert('Вы пытаетесь загрузить файлы неверного формата', 'Ошибка', {
        confirmButtonText: 'Понятно',
        type: 'error',
      })
      return files.splice(0, files.length)
    }
  })
  fileTransform(value)
    .then((transformedFiles) => {
      // Структура данных
      const result: PreProcessingFiles = {
        file_names: [],
        files: [],
      }
      transformedFiles.forEach((elem) => {
        result.file_names.push(elem.name)
        result.files.push(elem.base || '')
      })
      emit('readedFiles', result)
    })
    .catch((err) => {
      console.log(err)
      ElMessageBox.alert('Ошибка чтения фалов')
    })
}
function onChange(event: Event) {
  if (!event.target) return
  files.push(...((<HTMLInputElement>event.target).files || []))
  fileWatch(files)
  files.splice(0, files.length)
}
const ondrop = (event: DragEvent) => {
  draggable.value = false
  if (!event.target || !event.dataTransfer) return
  const value = event.dataTransfer.files
  files.push(...value)
  fileWatch(files)
  files.splice(0, files.length)
}
const ondragover = () => {
  draggable.value = true
  // enableBoolValue()
}
const ondragleave = () => {
  draggable.value = false
  // disableBoolValue()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["rb-upload flex-box flex-box--center flex-box--align-c", { draggable: draggable.value }]),
      onDrop: _withModifiers(ondrop, ["prevent"]),
      onDragover: _withModifiers(ondragover, ["prevent"]),
      onDragleave: _withModifiers(ondragleave, ["prevent"])
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(props.text), 1)
    ], 34),
    _createElementVNode("input", {
      class: "rb-input",
      type: "file",
      multiple: "",
      onChange: onChange
    }, null, 32)
  ]))
}
}

})