import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "flex justify-between p-4" }

import { CircleCloseFilled, View } from '@element-plus/icons-vue'
import { ref } from 'vue'

interface IProps {
  goodName: string
}
interface IEmits {
  (e: 'show-files', value: { show: boolean, goodName: string }): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ResultGoodCard',
  props: {
    goodName: {}
  },
  emits: ["show-files"],
  setup(__props: any, { emit: __emit }) {


const props = __props
const emit = __emit
const filesBoxIsOpen = ref(false)
function showFiles() {
  filesBoxIsOpen.value = true
  emit('show-files', { show: true, goodName: props.goodName })
}
function hideFiles() {
  filesBoxIsOpen.value = false
  emit('show-files', { show: false, goodName: props.goodName })
}

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "left")
            ])
          ]),
          _: 3
        }),
        _createVNode(_component_el_col, {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          class: "bg-gray"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("div", null, [
                _createElementVNode("h3", null, "Итоговые файлы")
              ], -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_el_button, {
                  icon: filesBoxIsOpen.value ? _unref(CircleCloseFilled) : _unref(View),
                  type: filesBoxIsOpen.value ? 'danger' : 'primary',
                  onClick: _cache[0] || (_cache[0] = () => { filesBoxIsOpen.value ? hideFiles() : showFiles() })
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(filesBoxIsOpen.value ? 'скрыть' : 'посмотреть'), 1)
                  ]),
                  _: 1
                }, 8, ["icon", "type"])
              ])
            ]),
            _renderSlot(_ctx.$slots, "right", { class: "p-4" })
          ]),
          _: 3
        })
      ]),
      _: 3
    }),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "bottom")
    ])
  ], 64))
}
}

})