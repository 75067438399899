import { computed, reactive, ref } from 'vue'
import { IAddItem } from './IAddItem.type'
import { AdditionalItem, AdditionalItemType, AdditionalItemValue, newAdditionalItem } from './AdditionalItem'

export interface ICollectionConfig {
  situationFieldName: AdditionalItemType
  describers: IAddItem[]
}
export function useCollection<T extends AdditionalItemValue>(config: ICollectionConfig) {
  const collection = reactive<AdditionalItem<T>[]>([])
  const deleted = ref(false)
  const bosedescriber = config.describers

  function setTouch() {
    collection.forEach((item) => (item.touched = true))
  }

  function hideAll() {
    collection.forEach((item) => {
      item.isEdit = false
    })
  }
  function createNew(situationName: string) {
    hideAll()
    const item = newAdditionalItem<T>({} as T, {
      isEdit: true,
      fieldWasEdited: true,
      baseField: config.situationFieldName,
      situationName,
      describer: config.describers,
    })
    collection.unshift(reactive(item))
  }
  function mergeValueFromServer(items: T[]) {
    collection.splice(0, collection.length)
    items.forEach((item) => {
      collection.push(
        reactive(
          newAdditionalItem<T>(item, {
            valueFromServer: true,
            situationName: item[config.situationFieldName],
            baseField: config.situationFieldName,
            describer: config.describers,
          })
        )
      )
    })
  }
  function deleteById(uniqId: string) {
    const index = collection.findIndex((item) => item.localUniqueId === uniqId)
    if (index < 0) return
    if (collection[index].valueFromServer) {
      deleted.value = true
    }
    collection.splice(index, 1)
  }
  function deleteBySituation(situationName: string) {
    const itemsInSituation = collection.filter((item) => item.situationName === situationName)
    itemsInSituation.forEach((item) => deleteById(item.localUniqueId))
  }
  function deleteAll() {
    collection.splice(0, collection.length)
    deleted.value = true
  }
  const edited = computed(() => {
    return deleted.value || collection.some((item) => item.fieldWasEdited)
  })
  return {
    collection,
    deleted,
    edited,
    createNew,
    mergeValueFromServer,
    deleteById,
    deleteBySituation,
    deleteAll,
    bosedescriber,
    setTouch,
  }
}
