import { IAuthUser, IUser } from './user.type'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useUserStore = defineStore(
  'user store',
  () => {
    const user = ref<IAuthUser | undefined>(undefined)
    const userShowForAdmin = ref<IUser | undefined>(undefined)
    const isAuth = computed<boolean>(() => {
      return user.value !== undefined
    })
    function checkTokenActuality() {
      if (!user.value) return false
      const exp_time = new Date(user.value?.refresh_exp)
      return exp_time > new Date()
    }
    function signOut() {
      user.value = undefined
    }
    return { user, isAuth, userShowForAdmin, checkTokenActuality, signOut }
  },
  {
    persist: true,
  }
)
