<script setup lang="ts">
import TMS from '@/04-Widgets/TMS/TMS.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const invoiceId = computed(() => route.params.id as string)
const declarationId = computed(() => route.params.good_id as string)
</script>

<template>
  <TMS :id="invoiceId" :good_id="declarationId" />
</template>

<style scoped lang="sass"></style>
