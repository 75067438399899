import { Maybe, AnyObject, Schema, Flags } from 'yup'
declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext = AnyObject,
    TDefault = undefined,
    TFlags extends Flags = ''
  > extends Schema<TType, TContext, TDefault, TFlags> {
    checkYearValue(year: number, errorMessage?: string): StringSchema<TType, TContext>
    checkNumberString(): StringSchema<TType, TContext>
  }
  interface NumberSchema {
    len(len: number | number[]): NumberSchema
  }
}

import * as yup from 'yup'

yup.addMethod(yup.number, 'len', function (len: number | number[]) {
  return this.test('test-number-length', '', function (value) {
    const { createError, path } = this
    if (value === undefined) return true
    if (Array.isArray(len)) {
      console.log(
        'len',
        len.some((l) => `${value}`.length === l)
      )
      if (!len.some((l) => `${value}`.length === l)) {
        return createError({ path, message: `Значение может содержать ${len.join(', ')} цифр` })
      }
      return true
    }
    if (`${value}`.length !== len) return createError({ path, message: `Значение должно содержать ${len} цифр` })
    return true
  })
})

yup.addMethod(
  yup.string,
  'checkYearValue',
  function (year: number, errorMessage: string = 'Год не соответсвует отчетному периоду') {
    return this.test('test-select-year', errorMessage, function (value) {
      const { createError, path } = this
      if (value === undefined) return true
      const reverse = value?.split('.').reverse().join('/') || ''
      const d = new Date(reverse)
      return d.getFullYear() === year || createError({ path, message: errorMessage })
    })
  }
)
yup.addMethod(yup.string, 'checkNumberString', function () {
  const errorMessage = 'Значене должно быть числом'
  return this.transform((value) => {
    if (!this.isType(value) && value === null) return value
    const localValue = `${value}`
    const reg = /[,]/
    return localValue.replace(reg, '.')
  }).test('test-number-string', errorMessage, function (value) {
    const { createError, path } = this
    if (!value) return true
    return /^-?[0-9]*[.]?[0-9]+$/.test(value) || createError({ path, message: errorMessage })
  })
})
