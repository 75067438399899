<script setup lang="ts">
import { FileFromServer } from '@/entityes/invoice/file/file.type'
import useTextFilters from '@/plugins/TextFilters'
interface IProps {
  noDelete?: boolean
  files: FileFromServer[]
}
const props = withDefaults(defineProps<IProps>(), {
  noDelete: false,
})
interface IEmit {
  (e: 'delete', fileId: number): void
}
const emit = defineEmits<IEmit>()
</script>

<template>
  <div class="padding-h-l">
    <div v-for="file in props.files" :key="'active_file_' + file.id" class="margin-v-s">
      <div class="flex-box flex-box--align-c flex-box--sb margin-v-xs">
        <el-tooltip effect="dark" :content="file.serverName" placement="top">
          <span>{{ file.name }}</span>
        </el-tooltip>
        <img
          src="@/assets/delete_icon.svg"
          v-if="!noDelete"
          alt="delete_icon"
          @click="emit('delete', file.id)"
          width="16"
          class="cursor-point button--text-hover"
        />
      </div>
      <el-divider class="el-divider-80" />
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
