import { defineStore } from 'pinia'
import { IAdminFileInfo, IAdminFileInfoList } from './file.type'
import { ref } from 'vue'

export const useFileStore = defineStore('file store', () => {
  const filesAdmin = ref<IAdminFileInfoList | null>(null)
  return {
    filesAdmin,
  }
})
