import { PreProcessingFilesForServer } from '@/entityes/invoice/file/loader/loader.type'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import { useRequestService } from '@/use/useRequestService'
import { ref } from 'vue'

export function useSendFileRequest() {
  const { fetch } = useRequestService()
  const invoiceStore = useInvoiceStore()
  function loadFile(invoiceId: number | string, body: PreProcessingFilesForServer) {
    loadFile.indicator.value = true
    return new Promise<IInvoice>((resolve, reject) => {
      fetch<IInvoice>(`/invoices/${invoiceId}/file_upload`, {
        method: 'patch',
        body: {
          broker_files: {
            ...body,
          },
        },
      })
        .then((res) => {
          invoiceStore.invoice = res
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          loadFile.indicator.value = false
        })
    })
  }
  loadFile.indicator = ref(false)
  return {
    loadFile,
  }
}
