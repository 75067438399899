<script lang="ts" setup>
import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import SocialLimit from './SocialLimit.vue'
import { computed, UnwrapNestedRefs } from 'vue'

interface IProps {
  yearToCalc: number
  items: UnwrapNestedRefs<AdditionalItem<Deduction>>[]
}
const props = defineProps<IProps>()
const socialRefund = computed(() =>
  props.items.filter((item) => {
    return item.situationName === 'social_refund'
  })
)
const propertyDeductionMortgageSum = computed(() =>
  props.items
    .filter((item) => item.situationName === 'property_deduction_mortgage')
    .reduce((acc, item) => (acc += item.item.sum) || 0, <number>0)
)
const propertyTaxDeduction = computed(() =>
  props.items
    .filter((item) => item.situationName === 'property_tax_deduction')
    .reduce((acc, item) => (acc += item.item.sum) || 0, <number>0)
)
const iis = computed(() =>
  props.items
    .filter((item) => item.situationName === 'iis')
    .reduce((acc, item) => (acc += Number(item.item.sum) || 0), <number>0)
)
</script>

<template>
  <div>
    <p class="text-2xl mb-4" v-if="items.length > 0">Лимиты</p>
    <SocialLimit :items="socialRefund" :yearToCalc="yearToCalc" />
    <div v-if="props.items.filter((item) => item.situationName === 'property_deduction_mortgage').length > 0">
      <div class="flex justify-between">
        <p class="w-5/6">Сумма вычета по процентам по ипотеке</p>
        <p class="w-1/6" :class="{ 'text-red-600': propertyDeductionMortgageSum > 3000000 }">
          <b>{{ propertyDeductionMortgageSum }}/{{ 3000000 }}</b> рублей.
        </p>
      </div>
    </div>
    <div v-if="props.items.filter((item) => item.situationName === 'property_tax_deduction').length > 0">
      <div class="flex justify-between">
        <p class="w-5/6">Сумма вычета за покупку жилья</p>
        <p class="w-1/6" :class="{ 'text-red-600': propertyTaxDeduction > 2000000 }">
          <b>{{ propertyTaxDeduction }}/{{ 2000000 }}</b> рублей.
        </p>
      </div>
    </div>
    <div v-if="props.items.filter((item) => item.situationName === 'iis').length > 0">
      <div class="flex justify-between">
        <p class="w-5/6">Сумма вычета по ИИС</p>
        <p class="w-1/6" :class="{ 'text-red-600': iis > 400000 }">
          <b>
            <span>{{ iis }}</span
            >/{{ 400000 }}
          </b>
          рублей.
        </p>
      </div>
    </div>
  </div>
</template>
<style lang="sass"></style>
