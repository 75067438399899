import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center mb-2" }
const _hoisted_2 = { class: "flex items-center font-color--deep-gray mb-2" }
const _hoisted_3 = { class: "flex items-center font-color--deep-gray mb-2" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex items-center font-color--deep-gray" }
const _hoisted_6 = { class: "mr-2 font-weight--extra" }

import useSummaryNew from '@/05-Features/DeclarationSummary/model/useSummaryNew'
import { computed } from 'vue'
import { GoodsTypeName } from '@/entityes/invoice/invoice.type'

interface IProps {
  actions: Record<GoodsTypeName, string[]>
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DeclarationSummary',
  props: {
    actions: {}
  },
  setup(__props: any) {

const props = __props
const declarationComplete = computed(() =>
  props.actions?.Declaration?.reduce((acc: boolean, item: string) => {
    return acc && ['Transfer_resolved', 'Missing_resolved', 'Short_resolved'].includes(item)
  }, true)
)
const stepStatus = computed(() => {
  if (props.actions.Declaration?.includes('Transfer')) {
    return 'Переводы с других счетов'
  }
  if (props.actions.Declaration?.includes('Missing')) {
    return 'Нет данных о покупке'
  }
  if (props.actions.Declaration?.includes('Short')) {
    return 'Незакрытые короткие позиции'
  }
  if (props.actions.Declaration?.includes('Add_account')) {
    return 'Указать данные по счету'
  }
  if (props.actions.Cfr?.includes('Need_file_for_last_year')) {
    return 'Необходимо догрузить файлы FF'
  }
  return 'ошибка определения статуса'
})
const titleList = {
  'additional_incomes': 'Дополнительные доходы',
  'deductions': 'Вычеты',
  'personal_data': 'Персональные данные'
}

const { summary, taxes15, taxes13 } = useSummaryNew()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "font-weight--extra font-color--deep-gray mr-2" }, "Статус:", -1)),
      _createElementVNode("span", {
        class: _normalizeClass([declarationComplete.value ? 'font-color--green' : 'font-color--red', "font-weight--extra"])
      }, _toDisplayString(declarationComplete.value ? 'Документы готовы' : stepStatus.value), 3)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mr-2 font-weight--extra" }, "Налог 13%: ", -1)),
      _createElementVNode("div", null, _toDisplayString(_unref(taxes13)) + " ₽", 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mr-2 font-weight--extra" }, "Налог 15%: ", -1)),
      _createElementVNode("div", null, _toDisplayString(_unref(taxes15)) + " ₽", 1)
    ]),
    (Object.keys(_unref(summary)).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(summary), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index + Date.now(),
              class: "mb-2"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (value, key) => {
                return (_openBlock(), _createElementBlock("div", { key: key }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(titleList[key]) + ": ", 1),
                    _createElementVNode("div", null, _toDisplayString(key === 'personal_data' ? value : value.length), 1)
                  ])
                ]))
              }), 128))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})