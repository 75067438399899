<script lang="ts" setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import { ElNotification } from 'element-plus'
import ClearLoader from '@/components/ClearLoader.vue'
import CurrencyDescriber from '@/describers/CurrencyDescriber'
const Transfers = defineAsyncComponent(() => import('@/05-Features/Transfers/ui/Transfers.vue'))
const UnclosedShorts = defineAsyncComponent(() => import('@/05-Features/UnclosedShorts/ui/UnclosedShorts.vue'))
const Missings = defineAsyncComponent(() => import('../../05-Features/Missings/ui/Missings.vue'))

interface IProps {
  id: string | number
  good_id: string | number
}
const props = defineProps<IProps>()
const invoiceRepository = useInvoiceRepository()
const declarationStore = useDeclarationStore()
const { getDeclaration, getTransfers, getUnclosedShorts, getMissings } = useDeclarationRepository()
const declarationActions = computed(() => declarationStore.declaration && declarationStore.declaration.actions)
const cPage = ref(1)
const tPages = ref(1)
const pageToChange = ref(0)
const changePage = (val: number) => {
  cPage.value = val
  init()
}
const totalLoading = {
  transferLoading: ref(false),
  missingLoading: ref(false),
  shortLoading: ref(false),
}
const TmsDescriber = [
  {
    name: 'Transfer',
    getMethod: () => {
      totalLoading.transferLoading.value = true
      getTransfers(props.good_id, 1).finally(() => {
        totalLoading.transferLoading.value = false
      })
    },
    resolveName: 'Transfer_resolved',
    component: Transfers,
    show: ref(false),
  },
  {
    name: 'Missing',
    getMethod: () => {
      totalLoading.missingLoading.value = true
      getMissings(props.good_id, 1).finally(() => {
        totalLoading.missingLoading.value = false
      })
    },
    resolveName: 'Missing_resolved',
    component: Missings,
    show: ref(false),
  },
  {
    name: 'Short',
    getMethod: () => {
      totalLoading.shortLoading.value = true
      getUnclosedShorts(props.good_id, 1).finally(() => {
        totalLoading.shortLoading.value = false
      })
    },
    resolveName: 'Short_resolved',
    component: UnclosedShorts,
    show: ref(false),
  },
]
const haveBaseRequest = ref(false)
const isLoading = computed(
  () => {
    return Object.values(totalLoading).some((loading) => loading.value)
  }
  // Object.keys(totalLoading).reduce((acc, key) => {
  //   return acc && totalLoading[key].value
  // }, false)
)
const getAllTMS = (val: string[] | null) => {
  haveBaseRequest.value = false
  TmsDescriber.forEach((item) => {
    if (val?.includes(item.name) && !haveBaseRequest.value) {
      haveBaseRequest.value = true
      item.getMethod()
      item.show.value = true
    }
    if (val?.includes(item.resolveName)) {
      item.getMethod()
      item.show.value = true
    }
  })
}
const showComponent = computed(() => TmsDescriber.filter((item) => item.show.value))
const renderComponent = computed(() => {
  return (
    declarationStore.tms?.transfers?.length > 0 ||
    declarationStore.tms?.missings?.length > 0 ||
    declarationStore.tms?.unclosed_shorts?.length > 0
  )
})
function init() {
  invoiceRepository.getInvoice(props.id).then(() => {
    getDeclaration(props.good_id)
      .then(() => {
        getAllTMS(declarationActions.value)
      })
      .catch((err) => {
        ElNotification({
          title: 'Ошибка',
          message: `Получения декларации ${err}`,
        })
      })
  })
}
watch(pageToChange, () => {
  init()
})
init()
</script>

<template>
  <div class="container-list">
    <h1 class="margin-v-xs">Дефицит данных</h1>
    <p class="margin-v-s">
      В вашем отчете отсутствуют данные по некоторым позициям. Загрузите файлы брокера с этой информацией или внесите
      цифры вручную.
    </p>
    <el-pagination
      class="margin-v-s"
      :hide-on-single-page="tPages === 1"
      :current-page="cPage"
      :total="tPages"
      layout="prev, pager, next"
      :page-size="1"
      @current-change="changePage"
      background
    />
    <clear-loader v-if="isLoading" />
    <div v-else v-for="item in showComponent" :key="item.name">
      <component @save="init" :is="item.component" v-if="renderComponent" :currency="CurrencyDescriber" />
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
