import { defineStore } from 'pinia'
import { IPersonalData, IPersonalDatas } from '@/entityes/invoice/personal_data/pd.type'
import { ref } from 'vue'

export const usePDStore = defineStore('pd store', () => {
  const pds = ref<IPersonalDatas | undefined>(undefined)
  const currentPD = ref<IPersonalData | undefined>(undefined)
  return {
    pds,
    currentPD,
  }
})
