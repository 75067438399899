import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useRoute } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'CheckStepperRouteComponent',
  setup(__props) {

const route = useRoute()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, _toDisplayString(_unref(route).name), 1))
}
}

})