<script setup lang="ts">
import { useRoute } from 'vue-router'
const route = useRoute()
</script>

<template>
  <div>{{ route.name }}</div>
</template>

<style lang="sass" scoped></style>
