<script setup lang="ts">
import { defineProps } from 'vue'
import { useField } from 'vee-validate'

interface IProps {
  label?: string
  name: string
  // todo добавить правильный тип для model
}
const props = defineProps<IProps>()
const field = useField(() => props.name)
function onBlur() {
  field.handleBlur()
  field.validate()
}
</script>

<template>
  <div>
    <p v-if="label" class="label font-weight--semi mb-1">
      {{ label }}
    </p>
    <div class="form-slot">
      <slot :value="field.value.value as any" :onBlur="onBlur" :onInput="field.handleChange" :meta="field.meta" />
      <el-popover
        placement="bottom"
        class="margin-h--left-s"
        :width="300"
        v-if="!field.meta.valid && field.meta.touched"
      >
        <template #reference>
          <span class="form-warning">
            <el-icon color="#FF9A9D" size="large" class="margin-h--left-s cursor-point"><WarningFilled /></el-icon>
          </span>
        </template>
        <p
          :class="{ 'rb-form-error-hide': field.meta.valid || !field.meta.touched }"
          class="font-color--red"
          style="word-break: break-word"
        >
          {{ field.errorMessage || 'нет ошибок' }}
        </p>
      </el-popover>
    </div>
  </div>
</template>

<style scoped lang="sass">
//.label
//  text-align: right
//  vertical-align: middle
//  float: left
//  box-sizing: border-box
.rb-form-error
  line-height: 14px
  opacity: 1
  margin-bottom: 10px
  &-hide
    opacity: 0 !important
.form-slot
  position: relative
.form-warning
  position: absolute
  right: -5px
  top: -5px
</style>
