import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "font-weight--extra font-color--deep-gray" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_3 = {
  key: 1,
  class: "font-weight--extra"
}
const _hoisted_4 = { class: "font-weight--extra" }

import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceInfo } from '@/use/AdminInfoTabs/useInvoiceInfo'
import BrokerAccountInfo from './BrokerAccountInfo.vue'
import { computed } from 'vue'
import { Loading } from '@element-plus/icons-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceInfo',
  setup(__props) {

const invoiceStore = useInvoiceStore()
const { info } = useInvoiceInfo(invoiceStore.invoice!)
const asyncInvoice = computed(() => invoiceStore.invoice?.status.async)
const amountOperations = computed(() => invoiceStore.invoice?.amount_operations?.Declaration)
const brokerAccounts = computed(() => invoiceStore.invoice?.broker_accounts)

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(info), (item) => {
        return (_openBlock(), _createBlock(_component_el_col, {
          key: item.title,
          xs: 24,
          sm: item.adaptive.sm,
          md: item.adaptive.md,
          lg: item.adaptive.lg
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, _toDisplayString(item.title), 1),
            (item.title === 'Статус')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (asyncInvoice.value)
                    ? (_openBlock(), _createBlock(_component_el_icon, {
                        key: 0,
                        color: "#FF9A9D",
                        class: "is-loading mr-2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Loading))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", {
                    class: _normalizeClass(["font-weight--extra flex align-center font-color--green", asyncInvoice.value ? 'font-color--red' : ''])
                  }, _toDisplayString(asyncInvoice.value ? 'раcчeт' : 'Посчитан'), 3)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.value.value), 1))
          ]),
          _: 2
        }, 1032, ["sm", "md", "lg"]))
      }), 128)),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        md: 4,
        lg: 4
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "font-weight--extra font-color--deep-gray" }, "Кол-во операций", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(amountOperations.value, (operations, years) => {
            return (_openBlock(), _createElementBlock("div", { key: years }, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(years) + " год: ", 1),
              _createTextVNode(" " + _toDisplayString(operations), 1)
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 3,
        md: 3,
        lg: 3
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "font-weight--extra font-color--deep-gray" }, "Номера счетов", -1)),
          _createVNode(BrokerAccountInfo, { "broker-account": brokerAccounts.value }, null, 8, ["broker-account"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})