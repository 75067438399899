import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "margin-v-s" }
const _hoisted_2 = ["src", "height"]

import { ref, computed } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'

interface IProps {
  showButton?: boolean
  videoLink: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'NgInvoiceVideo',
  props: {
    showButton: { type: Boolean, default: false },
    videoLink: {}
  },
  setup(__props: any, { expose: __expose }) {

const props = __props
const videoModalIsOpen = ref(false)
const openVideoModal = () => {
  videoModalIsOpen.value = true
}
const { screenLessValue } = useWindowWidth()
const dialogWidth = computed(() => {
  if (screenLessValue(767)) {
    return {
      frameHeight: 300,
      dialogWidth: '80%',
    }
  }
  if (screenLessValue(1780)) {
    return {
      frameHeight: 600,
      dialogWidth: '80%',
    }
  }
})
__expose({
  openVideoModal,
})

return (_ctx: any,_cache: any) => {
  const _component_VideoPlay = _resolveComponent("VideoPlay")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showButton)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "primary",
            size: "small",
            onClick: openVideoModal,
            class: "subscribe-button"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "el-icon-video-play font-weight--extra margin-h--right-10" }, {
                default: _withCtx(() => [
                  _createVNode(_component_VideoPlay)
                ]),
                _: 1
              }),
              _cache[1] || (_cache[1] = _createTextVNode(" Как это работает? "))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: videoModalIsOpen.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((videoModalIsOpen).value = $event)),
      "destroy-on-close": true,
      width: dialogWidth.value?.dialogWidth,
      title: "Как пользоваться сервисом НДФЛ Гуру"
    }, {
      default: _withCtx(() => [
        _createElementVNode("iframe", {
          src: _ctx.videoLink,
          title: "YouTube video player",
          width: "100%",
          height: dialogWidth.value?.frameHeight,
          allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: "",
          loading: "lazy"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["modelValue", "width"])
  ], 64))
}
}

})