import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "w-1/6" }
const _hoisted_6 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_7 = { class: "w-1/6" }
const _hoisted_8 = { key: 0 }

import { SocialRefundsDescriber } from '@/04-Widgets/DeductionWidget/model/SocialRefundsDescriber'
import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import { generatorId } from '@/07-Shared/lib/generatorId'
import { computed, UnwrapNestedRefs } from 'vue'

interface IProps {
  yearToCalc: number
  items: UnwrapNestedRefs<AdditionalItem<Deduction>>[]
}

export default /*@__PURE__*/_defineComponent({
  __name: 'SocialLimit',
  props: {
    yearToCalc: {},
    items: {}
  },
  setup(__props: any) {

const props = __props

const tags = SocialRefundsDescriber().tagsList
const group1tag = tags.filter((item) => item.limitGroup === 1).map((item) => item.tag)
const group1limit = props.yearToCalc >= 2024 ? 150000 : 120000
const group1sum = computed(() =>
  props.items
    .filter((item) => group1tag.includes(item.item.tag))
    .reduce((acc, item) => {
      return (acc += item.item.sum) || 0
    }, <number>0)
)
const group2tag = tags.filter((item) => item.limitGroup === 2).map((item) => item.tag)
const group2 = computed(() => props.items.filter((item) => group2tag.includes(item.item.tag)))
const group2sum = computed(() => group2.value.reduce((acc, item) => (acc += item.item.sum) || 0, <number>0))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (props.items.filter((item) => _unref(group1tag).includes(item.item.tag)).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "w-5/6" }, " Сумма вычетов по леченю, дорогим лекарствам, свое обучение, оценка квалицикации, добровольное страхование, добровольное медицинское страхование ", -1)),
            _createElementVNode("p", {
              class: _normalizeClass(["w-1/6", { 'text-red-600': group1sum.value > _unref(group1limit) }])
            }, [
              _createElementVNode("b", null, _toDisplayString(group1sum.value) + "/" + _toDisplayString(_unref(group1limit)), 1),
              _cache[0] || (_cache[0] = _createTextVNode(" рублей. "))
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (group2.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.yearToCalc >= 2024)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[2] || (_cache[2] = _createElementVNode("p", { class: "w-5/6" }, "Сумма вычета на обучение детей", -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", {
                    class: _normalizeClass({ 'text-red-600': group2.value.reduce((acc, item) => (acc += item.item.sum), <number>0) > 110000 })
                  }, [
                    _createElementVNode("b", null, _toDisplayString(group2sum.value) + "/110000 рублей ", 1)
                  ], 2)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[4] || (_cache[4] = _createElementVNode("p", { class: "w-5/6" }, "Сумма вычета на обучение каждого ребенка (" + _toDisplayString(50000) + ")", -1)),
                _createElementVNode("div", _hoisted_7, [
                  (group2.value.length === 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_8, "-"))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group2.value, (item) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: _unref(generatorId)(),
                      class: _normalizeClass({ 'text-red-600': item.item.sum > 50000 })
                    }, [
                      _createElementVNode("b", null, _toDisplayString(item.item.sum || 0) + "/" + _toDisplayString(50000), 1),
                      _cache[3] || (_cache[3] = _createTextVNode(" рублей "))
                    ], 2))
                  }), 128))
                ])
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})