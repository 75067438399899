import { NavigationGuardWithThis } from 'vue-router'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'

export const invoiceShowMiddleware: NavigationGuardWithThis<void> = async (to) => {
  await useInvoiceRepository()
    .getInvoice(to?.params?.id)
    .then((res) => {
      useAsyncInvoiceReload().initAsync(res)
      console.log('invoiceShowMiddleware', res)
    })
    .catch((err) => {
      console.log('invoiceShowMiddleware', err)
    })
}
