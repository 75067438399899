import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border mb-4" }
const _hoisted_2 = { class: "flex items-center" }

import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useRequestService } from '@/use/useRequestService'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useUserRepository } from '@/entityes/user/user.repository'
import { FetchError } from 'ofetch'
import { Search } from '@element-plus/icons-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Finder',
  setup(__props) {

const type = ref<'invoice' | 'user' | 'uid'>('invoice')
const searchString = ref<string>('')
const router = useRouter()
const { isProgress } = useRequestService()
const invoiceRepository = useInvoiceRepository()
const userRepository = useUserRepository()

async function send() {
  if (isProgress.value) return
  try {
    switch (type.value) {
      case 'invoice':
        const invoice = await invoiceRepository.getInvoice(searchString.value)
        router.push({
          name: 'invoice show',
          params: {
            id: invoice.id,
          },
        })
        break
      case 'user':
        const user_id = await userRepository.findUser(searchString.value)
        router.push({
          name: 'user show',
          params: {
            userId: user_id,
          },
        })
        break
      case 'uid':
        const res = await invoiceRepository.getInvoiceByUid(searchString.value)
        router.push({
          name: 'invoice show',
          params: {
            id: res,
          },
        })
        break
    }
  } catch (err) {
    if (err instanceof FetchError) {
      ElMessage.error({
        message: 'Данный запрос не найден',
      })
    } else {
      console.error(err)
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_UserFilled = _resolveComponent("UserFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_Document = _resolveComponent("Document")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(send, ["prevent"])
    }, [
      _createVNode(_component_el_input, {
        modelValue: searchString.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchString).value = $event)),
        placeholder: "Search",
        onKeyup: _withKeys(send, ["enter"]),
        class: "margin-v-xs"
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_select, {
          class: "mr-4",
          modelValue: type.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((type).value = $event)),
          placeholder: "-"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_option, {
              value: "user",
              label: `🙂 user`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  size: 14,
                  color: "#4b60cecc"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_UserFilled)
                  ]),
                  _: 1
                }),
                _cache[2] || (_cache[2] = _createElementVNode("span", null, " User", -1))
              ]),
              _: 1
            }),
            _createVNode(_component_el_option, {
              value: "invoice",
              label: `📋 invoice`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  size: 14,
                  color: "#4b60cecc"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Document)
                  ]),
                  _: 1
                }),
                _cache[3] || (_cache[3] = _createElementVNode("span", null, " Invoice", -1))
              ]),
              _: 1
            }),
            _createVNode(_component_el_option, {
              value: "uid",
              label: "📋 uid"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  size: 14,
                  color: "#4b60cecc"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Document)
                  ]),
                  _: 1
                }),
                _cache[4] || (_cache[4] = _createElementVNode("span", null, " uid", -1))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_el_button, {
          icon: _unref(Search),
          type: "primary",
          "native-type": "submit",
          loading: _unref(isProgress)
        }, null, 8, ["icon", "loading"])
      ])
    ], 32)
  ]))
}
}

})