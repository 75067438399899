import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '../../../assets/doc_icon.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mb-4 flex items-center cursor-point" }
const _hoisted_3 = {
  key: 1,
  class: "mr-2",
  src: _imports_0,
  alt: "doc_icon"
}

import useTextFilters from '@/plugins/TextFilters'
import { computed, ref } from 'vue'
import useFileDownload from '@/use/useFileDownload'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { ElNotification } from 'element-plus'
import { IFile } from '@/entityes/invoice/file/file.type'

interface IProps {
  isResult: boolean
  file: { id: number; name: string }
  type?: 'exante_virtual_files'
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadFileCard',
  props: {
    isResult: { type: Boolean },
    file: {},
    type: {}
  },
  setup(__props: any) {

const props = __props
const invoiceStore = useInvoiceStore()
const { subStringFileName } = useTextFilters()
const name = computed(() => subStringFileName(props.file.name, 20))
const isIndicating = ref(false)
const { downloadResultFileRequest, downloadFileRequest } = useFileDownload()

function request(file: IFile) {
  isIndicating.value = true
  if (props.isResult) {
    downloadResultFileRequest({
      fileId: file.id,
      name: `${file.name}`,
    })
      .catch((err) => {
        ElNotification({
          title: `Ошибка`,
          message: `скачивания файлов ${err}`,
          type: 'error',
        })
      })
      .finally(() => (isIndicating.value = false))
  } else {
    if (invoiceStore.invoice) {
      downloadFileRequest({
        invoiceId: invoiceStore.invoice?.id,
        fileId: file.id,
        fileType: props.type || '',
        name: `${file.name}`,
      })
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_ctx.file.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_tooltip, {
          effect: "dark",
          content: `Скачать ${_ctx.file?.name}`,
          disabled: _ctx.file?.name?.length < 20,
          placement: "right"
        }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (request(_ctx.file))),
              class: "clickable-text-link"
            }, [
              _createElementVNode("div", _hoisted_2, [
                (isIndicating.value)
                  ? (_openBlock(), _createBlock(_component_el_icon, {
                      key: 0,
                      size: "large",
                      class: "is-loading mr-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Loading)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("img", _hoisted_3)),
                _createTextVNode(" " + _toDisplayString(name.value), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["content", "disabled"])
      ]))
    : _createCommentVNode("", true)
}
}

})