<template>
  <el-popover
    :placement="screenLessValue(1200) ? 'top' : 'right'"
    :trigger="screenLessValue(1200) ? 'click' : 'hover'"
    class="margin-h--left-s"
    :width="width || 300"
  >
    <template #reference>
      <span>
        <el-icon :color="color" size="large" class="margin-h--left-s cursor-point"><QuestionFilled /></el-icon>
      </span>
    </template>
    <template #default>
      <slot></slot>
    </template>
  </el-popover>
</template>

<script lang="ts" setup>
import useWindowWidth from '@/use/useWindowWidth'

const { screenLessValue } = useWindowWidth()
interface IProps {
  color?: string
  mobile?: boolean
  width?: number
}

const props = defineProps<IProps>()
</script>

<style scoped lang="sass"></style>
