<script setup lang="ts">
import { useInvoiceMenu } from '@/controllers/useInvoiceMenu'
import usePolitic from '@/use/usePolitic'
import useWindowWidth from '@/use/useWindowWidth'
import { onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

interface Props {
  invoiceId: string | number
  showLoader: boolean
  invoicePayed: boolean
}
const props = defineProps<Props>()

const mobileStepperIsOpen = ref(false)

const openMobileMenu = () => {
  mobileStepperIsOpen.value = true
}
const closeMobileMenu = () => {
  mobileStepperIsOpen.value = false
}

const { screenLessValue } = useWindowWidth()
onBeforeMount(() => {
  mobileStepperIsOpen.value = !screenLessValue(992)
})
const route = useRoute()
const { isAdmin, isPartner } = usePolitic()
const { getInvoiceRoutes } = useInvoiceMenu()
</script>

<template>
  <div :class="isAdmin || isPartner ? 'left-menu-admin' : 'left-menu-slot'">
    <div class="padding--top_bot_10 bg-green margin-v-xs border" style="padding-left: 14px">
      <div class="flex-box flex-box--align-c">
        <el-icon :size="18" v-if="props.showLoader" class="is-loading margin-h--right-10" color="#FF9A9D">
          <Loading />
        </el-icon>
        <div v-else class="circle margin-h--right-10" :class="props.invoicePayed ? 'circle-success' : 'circle-fail'" />
        <h3 class="text-center">Заказ #{{ props.invoiceId }}</h3>
      </div>
    </div>
    <el-menu v-if="mobileStepperIsOpen" style="border: none" class="bg-gray">
      <router-link
        class="flex-box flex-box--align-c clickable margin-v-xs bg-gray cursor-point"
        v-for="item in getInvoiceRoutes"
        :class="route.name === item.tag ? 'bg-purple font-color--white font-weight--semi' : ''"
        :key="item.tag"
        :to="{
          name: item.tag,
          params: {
            id: invoiceId,
            good_id: item?.good_id?.value || '',
          },
        }"
      >
        <img
          :src="item?.icon"
          alt="icon"
          class="margin-h--right-10"
          width="18"
          :class="route.name === item.tag ? 'img-white' : 'img'"
        />
        {{ item.title }}
      </router-link>
    </el-menu>
  </div>
</template>

<style lang="sass" scoped>
.circle
  border: 1px solid transparent
  border-radius: 30px
  width: 14px
  height: 14px
  &-success
    background-color: #43d2cc
  &-fail
    background-color: #FF9A9D
.img
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(10%) contrast(200%)
  &-white
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(200%)
</style>
