import { computed, ref } from 'vue'
import { AdditionalItemValue } from './AdditionalItem'
import { ICollectionConfig, useCollection } from './useCollection'

export function useCollectionWithSituation<T extends AdditionalItemValue>(config: ICollectionConfig) {
  const collectionObj = useCollection<T>(config)
  const activeSituation = ref('')
  const itemsForActiveSituation = computed(() => {
    return collectionObj.collection.filter((item) => item.situationName === activeSituation.value)
  })
  const showItemDescibersWithHaveElements = computed(() => {
    return config.describers.filter((desc) => {
      return collectionObj.collection.some((item) => item.situationName === desc.tag)
    })
  })
  const availableSituation = computed(() => config.describers)
  const haveUnsaveInActiveSituation = computed<boolean>(() =>
    itemsForActiveSituation.value.some((item) => item.fieldWasEdited)
  )
  const haveUnsaveSituation = computed<boolean>(() => collectionObj.collection.some((item) => item.isEdit))
  const twoNdflDataIds = computed<string[]>(() => {
    return collectionObj.collection.reduce((acc, item) => {
      if (item.item.situation === 'two_ndfl_rus') {
        acc.push(item.localUniqueId)
      }
      if (item.item.situation === 'two_ndfl_source') {
        acc.push(item.localUniqueId)
      }
      return acc
    }, <string[]>[])
  })

  function availabilityCheckItemInSituationGroup() {
    if (itemsForActiveSituation.value.length === 0) {
      activeSituation.value = showItemDescibersWithHaveElements.value?.[0]?.tag || config.describers[0].tag
    }
  }

  function createNew(type: string) {
    collectionObj.createNew(type)
    activeSituation.value = type
  }

  function deleteById(localId: string): void {
    const hasFiles =
      collectionObj.collection.find((collection) => collection.localUniqueId === localId)?.item?.situation ===
      'two_ndfl_files'
    if (hasFiles) {
      twoNdflDataIds.value.forEach((i) => collectionObj.deleteById(i))
      collectionObj.deleteById(localId)
      availabilityCheckItemInSituationGroup()
    } else {
      collectionObj.deleteById(localId)
      availabilityCheckItemInSituationGroup()
    }
  }

  function deleteUnsaveBySituation(targetName: string) {
    const items = collectionObj.collection.filter((item) => item.situationName === targetName && item.isEdit)
    items.forEach((item) => deleteById(item.localUniqueId))
    availabilityCheckItemInSituationGroup()
  }

  function mergeValueFromServer(value: T[]) {
    collectionObj.mergeValueFromServer(value)
    availabilityCheckItemInSituationGroup()
  }

  return {
    ...collectionObj,
    activeSituation,
    itemsForActiveSituation,
    availableSituation,
    haveUnsaveInActiveSituation,
    haveUnsaveSituation,
    createNew,
    deleteById,
    deleteUnsaveBySituation,
    mergeValueFromServer,
    showItemDescibersWithHaveElements,
    describers: config.describers,
  }
}
