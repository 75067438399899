// import ReloadInvoiceMiddleware from '../middlewares/ReloadInvoiceMiddleware'
// import UserIsNotLoggedInMiddleware from '../middlewares/UserIsNotLoggedInMiddleware'
// const InvoiceView = () => import('@/views/account/invoice/InvoiceView')
// const TaxPlanCustomerView = () => import('@/views/account/invoice/TaxPlanCustomerView.vue')
// const TMS = () => import('@/04-Widgets/TMS/TMS.vue')
import TransfersMissingsShorts from '@/03-Pages/TMS/TransfersMissingsShorts.vue'
// const AddItems = () => import('@/components/Invoice/Declaration/AddItems.vue')
// const PersonalData = () => import('@/components/Invoice/Declaration/PersonalData.vue')
// const IncomesView = () => import('@/views/admin/IncomesView.vue')
// const LoadFile = () => import('@/views/account/invoice/LoadFile.vue')
import GoodsSelection from '@/views/account/GoodsSelection.vue'
import LoadFile from '@/03-Pages/LoadFilePage/LoadFile.vue'
import CheckStepperRouteComponent from './CheckStepperRouteComponent.vue'
import PersonalData from '@/components/Invoice/Declaration/PersonalData.vue'
import ResultFiles from '@/03-Pages/Result/ResultFiles.vue'
import PreAddIncomesDeduction from '@/components/Invoice/PreAddIncomesDeduction.vue'
import { isAuth } from '@/router/middlewares/isAuth'
import { AdditionalIncomePage } from '@/03-Pages/AdditionalIncomePage'
import { DeductionPage } from '@/03-Pages/DeductionPage'
// const GoodsResultFiles = () => import('@/views/account/invoice/GoodsResultFiles.vue')
// const PreAddIncomesDeduction = () => import('@/components/Invoice/PreAddIncomesDeduction.vue')
// import GoodsPreview from "@/04-Widgets/ResultStep/ui/GoodsPreview.vue";
import { invoiceShowMiddleware } from '@/router/middlewares/invoiceShowMiddleware'
export default [
  {
    name: 'invoice show',
    path: '',
    // component: CheckStepperRouteComponent,
    component: ResultFiles,
    props: true,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    name: 'file_in_invoice',
    path: 'file_in_invoice',
    // component: LoadFile,
    component: LoadFile,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    name: 'pre_add_deductions',
    path: 'pre_add_deductions',
    component: PreAddIncomesDeduction,
    // component: CheckStepperRouteComponent,
    props: true,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    name: 'good_select',
    path: 'good_select',
    component: GoodsSelection,
    // component: CheckStepperRouteComponent,
    props: true,
    meta: {
      middleware: [isAuth, invoiceShowMiddleware],
    },
  },
  // {
  //   name: 'goods result files',
  //   path: 'goods_result_files',
  //   component: GoodsPreview,
  //   // component: GoodsResultFiles,
  //   // component: CheckStepperRouteComponent,
  //   props: true,
  // },
  {
    name: 'tms',
    path: 'declarations/:good_id/tms',
    // component: CheckStepperRouteComponent,
    component: TransfersMissingsShorts,
    props: true,
    meta: {
      middleware: [isAuth],
    },
    // meta: {
    // middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
    // },
  },
  {
    name: 'add_incomes',
    path: 'declarations/:good_id/add_incomes',
    component: AdditionalIncomePage,
    props: true,
    meta: {
      // middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
    },
  },
  {
    name: 'deductions',
    path: 'declarations/:good_id/deductions',
    // component: CheckStepperRouteComponent,
    component: DeductionPage,
    props: true,
    meta: {
      // middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
    },
  },
  {
    name: 'personal_data',
    path: 'personal_data',
    component: PersonalData,
    props: true,
    meta: {
      // middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
    },
  },
  // {
  //   name: 'taxplan',
  //   path: 'taxplan/:good_id',
  //   component: TaxPlanCustomerView,
  //   props: true,
  //   meta: {
  //     // middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
  //   },
  // },
  {
    name: 'calculated_incomes',
    path: 'calculated_incomes',
    component: CheckStepperRouteComponent,
    props: true,
    meta: {
      // middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
    },
  },
]
