import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["onClick"]

import { computed } from 'vue'
import useFileDownload, { IDownloadFileConfig } from '@/use/useFileDownload'
import { useFileStore } from '@/entityes/invoice/file/file.store'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import AdminRenderFiles from '@/06-Entities/AdminInvoiceRemote/ui/AdminRenderFiles.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TBankChatFiles',
  setup(__props) {

const fileStore = useFileStore()
const invoiceStore = useInvoiceStore()
const { downloadFileRequest } = useFileDownload()
const invoiceId = computed(() => invoiceStore.invoice && invoiceStore.invoice?.id)
const TBankChatFiles = computed(() => fileStore.filesAdmin && fileStore.filesAdmin.files.filter((file) => file.tag === 'tinkoff_chat_files'))
const getFile = (config: IDownloadFileConfig, file: IDownloadFileConfig) => {
  file.isLoading = true
  downloadFileRequest({
    invoiceId: config.invoiceId,
    fileType: config.fileType,
    fileId: config.fileId,
    name: config.name,
  })?.finally(() => {
    file.isLoading = false
  })
}

return (_ctx: any,_cache: any) => {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createBlock(_component_el_scrollbar, { "max-height": "400" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(TBankChatFiles.value, (file) => {
          return (_openBlock(), _createElementBlock("div", {
            key: file.id,
            onClick: ($event: any) => (getFile({ invoiceId: invoiceId.value, fileType: file.tag, fileId: file.id, name: file.name }, file)),
            class: "mb-2 mr-2"
          }, [
            _createVNode(AdminRenderFiles, { file: file }, null, 8, ["file"])
          ], 8, _hoisted_2))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
}

})