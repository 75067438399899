import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "margin-top-m flex-box flex-box--center padding--20 bg-gray border" }

import { ElMessage, ElNotification } from 'element-plus'
import useCopyToClipboard from '@/07-Shared/model/useCopyToClipboard'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'

export interface IProps {
  id: string | number
  isAdmin: boolean
  tinkoffLink?: string
  isTinkoffPremium: boolean
}
export interface IEmits {
  (e: 'send', value: any): void
  (e: 'get-link', value: any): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FinishPaymentDialog',
  props: {
    id: {},
    isAdmin: { type: Boolean },
    tinkoffLink: {},
    isTinkoffPremium: { type: Boolean }
  },
  emits: ["send", "get-link"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const { invoice } = useInvoiceStore()
const { skipPayment } = useInvoiceRepository()
const { copyValue } = useCopyToClipboard()
const freeFinishRequest = () => {
  return new Promise((resolve, reject) => {
    skipPayment(props.id)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function copyRequest(link: string) {
  navigator.clipboard
    .writeText(link)
    .then(() => {
      ElNotification({
        title: `Ссылка`,
        message: 'скопирована в буфер обмена',
        type: 'success',
      })
    })
    .catch(() => {
      ElNotification({
        title: `Ошибка`,
        message: 'копирования в буфер обмена',
        type: 'error',
      })
    })
}
function finishPayment() {
  emit('send', {
    method: freeFinishRequest,
  })
}

function getLink() {
  emit('get-link', {
    method: copyRequest,
  })
}

function select() {
  emit('send', {
    method: () => {
      ElMessage({
        message: 'Товары выбраны',
        // closable: true,
      })
    },
  })
}

return (_ctx: any,_cache: any) => {
  const _component_GoodsFilled = _resolveComponent("GoodsFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_CopyDocument = _resolveComponent("CopyDocument")!
  const _component_CaretRight = _resolveComponent("CaretRight")!
  const _component_el_button_group = _resolveComponent("el-button-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button_group, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_tooltip, {
          class: "item",
          effect: "dark",
          content: "Сохранить товары в корзине",
          placement: "top-start"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              onClick: select,
              size: "large"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  class: "el-icon-goods-filled font-weight--extra margin-h--right-10",
                  size: 16
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_GoodsFilled)
                  ]),
                  _: 1
                }),
                _cache[1] || (_cache[1] = _createTextVNode(" Товары "))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.isTinkoffPremium)
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 0,
              class: "item",
              effect: "dark",
              content: "Скопировать ссылку на оплату",
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(copyValue)(_ctx.tinkoffLink || ''))),
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, {
                      class: "el-icon-copy-document font-weight--extra margin-h--right-10",
                      size: 16
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CopyDocument)
                      ]),
                      _: 1
                    }),
                    _cache[2] || (_cache[2] = _createTextVNode(" Ссылка TKassa "))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 1,
              class: "item",
              effect: "dark",
              content: "Скопировать ссылку на оплату",
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  onClick: getLink,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, {
                      class: "el-icon-copy-document font-weight--extra margin-h--right-10",
                      size: 16
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CopyDocument)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" Ссылка " + _toDisplayString(_ctx.isAdmin ? '' : 'на оплату'), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })),
        (_ctx.isAdmin)
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 2,
              class: "item",
              effect: "dark",
              content: "Пройти оплату",
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  loading: _unref(invoice)?.async,
                  type: "primary",
                  onClick: finishPayment,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(invoice)?.async ? 'Рассчитывается' : 'Далее') + " ", 1),
                    (!_unref(invoice)?.async)
                      ? (_openBlock(), _createBlock(_component_el_icon, {
                          key: 0,
                          class: "el-icon-caret-right font-weight--extra",
                          size: 16
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CaretRight)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})