import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex mr-12" }
const _hoisted_3 = { class: "flex flex-wrap" }
const _hoisted_4 = {
  style: {"width":"200px"},
  class: "mr-4"
}
const _hoisted_5 = { class: "mr-12" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_8 = { class: "font-weight--extra mb-2 font-color--deep-gray" }

import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useInvoiceRemoteAdminRequest from '@/06-Entities/AdminInvoiceRemote/api/invoice_remote.admin.request'
import { computed, ref } from 'vue'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import FormItem from '@/components/supportComponents/FormItem.vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { ElNotification } from 'element-plus'
import { useForm, useFormValues } from 'vee-validate'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import {useAsyncInvoiceReload} from "@/entityes/invoice/useAsyncInvoiceReload";
import { SuccessFilled, Refresh, CircleCloseFilled, Loading } from '@element-plus/icons-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminRemote',
  setup(__props) {

const invoiceStore = useInvoiceStore()
const { recalculation, assignInvoice, toggleBlockInvoice } = useInvoiceRemoteAdminRequest()
const { getInvoice } = useInvoiceRepository()
const asyncInvoice = computed(() => invoiceStore.invoice && invoiceStore.invoice.status.async)
const blocked = computed(() => invoiceStore.invoice && invoiceStore.invoice.status.blocked)
const schema = toTypedSchema(
  yup.object({
    email: yup.string().required().email(),
  })
)
const { handleSubmit } = useForm({
  validationSchema: schema,
})
const formValues = useFormValues()
const isLoading = ref(false)
const { initAsync } = useAsyncInvoiceReload()

function recalculateInvoice() {
  isLoading.value = true
  recalculation(invoiceStore.invoice?.id).then(() => {
    getInvoice(invoiceStore.invoice?.id).then(() => {
      ElNotification({
        title: 'Успешно',
        message: `отправлен на пересчет заказ ${invoiceStore.invoice.id}`,
        type: 'success'
      })
      initAsync(invoiceStore.invoice)
    })
  }).catch((err) => {
    ElNotification({
      title: 'Ошибка',
      message: `при пересчете. Попробуйте позже. ${err}`,
      type: 'error'
    })
  }).finally(() => isLoading.value = false)
}
function updateBlock(val: boolean) {
  toggleBlockInvoice(invoiceStore.invoice.id, { blocked: val }).then((res) => {
    getInvoice(res.id)
  })
}
const assignInvoiceToUser = handleSubmit((form) => {
  isLoading.value = true
  if (invoiceStore.invoice){
    assignInvoice(invoiceStore.invoice?.id, form).then((res: IInvoice) => {
      ElNotification({
        title: 'Успещно',
        message: `заказ ${res.id} привязан к пользователю ${res.email}`,
        type: 'success'
      })
      getInvoice(res.id)
    }).catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `во время привзяки заказа к пользователю ${formValues.value.email}. Попробуйте позже. ${err}`,
        type: 'error'
      })
    }).finally(() => isLoading.value = false)
  }
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "font-weight--extra font-color--deep-gray mb-2" }, "Привязать", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_form, {
              onSubmit: _withModifiers(_unref(assignInvoice), ["prevent"]),
              "validation-schema": _unref(schema)
            }, {
              default: _withCtx(() => [
                _createVNode(FormItem, {
                  name: "email",
                  label: ''
                }, {
                  default: _withCtx(({ value, onBlur, onInput }) => [
                    _createVNode(_component_el_input, {
                      "model-value": value,
                      type: "text",
                      name: "email",
                      size: "small",
                      onBlur: onBlur,
                      onInput: onInput,
                      placeholder: "example@ya.ru"
                    }, null, 8, ["model-value", "onBlur", "onInput"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onSubmit", "validation-schema"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_el_button, {
              size: "small",
              type: "primary",
              loading: isLoading.value,
              disabled: isLoading.value || asyncInvoice.value,
              onClick: _unref(assignInvoiceToUser)
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Привязать")
              ])),
              _: 1
            }, 8, ["loading", "disabled", "onClick"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "font-weight--extra mb-2 font-color--deep-gray" }, "Пересчитать", -1)),
      (!asyncInvoice.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_el_tooltip, {
              class: "box-item",
              effect: "dark",
              content: "На основании загруженных файлов",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  size: "small",
                  loading: isLoading.value,
                  type: "primary",
                  onClick: recalculateInvoice,
                  disabled: isLoading.value || asyncInvoice.value,
                  icon: _unref(Refresh)
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Пересчитать ")
                  ])),
                  _: 1
                }, 8, ["loading", "disabled", "icon"])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (asyncInvoice.value)
              ? (_openBlock(), _createBlock(_component_el_icon, {
                  key: 0,
                  color: "#FF9A9D",
                  class: "is-loading mr-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Loading))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-color--red" }, "расчет", -1))
          ]))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("h4", _hoisted_8, [
        _cache[7] || (_cache[7] = _createTextVNode(" Блокировка: ")),
        _createElementVNode("span", {
          class: _normalizeClass(["font-weight--extra", blocked.value ? 'font-color--red' : 'font-color--green'])
        }, _toDisplayString(blocked.value ? 'да' : 'нет'), 3)
      ]),
      (!blocked.value)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            class: "box-item",
            effect: "dark",
            content: "Закрыть скачивание документов",
            placement: "top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "small",
                loading: isLoading.value,
                type: "danger",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (updateBlock(true))),
                disabled: isLoading.value || asyncInvoice.value,
                icon: _unref(CircleCloseFilled)
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Заблокировать ")
                ])),
                _: 1
              }, 8, ["loading", "disabled", "icon"])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 1,
            class: "box-item",
            effect: "dark",
            content: "Открыть скачивание документов",
            placement: "top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "small",
                loading: isLoading.value,
                type: "success",
                icon: _unref(SuccessFilled),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (updateBlock(false))),
                disabled: isLoading.value || asyncInvoice.value
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" Разблокировать ")
                ])),
                _: 1
              }, 8, ["loading", "icon", "disabled"])
            ]),
            _: 1
          }))
    ])
  ]))
}
}

})