import invoices from './Account/invoices'
// import CheckExanteTokenInAccount from './middlewares/CheckExanteTokenInAccount'
// import UserIsNotLoggedInMiddleware from './middlewares/UserIsNotLoggedInMiddleware'
import { middlewarePipeline } from './middlewares/middelwarePipeline'
import { isAuth } from './middlewares/isAuth'
import { RouteRecordRaw } from 'vue-router'
import InvoiceView from "@/views/account/invoice/InvoiceView.vue";
const AccountHome = () => import('../views/account/AccountHome.vue')
const CustomerShow = () => import('../views/account/CustomerShow.vue')

const routes: RouteRecordRaw[] = [
  // ...invoices,
  {
    name: 'account home',
    path: '',
    component: AccountHome,
    meta: {
      middleware: [isAuth],
    },
    // meta: {
    //   middleware: [CheckExanteTokenInAccount, UserIsNotLoggedInMiddleware],
    // },
  },
  {
    path: 'invoices/:id',
    // name: 'invoice show',
    component: InvoiceView,
    children: [...invoices],
    // meta: {
    //   middleware: [isAuth],
    // },
  },
  {
    name: 'customer show',
    path: 'user_show/:id',
    component: CustomerShow,
    props: true,
    meta: {
      middleware: [isAuth],
    },
    // meta: {
    //   middleware: [CheckExanteTokenInAccount, UserIsNotLoggedInMiddleware],
    // },
  },
]
export default routes
