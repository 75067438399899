import { computed, reactive, ref } from 'vue'

export default function useTypeWriter(text: string | string[]){
  const speedForChar = 100
  const speedForWord = 2000
  const renderString = ref('')
  const counter = ref(0)
  const stringArr = reactive(Array.isArray(text) ? [...text] : [text])
  const index = ref(0)

  const typeWriter = () => {
    if (counter.value < stringArr[index.value].length) {
      renderString.value += stringArr[index.value].charAt(counter.value)
      counter.value++
      setTimeout(() => {
        typeWriter()
      }, speedForChar)
    } else {
      setTimeout(() => {
        counter.value = 0
        renderString.value = ''
        index.value += 1
        if (index.value >= stringArr.length) index.value = 0
        typeWriter()
      }, speedForWord)
    }
  }
  setTimeout(() => {
    typeWriter()
  }, speedForChar)

  const renderStringUp = computed(() => {
    return renderString.value.toString()
  })

  return {
    renderString,
    stringArr,
    renderStringUp,
  }
}
