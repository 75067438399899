import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-weight--extra" }
const _hoisted_2 = {
  key: 0,
  class: "font-color--green"
}
const _hoisted_3 = {
  key: 1,
  class: "font-color--red"
}

import { computed, UnwrapNestedRefs } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import { LiveInsureContractType } from '../../model/LiveInsureContractType'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { SocialRefundsDescriber } from '../../model/SocialRefundsDescriber'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
}

export default /*@__PURE__*/_defineComponent({
  __name: 'SocialCard',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props

const { screenLessValue } = useWindowWidth()
const contracts = SocialRefundsDescriber().tagsList
const tag = computed(
  () =>
    contracts.find((item) => item.tag === props.item.item.tag) || {
      tag: 'other',
      label: 'Не выбрано',
    }
)
const contractType = computed(() => {
  return LiveInsureContractType.find((item) => item.id === props.item.item?.other_attributes?.contract_type)
})

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { align: "middle" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : ''),
        lg: 10
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "Тип", -1)),
          _createElementVNode("p", null, _toDisplayString(tag.value.tag === 'live_insure' ? contractType.value?.title : tag.value.label), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : ''),
        lg: 4
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "Сумма", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item?.sum || 0) + " руб.", 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : ''),
        lg: 3
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, [
            (_ctx.item?.valueFromServer && !_ctx.item.fieldWasEdited)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Вычет сохранен"))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "Вычет не сохранен"))
          ])
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: { offset: 0, span: 24 },
        md: { offset: 0, span: 24 },
        lg: { offset: 4, span: 3 },
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-m' : '')
      }, {
        default: _withCtx(() => [
          _createVNode(EditSaveCardButtonGroup, {
            onToggle: _ctx.item.toggleEdit,
            onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove'))),
            "mobile-screen": _unref(screenLessValue)(1200)
          }, null, 8, ["onToggle", "mobile-screen"])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}
}

})