<script lang="ts" setup>
import { computed, Ref, ref } from 'vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import usePolitic from '@/use/usePolitic'
import { useGetResultFilesRequest } from '@/05-Features/ResultFiles/api/getResultFiles.request'
import { ElNotification } from 'element-plus'
import DownloadFileCard from '@/06-Entities/File/ui/DownloadFileCard.vue'
import { IFile } from '@/entityes/invoice/file/file.type'

interface IProps {
  goodName: string
  goodId: string | number
}

const props = defineProps<IProps>()
const invoiceStore = useInvoiceStore()
const { getDeclarationResultFiles, getCfrResulFiles, getTaxPlanResultFiles } = useGetResultFilesRequest()
const { isAdmin } = usePolitic()
const timer = ref<number | null>(null)
const resultFiles = ref<IFile[]>([])
const resultsBlocked = computed(() => invoiceStore?.invoice?.status?.blocked)
const methodsForGoods = {
  Declaration: getDeclarationResultFiles,
  Cfr: getCfrResulFiles,
  TaxPlan: getTaxPlanResultFiles,
}
if (props.goodName !== 'Cfr') {
  methodsForGoods[props.goodName as 'Cfr' | 'Declaration' | 'TaxPlan'](props.goodId)
    .then((res) => {
      if (res?.length > 0) {
        resultFiles.value.splice(0, resultFiles.value.length, ...res)
      } else {
        timer.value = setTimeout(() => {
          methodsForGoods[props.goodName as 'Cfr' | 'Declaration' | 'TaxPlan'](props.goodId)
        }, 5000)
      }
    })
    .catch((err: any) => {
      ElNotification({
        title: `Ошибка`,
        message: `получения файлов ${err}`,
        type: 'error',
      })
    })
}
</script>

<template>
  <div class="pl-4" v-if="resultFiles.length > 0">
    <div class="mb-4">
      <div v-for="file in resultFiles" :key="file?.id">
        <download-file-card
          v-loading="methodsForGoods[props.goodName as 'Cfr' | 'Declaration' | 'TaxPlan'].indicator.value === true"
          :file="file"
          is-result
        />
      </div>
    </div>
  </div>
  <div v-if="resultsBlocked && !isAdmin">
    <p class="mb-4">
      <el-icon color="#FF9A9D"><WarningFilled /></el-icon>
      Скачивание итоговых документов заблокировано, так как заказ находится на стадии проверки.
    </p>
    <p>
      <el-icon color="#43d2cc"><SuccessFilled /></el-icon>
      Однако вы можете посмотреть предварительный расчет.
    </p>
  </div>
</template>
