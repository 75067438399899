import { AdditionalIncome, AdditionalItem } from '@/06-Entities/IncomeAndRefund'
import { IFormEmitterHelpers } from '@/plugins/formEmitterPlugin'
import { inject, UnwrapNestedRefs, watch } from 'vue'

export function setTouchedHelper(item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>) {
  const emitterHelpers = inject<IFormEmitterHelpers>('form-emitter-helpers')
  function setT() {
    emitterHelpers?.setFieldTouched()
  }
  watch(item, (value) => {
    if (value.touched) {
      setT()
    }
  })
  if (item.touched) {
    setT()
  }
}
