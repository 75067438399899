<script lang="ts" setup>
import { SocialRefundsDescriber } from '@/04-Widgets/DeductionWidget/model/SocialRefundsDescriber'
import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import { generatorId } from '@/07-Shared/lib/generatorId'
import { computed, UnwrapNestedRefs } from 'vue'

interface IProps {
  yearToCalc: number
  items: UnwrapNestedRefs<AdditionalItem<Deduction>>[]
}
const props = defineProps<IProps>()

const tags = SocialRefundsDescriber().tagsList
const group1tag = tags.filter((item) => item.limitGroup === 1).map((item) => item.tag)
const group1limit = props.yearToCalc >= 2024 ? 150000 : 120000
const group1sum = computed(() =>
  props.items
    .filter((item) => group1tag.includes(item.item.tag))
    .reduce((acc, item) => {
      return (acc += item.item.sum) || 0
    }, <number>0)
)
const group2tag = tags.filter((item) => item.limitGroup === 2).map((item) => item.tag)
const group2 = computed(() => props.items.filter((item) => group2tag.includes(item.item.tag)))
const group2sum = computed(() => group2.value.reduce((acc, item) => (acc += item.item.sum) || 0, <number>0))
</script>
<template>
  <div v-if="props.items.filter((item) => group1tag.includes(item.item.tag)).length > 0">
    <div class="flex justify-between">
      <p class="w-5/6">
        Сумма вычетов по леченю, дорогим лекарствам, свое обучение, оценка квалицикации, добровольное страхование,
        добровольное медицинское страхование
      </p>
      <p class="w-1/6" :class="{ 'text-red-600': group1sum > group1limit }">
        <b>{{ group1sum }}/{{ group1limit }}</b> рублей.
      </p>
    </div>
  </div>
  <div v-if="group2.length > 0">
    <div v-if="yearToCalc >= 2024">
      <p class="w-5/6">Сумма вычета на обучение детей</p>
      <div class="w-1/6">
        <p :class="{ 'text-red-600': group2.reduce((acc, item) => (acc += item.item.sum), <number>0) > 110000 }">
          <b> {{ group2sum }}/110000 рублей </b>
        </p>
      </div>
    </div>
    <div v-else class="flex justify-between">
      <p class="w-5/6">Сумма вычета на обучение каждого ребенка ({{ 50000 }})</p>
      <div class="w-1/6">
        <p v-if="group2.length === 0">-</p>
        <p v-for="item in group2" :key="generatorId()" :class="{ 'text-red-600': item.item.sum > 50000 }">
          <b>{{ item.item.sum || 0 }}/{{ 50000 }}</b> рублей
        </p>
      </div>
    </div>
  </div>
</template>
<style lang="sass"></style>
