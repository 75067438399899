import { useUserStore } from '@/entityes/user/userStore'
import { computed, reactive, ref } from 'vue'

export default function usePolitic(initStore = null) {
  const userStore = useUserStore()
  const roles = reactive(['customer', 'affiliate', 'manager', 'partner', 'admin'])

  const isAdmin = computed(() => userStore.user?.role === 'admin')
  const isCustomer = computed(() => userStore.user?.role === 'customer')
  const isPartner = computed(() => userStore.user?.role === 'partner')
  const isAffiliate = computed(() => userStore.user?.role === 'affiliate')
  const isAllRole = ref(true)
  return {
    isAdmin,
    isCustomer,
    isAllRole,
    isPartner,
    roles,
    isAffiliate,
  }
}
