import Rental from '../ui/Rental.vue'
import TransportSale from '../ui/TransportSale.vue'
import Dividends from '../ui/Dividends.vue'
import PropertySale from '../ui/PropertySale.vue'
import rentalIcon from '@/assets/pre_add_incomes_icons/rental.svg'
import carIcon from '@/assets/pre_add_incomes_icons/car.svg'
import divIcon from '@/assets/pre_add_incomes_icons/foreign_dividend.svg'
import propertySaleIcon from '@/assets/pre_add_incomes_icons/property_sale.svg'
import unknownIcon from '@/assets/pre_add_incomes_icons/unknown.svg'
import rightsIcon from '@/assets/pre_add_incomes_icons/assignment_of_rights.svg'
import ndflIcon from '@/assets/pre_add_incomes_icons/two_ndfl_files.svg'
import { IAddItem } from '@/06-Entities/IncomeAndRefund/model/IAddItem.type'
import RentalCard from '../ui/cards/RentalCard.vue'
import BaseCard from '../ui/cards/BaseCard.vue'
import AssignmentOfRights from '../ui/AssignmentOfRights.vue'
import TwoNdflFiles from '../ui/TwoNdflFiles.vue'
import TwoNdflCard from '../ui/cards/TwoNdflCard.vue'
import UnknownCard from '../ui/cards/UnknownCard.vue'
import Unknown from '../ui/Unknown.vue'
import ParsedTwoNdflCard from '../ui/cards/ParsedTwoNdflCard.vue'

export const IncomeDescriber: IAddItem[] = [
  {
    name: 'Сдача недвижимости в аренду',
    tag: 'rental',
    component: Rental,
    cardComponent: RentalCard,
    icon: rentalIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },
  {
    name: 'Получение дивидендов',
    tag: 'foreign_dividend',
    component: Dividends,
    cardComponent: BaseCard,
    icon: divIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },
  {
    name: 'Продажа транспорта',
    tag: 'sale_transport',
    component: TransportSale,
    cardComponent: BaseCard,
    icon: carIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },
  {
    name: 'Продажа недвижимости',
    tag: 'property_sale',
    component: PropertySale,
    cardComponent: BaseCard,
    icon: propertySaleIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },

  {
    name: 'Переуступка права по ДДУ',
    tag: 'assignment_of_rights',
    component: AssignmentOfRights,
    cardComponent: BaseCard,
    icon: rightsIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },
  {
    name: 'Иной вид дохода',
    tag: 'unknown',
    component: Unknown,
    cardComponent: UnknownCard,
    icon: unknownIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },

  {
    name: 'Загрузка 2-НДФЛ',
    tag: 'two_ndfl_files',
    component: TwoNdflFiles,
    cardComponent: TwoNdflCard,
    // help: null,
    icon: ndflIcon,
    meta: {
      onlyOne: true,
      show_pre: true,
    },
  },

  {
    name: 'Доходы из 2-НДФЛ',
    tag: 'two_ndfl_source',
    component: null,
    cardComponent: ParsedTwoNdflCard,
    // help: null,
    icon: ndflIcon,
    meta: {
      onlyOne: true,
      show: false,
      show_pre: false,
    },
  },
]
