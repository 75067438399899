<script lang="ts" setup>
import useWindowWidth from '@/use/useWindowWidth'
import { defineAsyncComponent, reactive, ref } from 'vue'
import { useGetOnlineCfrInfo } from '@/05-Features/CfrOnlineResults/api/getOnlineCfrInfo.request'
import { ElNotification } from 'element-plus'
import { ICfrOnline } from '@/entityes/invoice/cfr/cfr.type'
import { IFile } from '@/06-Entities/File'
import DownloadFileCard from '@/06-Entities/File/ui/DownloadFileCard.vue'
const CfrOnlineAccCard = defineAsyncComponent(() => import('@/06-Entities/ResultFiles/ui/CfrOnlineAccCard.vue'))
interface IProps {
  goodId: string | number
}
const props = defineProps<IProps>()
const { getCfrOnlineInfo } = useGetOnlineCfrInfo()
const cfrAccounts = ref<ICfrOnline[]>([])
const files = reactive<IFile[]>([])
const { screenLessValue } = useWindowWidth()

getCfrOnlineInfo(props.goodId)
  .then((res) => {
    cfrAccounts.value = [...res.data]
    files.splice(0, files.length, ...res.result_files)
  })
  .catch((err) => {
    ElNotification({
      title: `Ошибка`,
      message: `получения данных для онлайн ОДС ${err}`,
      type: 'error',
    })
  })
</script>

<template>
  <h3 class="mb-4">Данные для заполнения ОДС на сайте налоговой</h3>
  <div class="mb-8">
    <i class="el-icon-link font-weight--extra font-color--purple"></i>
    <a href="https://help.ndfl.guru/i8" class="clickable-text-link" target="_blank"
      >Инструкция для подачи ОДС онлайн через личный кабинет ФНС</a
    >
  </div>
  <div v-if="files.length > 0">
    <el-collapse class="mb-4">
      <el-collapse-item>
        <template #title>
          <p class="p-4 font-bold">
            <span>Итоговые файлы</span>
          </p>
        </template>
        <div>
          <div v-for="file in files" :key="file?.id">
            <download-file-card :file="file" is-result />
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  <div v-for="item in cfrAccounts" :key="item.id">
    <el-collapse class="mb-4">
      <el-collapse-item>
        <template #title>
          <p class="p-4 font-bold">
            <span>Счет&nbsp;</span><span>{{ item.account_number }}</span>
          </p>
        </template>
        <div :class="screenLessValue(767) ? '' : 'padding--20'">
          <!-- <cfr-online-info-card :broker-info="item" /> -->
          <h3 class="mb-8">Информация о движении средств и стоимости иных финансовых активов</h3>
          <cfr-online-acc-card
            v-for="accountInfo in item?.broker_accounts"
            :key="accountInfo.id"
            :broker-account="accountInfo"
          />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<style scoped lang="sass"></style>
