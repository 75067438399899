import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onUpdated, ref, watch } from 'vue'
interface IProps {
  label?: string
  value: boolean | undefined
}
interface IEmits {
  (e: 'update:value', value: boolean): void
  (e: 'change', value: boolean): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Checkbox',
  props: {
    label: {},
    value: { type: Boolean }
  },
  emits: ["update:value", "change"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const checked = ref(props.value || false)
watch(checked, (val) => {
  emit('update:value', val)
  emit('change', val)
})
onUpdated(() => {
  if (props.value === false) checked.value = false
  if (props.value === true) checked.value = true
})

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_checkbox, {
      modelValue: checked.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checked).value = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["modelValue"])
  ]))
}
}

})