import { useRequestService } from '@/use/useRequestService'
import { useFileStore } from './file.store'
import { IAdminFileInfoList } from './file.type'
import { ref } from 'vue'

export function useFileAdminRepository() {
  const fileStore = useFileStore()
  const { fetch } = useRequestService()
  function getAllFilesInInvoice(id: number | string, page: number | string) {
    return new Promise<IAdminFileInfoList>((resolve, reject) => {
      getAllFilesInInvoice.indicator.value = true
      fetch<IAdminFileInfoList>(`/admin/invoices/${id}/all_broker_files?page=${page}`)
        .then((res) => {
          fileStore.filesAdmin = {
            ...res,
            files: res.files.map((item) => ({ ...item, isLoading: false })),
          }
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          getAllFilesInInvoice.indicator.value = false
        })
    })
  }
  getAllFilesInInvoice.indicator = ref(false)

  return {
    getAllFilesInInvoice,
  }
}
