import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "px-4 pt-4" }
const _hoisted_2 = { class: "flex mb-4" }
const _hoisted_3 = { class: "mr-4" }
const _hoisted_4 = { class: "flex items-center mb-1" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "mr-4" }
const _hoisted_7 = { class: "flex items-center mb-1" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "flex mb-1 items-center" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "flex items-center flex-wrap mb-4" }
const _hoisted_12 = { class: "mr-4" }
const _hoisted_13 = { class: "flex items-center mb-1" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "mr-4" }
const _hoisted_16 = { class: "flex items-center mb-1" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "flex items-center mb-1" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "flex items-center flex-wrap mb-4" }
const _hoisted_21 = { class: "mr-8" }
const _hoisted_22 = { class: "flex items-center mb-1" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { class: "flex items-center mb-1" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { key: 0 }

import currency from '@/describers/CurrencyDescriber'
import useCountryDescriber from '@/describers/useCountryDescriber'
import { ref, watch, nextTick, computed, UnwrapNestedRefs } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { string as str, object as obj, number as numb } from 'yup'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import Checkbox from '@/components/supportComponents/Checkbox.vue'
import { cloneDeep } from 'lodash'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { setTouchedHelper } from '../lib/setTouchedHelper'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber: any
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Dividends',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {}
  },
  emits: ["update-item"],
  setup(__props: any, { emit: __emit }) {

// import newIncomeForm from '@/04-Widgets/AdditionelIncome/ui/incomeForm'
const { screenLessValue } = useWindowWidth()
const props = __props
const emit = __emit

setTouchedHelper(props.item)
const mainField = {
  currency: str().length(3).required('Это поле обязательно для заполнения'),
  date: str().required('Это поле обязательно для заполнения').checkYearValue(props.yearToCalc),
  amount: str().required('Это поле обязательно для заполнения'),
}
const paid_tax = ref(props.item.item?.other_attributes?.paid_tax || '')
const other_attributes = computed(() => {
  const res = {
    name_income: str().required('Это поле обязательно для заполнения'),
    oksm: str().required('Это поле обязательно для заполнения'),
    oksm_broker: str().required('Это поле обязательно для заполнения'),
    paid_tax: str(),
    paid_tax_date: str(),
  }
  if (paid_tax.value !== '') {
    res.paid_tax = str().required('Это поле обязательно для заполнения')
    res.paid_tax_date = str().required('Это поле обязательно для заполнения').checkYearValue(props.yearToCalc)
  }
  return res
})

const schema = computed(() => {
  return toTypedSchema(obj({ ...mainField, other_attributes: obj({ ...other_attributes.value }) }))
})
const sameDateTax = ref(false)

const { values, setFieldTouched, validate, setFieldValue } = useForm({
  validationSchema: schema,
  validateOnMount: true,
  initialValues: {
    ...props.item.item,
  },
})

if (props.item.valueFromServer) {
  if (values.other_attributes?.paid_tax_date === values.date) sameDateTax.value = true
}
watch(paid_tax, (val) => {
  if (val !== '') setFieldTouched('other_attributes.paid_tax_date', true)
})
watch(sameDateTax, (val) => {
  if (val) {
    setFieldValue('other_attributes.paid_tax_date', values.date)
  } else {
    setFieldValue('other_attributes.paid_tax_date', '')
  }
})

async function mergeIncomeResult() {
  const res = {
    ...cloneDeep(values),
    valid: (await validate()).valid,
  }
  emit('update-item', res)
}
watch(values, () => {
  mergeIncomeResult()
})
mergeIncomeResult()

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-semibold" }, "Тикер", -1)),
          _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                style: {"word-break":"break-word"},
                innerHTML: _ctx.helpDescriber.name_income
              }, null, 8, _hoisted_5)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, {
          class: "rb-margin-m",
          name: "other_attributes.name_income"
        }, {
          default: _withCtx(({ value, onInput, onBlur }) => [
            _createVNode(_component_el_input, {
              "model-value": value,
              "onUpdate:modelValue": onInput,
              onBlur: onBlur
            }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-semibold" }, "Страна источника выплаты", -1)),
          _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                style: {"word-break":"break-word"},
                innerHTML: _ctx.helpDescriber.oksm
              }, null, 8, _hoisted_8)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, {
          class: "rb-margin-m",
          name: "other_attributes.oksm"
        }, {
          default: _withCtx(({ value, onInput, onBlur }) => [
            _createVNode(_component_el_select, {
              "model-value": value,
              "onUpdate:modelValue": onInput,
              onBlur: onBlur,
              filterable: "",
              "fit-input-width": "",
              placeholder: "Выберите страну"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.code,
                    value: item.code,
                    label: item.shortname
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_9, [
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "font-semibold" }, "Страна зачисления выплаты", -1)),
          _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                style: {"word-break":"break-word"},
                innerHTML: _ctx.helpDescriber.oksm_broker
              }, null, 8, _hoisted_10)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, { name: "other_attributes.oksm_broker" }, {
          default: _withCtx(({ value, onBlur, onInput }) => [
            _createVNode(_component_el_select, {
              "model-value": value,
              "onUpdate:modelValue": onInput,
              onBlur: onBlur,
              filterable: "",
              "fit-input-width": "",
              placeholder: "Выберите страну"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.code,
                    value: item.code,
                    label: item.shortname
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _cache[4] || (_cache[4] = _createElementVNode("p", { class: "font-semibold" }, "Сумма", -1)),
          _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                style: {"word-break":"break-word"},
                innerHTML: _ctx.helpDescriber.amount
              }, null, 8, _hoisted_14)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, {
          class: "rb-margin-m",
          name: "amount"
        }, {
          default: _withCtx(({ value, onInput, onBlur }) => [
            _createVNode(_component_el_input, {
              "model-value": value,
              onBlur: onBlur,
              onInput: onInput
            }, null, 8, ["model-value", "onBlur", "onInput"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "font-semibold" }, "Валюта", -1)),
          _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                style: {"word-break":"break-word"},
                innerHTML: _ctx.helpDescriber.currency
              }, null, 8, _hoisted_17)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, { name: "currency" }, {
          default: _withCtx(({ value, onBlur, onInput }) => [
            _createVNode(_component_el_select, {
              class: "min-w-[220px]",
              "model-value": value,
              "onUpdate:modelValue": onInput,
              onBlur: onBlur,
              filterable: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currency), (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.code,
                    value: item.code,
                    label: item.code
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_18, [
          _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "font-semibold" }, "Дата выплаты дивидендов", -1)),
          _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                style: {"word-break":"break-word"},
                innerHTML: _ctx.helpDescriber.date
              }, null, 8, _hoisted_19)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, {
          class: "rb-margin-m",
          name: "date"
        }, {
          default: _withCtx(({ value, onInput, onBlur }) => [
            _createVNode(_component_el_date_picker, {
              "model-value": value,
              "onUpdate:modelValue": onInput,
              type: "date",
              placeholder: "Выберите дату",
              format: "DD.MM.YYYY",
              "value-format": "DD.MM.YYYY",
              onBlur: onBlur
            }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "font-semibold" }, "Сумма налога в валюте", -1)),
          _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                style: {"word-break":"break-word"},
                innerHTML: _ctx.helpDescriber.paid_tax_dividend
              }, null, 8, _hoisted_23)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, { name: "other_attributes.paid_tax" }, {
          default: _withCtx(({ value, onInput, onBlur }) => [
            _createVNode(_component_el_input, {
              "model-value": value,
              "onUpdate:modelValue": (val) => {onInput(val); paid_tax.value = val},
              onBlur: onBlur
            }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_24, [
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "font-semibold" }, "Дата выплаты налога", -1)),
          _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                style: {"word-break":"break-word"},
                innerHTML: _ctx.helpDescriber.paid_tax_dividend_date
              }, null, 8, _hoisted_25)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, { name: "other_attributes.paid_tax_date" }, {
          default: _withCtx(({ value, onInput, onBlur }) => [
            _createVNode(_component_el_date_picker, {
              "model-value": value,
              "onUpdate:modelValue": onInput,
              onBlur: onBlur,
              type: "date",
              placeholder: "Выберите дату",
              format: "DD.MM.YYYY",
              "value-format": "DD.MM.YYYY"
            }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(Checkbox, {
      value: sameDateTax.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((sameDateTax).value = $event)),
      class: "mb-4"
    }, {
      default: _withCtx(() => [
        _cache[9] || (_cache[9] = _createTextVNode(" Налог уплачен в дату")),
        (_unref(screenLessValue)(469))
          ? (_openBlock(), _createElementBlock("br", _hoisted_26))
          : _createCommentVNode("", true),
        _cache[10] || (_cache[10] = _createTextVNode(" получения дивидендов "))
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}
}

})