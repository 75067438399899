import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/delete_icon.svg'


const _hoisted_1 = { class: "mb-10" }
const _hoisted_2 = { class: "step-box" }
const _hoisted_3 = { class: "p-4 bg-gray" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "p-4 bg-gray" }
const _hoisted_8 = { class: "flex items-center p-4" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "font-bold" }
const _hoisted_11 = { class: "flex items-center" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "sticky-basket step-box" }
const _hoisted_14 = { class: "p-4" }
const _hoisted_15 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_16 = { class: "padding--right-20" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "flex items-center justify-between" }
const _hoisted_19 = { class: "flex items-center" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { style: {"hyphens":"none"} }
const _hoisted_22 = { class: "flex items-center" }
const _hoisted_23 = { class: "font-weight--semi mr-4" }
const _hoisted_24 = ["onClick"]

import { useForm, useFormValues } from 'vee-validate'
import { computed } from 'vue'
import ShowOnlyLoadedFiles from '@/components/Files/ShowOnlyLoadedFiles'
import { useRouter } from 'vue-router'
import OnBoardingDescriber from '@/describers/OnBoardingDescriber'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import FormItem from '@/components/supportComponents/FormItem.vue'
import { ElNotification } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'

export interface IProps {
  id: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PreAddIncomesDeduction',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props
const invoiceStore = useInvoiceStore()
const { createPreAddDeductions } = useInvoiceRepository()
const router = useRouter()
const { incomesDeductionsDescribers: describer, incomes, refunds } = AddIncomesRefundsDescriber()
const { preAddOnBoarding } = OnBoardingDescriber()
const hasErrors = computed(() => Object.keys(invoiceStore.invoice?.warning_and_error?.errors || {}).length !== 0)
const form = computed(() => {
  return describer
    .map((i: { tag: string }) => i.tag)
    .reduce((acc: { [key: string]: number }, tag: string) => {
      if (invoiceStore.invoice?.pre_selected_add_incomes) {
        Object.assign(acc, { [tag]: invoiceStore.invoice?.pre_selected_add_incomes[tag] || 0 })
      } else {
        Object.assign(acc, { [tag]: 0 })
      }
      return acc
    }, {})
})
useForm({
  initialValues: { ...form.value },
})
const formValues = useFormValues()
function deleteItemFromBasket(key: string) {
  formValues.value[key] = 0
}
const basketItems = computed(() => {
  return Object.keys(formValues.value).reduce((acc: { [key: string]: string | number }, key) => {
    if (formValues.value[key] > 0) {
      acc.push({
        value: formValues.value[key],
        tag: key,
        icon: describer.find((i: { [key: string]: number | string }) => i.tag === key)?.icon,
        name: describer.find((i: { [key: string]: number | string }) => i.tag === key)?.name,
      })
    }
    return acc
  }, [])
})
function sendData() {
  const body = basketItems.value.reduce((acc: any, item: any) => {
    return Object.assign(acc, { [item?.tag]: item?.value })
  }, {})
  createPreAddDeductions(props.id, { pre_selected_add_incomes: body })
    .then(() => {
      router.push({ name: 'good_select' })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка cохранения',
        message: `Дополнительных доходов и вычетов ${err}`,
        type: 'error',
      })
    })
}

if (hasErrors.value) router.push({ name: 'file_in_invoice' })

useInvoiceRepository()
  .getInvoice(props.id)
  .then((res) => {
    useAsyncInvoiceReload().initAsync(res)
  })

return (_ctx: any,_cache: any) => {
  const _component_ng_global_question_popover = _resolveComponent("ng-global-question-popover")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "margin-v-s" }, "Шаг 2. Выберите дополнительные доходы и вычеты", -1)),
    _cache[5] || (_cache[5] = _createElementVNode("p", { class: "margin-v-s" }, " Выбранные позиции можно будет самостоятельно внести во время оформления заказа или поручить внесение нам. ", -1)),
    _createVNode(_component_el_row, { gutter: 20 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { lg: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h3", null, _toDisplayString(_unref(incomes).title), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(incomes).items, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.tag,
                  class: _normalizeClass(["flex items-center justify-between p-4", _unref(formValues)[item.tag] > 0 ? 'bg-green' : ''])
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: item?.icon,
                      alt: "close_icon",
                      width: "20",
                      class: "margin-h--right-6"
                    }, null, 8, _hoisted_5),
                    _createElementVNode("p", null, _toDisplayString(item.name), 1),
                    _createVNode(_component_ng_global_question_popover, {
                      color: "#021c1a4d",
                      width: 500
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          style: {"word-break":"break-word","hyphens":"none","text-align":"left"},
                          innerHTML: _unref(preAddOnBoarding)[item.tag]?.text
                        }, null, 8, _hoisted_6)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createVNode(_component_el_tooltip, {
                    content: 'более одного нельзя',
                    trigger: "hover",
                    disabled: item?.max !== 1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(FormItem, {
                        name: item.tag,
                        label: ''
                      }, {
                        default: _withCtx(({ value, onBlur, onInput }) => [
                          _createVNode(_component_el_input_number, {
                            style: {"width":"86px !important"},
                            min: 0,
                            max: item.max || undefined,
                            size: "small",
                            "model-value": value,
                            type: "text",
                            name: item.tag,
                            onBlur: onBlur,
                            onInput: onInput
                          }, null, 8, ["max", "model-value", "name", "onBlur", "onInput"])
                        ]),
                        _: 2
                      }, 1032, ["name"])
                    ]),
                    _: 2
                  }, 1032, ["disabled"])
                ], 2))
              }), 128)),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h3", null, _toDisplayString(_unref(refunds).title), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(refunds).items, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.key
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("img", {
                        src: item?.icon,
                        alt: "close_icon",
                        width: "20",
                        class: "margin-h--right-6"
                      }, null, 8, _hoisted_9),
                      _createElementVNode("p", _hoisted_10, _toDisplayString(item.title), 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.refunds, (refund) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: refund.tag,
                        class: _normalizeClass(["flex items-center justify-between pl-11 py-4 pr-4", _unref(formValues)[refund.tag] > 0 ? 'bg-green' : ''])
                      }, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("p", null, _toDisplayString(refund.name), 1),
                          _createVNode(_component_ng_global_question_popover, {
                            color: "#021c1a4d",
                            width: 500
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                style: {"word-break":"break-word","hyphens":"none","text-align":"left"},
                                innerHTML: _unref(preAddOnBoarding)[refund.tag]?.text
                              }, null, 8, _hoisted_12)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createVNode(_component_el_tooltip, {
                          content: 'более одного нельзя',
                          trigger: "hover",
                          disabled: refund?.max !== 1
                        }, {
                          default: _withCtx(() => [
                            _createVNode(FormItem, {
                              name: refund.tag,
                              label: ''
                            }, {
                              default: _withCtx(({ value, onBlur, onInput }) => [
                                _createVNode(_component_el_input_number, {
                                  style: {"width":"86px !important"},
                                  min: 0,
                                  max: refund.max || undefined,
                                  size: "small",
                                  "model-value": value,
                                  type: "text",
                                  name: refund.tag,
                                  onBlur: onBlur,
                                  onInput: onInput
                                }, null, 8, ["max", "model-value", "name", "onBlur", "onInput"])
                              ]),
                              _: 2
                            }, 1032, ["name"])
                          ]),
                          _: 2
                        }, 1032, ["disabled"])
                      ], 2))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { lg: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_13, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "p-4 bg-gray" }, [
                _createElementVNode("h3", null, "Портфель")
              ], -1)),
              _createElementVNode("div", _hoisted_14, [
                (_unref(invoiceStore).invoice)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_unref(ShowOnlyLoadedFiles), {
                        "broker-files": _unref(invoiceStore).invoice?.broker_files
                      }, null, 8, ["broker-files"])
                    ]))
                  : _createCommentVNode("", true),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "el-divider-purple mb-4" }, null, -1)),
                _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "mb-8" }, "Выбранные дополнительные доходы и вычеты", -1)),
                _createVNode(_component_el_scrollbar, {
                  class: "mb-4",
                  height: basketItems.value.length > 8 ? '300px' : ''
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_16, [
                      (basketItems.value.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(basketItems.value, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "mb-8"
                              }, [
                                _createElementVNode("div", _hoisted_18, [
                                  _createElementVNode("div", _hoisted_19, [
                                    _createElementVNode("img", {
                                      class: "mr-2",
                                      src: item?.icon,
                                      alt: "close_icon",
                                      width: "20"
                                    }, null, 8, _hoisted_20),
                                    _createElementVNode("p", _hoisted_21, _toDisplayString(item.name), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_22, [
                                    _createElementVNode("p", _hoisted_23, _toDisplayString(item.value), 1),
                                    _createElementVNode("img", {
                                      class: "button--text-hover",
                                      onClick: ($event: any) => (deleteItemFromBasket(item.tag)),
                                      src: _imports_0,
                                      alt: "close_icon",
                                      width: "20"
                                    }, null, 8, _hoisted_24)
                                  ])
                                ])
                              ]))
                            }), 128))
                          ]))
                        : (_openBlock(), _createBlock(_component_el_empty, {
                            key: 1,
                            description: "Вы не добавили ни одного дохода или вычета"
                          }))
                    ])
                  ]),
                  _: 1
                }, 8, ["height"]),
                _createVNode(_component_el_button, {
                  loading: _unref(createPreAddDeductions).indicator.value === true,
                  type: "success",
                  class: "button-main",
                  onClick: sendData,
                  icon: _unref(UploadFilled)
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Рассчитать стоимость ")
                  ])),
                  _: 1
                }, 8, ["loading", "icon"])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})