import fileLoader from './FileLoader'
import img from '@/07-Shared/ui/ImgDescriber'
import IBLogo from '@/assets/landing_imgs/brokers_logos/ib_logo.webp'
import FFLogo from '@/assets/landing_imgs/brokers_logos/ff_logo.webp'
import TinLogo from '@/assets/landing_imgs/brokers_logos/tinkoff_logo.webp'
import ExLogo from '@/assets/landing_imgs/brokers_logos/exante_logo.webp'
import GuruLogo from '@/assets/guru_logo.svg'
import { transformFileFromServer } from './FileFromServer'
import { ElMessageBox } from 'element-plus'
import { computed, reactive } from 'vue'
import { EventEmitter } from 'events'
import { ILoaderDescriber, PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'
import { BrokerFileType, FileTagType } from '@/entityes/invoice/file/file.type'
import { IInvoice } from '@/entityes/invoice/invoice.type'

export const describers: ILoaderDescriber[] = [
  {
    title: 'Interactive Brokers',
    text: 'Нажмите или перетащите файлы для загрузки отчетов Interactive Brokers',
    serverTag: 'interactive_brokers_files',
    name: 'IB',
    fileFormat: /.*\.(csv)$/,
    img: img.csv,
    type: 'auto',
    logo: IBLogo,
  },
  {
    title: 'Freedom Finance Belize',
    text: 'Нажмите или перетащите файлы для загрузки отчетов Freedom Finance',
    serverTag: 'freedom_finance_files',
    name: 'FF Belize',
    fileFormat: /.*\.(xlsx)$/,
    img: img.csv,
    type: 'auto',
    logo: FFLogo,
  },
  {
    title: 'Exante',
    text: 'Нажмите или перетащите файлы для загрузки отчетов Freedom Finance',
    serverTag: 'exante_virtual_files',
    name: 'exante',
    fileFormat: /.*\.(xlsx)$/,
    img: img.csv,
    type: 'auto',
    logo: ExLogo,
  },
  // {
  //   title: 'Julius Baer',
  //   text: 'Нажмите или перетащите файлы для загрузки отчетов Julius Baer',
  //   serverTag: 'julius_bar_files',
  //   name: 'JB',
  //   fileFormat: /.*\.(pdf)$/,
  //   img: img.csv,
  //   type: 'auto',
  // },
  {
    title: 'GuruFiles',
    text: 'Нажмите или перетащите файлы для загрузки отчетов GuruFiles',
    serverTag: 'guru_files',
    name: 'GURU',
    fileFormat: /.*\.(xlsx)$/,
    img: img.csv,
    roles: ['isAdmin'],
    logo: GuruLogo,
  },
  {
    title: 'Брокеры и банки',
    description: 'При оформлении заказа оплачивается 5 000 руб. предоплаты. Далее по тарифу.',
    text: 'Нажмите или перетащите файлы для загрузки отчетов зарубежных брокеров, кроме Interactive Brokers, Freedom Finance и Тинькофф',
    serverTag: 'bucket_files',
    fileFormat: /.*\.(csv|xlsx|xls|pdf|xml|jpeg|jpg|png|json|docx|XLSX|PDF|CSV|XML|JPEG|JPG|PNG)$/,
    img: img.csv,
    type: 'manual',
    logo: GuruLogo,
  },
  // {
  //   title: 'ОДС для иностранных банков',
  //   description: 'При оформлении заказа оплачивается 5 000 руб. предоплаты.',
  //   text: 'Нажмите или перетащите файлы для загрузки отчетов зарубежных брокеров, кроме Interactive Brokers, Freedom Finance и Тинькофф',
  //   serverTag: 'cfr_bucket_files',
  //   fileFormat: /.*\.(csv|xlsx|xls|pdf|xml|jpeg|jpg|png|json|docx|XLSX|PDF|CSV|XML|JPEG|JPG|PNG)$/,
  //   img: img.csv,
  //   type: 'cfr',
  // },
  {
    title: 'Тинькофф',
    text: 'Нажмите или перетащите файлы для загрузки отчетов Тинькофф',
    serverTag: 'tinkoff_files',
    name: 'tinkoff',
    fileFormat: /.*\.(xlsx|XLSX)$/,
    img: img.csv,
    type: 'rus',
    logo: TinLogo,
  },
]

const loaderDescriber = (desc: ILoaderDescriber[] | null = null) => {
  class Emitter extends EventEmitter {}
  const emitter = new Emitter()
  const describer = reactive(
    (desc || describers).map((item) => {
      const fl = fileLoader(item)
      const title = 'Дубликаты имен файлов'
      const msg = 'Вы пытаетесь загрузить файлы с одинаковым именем.'
      fl.emitter.on('double_files', (val) => {
        ElMessageBox({
          title: title,
          message: `<p>
                        <p style="maring-bottom: 20px">${msg}</p>
                        <p style="font-weight: bold">Дубликаты</p>
                        <span>${val.join(', ')}</span>
                    </p>`,
          confirmButtonText: 'Понятно',
          dangerouslyUseHTMLString: true,
        })
      })
      fl.emitter.on('local_files_update', () => {
        emitter.emit('local_files_update')
      })
      return fl
    })
  )
  const mergeFile = (inputFileObject: IInvoice['broker_files']) => {
    if (!inputFileObject) return
    const a = Object.keys(inputFileObject) as BrokerFileType[]
    Object.entries(inputFileObject).forEach(([key, value]) => {
      const localDescriber = describer.find((desc) => desc.config.serverTag === key)
      localDescriber?.setFilesFromServer(value.map((item) => transformFileFromServer(item)))
    })
  }
  const toJson = () => {
    const brokerFiles: Partial<Record<FileTagType, PreProcessingFiles & { delete_files: string[] | number[] }>> = {}
    describer.forEach((item) => {
      brokerFiles[item.config.serverTag] = {
        ...item.localFiles,
        delete_files: item.deleteFiles,
      }
    })
    return brokerFiles
  }
  const allClear = () => {
    describer.forEach((item) => {
      item.allClear()
    })
  }
  const hasLocalFiles = computed(() => {
    return describer.some((item) => item.localFiles.file_names.length > 0)
  })
  const hasFileToDelete = computed(() => {
    return describer.some((item) => item.deleteFiles.length > 0)
  })
  const getChangedLoaders = computed(() =>
    describer.filter((item) => item.localFiles.file_names.length > 0 || item.deleteFiles.length > 0)
  )
  function deleteFromServer({ serverTag, index }: { serverTag: FileTagType; index: number }) {
    describer.find((item) => item.serverTag === serverTag)?.onAddDeleteFile(index)
  }
  function removeDeleteFile({ serverTag, index }: { serverTag: FileTagType; index: number }) {
    describer.find((item) => item.serverTag === serverTag)?.onRemoveDeleteFile(index)
  }
  function removeLocalFile({ serverTag, index }: { serverTag: FileTagType; index: number }) {
    describer.find((item) => item.serverTag === serverTag)?.onRemoveLocalFile(index)
  }

  return {
    describers: describer,
    mergeFile,
    toJson,
    allClear,
    hasLocalFiles,
    emitter,
    hasFileToDelete,
    deleteFromServer,
    removeDeleteFile,
    getChangedLoaders,
    removeLocalFile,
  }
}
export default loaderDescriber

export type LoaderDescriberType = ReturnType<typeof loaderDescriber>
