import { computed, ref } from 'vue'
import useTextFilters from '@/plugins/TextFilters'
import { FileFromServer, IFile } from '@/entityes/invoice/file/file.type'

const { subStringFileName } = useTextFilters()
export function useFileFromServer(config: IFile) {
  const id = ref(config.id)
  const serverName = ref(config.name || 'Без названия')
  const name = computed(() => subStringFileName(serverName.value, 20))
  const isLoading = ref(false)

  return {
    id,
    name,
    serverName,
    isLoading,
  }
}
export function transformFileFromServer(config: IFile): FileFromServer {
  const serverName = config.name || 'Без названия'
  return {
    id: config.id,
    serverName,
    name: subStringFileName(serverName, 30),
    isLoading: false,
  }
}
