import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-weight--extra" }
const _hoisted_2 = {
  key: 0,
  class: "font-color--green"
}
const _hoisted_3 = {
  key: 1,
  class: "font-color--red"
}

import useTextFilters from '@/plugins/TextFilters'
import useWindowWidth from '@/use/useWindowWidth'
import { UnwrapNestedRefs } from 'vue'
import { AdditionalIncome, AdditionalItem } from '@/06-Entities/IncomeAndRefund'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
}
interface IEmits {
  (e: 'remove'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'UnknownCard',
  props: {
    item: {}
  },
  emits: ["remove"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const { subStringText } = useTextFilters()
const { screenLessValue } = useWindowWidth()

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { align: "middle" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        lg: 10,
        class: "margin-v-xs"
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Сообщение", -1)),
          _createElementVNode("p", null, _toDisplayString(_unref(subStringText)(_ctx.item.item?.other_attributes?.message || '')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        lg: 6,
        class: "margin-v-xs"
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Телефон", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item?.other_attributes?.phone), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        lg: 5,
        class: "margin-v-xs"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, [
            (_ctx.item?.valueFromServer && !_ctx.item.fieldWasEdited)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Доход сохранен"))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "Доход не сохранен"))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        lg: 3,
        class: "b-margin-top"
      }, {
        default: _withCtx(() => [
          _createVNode(EditSaveCardButtonGroup, {
            "mobile-screen": _unref(screenLessValue)(1200),
            onToggle: _ctx.item.toggleEdit,
            onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove')))
          }, null, 8, ["mobile-screen", "onToggle"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})