<script lang="ts" setup>
const msg = 'Магические операции, подождите пожалуйста'
</script>

<template>
  <div class="flex flex-col justify-center items-center mx-auto max-w-7xl">
    <img src="@/assets/loader.gif" alt="load_gif" width="200" />
    <h4>{{ msg }}</h4>
  </div>
</template>

<style scoped lang="sass">
</style>
