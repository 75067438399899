// import axios from '@/plugins/axios'
import fileDownload from 'js-file-download'
import { useRequestService } from './useRequestService'
import { FileTagType } from '@/entityes/invoice/file/file.type'
import { ref } from 'vue'
export interface IDownloadFileConfig {
  invoiceId: number | string
  fileId: number | string
  fileType: FileTagType
  name: string
  isLoading?: boolean
}
export default function useFileDownload() {
  const { fetch } = useRequestService()
  async function downloadFileRequest({ invoiceId, fileId, fileType, name }: IDownloadFileConfig) {
    downloadFileRequest.isLoading.value = true
    const isExante = fileType === 'exante_virtual_files'
    try {
      if (isExante) {
        return await downloadResultFileRequest({ fileId, name })
      }
      if (!isExante) {
        const res = await fetch<Blob, 'blob'>(`/invoices/${invoiceId}/download_file`, {
          params: {
            file_type: fileType,
            file_id: fileId,
          },
          method: 'GET',
          responseType: 'blob',
        })

        fileDownload(res, name)
      }
    } catch (err) {
      console.error(err)
    } finally {
      downloadFileRequest.isLoading.value = false
    }
  }
  downloadFileRequest.isLoading = ref(false)
  async function downloadResultFileRequest({ fileId, name }: { fileId: number | string; name: string }) {
    downloadResultFileRequest.isLoading.value = true
    try {
      const res = await fetch<Blob, 'blob'>(`/result_files/${fileId}`, {
        method: 'GET',
        responseType: 'blob',
      })
      fileDownload(res, name)
    } catch (err) {
      console.error(err)
    } finally {
      downloadResultFileRequest.isLoading.value = false
    }
  }
  downloadResultFileRequest.isLoading = ref(false)

  return {
    downloadFileRequest,
    downloadResultFileRequest,
  }
}
