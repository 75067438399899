<script setup lang="ts">
import { FileFromServer, IAdminFileInfo } from '@/entityes/invoice/file/file.type'

interface IProps {
  files: FileFromServer[]
}
const props = defineProps<IProps>()
</script>

<template>
  <div class="padding-h-l">
    <div v-for="file in props.files" :key="'file_to_delete_' + file.id">
      <div class="flex-box margin-v-xs">
        <i class="el-icon-close font-color--red font-weight--extra"></i>
        <span class="margin-h--left-s text-strike opacity">{{ file.name }}</span>
      </div>
      <div class="margin-v-xs">
        <el-divider class="el-divider-80" />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
