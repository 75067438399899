import { useUserStore } from '@/entityes/user/userStore'
import { NavigationGuardWithThis } from 'vue-router'

export const CheckAdminRoleMiddleware: NavigationGuardWithThis<any> = () => {
  const { user } = useUserStore()
  if (user?.role === 'admin') return
  return {
    name: 'account home',
    replace: true,
  }
}

// const localStoreUser = JSON.parse(localStorage.getItem('user'))
// if (localStoreUser?.role === 'admin') return next()
// axios({
//   url: '/users/role',
//   method: 'GET',
// })
//   .then((res) => {
//     if (['admin'].includes(res.data?.role)) {
//       store.commit('user/updateUserField', {
//         role: res.data.role,
//       })
//       next()
//     } else next({ name: 'account home' })
//   })
//   .catch(() => {
//     next({ name: 'account home' })
//   })
