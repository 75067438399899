<script setup lang="ts">
import {toTypedSchema} from '@vee-validate/yup'
import * as yup from 'yup'
import {useForm, useFormValues} from 'vee-validate'
import FormItem from '@/components/supportComponents/FormItem.vue'
import {useInvoiceStore} from '@/entityes/invoice/invoice.store'
import useInvoiceRemoteAdminRequest from '@/06-Entities/AdminInvoiceRemote/api/invoice_remote.admin.request'
import useCopyToClipboard from '@/07-Shared/model/useCopyToClipboard'
import { ref } from 'vue'

const invoiceStore = useInvoiceStore()
const { copyValue } = useCopyToClipboard()
const { createTinkoffPaymentLink } = useInvoiceRemoteAdminRequest()
const kassaLink = ref('')

const schema = toTypedSchema(
  yup.object({
    name: yup.string().required(),
    qty: yup.number().required(),
    price: yup.number().required()
  })
)
const { handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    name: 'Лицензионный договор "Декларация". Предоставление права использования программы для ЭВМ и баз данных.'
  }
})
// const formValues = useFormValues()
const getTBankKassaLink = handleSubmit((form) => {
  console.log(form)
  createTinkoffPaymentLink(invoiceStore.invoice.id, form).then(( res: { kassa_link: string } ) => {
    kassaLink.value += res.kassa_link
  })
})
</script>

<template>
  <div class="flex">
    <el-form class="flex items-center mr-12" @submit.prevent="getTBankKassaLink" :validation-schema="schema">
      <FormItem  class="mr-4" name="name" v-slot="{ value, onBlur, onInput }" label="Имя">
        <el-input :model-value="value"
                  type="text"
                  name="name"
                  size="small"
                  @blur="onBlur"
                  @input="onInput"
                  placeholder="example@ya.ru" />
      </FormItem>
      <FormItem class="mr-4" name="qty" v-slot="{ value, onBlur, onInput }" label="Количество">
        <el-input-number :model-value="value"
                         type="number"
                         name="qty"
                         :min="1"
                         size="small"
                         @blur="onBlur"
                         @input="onInput"
                         placeholder="0" />
      </FormItem>
      <FormItem class="mr-4" name="price" v-slot="{ value, onBlur, onInput }" label="Цена">
        <el-input :model-value="value"
                  type="text"
                  name="price"
                  size="small"
                  @blur="onBlur"
                  @input="onInput"
                  placeholder="123" />
      </FormItem>
      <el-button class="mt-6" native-type="submit" type="primary" size="small">Получить ссылку</el-button>
    </el-form>
    <div>
      <h4>Ссылка на оплату</h4>
      <div class="flex">
        <p class="mr-2">{{ kassaLink !== '' ? kassaLink : 'не сформирована' }}</p>
        <el-tooltip effect="dark" content="Скопировать значение" placement="top-start">
          <div>
            <el-icon
              @click="copyValue(kassaLink)"
              class="button--text-hover"
              color="#4b60cecc"
              size="large"
              v-if="kassaLink !== ''"
            >
              <CopyDocument />
            </el-icon>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
