import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex items-center pl-2 flex-wrap" }
const _hoisted_3 = { class: "mr-2" }
const _hoisted_4 = { class: "font-bold font-color--gray" }
const _hoisted_5 = {
  key: 1,
  class: "ml-2"
}

import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useTextFilters from '@/plugins/TextFilters'
import { useBrokerName } from '@/use/useBrokerName'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminErrors',
  setup(__props) {

const invoiceStore = useInvoiceStore()
const hasErrors = computed(
  () => invoiceStore.invoice && Object.keys(invoiceStore.invoice?.warning_and_error.errors).length !== 0
)
const errors = computed(() => invoiceStore.invoice?.warning_and_error.errors)
const { brokerNames } = useBrokerName()
const { subStringFileName } = useTextFilters()

return (_ctx: any,_cache: any) => {
  const _component_WarningFilled = _resolveComponent("WarningFilled")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (hasErrors.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_el_collapse, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(errors.value, (accounts, broker) => {
                return (_openBlock(), _createBlock(_component_el_collapse_item, { key: broker }, {
                  title: _withCtx(() => [
                    _createVNode(_component_WarningFilled, {
                      style: {"font-weight":"700","width":"1em","height":"1em"},
                      color: "#FF9A9D",
                      class: "ml-2 mr-2"
                    }),
                    _createElementVNode("h3", null, _toDisplayString(_unref(brokerNames)[broker] || broker), 1)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accounts, (err, account) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: account,
                        class: "mb-2"
                      }, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("p", _hoisted_4, _toDisplayString(broker === 'workers' ? 'воркер: ' : 'имя файла: '), 1),
                            _createElementVNode("p", null, _toDisplayString(_unref(subStringFileName)(account, 30)), 1)
                          ]),
                          _createElementVNode("div", null, [
                            _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-bold font-color--gray" }, "ошибка:", -1)),
                            _createElementVNode("p", null, _toDisplayString(err), 1)
                          ])
                        ])
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
        _createElementVNode("p", null, "Ошибок не обнаружено", -1)
      ])))
}
}

})