<script setup lang="ts">
import { computed } from 'vue'
import useFileDownload, { IDownloadFileConfig } from '@/use/useFileDownload'
import { useFileStore } from '@/entityes/invoice/file/file.store'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import AdminRenderFiles from '@/06-Entities/AdminInvoiceRemote/ui/AdminRenderFiles.vue'

const fileStore = useFileStore()
const invoiceStore = useInvoiceStore()
const { downloadFileRequest } = useFileDownload()
const invoiceId = computed(() => invoiceStore.invoice && invoiceStore.invoice?.id)
const TBankChatFiles = computed(() => fileStore.filesAdmin && fileStore.filesAdmin.files.filter((file) => file.tag === 'tinkoff_chat_files'))
const getFile = (config: IDownloadFileConfig, file: IDownloadFileConfig) => {
  file.isLoading = true
  downloadFileRequest({
    invoiceId: config.invoiceId,
    fileType: config.fileType,
    fileId: config.fileId,
    name: config.name,
  })?.finally(() => {
    file.isLoading = false
  })
}
</script>

<template>
  <el-scrollbar max-height="400">
    <div class="flex items-center">
      <div
        v-for="file in TBankChatFiles"
        :key="file.id"
        @click="getFile({ invoiceId: invoiceId, fileType: file.tag, fileId: file.id, name: file.name }, file)"
        class="mb-2 mr-2"
      >
        <admin-render-files :file="file" />
      </div>
    </div>
  </el-scrollbar>
</template>

<style scoped lang="sass"></style>
