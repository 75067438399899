import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/delete_icon.svg'


const _hoisted_1 = { class: "flex-box flex-box--align-c flex-box--sb" }
const _hoisted_2 = { class: "flex-box flex-box--align-c" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = ["onClick"]

import { IFile } from '../file.type'
import useTextFilters from '@/plugins/TextFilters'
interface IProps {
  files: string[]
}

interface IEmits {
  (e: 'deleteLoadedFile', index: number): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddedFiles',
  props: {
    files: {}
  },
  emits: ["deleteLoadedFile"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const { subStringFileName } = useTextFilters()
const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_CirclePlusFilled = _resolveComponent("CirclePlusFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.files, (name, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: name,
      class: "margin-h--left-m margin-v-xs"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_icon, {
            color: "#43d2cc",
            size: "large",
            class: "margin-h--right-6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CirclePlusFilled)
            ]),
            _: 1
          }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(subStringFileName)(name, 30)), 1)
        ]),
        _createElementVNode("img", {
          class: "button--text-hover",
          onClick: ($event: any) => (emit('deleteLoadedFile', index)),
          src: _imports_0,
          alt: "close_icon",
          width: "20"
        }, null, 8, _hoisted_4)
      ])
    ]))
  }), 128))
}
}

})