export default [
  {
    okv: '008',
    code: 'ALL',
    name: 'Лек',
    country: 'Албания',
  },
  {
    okv: '012',
    code: 'DZD',
    name: 'Алжирский динар',
    country: 'Алжир',
  },
  {
    okv: '032',
    code: 'ARS',
    name: 'Аргентинское песо',
    country: 'Аргентина',
  },
  {
    okv: '036',
    code: 'AUD',
    name: 'Австралийский доллар',
    country:
      'Австралия; Кирибати; Кокосовые (Килинг) острова; Науру; Остров Норфолк; Остров Рождества; Остров Херд и острова Макдональд; Тувалу',
  },
  {
    okv: '044',
    code: 'BSD',
    name: 'Багамский доллар',
    country: 'Багамы',
  },
  {
    okv: '048',
    code: 'BHD',
    name: 'Бахрейнский динар',
    country: 'Бахрейн',
  },
  {
    okv: '050',
    code: 'BDT',
    name: 'Така',
    country: 'Бангладеш',
  },
  {
    okv: '051',
    code: 'AMD',
    name: 'Армянский драм',
    country: 'Армения',
  },
  {
    okv: '052',
    code: 'BBD',
    name: 'Барбадосский доллар',
    country: 'Барбадос',
  },
  {
    okv: '060',
    code: 'BMD',
    name: 'Бермудский доллар',
    country: 'Бермуды',
  },
  {
    okv: '064',
    code: 'BTN',
    name: 'Нгултрум',
    country: 'Бутан',
  },
  {
    okv: '068',
    code: 'BOB',
    name: 'Боливиано',
    country: 'Боливия, многонациональное государство',
  },
  {
    okv: '072',
    code: 'BWP',
    name: 'Пула',
    country: 'Ботсвана',
  },
  {
    okv: '084',
    code: 'BZD',
    name: 'Белизский доллар',
    country: 'Белиз',
  },
  {
    okv: '090',
    code: 'SBD',
    name: 'Доллар Соломоновых Островов',
    country: 'Соломоновы Острова',
  },
  {
    okv: '096',
    code: 'BND',
    name: 'Брунейский доллар',
    country: 'Бруней-Даруссалам',
  },
  {
    okv: '104',
    code: 'MMK',
    name: 'Кьят',
    country: 'Мьянма',
  },
  {
    okv: '108',
    code: 'BIF',
    name: 'Бурундийский франк',
    country: 'Бурунди',
  },
  {
    okv: '116',
    code: 'KHR',
    name: 'Риель',
    country: 'Камбоджа',
  },
  {
    okv: '124',
    code: 'CAD',
    name: 'Канадский доллар',
    country: 'Канада',
  },
  {
    okv: '132',
    code: 'CVE',
    name: 'Эскудо Кабо-Верде',
    country: 'Кабо-Верде',
  },
  {
    okv: '136',
    code: 'KYD',
    name: 'Доллар Островов Кайман',
    country: 'Острова Кайман',
  },
  {
    okv: '144',
    code: 'LKR',
    name: 'Шри-ланкийская рупия',
    country: 'Шри-Ланка',
  },
  {
    okv: '152',
    code: 'CLP',
    name: 'Чилийское песо',
    country: 'Чили',
  },
  {
    okv: '156',
    code: 'CNY',
    name: 'Юань',
    country: 'Китай',
  },
  {
    okv: '170',
    code: 'COP',
    name: 'Колумбийское песо',
    country: 'Колумбия',
  },
  {
    okv: '174',
    code: 'KMF',
    name: 'Коморский франк',
    country: 'Коморы',
  },
  {
    okv: '188',
    code: 'CRC',
    name: 'Коста-риканский колон',
    country: 'Коста-Рика',
  },
  {
    okv: '191',
    code: 'HRK',
    name: 'Куна',
    country: 'Хорватия',
  },
  {
    okv: '192',
    code: 'CUP',
    name: 'Кубинское песо',
    country: 'Куба',
  },
  {
    okv: '203',
    code: 'CZK',
    name: 'Чешская крона',
    country: 'Чехия',
  },
  {
    okv: '208',
    code: 'DKK',
    name: 'Датская крона',
    country: 'Гренландия; Дания; Фарерские острова',
  },
  {
    okv: '214',
    code: 'DOP',
    name: 'Доминиканское песо',
    country: 'Доминиканская Республика',
  },
  {
    okv: '222',
    code: 'SVC',
    name: 'Сальвадорский колон',
    country: 'Эль-Сальвадор',
  },
  {
    okv: '230',
    code: 'ETB',
    name: 'Эфиопский быр',
    country: 'Эфиопия',
  },
  {
    okv: '232',
    code: 'ERN',
    name: 'Накфа',
    country: 'Эритрея',
  },
  {
    okv: '238',
    code: 'FKP',
    name: 'Фунт Фолклендских островов',
    country: 'Фолклендские острова (Мальвинские)',
  },
  {
    okv: '242',
    code: 'FJD',
    name: 'Доллар Фиджи',
    country: 'Фиджи',
  },
  {
    okv: '262',
    code: 'DJF',
    name: 'Франк Джибути',
    country: 'Джибути',
  },
  {
    okv: '270',
    code: 'GMD',
    name: 'Даласи',
    country: 'Гамбия',
  },
  {
    okv: '292',
    code: 'GIP',
    name: 'Гибралтарский фунт',
    country: 'Гибралтар',
  },
  {
    okv: '320',
    code: 'GTQ',
    name: 'Кетсаль',
    country: 'Гватемала',
  },
  {
    okv: '324',
    code: 'GNF',
    name: 'Гвинейский франк',
    country: 'Гвинея',
  },
  {
    okv: '328',
    code: 'GYD',
    name: 'Гайанский доллар',
    country: 'Гайана',
  },
  {
    okv: '332',
    code: 'HTG',
    name: 'Гурд',
    country: 'Гаити',
  },
  {
    okv: '340',
    code: 'HNL',
    name: 'Лемпира',
    country: 'Гондурас',
  },
  {
    okv: '344',
    code: 'HKD',
    name: 'Гонконгский доллар',
    country: 'Гонконг',
  },
  {
    okv: '348',
    code: 'HUF',
    name: 'Форинт',
    country: 'Венгрия',
  },
  {
    okv: '352',
    code: 'ISK',
    name: 'Исландская крона',
    country: 'Исландия',
  },
  {
    okv: '356',
    code: 'INR',
    name: 'Индийская рупия',
    country: 'Бутан; Индия',
  },
  {
    okv: '360',
    code: 'IDR',
    name: 'Рупия',
    country: 'Индонезия',
  },
  {
    okv: '364',
    code: 'IRR',
    name: 'Иранский риал',
    country: 'Иран (Исламская Республика)',
  },
  {
    okv: '368',
    code: 'IQD',
    name: 'Иракский динар',
    country: 'Ирак',
  },
  {
    okv: '376',
    code: 'ILS',
    name: 'Новый израильский шекель',
    country: 'Израиль',
  },
  {
    okv: '388',
    code: 'JMD',
    name: 'Ямайский доллар',
    country: 'Ямайка',
  },
  {
    okv: '392',
    code: 'JPY',
    name: 'Иена',
    country: 'Япония',
  },
  {
    okv: '398',
    code: 'KZT',
    name: 'Тенге',
    country: 'Казахстан',
  },
  {
    okv: '400',
    code: 'JOD',
    name: 'Иорданский динар',
    country: 'Иордания',
  },
  {
    okv: '404',
    code: 'KES',
    name: 'Кенийский шиллинг',
    country: 'Кения',
  },
  {
    okv: '408',
    code: 'KPW',
    name: 'Северокорейская вона',
    country: 'Корея, народно-демократическая республика',
  },
  {
    okv: '410',
    code: 'KRW',
    name: 'Вона',
    country: 'Корея, республика',
  },
  {
    okv: '414',
    code: 'KWD',
    name: 'Кувейтский динар',
    country: 'Кувейт',
  },
  {
    okv: '417',
    code: 'KGS',
    name: 'Сом',
    country: 'Киргизия',
  },
  {
    okv: '418',
    code: 'LAK',
    name: 'Лаосский кип',
    country: 'Лаосская Народно-Демократическая Республика',
  },
  {
    okv: '422',
    code: 'LBP',
    name: 'Ливанский фунт',
    country: 'Ливан',
  },
  {
    okv: '426',
    code: 'LSL',
    name: 'Лоти',
    country: 'Лесото',
  },
  {
    okv: '430',
    code: 'LRD',
    name: 'Либерийский доллар',
    country: 'Либерия',
  },
  {
    okv: '434',
    code: 'LYD',
    name: 'Ливийский динар',
    country: 'Ливия',
  },
  {
    okv: '446',
    code: 'MOP',
    name: 'Патака',
    country: 'Макао',
  },
  {
    okv: '454',
    code: 'MWK',
    name: 'Малавийская квача',
    country: 'Малави',
  },
  {
    okv: '458',
    code: 'MYR',
    name: 'Малайзийский ринггит',
    country: 'Малайзия',
  },
  {
    okv: '462',
    code: 'MVR',
    name: 'Руфия',
    country: 'Мальдивы',
  },
  {
    okv: '480',
    code: 'MUR',
    name: 'Маврикийская рупия',
    country: 'Маврикий',
  },
  {
    okv: '484',
    code: 'MXN',
    name: 'Мексиканское песо',
    country: 'Мексика',
  },
  {
    okv: '496',
    code: 'MNT',
    name: 'Тугрик',
    country: 'Монголия',
  },
  {
    okv: '498',
    code: 'MDL',
    name: 'Молдавский лей',
    country: 'Молдова, республика',
  },
  {
    okv: '504',
    code: 'MAD',
    name: 'Марокканский дирхам',
    country: 'Западная Сахара; Марокко',
  },
  {
    okv: '512',
    code: 'OMR',
    name: 'Оманский риал',
    country: 'Оман',
  },
  {
    okv: '516',
    code: 'NAD',
    name: 'Доллар Намибии',
    country: 'Намибия',
  },
  {
    okv: '524',
    code: 'NPR',
    name: 'Непальская рупия',
    country: 'Непал',
  },
  {
    okv: '532',
    code: 'ANG',
    name: 'Нидерландский антильский гульден',
    country: 'Кюрасао; Сен-Мартен (нидерландская часть)',
  },
  {
    okv: '533',
    code: 'AWG',
    name: 'Арубанский флорин',
    country: 'Аруба',
  },
  {
    okv: '548',
    code: 'VUV',
    name: 'Вату',
    country: 'Вануату',
  },
  {
    okv: '554',
    code: 'NZD',
    name: 'Новозеландский доллар',
    country: 'Ниуэ; Новая Зеландия; Острова Кука; Питкерн; Токелау',
  },
  {
    okv: '558',
    code: 'NIO',
    name: 'Золотая кордоба',
    country: 'Никарагуа',
  },
  {
    okv: '566',
    code: 'NGN',
    name: 'Найра',
    country: 'Нигерия',
  },
  {
    okv: '578',
    code: 'NOK',
    name: 'Норвежская крона',
    country: 'Норвегия; Остров Буве; Шпицберген и Ян Майен',
  },
  {
    okv: '586',
    code: 'PKR',
    name: 'Пакистанская рупия',
    country: 'Пакистан',
  },
  {
    okv: '590',
    code: 'PAB',
    name: 'Бальбоа',
    country: 'Панама',
  },
  {
    okv: '598',
    code: 'PGK',
    name: 'Кина',
    country: 'Папуа Новая Гвинея',
  },
  {
    okv: '600',
    code: 'PYG',
    name: 'Гуарани',
    country: 'Парагвай',
  },
  {
    okv: '604',
    code: 'PEN',
    name: 'Соль',
    country: 'Перу',
  },
  {
    okv: '608',
    code: 'PHP',
    name: 'Филиппинское песо',
    country: 'Филиппины',
  },
  {
    okv: '634',
    code: 'QAR',
    name: 'Катарский риал',
    country: 'Катар',
  },
  {
    okv: '643',
    code: 'RUB',
    name: 'Российский рубль',
    country: 'Россия',
  },
  {
    okv: '646',
    code: 'RWF',
    name: 'Франк Руанды',
    country: 'Руанда',
  },
  {
    okv: '654',
    code: 'SHP',
    name: 'Фунт Святой Елены',
    country: 'Святая Елена, остров Вознесения, Тристан-да-Кунья',
  },
  {
    okv: '682',
    code: 'SAR',
    name: 'Саудовский риял',
    country: 'Саудовская Аравия',
  },
  {
    okv: '690',
    code: 'SCR',
    name: 'Сейшельская рупия',
    country: 'Сейшелы',
  },
  {
    okv: '694',
    code: 'SLL',
    name: 'Леоне',
    country: 'Сьерра-Леоне',
  },
  {
    okv: '702',
    code: 'SGD',
    name: 'Сингапурский доллар',
    country: 'Сингапур',
  },
  {
    okv: '704',
    code: 'VND',
    name: 'Донг',
    country: 'Вьетнам',
  },
  {
    okv: '706',
    code: 'SOS',
    name: 'Сомалийский шиллинг',
    country: 'Сомали',
  },
  {
    okv: '710',
    code: 'ZAR',
    name: 'Рэнд',
    country: 'Лесото; Намибия; Южная Африка',
  },
  {
    okv: '728',
    code: 'SSP',
    name: 'Южносуданский фунт',
    country: 'Южный Судан',
  },
  {
    okv: '748',
    code: 'SZL',
    name: 'Лилангени',
    country: 'Эсватини',
  },
  {
    okv: '752',
    code: 'SEK',
    name: 'Шведская крона',
    country: 'Швеция',
  },
  {
    okv: '756',
    code: 'CHF',
    name: 'Швейцарский франк',
    country: 'Лихтенштейн; Швейцария',
  },
  {
    okv: '760',
    code: 'SYP',
    name: 'Сирийский фунт',
    country: 'Сирийская Арабская Республика',
  },
  {
    okv: '764',
    code: 'THB',
    name: 'Бат',
    country: 'Таиланд',
  },
  {
    okv: '776',
    code: 'TOP',
    name: 'Паанга',
    country: 'Тонга',
  },
  {
    okv: '780',
    code: 'TTD',
    name: 'Доллар Тринидада и Тобаго',
    country: 'Тринидад и Тобаго',
  },
  {
    okv: '784',
    code: 'AED',
    name: 'Дирхам (ОАЭ)',
    country: 'Объединенные Арабские Эмираты (ОАЭ)',
  },
  {
    okv: '788',
    code: 'TND',
    name: 'Тунисский динар',
    country: 'Тунис',
  },
  {
    okv: '800',
    code: 'UGX',
    name: 'Угандийский шиллинг',
    country: 'Уганда',
  },
  {
    okv: '807',
    code: 'MKD',
    name: 'Денар',
    country: 'Македония, бывшая Югославская Республика',
  },
  {
    okv: '818',
    code: 'EGP',
    name: 'Египетский фунт',
    country: 'Египет',
  },
  {
    okv: '826',
    code: 'GBP',
    name: 'Фунт стерлингов',
    country: 'Соединенное королевство',
  },
  {
    okv: '834',
    code: 'TZS',
    name: 'Танзанийский шиллинг',
    country: 'Танзания, объединенная республика',
  },
  {
    okv: '840',
    code: 'USD',
    name: 'Доллар США',
    country:
      'Американское Самоа; Британская территория в Индийском океане; Бонэйр, Синт-Эстатиус и Саба; Виргинские острова (Британские); Виргинские острова (США); Гаити; Гуам; Малые Тихоокеанские Отдаленные острова Соединенных Штатов; Маршалловы Острова; Микронезия, федеративные штаты; Острова Теркс и Кайкос; Палау; Панама; Пуэрто-Рико; Северные Марианские острова; Соединенные Штаты; Тимор-Лесте; Эквадор; Эль-Сальвадор',
  },
  {
    okv: '858',
    code: 'UYU',
    name: 'Уругвайское песо',
    country: 'Уругвай',
  },
  {
    okv: '860',
    code: 'UZS',
    name: 'Узбекский сум',
    country: 'Узбекистан',
  },
  {
    okv: '882',
    code: 'WST',
    name: 'Тала',
    country: 'Самоа',
  },
  {
    okv: '886',
    code: 'YER',
    name: 'Йеменский риал',
    country: 'Йемен',
  },
  {
    okv: '901',
    code: 'TWD',
    name: 'Новый тайваньский доллар',
    country: 'Тайвань (Китай)',
  },
  {
    okv: '928',
    code: 'VES',
    name: 'Боливар Соберано',
    country: 'Венесуэла (Боливарианская Республика)',
  },
  {
    okv: '929',
    code: 'MRU',
    name: 'Угия',
    country: 'Мавритания',
  },
  {
    okv: '930',
    code: 'STN',
    name: 'Добра',
    country: 'Сан-Томе и Принсипи',
  },
  {
    okv: '931',
    code: 'CUC',
    name: 'Конвертируемое песо',
    country: 'Куба',
  },
  {
    okv: '932',
    code: 'ZWL',
    name: 'Доллар Зимбабве',
    country: 'Зимбабве',
  },
  {
    okv: '933',
    code: 'BYN',
    name: 'Белорусский рубль',
    country: 'Беларусь',
  },
  {
    okv: '934',
    code: 'TMT',
    name: 'Новый туркменский манат',
    country: 'Туркменистан',
  },
  {
    okv: '936',
    code: 'GHS',
    name: 'Ганский седи',
    country: 'Гана',
  },
  {
    okv: '938',
    code: 'SDG',
    name: 'Суданский фунт',
    country: 'Судан',
  },
  {
    okv: '940',
    code: 'UYI',
    name: 'Уругвайское песо в индексированных единицах',
    country: 'Уругвай',
  },
  {
    okv: '941',
    code: 'RSD',
    name: 'Сербский динар',
    country: 'Сербия',
  },
  {
    okv: '943',
    code: 'MZN',
    name: 'Мозамбикский метикал',
    country: 'Мозамбик',
  },
  {
    okv: '944',
    code: 'AZN',
    name: 'Азербайджанский манат',
    country: 'Азербайджан',
  },
  {
    okv: '946',
    code: 'RON',
    name: 'Румынский лей',
    country: 'Румыния',
  },
  {
    okv: '949',
    code: 'TRY',
    name: 'Турецкая лира',
    country: 'Турция',
  },
  {
    okv: '950',
    code: 'XAF',
    name: 'Франк КФА ВЕАС',
    country: 'Габон; Камерун; Конго; Центрально-Африканская Республика; Чад; Экваториальная Гвинея',
  },
  {
    okv: '951',
    code: 'XCD',
    name: 'Восточно-карибский доллар',
    country:
      'Ангилья; Антигуа и Барбуда; Гренада; Доминика; Монтсеррат; Сент-Винсент и Гренадины; Сент-Китс и Невис; Сент-Люсия',
  },
  {
    okv: '952',
    code: 'XOF',
    name: 'Франк КФА ВСЕАО',
    country: "Бенин; Буркина-Фасо; Гвинея-Бисау; Кот д'Ивуар; Мали; Нигер; Сенегал; Того",
  },
  {
    okv: '953',
    code: 'XPF',
    name: 'Франк КФП',
    country: 'Новая Каледония; Французская Полинезия; Уоллис и Футуна',
  },
  {
    okv: '960',
    code: 'XDR',
    name: 'СДР (специальные права заимствования)',
    country: 'Международный валютный фонд (МВФ)',
  },
  {
    okv: '967',
    code: 'ZMW',
    name: 'Замбийская квача',
    country: 'Замбия',
  },
  {
    okv: '968',
    code: 'SRD',
    name: 'Суринамский доллар',
    country: 'Суринам',
  },
  {
    okv: '969',
    code: 'MGA',
    name: 'Малагасийский ариари',
    country: 'Мадагаскар',
  },
  {
    okv: '970',
    code: 'COU',
    name: 'Единица реальной стоимости',
    country: 'Колумбия',
  },
  {
    okv: '971',
    code: 'AFN',
    name: 'Афгани',
    country: 'Афганистан',
  },
  {
    okv: '972',
    code: 'TJS',
    name: 'Сомони',
    country: 'Таджикистан',
  },
  {
    okv: '973',
    code: 'AOA',
    name: 'Кванза',
    country: 'Ангола',
  },
  {
    okv: '975',
    code: 'BGN',
    name: 'Болгарский лев',
    country: 'Болгария',
  },
  {
    okv: '976',
    code: 'CDF',
    name: 'Конголезский франк',
    country: 'Конго, демократическая республика',
  },
  {
    okv: '977',
    code: 'ВАМ',
    name: 'Конвертируемая марка',
    country: 'Босния и Герцеговина',
  },
  {
    okv: '978',
    code: 'EUR',
    name: 'Евро',
    country:
      'Австрия; Андорра; Бельгия; Гваделупа; Германия; Греция; Ирландия; Испания; Италия; Кипр; Латвия; Литва; Люксембург; Майотта; Мальта; Мартиника; Монако; Нидерланды; Папский Престол (Государство-город Ватикан); Португалия; Реюньон; Сан-Марино; Сен-Бартелеми; Сен-Мартен (французская часть); Сент-Пьер и Микелон; Словакия; Словения; Финляндия; Франция; Французская Гвиана; Французские Южные территории; Черногория; Эландские острова; Эстония',
  },
  {
    okv: '980',
    code: 'UAH',
    name: 'Гривна',
    country: 'Украина',
  },
  {
    okv: '981',
    code: 'GEL',
    name: 'Лари',
    country: 'Грузия',
  },
  {
    okv: '985',
    code: 'PLN',
    name: 'Злотый',
    country: 'Польша',
  },
  {
    okv: '986',
    code: 'BRL',
    name: 'Бразильский реал',
    country: 'Бразилия',
  },
]
