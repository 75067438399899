import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import useWindowWidth from '@/use/useWindowWidth'


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderPreview',
  setup(__props) {

const { screenLessValue } = useWindowWidth()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "margin-v-xs" }, "Дополнительные доходы", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "margin-v-xs" }, "Укажите дополнительные доходы за рубежом или в РФ.", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "margin-v-xs" }, " К дополнительным доходам могут относиться дивиденды, доходы от сдачи имущества в аренду или от его продажи и т.д. ", -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", { class: "margin-v-s" }, "Если у вас нет дополнительных доходов, пропустите этот шаг.", -1))
  ], 64))
}
}

})