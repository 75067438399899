import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/close_icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "ng_right_sidebar"
}
const _hoisted_2 = { class: "ng_right_sidebar--main sidebar-area" }


export default /*@__PURE__*/_defineComponent({
  __name: 'RightSideBar',
  props: {
    "modelValue": { type: Boolean, ...{ default: false, required: true } },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const model = _useModel<boolean>(__props, "modelValue")
function closeSidebar() {
  model.value = false
}
// const emit = defineEmits<IEmit>()
// const visible = ref(false)
// const closeSidebar = () => {
//   visible.value = false
//   emit('closeSidebarEvent')
// }
// const showSidebar = () => {
//   visible.value = true
// }
// defineExpose({
//   showSidebar,
//   closeSidebar,
// })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "slide-fade" }, {
    default: _withCtx(() => [
      (model.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "without-sidebar-area",
              onClick: closeSidebar
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", { class: "bg-gray padding--20" }, [
                _createElementVNode("img", {
                  class: "button--text-hover",
                  width: "20",
                  onClick: closeSidebar,
                  src: _imports_0,
                  alt: "close_icon"
                })
              ]),
              _renderSlot(_ctx.$slots, "default")
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})