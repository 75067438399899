<template>
  <el-row align="middle">
    <el-col :xs="24" :sm="7" :md="7" :lg="8" :class="screenLessValue(1200) ? 'margin-top-s' : ''">
      <h3 class="font-color--deep-gray margin-v-xs">Наименование</h3>
      <p>{{ incomeSourceName }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="2" :class="screenLessValue(1200) ? 'margin-top-s' : ''">
      <h3 class="font-color--deep-gray margin-v-xs">Тип</h3>
      <p>{{ item?.item?.other_attributes?.property_type === false ? 'Жилая' : 'Нежилая' }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="4" :class="screenLessValue(1200) ? 'margin-top-s' : ''">
      <h3 class="font-color--deep-gray margin-v-xs">Сумма</h3>
      <p>{{ sum || '-' }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="2" :class="screenLessValue(1200) ? 'margin-top-s' : ''"> </el-col>
    <el-col :xs="24" :sm="5" :md="5" :lg="4" :class="screenLessValue(1200) ? 'margin-top-m' : ''">
      <p class="font-weight--extra">
        <span v-if="item?.valueFromServer && !item.fieldWasEdited" class="font-color--green">Доход сохранен</span>
        <span v-else class="font-color--red">Доход не сохранен</span>
      </p>
    </el-col>
    <el-col
      :xs="24"
      :sm="{ offset: 0, span: 24 }"
      :md="{ offset: 0, span: 24 }"
      :lg="{ offset: 1, span: 3 }"
      :class="screenLessValue(1200) ? 'margin-top-s' : ''"
    >
      <edit-save-card-button-group
        @toggle="item.toggleEdit"
        @remove="$emit('remove')"
        :mobile-screen="screenLessValue(1200)"
      />
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { AdditionalIncome, AdditionalItem } from '@/06-Entities/IncomeAndRefund'

interface IProps {
  item: AdditionalItem<AdditionalIncome>
}

const props = defineProps<IProps>()

const incomeSourceName = computed(() => {
  return (
    props.item.item?.other_attributes?.name_income ||
    props.item.item?.other_attributes?.name_organization ||
    props.item.item?.other_attributes?.name ||
    '-'
  )
})
const currency = computed(() => props.item.item?.currency || '-')
const sum = computed(() => {
  return props.item?.item?.other_attributes?.rental_builds
    ?.map((i) => Number(i?.amount))
    ?.reduce((prev, next) => {
      return prev + next
    }, 0)
})

const { screenLessValue } = useWindowWidth()
</script>

<style lang="sass" scoped></style>
