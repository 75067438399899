import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-weight--extra" }
const _hoisted_2 = {
  key: 0,
  class: "font-color--green"
}
const _hoisted_3 = {
  key: 1,
  class: "font-color--red"
}

import { AdditionalIncome, AdditionalItem } from '@/06-Entities/IncomeAndRefund'
import useWindowWidth from '@/use/useWindowWidth'
import { UnwrapNestedRefs } from 'vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ParsedTwoNdflCard',
  props: {
    item: {}
  },
  setup(__props: any) {



const { screenLessValue } = useWindowWidth()

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { align: "middle" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 7,
        md: 7,
        lg: 5,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Наименование", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item.other_attributes.name_organization), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        md: 4,
        lg: 3,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Дата", -1)),
          _createElementVNode("p", null, _toDisplayString(!_ctx.item.item.date || _ctx.item.item.date === 'null' ? '-' : _ctx.item.item.date), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        md: 4,
        lg: 3,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Валюта", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item.currency || '-'), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        md: 4,
        lg: 3,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Сумма", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item?.amount || '-'), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        md: 4,
        lg: 3,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Код дохода", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item?.other_attributes.income_code || '-'), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        md: 4,
        lg: 3,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Код вычета", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item?.other_attributes.expense_type || '-'), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 5,
        md: 5,
        lg: 3,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-m' : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, [
            (_ctx.item?.valueFromServer && !_ctx.item.fieldWasEdited)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Доход сохранен"))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "Доход не сохранен"))
          ])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}
}

})