import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-box flex-box--align-c"
}
const _hoisted_2 = { class: "font-color--red" }
const _hoisted_3 = {
  key: 1,
  class: "font-color--green font-weight--extra"
}
const _hoisted_4 = { class: "flex-box flex-box--end" }

import { cloneDeep } from 'lodash'
import axios from '@/plugins/axios'
import { onBeforeUnmount, ref } from 'vue'
import useTypeWriter from '@/plugins/useTypeWriter'
import {ITaxPlan} from "@/entityes/invoice/tax_plan/tax_plan.type";

// export default {
//   name: 'TaxPlanCard',
//   props: {
//     plan: Object,
//     profile_id: [String, Number],
//   },
//   setup(props) {
interface IProps {
  plan: ITaxPlan
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TaxPlanCard',
  props: {
    plan: {}
  },
  setup(__props: any) {

const props = __props
const localPlan = ref(cloneDeep(props.plan))
const timer = ref(null)
const { renderString } = useTypeWriter(['расчет...', 'минуточку...', 'еще чуть...:)'])

    // const async = () => {
    //   axios({
    //     url: `/profiles/${props.profile_id}/tax_plans/${localPlan.value.id}`,
    //     method: 'GET',
    //   }).then((res) => {
    //     Object.assign(localPlan.value, res.data)
    //     if (res.data.async) {
    //       timer.value = setTimeout(() => {
    //         async()
    //       }, 5000)
    //     }
    //   })
    // }
    // if (localPlan.value.async) {
    //   async()
    // }
    //
    // onBeforeUnmount(() => {
    //   clearTimeout(timer.value)
    //   timer.value = null
    // })

//     return {
//       localPlan,
//       renderString,
//     }
//   },
// }

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, {
    align: "middle",
    class: "step-box--small padding--20"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 2,
        lg: 2
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "id:", -1)),
          _createElementVNode("p", null, _toDisplayString(localPlan.value.id), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 6,
        lg: 6
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "name:", -1)),
          _createElementVNode("p", null, _toDisplayString(localPlan.value.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        lg: 4
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "Статус:", -1)),
          (localPlan.value.async)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_icon, {
                  class: "el-icon-loading margin-h--right-6",
                  color: "#FF9A9D"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Loading)
                  ]),
                  _: 1
                }),
                _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(renderString)), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
                _createElementVNode("p", null, "Посчитан", -1)
              ])))
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        lg: 4
      }, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "Старт:", -1)),
          _createElementVNode("p", null, _toDisplayString(localPlan.value.start_date), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        lg: 4
      }, {
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "font-color--gray margin-v-xs" }, "Финиш:", -1)),
          _createElementVNode("p", null, _toDisplayString(localPlan.value.finish_date), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        lg: 4
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: { name: 'tax plan show', params: { id: _ctx.plan.id } },
              class: "el-button"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Подробнее ")
              ])),
              _: 1
            }, 8, ["to"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})