import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import AccountRouter from './AccountRouter'
import store from '../store/store'
import { IMiddleware } from './middlewares/index'
import { middlewarePipeline } from './middlewares/middelwarePipeline'
// import UserIsNotLoggedInMiddleware from './middlewares/UserIsNotLoggedInMiddleware'
// import indexMiddleware from '@/router/middlewares/indexMiddleware'
// import globalMiddleware from './GlobalMiddleware'
// import UserIsAuthorizedMiddleware from './middlewares/UserIsAuthorizedMiddleware'
import AdminRouter from '@/router/AdminRouter'
import { isAuth } from './middlewares/isAuth'
import { checkPasswordTokenMiddleware } from './middlewares/checkPasswordTokenMiddleware'
// import CheckAdminRoleMiddleware from '@/router/middlewares/CheckAdminRoleMiddleware'
// import Home from '@/views/Home.vue'
// import SaveExanteTokenMiddleware from './middlewares/SaveExanteTokenMiddleware'
// import { RedirectFromExanteRegistrationMiddleware } from './middlewares/RedirectFromExanteRegistrationMiddleware'
const Authorization = () => import('../views/auth/Authorization.vue')
const Registration = () => import('../views/auth/Registration.vue')
const ChangePassword = () => import('../views/auth/ChangePassword.vue')
const ExanteRegistration = () => import('@/views/auth/ExanteRegistration.vue')
const AccountView = () => import('../views/account/AccountView.vue')
const ChangePasswordLogout = () => import('../views/auth/ChangePasswordLogout.vue')
const AdminView = () => import('@/views/admin/AdminView.vue')
// const NoPageFound = () => import('../views/NoPageFound.vue')
// const Page500 = () => import('@/views/Page500.vue')
// import { testMiddleware } from './middlewares/testMiddleware'

const routes: RouteRecordRaw[] = [
  {
    name: 'Home',
    path: '/',
    redirect: '/account',
  },
  {
    path: '/account',
    component: AccountView,
    children: [...AccountRouter],
  },
  {
    path: '/admin',
    component: AdminView,
    children: [...AdminRouter],
  },
  {
    name: 'authorization',
    path: '/authorization',
    component: Authorization,
    meta: {
      // middleware: [UserIsAuthorizedMiddleware],
    },
  },
  {
    name: 'registration',
    path: '/registration',
    component: Registration,
    meta: {
      // middleware: [UserIsAuthorizedMiddleware],
    },
  },
  {
    name: 'exanteRegistration',
    path: '/registration_exante',
    component: ExanteRegistration,
    meta: {
      // middleware: [UserIsAuthorizedMiddleware, SaveExanteTokenMiddleware, RedirectFromExanteRegistrationMiddleware],
    },
  },
  {
    name: 'change_password',
    path: '/change_password/:token',
    component: ChangePassword,
    props: true,
    meta: {
      middleware: [checkPasswordTokenMiddleware],
    },
  },
  {
    name: 'change_password_logout',
    path: '/change_password_logout',
    component: ChangePasswordLogout,
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

export {}
declare module 'vue-router' {
  interface RouteMeta {
    middleware?: NavigationGuardWithThis<any>[]
  }
}
router.beforeEach(async (to, from) => {
  if (to.meta.middleware) {
    return await middlewarePipeline(...to.meta.middleware)(to, from, () => {})
  }
})

export default router
