<script setup lang="ts">
import useMenuDescription from '@/describers/MenuDescriber'
import { computed, ref } from 'vue'
import NgAdminMenu from '@/components/NgMenu/NgAdminMenu.vue'
import usePolitic from '@/use/usePolitic'
import { useRouter } from 'vue-router'
import Finder from '@/components/Admin/Finder.vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'

const { accountMenuDescriptor } = useMenuDescription()
const bottomMenu = computed(() => accountMenuDescriptor.value.filter((_, index) => index !== 0))
const { isAdmin, isPartner } = usePolitic()
const router = useRouter()
const invoiceVideo = ref(null)
// const open = () => {
//   invoiceVideo.value.openVideoModal()
// }
</script>

<template>
  <div class="left-menu-wrapper bg-gray padding--10">
    <div>
      <div class="margin-v-xs flex-box flex-box--wrap flex-box--sb flex-box--column">
        <router-link to="/" class="margin-v-xs">
          <div>
            <img src="@/assets/new_logo.svg" alt="logo" width="150" />
          </div>
        </router-link>
        <Finder v-if="isAdmin || isPartner" />
        <div v-if="isAdmin" class="cursor-point clickable">
          <el-popover placement="right" :width="200" trigger="hover">
            <template #reference>
              <div class="flex-box flex-box--align-c">
                <el-icon color="#4b60cecc" class="margin-h--right-10" :size="18">
                  <Menu />
                </el-icon>
                <p>Меню админа</p>
              </div>
            </template>
            <NgAdminMenu />
          </el-popover>
        </div>
      </div>
      <div
        class="flex-box flex-box--align-c clickable cursor-point margin-v-xs"
        @click="accountMenuDescriptor[0].clickAction"
        :class="
          router.currentRoute.value.name === 'account home' ? 'bg-purple font-color--white font-weight--semi' : ''
        "
      >
        <el-icon :size="18" class="margin-h--right-10">
          <component :is="accountMenuDescriptor[0].icon" />
        </el-icon>
        <p>
          {{ accountMenuDescriptor[0].title }}
        </p>
      </div>
      <div class="el-divider-purple margin-v-xs" />
    </div>
    <slot>
      <div :class="isAdmin || isPartner ? 'left-menu-admin' : 'left-menu-slot'"></div>
    </slot>
    <div class="bg-gray">
      <div>
        <a href="tel:+74954142898">
          <div class="margin-v-xs clickable flex-box flex-box--align-c">
            <el-icon :size="18">
              <PhoneFilled />
            </el-icon>
            <p>+7(495)414-28-98</p>
          </div>
        </a>
      </div>
      <div class="el-divider-purple margin-v-xs" />
      <div>
        <div
          v-for="(item, index) in bottomMenu"
          :key="item.title"
          @click="item.clickAction"
          class="flex-box flex-box--align-c cursor-point clickable margin-v-xs"
          :class="
            router.currentRoute.value.name === 'customer show' && index === 0
              ? 'bg-purple font-color--white font-weight--semi'
              : ''
          "
        >
          <el-icon :size="18" class="margin-h--right-6">
            <component :is="item.icon" />
          </el-icon>
          <p>
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <NgInvoiceVideo
    ref="invoiceVideo"
    :show-button="false"
    :video-link="'https://www.youtube.com/embed/o3S17yaUvTI?enablejsapi=1'"
  />
</template>

<style scoped lang="sass"></style>
