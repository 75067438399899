import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container-list" }
const _hoisted_2 = { class: "flex items-center my-4" }
const _hoisted_3 = { class: "flex flex-col items-center justify-between my-2" }
const _hoisted_4 = { class: "step-box--small my-2" }
const _hoisted_5 = { key: 0 }

import { computed, ref, watch } from 'vue'
import { useIncomeCollection } from '../model/useIncomeCollection'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import usePolitic from '@/use/usePolitic'
import AddSaveButtonGroup from './AddSaveButtonGroup.vue'
import MessageBoxSelect from '@/07-Shared/ui/MessageBoxSelect.vue'
import { ElMessageBox, ElNotification } from 'element-plus'
import useWindowWidth from '@/use/useWindowWidth'
import { ValidationAddError } from '@/components/Invoice/Declaration/support/Errors'
import onBoardingDescriber from '@/describers/OnBoardingDescriber'
import { useRouter } from 'vue-router'
import { CirclePlusFilled, RemoveFilled } from '@element-plus/icons-vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'

interface IProps {
  invoiceId: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AdditionalIncomeWidget',
  props: {
    invoiceId: {}
  },
  setup(__props: any) {

const props = __props
const invoiceStore = useInvoiceStore()
const goodId = computed(() => invoiceStore.invoice?.goods.Declaration || '')
const ItemsCollection = useIncomeCollection()
const onBoardingFields = onBoardingDescriber().onBoardingIncomeFields
function getItemsFromServer() {
  if (!invoiceStore.invoice) return
  ItemsCollection.getFromServer(goodId.value)
}
const situationList = computed(() => {
  return ItemsCollection.availableSituation.value.filter((i) => !['two_ndfl_source', 'standard_refund'].includes(i.tag))
})
const politics = usePolitic()
const { screenLessValue } = useWindowWidth()
const router = useRouter()

function show() {
  messageBox.value?.show()
}
function sendToServer() {
  if (!goodId.value) return
  ItemsCollection.setTouch()
  ItemsCollection.sendToServer(goodId.value)
    .then(() => {
      ElNotification({
        title: 'Дополнительные доходы',
        message: 'успешно сохранены',
        type: 'success',
      })
      useInvoiceRepository()
        .getInvoice(props.invoiceId)
        .then((res) => {
          useAsyncInvoiceReload()
            .initAsync(res)
            .then(() => {
              getItemsFromServer()
            })
        })
    })
    .catch((err) => {
      if (err instanceof ValidationAddError) {
        ElMessageBox.alert(
          'Вы заполнили не все поля или некорректно ввели данные. Проверьте введенную информацию и повторите попытку.',
          'Ошибка',
          {
            confirmButtonText: 'Понятно',
            type: 'error',
          }
        )
      } else {
        console.error(err)
      }
    })
}

const messageBox = ref<InstanceType<typeof MessageBoxSelect> | null>(null)

function deleteUnsaveGroup(targetName: string) {
  const msg = `Вы хотите удалить все несохраненные ситуации ${
    ItemsCollection.describers.find((item) => item.tag === targetName)?.name
  }?
      Внесенные данные вернуть будет невозможно.`
  ElMessageBox.confirm(msg, {
    confirmButtonText: 'Да, хочу удалить',
    cancelButtonText: 'Нет, не хочу удалять',
    type: 'warning',
  }).then(() => {
    // wantSet.value = true
    ItemsCollection.deleteUnsaveBySituation(targetName)
  })
}
function deleteGroup(targetName: string) {
  const msg = `Вы хотите удалить все ситуации ${
    ItemsCollection.describers.find((item) => item.tag === targetName)?.name
  }`
  ElMessageBox.confirm(msg, {
    confirmButtonText: 'Да, хочу удалить',
    cancelButtonText: 'Нет, не хочу удалять',
    type: 'warning',
  }).then(() => {
    // wantSet.value = true
    ItemsCollection.deleteBySituation(targetName)
  })
}

function createNew(name: string) {
  if (
    name === 'two_ndfl_files' &&
    ItemsCollection.showItemDescibersWithHaveElements.value.some((item) => item.tag === 'two_ndfl_files')
  ) {
    ItemsCollection.activeSituation.value = 'two_ndfl_files'
  } else {
    ItemsCollection.createNew(name)
  }
}

watch(
  goodId,
  () => {
    useInvoiceRepository()
      .getInvoice(props.invoiceId)
      .then((res) => {
        useAsyncInvoiceReload()
          .initAsync(res)
          .then(() => {
            getItemsFromServer()
          })
      })
  },
  { immediate: true }
)

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(ItemsCollection).meta.headerComponent))),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(AddSaveButtonGroup, {
          "want-save": _unref(ItemsCollection).edited.value,
          page: "AddIncomes",
          "has-items": _unref(ItemsCollection).collection.length > 0,
          onAdd: show,
          onSave: sendToServer,
          onSkip: sendToServer
        }, null, 8, ["want-save", "has-items"])
      ]),
      _createVNode(_component_el_tabs, {
        modelValue: _unref(ItemsCollection).activeSituation.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(ItemsCollection).activeSituation.value) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ItemsCollection).showItemDescibersWithHaveElements.value, (item) => {
            return (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: item.name,
              label: item.name,
              name: item.tag
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (!['standard_refund', 'two_ndfl_files', 'material_losses', 'two_ndfl_source'].includes(item.tag))
                    ? (_openBlock(), _createBlock(_component_el_button_group, {
                        key: 0,
                        class: "w-full mb-4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_tooltip, {
                            class: "item",
                            effect: "dark",
                            content: `Добавить ситуацию ${item.name}`,
                            placement: "top-start"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                icon: _unref(CirclePlusFilled),
                                type: "primary",
                                onClick: ($event: any) => (_unref(ItemsCollection).createNew(item.tag))
                              }, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                  _createTextVNode(" Добавить ")
                                ])),
                                _: 2
                              }, 1032, ["icon", "onClick"])
                            ]),
                            _: 2
                          }, 1032, ["content"]),
                          (
                _unref(ItemsCollection).haveUnsaveInActiveSituation.value && _unref(politics).isAdmin.value && !_unref(screenLessValue)(767)
              )
                            ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                key: 0,
                                class: "item",
                                effect: "dark",
                                content: `Удалить вcе ситуации несохраненные из ${item.name}`,
                                placement: "top-start"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_button, {
                                    icon: _unref(RemoveFilled),
                                    onClick: ($event: any) => (deleteUnsaveGroup(item.tag))
                                  }, {
                                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                                      _createTextVNode(" Удалить все несохраненные ")
                                    ])),
                                    _: 2
                                  }, 1032, ["icon", "onClick"])
                                ]),
                                _: 2
                              }, 1032, ["content"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_el_tooltip, {
                            class: "item",
                            effect: "dark",
                            content: `Удалить вcе ситуации из ${item.name}`,
                            placement: "top-start"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                icon: _unref(RemoveFilled),
                                type: "danger",
                                onClick: ($event: any) => (deleteGroup(item.tag))
                              }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode(" Удалить все ")
                                ])),
                                _: 2
                              }, 1032, ["icon", "onClick"])
                            ]),
                            _: 2
                          }, 1032, ["content"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ItemsCollection).itemsForActiveSituation.value, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.localUniqueId,
                      class: "w-full"
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", {
                          class: _normalizeClass([item.fieldWasEdited ? 'bg-gray' : '', "p-4"])
                        }, [
                          (Object.keys(item).length > 0)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.getActiveComponent?.cardComponent), {
                                item: item,
                                key: 'item_card_' + item.localUniqueId,
                                onRemove: 
                    () => {
                      _unref(ItemsCollection).deleteById(item.localUniqueId)
                    }
                  ,
                                onToggleEdit: 
                    () => {
                      item.toggleEdit()
                    }
                  
                              }, null, 40, ["item", "onRemove", "onToggleEdit"]))
                            : _createCommentVNode("", true)
                        ], 2),
                        (item.isEdit)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createElementVNode("div", null, [
                                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "divider my-2" }, null, -1)),
                                (_openBlock(), _createBlock(_resolveDynamicComponent(item.getActiveComponent?.component), {
                                  key: 'item_card#' + item.localUniqueId,
                                  item: item,
                                  onUpdateItem: item.onUpdateEvent,
                                  "year-to-calc": _unref(invoiceStore).invoice?.year_to_calc,
                                  "help-describer": item.getActiveComponent?.tag && _unref(onBoardingFields)[item.getActiveComponent.tag]
                                }, null, 40, ["item", "onUpdateItem", "year-to-calc", "help-describer"]))
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1032, ["label", "name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createVNode(MessageBoxSelect, {
      ref_key: "messageBox",
      ref: messageBox,
      title: "Добавление ситуации",
      value: situationList.value,
      "value-tag": "tag",
      "label-tag": "name",
      onSelect: createNew
    }, null, 8, ["value"])
  ], 64))
}
}

})