<script lang="ts" setup>
import { toRef } from 'vue'
import LoaderDescriber from '@/06-Entities/File/model/LoaderDescriber'
import useTextFilters from '@/plugins/TextFilters'
import { useRouter } from 'vue-router'
import { BrokerFileType } from '@/entityes/invoice/file/file.type'

interface IProps {
  brokerFiles: Partial<Record<BrokerFileType, { id: number; name: string }[]>>
}
const props = defineProps<IProps>()
const { subStringFileName } = useTextFilters()
const loaders = LoaderDescriber().describers
const router = useRouter()

const filesInInvoice = toRef(props.brokerFiles)

const onlyLoadedFiles = Object.keys(filesInInvoice.value).reduce((acc, key) => {
  if (filesInInvoice.value[key].length > 0) {
    const loader = loaders.find((i) => i.config.serverTag === key)
    loader.loadedFiles = filesInInvoice.value[key]
    acc.push(loader)
  }
  return acc
}, [])
</script>

<template>
  <div>
    <div class="flex-box flex-box--align-c flex-box--sb margin-v-s">
      <div>
        <h3>Загруженные отчеты</h3>
      </div>
      <div>
        <el-button @click="router.push({ name: 'file_in_invoice' })" link type="primary">
          редактировать отчеты брокеров
        </el-button>
      </div>
    </div>
    <div v-for="(loader, index) in onlyLoadedFiles" :key="index" class="margin-v-xs">
      <div class="flex-box flex-box--align-c margin-v-xs">
        <img :src="loader.config.logo" class="margin-h--right-6" :width="24" />
        <p class="font-weight--extra">{{ loader.config.title }}</p>
      </div>
      <div v-for="(file, index) in loader.loadedFiles" :key="index" class="margin-h--left-m">
        <div class="flex-box flex-box--align-c">
          <el-icon color="#4b60cecc" size="large" class="margin-h--right-6">
            <Files />
          </el-icon>
          <p>{{ subStringFileName(file.name, 30) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
