<script lang="ts" setup>
import { computed, ref, watch, inject, nextTick, watchEffect, UnwrapNestedRefs } from 'vue'
import useCountryDescriber from '@/describers/useCountryDescriber'
import currency from '@/describers/CurrencyDescriber'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { getFields } from '../model/formFields'
import { object as obj, string as str, bool, number as num } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { ElMessageBox } from 'element-plus'
import { setTouchedHelper } from '../lib/setTouchedHelper'
import CustomCheckbox from '@/07-Shared/ui/Checkbox.vue'
// import { prepareHelper } from '../lib/prepareHelper'
import { cloneDeep } from 'lodash'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber?: any
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}
const emit = defineEmits<IEmits>()

setTouchedHelper(props.item)

const methodOptions = {
  purchase: 'Покупка',
  donation: 'Дарение',
  donation_from_relatives: 'Дарение от близкого родственника',
  inheritance: 'Наследство',
  privatization: 'Приватизация',
  annuity: '<p>Рента с передачей имущества по договору<br class="br"> пожизненного содержания с иждивением</p>',
}
const expired = ref(true)
const expiredPeriod = ref(5)
watchEffect(() => {
  if (!expired.value) {
    ElMessageBox.confirm('Не подлежит декларации', {
      type: 'warning',
      message:
        'Указанный срок владения недвижимостью говорит о том, что декларировать доход от ее продажи нет необходимости.',
      confirmButtonText: 'Удалить этот доход',
      cancelButtonText: 'Не удалять',
    })
      .then(() => {
        emit('delete')
      })
      .catch(() => {
        expired.value = true
      })
  }
})

const { mainFields, otherAttributesField } = getFields(props.yearToCalc)
const schema = obj({
  ...mainFields,
  other_attributes: obj({
    ...otherAttributesField,
    amount_of_expenses: str().checkNumberString(),
    method: str().required(),
    property_type: bool(),
    // depends on shareProperty
    property_denominator: num().when('shareProperty', {
      is: true,
      then: (schema) => schema.required(),
    }),
    property_numerator: num().when('sharedProperty', {
      is: true,
      then: (schema) => schema.required(),
    }),
    // depends on rus value is true
    cadastral_number: str().test('cadastral-number-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      if (rus === true) return str().required().isValidSync(value)
      return true
    }),
    cadastral_value: str()
      .checkNumberString()
      .test('cadastral-number-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === true) return str().required().isValidSync(value)
        return true
      }),
    set_property_deduction: bool(),
    expense_currency: str()
      .length(3)
      .test('expense_currency-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const amountOfExpenses = Number(context.from?.[0].value.amount_of_expenses)
        if (rus) return true
        if (isNaN(amountOfExpenses) || amountOfExpenses <= 0) return true
        return str().required().isValidSync(value)
      }),
    expense_date: str()
      .checkYearValue(props.yearToCalc)
      .test('expense_currency-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const amountOfExpenses = Number(context.from?.[0].value.amount_of_expenses)
        if (rus) return true
        if (isNaN(amountOfExpenses) || amountOfExpenses <= 0) return true
        return str().required().isValidSync(value)
      }),
    // paid tax
    paid_tax: str().checkNumberString(),
    paid_tax_date: str().test('check_tax_date_validation', function (value) {
      const { createError, path } = this
      if (!!this.parent.paid_tax) {
        const reverse = value?.split('.').reverse().join('/') || ''
        const d = new Date(reverse)
        if (!value) return createError({ path, message: 'Поле является обязательным' })
        return (
          d.getFullYear() === props.yearToCalc ||
          createError({ path, message: 'Год не соответсвует отчетному периоду' })
        )
      }
      return true
    }),
    //helpFields
    shareProperty: bool(),
  }),
})
const validationSchema = toTypedSchema(schema)

const { values, validate, handleSubmit, errorBag, setValues } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: {
    ...{
      rus: true,
      physical_person: true,
      other_attributes: {
        property_type: false,
        method: 'purchase',
        set_property_deduction: true,
      },
    },
    ...{
      ...props.item.item,
    },
  },
})
watch(
  values,
  (value) => {
    if (value.other_attributes?.method && ['purchase', 'donation'].includes(value.other_attributes.method)) {
      expiredPeriod.value = 5
    } else {
      expiredPeriod.value = 3
    }
  },
  { immediate: true }
)
function setOksm(rus: boolean) {
  if (rus) {
    setValues({
      currency: 'RUB',
      other_attributes: {
        expense_currency: 'RUB',
        oksm: '643',
        oksm_broker: '643',
      },
    })
  } else {
    setValues({
      currency: '',
      other_attributes: {
        expense_currency: '',
        oksm: '',
        oksm_broker: '',
      },
    })
  }
}
watch(
  values,
  (val) => {
    val.rus !== undefined && setOksm(val.rus)
  },
  { immediate: true }
)

const dontHaveAmountOfExpense = computed(() => {
  return (
    values.other_attributes?.amount_of_expenses !== undefined ||
    Number(values.other_attributes?.amount_of_expenses) === 0
  )
})
async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div class="px-4 pt-4 mb-4">
    <div class="flex items-center mb-4">
      <h4>Доход получен</h4>
      <question-popover color="#021c1a4d">
        <div style="word-break: break-word" v-html="helpDescriber?.income_info"></div>
      </question-popover>
    </div>
    <div class="mb-4">
      <FormItem name="rus" v-slot="{ value, onInput }">
        <el-radio-group
          :model-value="value"
          @change="
          (val: boolean) => {
            onInput(val)
          }
        "
        >
          <el-radio :value="true">в РФ</el-radio>
          <el-radio :value="false">в иностранном государстве</el-radio>
        </el-radio-group>
      </FormItem>
    </div>
    <div v-if="!values.rus" class="flex flex-wrap mb-4">
      <div class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold">Страна источника выплаты</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.oksm"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.oksm" v-slot="{ field, value }">
          <el-select :model-value="value" v-bind="field" filterable class="width-100" placeholder="Выберите страну">
            <el-option
              v-for="item in useCountryDescriber()"
              :key="item.code"
              :value="item.code"
              :label="item.shortname"
            />
          </el-select>
        </form-item>
      </div>
      <div>
        <div class="flex items-center mb-1">
          <p class="font-semibold">Страна зачисления выплаты</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.oksm_broker"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.oksm_broker" v-slot="{ value, field }">
          <el-select :model-value="value" v-bind="field" filterable placeholder="Выберите страну">
            <el-option
              v-for="item in useCountryDescriber()"
              :key="item.code"
              :value="item.code"
              :label="item.shortname"
            />
          </el-select>
        </form-item>
      </div>
    </div>
    <div class="mb-8">
      <div class="flex items-center mb-1">
        <h4>Способ приобретения</h4>
        <question-popover color="#021c1a4d">
          <div style="word-break: break-word" v-html="helpDescriber?.method"></div>
        </question-popover>
      </div>
      <FormItem name="other_attributes.method" v-slot="{ value, onInput }">
        <el-radio-group
          :model-value="value"
          @change="
          (val: boolean) => {
            onInput(val)
          }
        "
        >
          <el-radio v-for="(item, key) in methodOptions" :key="key" :value="key">
            <span v-html="item"></span>
          </el-radio>
        </el-radio-group>
      </FormItem>
    </div>
    <el-row>
      <el-col :span="8">
        <div>
          <div class="flex items-center mb-1">
            <h4>Срок владения</h4>
            <question-popover color="#021c1a4d">
              <div style="word-break: break-word" v-html="helpDescriber?.expired"></div>
            </question-popover>
          </div>
          <el-switch
            v-model="expired"
            :active-text="`Менее ${expiredPeriod} лет`"
            :inactive-text="`Более ${expiredPeriod} лет`"
          />
        </div>
      </el-col>
      <el-col :span="8">
        <div>
          <div class="flex items-center mb-1">
            <h4>Тип недвижимости</h4>
            <question-popover color="#021c1a4d">
              <div style="word-break: break-word" v-html="helpDescriber?.type"></div>
            </question-popover>
          </div>
          <form-item name="other_attributes.property_type" v-slot="{ field, value }">
            <el-switch :model-value="value" v-bind="field" active-text="Нежилая" inactive-text="Жилая" />
          </form-item>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="flex items-center mb-1">
          <h4>Продажа имущества</h4>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.share"></div>
          </question-popover>
        </div>
        <div :class="[values?.other_attributes?.shareProperty ? 'margin-v-xs' : '']">
          <form-item name="other_attributes.shareProperty" v-slot="{ field, value }">
            <el-switch :model-value="value" v-bind="field" active-text="Доли" inactive-text="Целого" />
          </form-item>
        </div>
        <div v-if="values?.other_attributes?.shareProperty" class="flex">
          <div>
            <form-item name="other_attributes.property_numerator" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" placeholder="1" v-mask="['####']" />
            </form-item>
          </div>
          <div>
            <h1 class="separator">/</h1>
          </div>
          <div>
            <form-item name="other_attributes.property_denominator" class="share_input" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" placeholder="1" v-mask="['####']" />
            </form-item>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="el-divider-purple my-4"></div>
  <div class="px-4">
    <h4 class="mb-4">Данные о доходе</h4>
    <div class="flex flex-wrap mb-4">
      <div class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold">Сумма дохода от продажи</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.amount"></div>
          </question-popover>
        </div>
        <form-item name="amount" v-slot="{ field, value }">
          <el-input :model-value="value" v-bind="field" placeholder="Сумма, руб." />
        </form-item>
      </div>
      <div v-if="!values.rus" class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold">Валюта продажи</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.currency"></div>
          </question-popover>
        </div>
        <form-item name="currency" v-slot="{ field, value }">
          <el-select :model-value="value" v-bind="field" filterable class="width-100" placeholder="Укажите валюту">
            <el-option v-for="item in currency" :key="item.code" :value="item.code" :label="item.code" />
          </el-select>
        </form-item>
      </div>
      <div class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold">Дата получения дохода</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.date"></div>
          </question-popover>
        </div>
        <form-item name="date" v-slot="{ field, value }">
          <el-date-picker
            :model-value="value"
            v-bind="field"
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
          />
        </form-item>
      </div>
      <div v-if="values.rus" class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold">Кадастровый номер</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.cadastral_number"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.cadastral_number" v-slot="{ field, value }">
          <el-input :model-value="value" v-bind="field" />
        </form-item>
      </div>
      <div v-if="values.rus">
        <div class="flex items mb-1">
          <p class="font-semibold">Кадастровая стоимость</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.cadastral_value"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.cadastral_value" v-slot="{ field, value }">
          <el-input :model-value="value" v-bind="field" />
        </form-item>
      </div>
    </div>
    <!-- расходы на покупку -->
    <div class="flex flex-wrap mb-4">
      <div class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold">Сумма расходов на покупку</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.amount_of_expenses"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.amount_of_expenses" v-slot="{ field, value }">
          <el-input :model-value="value" v-bind="field" />
        </form-item>
      </div>
      <div
        class="mr-4 mb-4"
        v-if="
          !values.rus &&
          values.other_attributes?.amount_of_expenses &&
          Number(values.other_attributes.amount_of_expenses) > 0
        "
      >
        <div class="flex mb-1 items-center">
          <p class="font-semibold">Валюта покупки</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.expense_currency"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.expense_currency" v-slot="{ field, value }">
          <el-select :model-value="value" v-bind="field" filterable class="w-full" placeholder="Укажите валюту">
            <el-option v-for="item in currency" :key="item.code" :value="item.code" :label="item.code" />
          </el-select>
        </form-item>
      </div>
      <div
        v-if="
          !values.rus &&
          values.other_attributes?.amount_of_expenses &&
          Number(values.other_attributes.amount_of_expenses) > 0
        "
      >
        <div class="flex items-center mb-1">
          <p class="font-semibold">Дата покупки</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.expense_date"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.expense_date" v-slot="{ field, value }">
          <el-date-picker
            :model-value="value"
            v-bind="field"
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
          />
        </form-item>
      </div>
    </div>
    <!-- end расходы на покупку -->
    <!-- в иностранном гос-ве -->
    <div v-if="!values.rus" class="flex items-center flex-wrap mb-4">
      <div class="mr-4">
        <div class="flex mb-1 items-center">
          <p class="font-semibold">Налог в иностранном государстве</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.paid_tax"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.paid_tax" v-slot="{ field, value }">
          <el-input :model-value="value" v-bind="field" />
        </form-item>
      </div>
      <div>
        <div class="flex mb-1 items-center">
          <p class="font-semibold">Дата уплаты налога</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber?.paid_tax_date"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.paid_tax_date" v-slot="{ field, value }">
          <el-date-picker
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
            :model-value="value"
            v-bind="field"
          />
        </form-item>
      </div>
    </div>
    <!-- end в иностранном гос-веу -->
    <form-item name="other_attributes.set_property_deduction" v-slot="{ field, value }">
      <CustomCheckbox v-if="dontHaveAmountOfExpense" :value="value" v-bind="field">
        Применить имущественный вычет
      </CustomCheckbox>
    </form-item>
  </div>
  <div class="el-divider-purple my-4"></div>
  <div class="px-4 mb-4">
    <div class="flex items-center mb-1">
      <p class="font-semibold">Другая сторона договора</p>
      <question-popover color="#021c1a4d">
        <div style="word-break: break-word" v-html="helpDescriber?.other_side"></div>
      </question-popover>
    </div>
    <div class="mb-4" v-if="values.rus">
      <form-item name="physical_person" v-slot="{ field, value }">
        <el-radio-group v-bind="field" :model-value="value">
          <el-radio :value="false">Юридическое лицо</el-radio>
          <el-radio :value="true">Физическое лицо</el-radio>
        </el-radio-group>
      </form-item>
    </div>
    <div class="mb-4">
      <div v-if="values.rus">
        <div v-if="values.physical_person">
          <div class="flex">
            <div>
              <p class="font-semibold mb-1">ФИО</p>
              <form-item class="rb-margin-m" name="other_attributes.name" v-slot="{ field, value }">
                <el-input :model-value="value" v-bind="field" />
              </form-item>
            </div>
          </div>
        </div>
        <div class="flex items-center flex-wrap" v-else>
          <div class="mr-4">
            <p class="font-semibold mb-1">Наименование организации</p>
            <form-item class="rb-margin-m" name="other_attributes.name_organization" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" />
            </form-item>
          </div>
          <div class="mr-4">
            <p class="font-semibold mb-1">ИНН</p>
            <form-item name="other_attributes.inn" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" placeholder="123456789012" v-mask="['############']" />
            </form-item>
          </div>
          <div class="mr-4">
            <p class="font-semibold mb-1">КПП</p>
            <form-item name="other_attributes.kpp" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" placeholder="123456789" v-mask="['#########']" />
            </form-item>
          </div>
          <div>
            <p class="mb-1 font-semibold">ОКТМО</p>
            <form-item name="other_attributes.oktmo" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" placeholder="12345678" v-mask="['########']" />
            </form-item>
          </div>
        </div>
      </div>
      <div class="flex" v-else>
        <div>
          <p class="font-semibold mb-1">Наименование</p>
          <form-item name="other_attributes.name_income" v-slot="{ field, value }">
            <el-input :model-value="value" v-bind="field" />
          </form-item>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.separator
  margin: 8px 10px 0 10px
</style>
