import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/new_logo.svg'


const _hoisted_1 = { class: "left-menu-wrapper bg-gray padding--10" }
const _hoisted_2 = { class: "margin-v-xs flex-box flex-box--wrap flex-box--sb flex-box--column" }
const _hoisted_3 = {
  key: 1,
  class: "cursor-point clickable"
}
const _hoisted_4 = { class: "flex-box flex-box--align-c" }
const _hoisted_5 = { class: "bg-gray" }
const _hoisted_6 = { href: "tel:+74954142898" }
const _hoisted_7 = { class: "margin-v-xs clickable flex-box flex-box--align-c" }
const _hoisted_8 = ["onClick"]

import useMenuDescription from '@/describers/MenuDescriber'
import { computed, ref } from 'vue'
import NgAdminMenu from '@/components/NgMenu/NgAdminMenu.vue'
import usePolitic from '@/use/usePolitic'
import { useRouter } from 'vue-router'
import Finder from '@/components/Admin/Finder.vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LeftSideBar',
  setup(__props) {

const { accountMenuDescriptor } = useMenuDescription()
const bottomMenu = computed(() => accountMenuDescriptor.value.filter((_, index) => index !== 0))
const { isAdmin, isPartner } = usePolitic()
const router = useRouter()
const invoiceVideo = ref(null)
// const open = () => {
//   invoiceVideo.value.openVideoModal()
// }

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_PhoneFilled = _resolveComponent("PhoneFilled")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "margin-v-xs"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "logo",
                  width: "150"
                })
              ], -1)
            ])),
            _: 1
          }),
          (_unref(isAdmin) || _unref(isPartner))
            ? (_openBlock(), _createBlock(Finder, { key: 0 }))
            : _createCommentVNode("", true),
          (_unref(isAdmin))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_el_popover, {
                  placement: "right",
                  width: 200,
                  trigger: "hover"
                }, {
                  reference: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_el_icon, {
                        color: "#4b60cecc",
                        class: "margin-h--right-10",
                        size: 18
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Menu)
                        ]),
                        _: 1
                      }),
                      _cache[2] || (_cache[2] = _createElementVNode("p", null, "Меню админа", -1))
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(NgAdminMenu)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["flex-box flex-box--align-c clickable cursor-point margin-v-xs", 
          _unref(router).currentRoute.value.name === 'account home' ? 'bg-purple font-color--white font-weight--semi' : ''
        ]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(accountMenuDescriptor)[0].clickAction && _unref(accountMenuDescriptor)[0].clickAction(...args)))
        }, [
          _createVNode(_component_el_icon, {
            size: 18,
            class: "margin-h--right-10"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(accountMenuDescriptor)[0].icon)))
            ]),
            _: 1
          }),
          _createElementVNode("p", null, _toDisplayString(_unref(accountMenuDescriptor)[0].title), 1)
        ], 2),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "el-divider-purple margin-v-xs" }, null, -1))
      ]),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("div", {
          class: _normalizeClass(_unref(isAdmin) || _unref(isPartner) ? 'left-menu-admin' : 'left-menu-slot')
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("a", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_el_icon, { size: 18 }, {
                default: _withCtx(() => [
                  _createVNode(_component_PhoneFilled)
                ]),
                _: 1
              }),
              _cache[4] || (_cache[4] = _createElementVNode("p", null, "+7(495)414-28-98", -1))
            ])
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "el-divider-purple margin-v-xs" }, null, -1)),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bottomMenu.value, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.title,
              onClick: item.clickAction,
              class: _normalizeClass(["flex-box flex-box--align-c cursor-point clickable margin-v-xs", 
            _unref(router).currentRoute.value.name === 'customer show' && index === 0
              ? 'bg-purple font-color--white font-weight--semi'
              : ''
          ])
            }, [
              _createVNode(_component_el_icon, {
                size: 18,
                class: "margin-h--right-6"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                ]),
                _: 2
              }, 1024),
              _createElementVNode("p", null, _toDisplayString(item.title), 1)
            ], 10, _hoisted_8))
          }), 128))
        ])
      ])
    ]),
    _createVNode(NgInvoiceVideo, {
      ref_key: "invoiceVideo",
      ref: invoiceVideo,
      "show-button": false,
      "video-link": 'https://www.youtube.com/embed/o3S17yaUvTI?enablejsapi=1'
    }, null, 512)
  ], 64))
}
}

})