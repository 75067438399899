import { useRequestService } from '@/use/useRequestService'
import { ICfrInfo } from '@/entityes/invoice/cfr/cfr.type'

export function useGetOnlineCfrInfo() {
  const { fetch } = useRequestService()

  function getCfrOnlineInfo(goodId: string | number): Promise<ICfrInfo> {
    return new Promise((resolve, reject) => {
      fetch<ICfrInfo>(`/cfrs/${goodId}/show_accounts_and_cash`)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  return {
    getCfrOnlineInfo,
  }
}
