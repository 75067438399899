import { IAddItem } from '@/06-Entities/IncomeAndRefund'
import SocialDeduction from '../ui/SocialDeduction.vue'
import SocialCard from '../ui/cards/SocialCard.vue'
import MaterialLossesCard from '../ui/cards/MaterialLossesCard.vue'
import materialLosses from '@/assets/pre_add_incomes_icons/material_losses.svg'
import iisIcon from '@/assets/pre_add_incomes_icons/iis.svg'
import MaterialLosses from '../ui/MaterialLosses.vue'
import IisCard from '../ui/cards/IisCard.vue'
import IIS from '../ui/IIS.vue'
import propertySaleIcon from '@/assets/pre_add_incomes_icons/property_sale.svg'
import PropertyDeduction from '../ui/PropertyDeduction.vue'
import OtherCard from '../ui/cards/OtherCard.vue'

export const DeductionDescriber: IAddItem[] = [
  {
    name: 'Социальные вычеты',
    tag: 'social_refund',
    component: SocialDeduction,
    cardComponent: SocialCard,
    meta: {
      onlyOne: true,
      show_pre: false,
    },
  },
  {
    name: 'Убытки прошлых лет (справок/деклараций)',
    tag: 'material_losses',
    component: MaterialLosses,
    cardComponent: MaterialLossesCard,
    icon: materialLosses,
    meta: {
      onlyOne: true,
      show_pre: true,
    },
  },
  {
    name: 'Вычет по ИИС типа А',
    tag: 'iis',
    component: IIS,
    cardComponent: IisCard,
    icon: iisIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },
  {
    name: 'Проценты по ипотеке',
    tag: 'property_deduction_mortgage',
    component: PropertyDeduction,
    cardComponent: null,
    icon: propertySaleIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },
  {
    name: 'Покупка или строительство жилой недвижимости',
    tag: 'property_deduction_build',
    component: PropertyDeduction,
    cardComponent: null,
    icon: propertySaleIcon,
    meta: {
      onlyOne: false,
      show_pre: true,
    },
  },
  {
    name: 'Стандартные вычеты',
    tag: 'standard_refund',
    component: null,
    cardComponent: OtherCard,
    meta: {
      onlyOne: false,
      show_pre: false,
    },
  },
] as const
