export interface FileTransformed {
  base?: string
  name: string
}

export default function useFileBase64Converter() {
  const fileReading = (file: File) => {
    return new Promise<FileTransformed>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve({
          base: reader.result?.toString(),
          name: file.name,
        })
      }
      function myError() {
        reject(new Error('Ошибка при чтении файла'))
      }
      reader.onabort = myError
      reader.onerror = myError
      reader.readAsDataURL(file)
    })
  }

  const fileTransform = (files: File[]) => {
    return Promise.all(files.map((item) => fileReading(item)))
  }

  return {
    fileTransform,
  }
}
