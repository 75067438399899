<template>
  <h1 class="margin-v-xs">Дополнительные доходы</h1>
  <p class="margin-v-xs">Укажите дополнительные доходы за рубежом или в РФ.</p>
  <p class="margin-v-xs">
    К дополнительным доходам могут относиться дивиденды, доходы от сдачи имущества в аренду или от его продажи и т.д.
  </p>
  <p class="margin-v-s">Если у вас нет дополнительных доходов, пропустите этот шаг.</p>
</template>

<script lang="ts" setup>
import useWindowWidth from '@/use/useWindowWidth'

const { screenLessValue } = useWindowWidth()
</script>

<style lang="sass" scoped></style>
