import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-2xl mb-4"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "flex justify-between" }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { class: "flex justify-between" }

import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import SocialLimit from './SocialLimit.vue'
import { computed, UnwrapNestedRefs } from 'vue'

interface IProps {
  yearToCalc: number
  items: UnwrapNestedRefs<AdditionalItem<Deduction>>[]
}

export default /*@__PURE__*/_defineComponent({
  __name: 'LimitDeductiontInfo',
  props: {
    yearToCalc: {},
    items: {}
  },
  setup(__props: any) {

const props = __props
const socialRefund = computed(() =>
  props.items.filter((item) => {
    return item.situationName === 'social_refund'
  })
)
const propertyDeductionMortgageSum = computed(() =>
  props.items
    .filter((item) => item.situationName === 'property_deduction_mortgage')
    .reduce((acc, item) => (acc += item.item.sum) || 0, <number>0)
)
const propertyTaxDeduction = computed(() =>
  props.items
    .filter((item) => item.situationName === 'property_tax_deduction')
    .reduce((acc, item) => (acc += item.item.sum) || 0, <number>0)
)
const iis = computed(() =>
  props.items
    .filter((item) => item.situationName === 'iis')
    .reduce((acc, item) => (acc += Number(item.item.sum) || 0), <number>0)
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.items.length > 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, "Лимиты"))
      : _createCommentVNode("", true),
    _createVNode(SocialLimit, {
      items: socialRefund.value,
      yearToCalc: _ctx.yearToCalc
    }, null, 8, ["items", "yearToCalc"]),
    (props.items.filter((item) => item.situationName === 'property_deduction_mortgage').length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "w-5/6" }, "Сумма вычета по процентам по ипотеке", -1)),
            _createElementVNode("p", {
              class: _normalizeClass(["w-1/6", { 'text-red-600': propertyDeductionMortgageSum.value > 3000000 }])
            }, [
              _createElementVNode("b", null, _toDisplayString(propertyDeductionMortgageSum.value) + "/" + _toDisplayString(3000000), 1),
              _cache[0] || (_cache[0] = _createTextVNode(" рублей. "))
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (props.items.filter((item) => item.situationName === 'property_tax_deduction').length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "w-5/6" }, "Сумма вычета за покупку жилья", -1)),
            _createElementVNode("p", {
              class: _normalizeClass(["w-1/6", { 'text-red-600': propertyTaxDeduction.value > 2000000 }])
            }, [
              _createElementVNode("b", null, _toDisplayString(propertyTaxDeduction.value) + "/" + _toDisplayString(2000000), 1),
              _cache[2] || (_cache[2] = _createTextVNode(" рублей. "))
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (props.items.filter((item) => item.situationName === 'iis').length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "w-5/6" }, "Сумма вычета по ИИС", -1)),
            _createElementVNode("p", {
              class: _normalizeClass(["w-1/6", { 'text-red-600': iis.value > 400000 }])
            }, [
              _createElementVNode("b", null, [
                _createElementVNode("span", null, _toDisplayString(iis.value), 1),
                _cache[4] || (_cache[4] = _createTextVNode("/" + _toDisplayString(400000)))
              ]),
              _cache[5] || (_cache[5] = _createTextVNode(" рублей. "))
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})