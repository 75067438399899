import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_3 = {
  key: 1,
  class: "text-strike font-color--deep-gray",
  style: {"font-size":"12px"}
}

import { computed } from 'vue'

export interface IProps {
  good: { [key: string]: number | string | any }
  addDeductions: number
  hasShitOnly: boolean
  hasTinkoffOnly: boolean
  cfrBucketSum: number
  cfrBucketBaseSum: number
  baseBundleDiscount: number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BasketCard',
  props: {
    good: {},
    addDeductions: {},
    hasShitOnly: { type: Boolean },
    hasTinkoffOnly: { type: Boolean },
    cfrBucketSum: {},
    cfrBucketBaseSum: {},
    baseBundleDiscount: {}
  },
  setup(__props: any) {

const props = __props
const cfrPrice = computed(() => (props.hasShitOnly ? 0 : props.cfrBucketSum))
const cfrBasePrice = computed(() => (props.hasShitOnly ? 0 : props.cfrBucketBaseSum))
const price = computed(() => {
  if (props.good.name === 'bundle') {
    return props.good.price.value + props.addDeductions + cfrPrice.value
  } else if (props.good.name === 'declaration_pdf' || props.good.name === 'tax_plan') {
    return props.good.price.value
  } else if (props.hasShitOnly && props.good.name === 'Declaration') {
    return 5700 + props.addDeductions
  } else {
    return props.good.price.value + cfrPrice.value + props.addDeductions
  }
})
const basePrice = computed(() => {
  const shitOnlyNoDiscounts =
    props.hasShitOnly || props.hasTinkoffOnly ? 0 : cfrBasePrice.value + props.baseBundleDiscount
  if (props.good.name === 'bundle') {
    return props.hasShitOnly
      ? props.cfrBucketBaseSum
      : props.good.basePrice.value + props.addDeductions + shitOnlyNoDiscounts
  } else if (props.good.name === 'Declaration') {
    return props.good.basePrice.value + props.addDeductions + shitOnlyNoDiscounts
  } else if (props.hasShitOnly && props.good.name === 'Declaration') {
    return 6000
  } else {
    return props.hasShitOnly ? props.cfrBucketBaseSum : props.good.basePrice.value + cfrBasePrice.value
  }
})
const showBasePrice = computed(() => props.good.name !== 'declaration_pdf' && props.good.name !== 'tax_plan')

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.good.title), 1),
    _createElementVNode("div", null, [
      (_ctx.good.name !== 'tax_plan')
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, "₽ " + _toDisplayString(price.value), 1))
        : _createCommentVNode("", true),
      (showBasePrice.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, "₽ " + _toDisplayString(basePrice.value), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})