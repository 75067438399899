<script lang="ts" setup>
import {useUserStore} from "@/entityes/user/userStore";
import { computed } from 'vue'
import { useRouter } from 'vue-router'
// import { useProfilesRepository } from '@/entityes/profiles/profiles.repository'
import { IUser } from '@/entityes/user/user.type'
import {useProfilesStore} from '@/entityes/profiles/profiles.store'
import {useTaxPlanRepository} from '@/entityes/invoice/tax_plan/tax_plan.repository'
import {useTaxPlanStore} from '@/entityes/invoice/tax_plan/tax_plan.store'
import { ITaxPlan } from '@/entityes/invoice/tax_plan/tax_plan.type'
import { ElNotification } from 'element-plus'
import { CirclePlusFilled, Loading } from '@element-plus/icons-vue'
import useWindowWidth from "@/use/useWindowWidth";
import TaxPlanCard from "@/07-Shared/ui/TaxPlanCard.vue";
import useTypeWriter from "@/plugins/useTypeWriter";

const userStore = useUserStore()
// const profilesStore = useProfilesStore()
const taxPlanStore = useTaxPlanStore()
// const { getUserProfiles } = useProfilesRepository()
const { getTaxPlanList, createNewTaxPlan } = useTaxPlanRepository()
const router = useRouter()
const user = computed(() => userStore.user as IUser)
const { screenLessValue } = useWindowWidth()
const { renderString } = useTypeWriter(['расчет...', 'минуточку...', 'еще чуть...:)'])

function createTaxPlans() {
  createNewTaxPlan(user.value.active_profile_id, { name: 'Налоговое планирование'}).then((res) => {
    ElNotification({
      title: `Успешно`,
      message: `создан таксплан ${res.id}`,
      type:  'success',
    })
    router.push({
      name: 'tax plan show',
      params: {
        id: res.id,
      },
    })
  }).catch((err) => {
    ElNotification({
      title: `Ошибка`,
      message: `создания таксплана. ${err}`,
      type:  'error',
    })
  })
}
function moveToTaxPlan(taxPlan: ITaxPlan) {
  router.push( {name: 'tax plan show', params: { id: taxPlan.id } })
}
getTaxPlanList(user.value?.active_profile_id).catch((err) => {
  ElNotification({
    title: `Ошибка`,
    message: `получения списка такспланов. ${err}`,
    type:  'error',
  })
})
</script>

<template>
  <div class="container-list">
    <div v-if="screenLessValue(767)">
      <div v-if="user.active_profile_id">
        <tax-plan-card
          v-for="plan in taxPlanStore.taxPlans"
          :key="plan.id"
          :plan="plan"
          :profile_id="user.active_profile_id"
          class="margin-v-xs"
        />
      </div>
    </div>
    <div v-else>
      <div class="mb-8 flex items-center justify-between">
        <div>
          <span class="font-bold mr-2">Активный профиль:</span>
          <span class="mr-2">{{ user.active_profile_id }}</span>
        </div>
        <el-button :icon="CirclePlusFilled" type="primary" @click="createTaxPlans">
          Создать
        </el-button>
      </div>
      <div>
        <el-table
          :data="taxPlanStore.taxPlans"
          @row-click="moveToTaxPlan"
          style="max-width: 1200px; cursor: pointer"
          border
          flexible
          highlight-current-row
          v-if="taxPlanStore.taxPlans"
        >
          <el-table-column prop="id" label="Номер таксплана" />
          <el-table-column prop="invoice_id" label="Номер инвойса" />
          <el-table-column prop="name" label="Название" />
          <el-table-column prop="start_date" label="Дата старта" />
          <el-table-column prop="finish_date" label="Дата финиша" />
          <el-table-column prop="async" label="Расчет">
            <template #default="scope">
              <div v-if="scope.row.async" class="flex items-center">
                <el-icon class="el-icon-loading margin-h--right-6" color="#FF9A9D">
                  <Loading />
                </el-icon>
                <p class="font-color--red">{{ renderString }}</p>
              </div>
              <div v-else>
                <p class="font-color--green">Посчитан</p>
              </div>
            </template>
          </el-table-column>

        </el-table>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
