<template>
  <el-row align="middle">
    <el-col :lg="4" class="margin-v-xs">
      <h3 class="font-color--gray margin-v-xs">Наименование</h3>
      <p>{{ incomeSourceName }}</p>
    </el-col>
    <el-col :lg="5" class="margin-v-xs">
      <h3 class="font-color--gray margin-v-xs">Дата</h3>
      <p>{{ item.item?.other_attributes?.date || 'не выбрано' }}</p>
    </el-col>
    <el-col :lg="4" class="margin-v-xs">
      <h3 class="font-color--gray margin-v-xs">Контракт id</h3>
      <p>{{ item.item?.other_attributes?.contract_id || 'не указан' }}</p>
    </el-col>
    <el-col :lg="4" class="margin-v-xs">
      <h3 class="font-color--gray margin-v-xs">Сумма</h3>
      <p>{{ item.item?.sum || 0 }}</p>
    </el-col>
    <el-col :lg="4" class="margin-v-xs">
      <p class="font-weight--extra">
        <span v-if="item?.valueFromServer && !item.fieldWasEdited" class="font-color--green">Вычет сохранен</span>
        <span v-else class="font-color--red">Доход не сохранен</span>
      </p>
    </el-col>
    <el-col :lg="3" class="b-margin-top">
      <edit-save-card-button-group @toggle="item.toggleEdit" @remove="$emit('remove')" />
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { computed, UnwrapNestedRefs } from 'vue'
interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'remove'): void
}
const emit = defineEmits<IEmits>()
const incomeSourceName = computed(() => {
  return (
    props.item.item?.name ||
    props.item.item?.other_attributes?.name_organization ||
    props.item.item?.other_attributes?.name ||
    'Нет'
  )
})
</script>

<style lang="sass" scoped></style>
