import { defineStore } from 'pinia'
import { IInvoice, IInvoiceList } from './invoice.type'
import { ref } from 'vue'

export const useInvoiceStore = defineStore('invoice store', () => {
  const invoices = ref<IInvoiceList | undefined>(undefined)
  const invoice = ref<IInvoice | null>(null)
  const userShowInvoices = ref<IInvoiceList | undefined>(undefined)
  return {
    invoices,
    invoice,
    userShowInvoices,
  }
})
