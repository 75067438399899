import { useRequestService } from '@/use/useRequestService'
import { IDeclaration } from '@/entityes/invoice/declaration/declaration.type'

export function useUpdateDeclarationVersion() {
  const { fetch } = useRequestService()

  function updateDeclarationVersion(declarationId: Pick<IDeclaration, 'id'>, body: { correction_number: number }) {
    return fetch(`/declarations/${declarationId}/update_correction_number`, {
      method: 'patch',
      body
    })
  }

  return {
    updateDeclarationVersion
  }
}
