<script lang="ts" setup>
import SidebarLoader from '@/05-Features/SidebarLoader/ui/SidebarLoader.vue'
import { ILoaderDescriber } from '@/entityes/invoice/file/loader/loader.type'
import { computed, ref } from 'vue'
import { FileLoaderType, LoaderDescriberType } from '../model'
import AddedFiles from './AddedFiles.vue'

interface IProps {
  brokerName: string
  describer: FileLoaderType
}
interface IEmits {
  (e: 'update'): void
}
const emit = defineEmits<IEmits>()
const props = defineProps<IProps>()
const activeLoader = computed(() => props.describer)
const showSidebar = ref<boolean>(false)
const hasLocalFiles = computed<boolean>(() => props.describer.localFiles.files.length > 0 || false)
</script>
<template>
  <div>
    <el-button type="primary" @click="() => (showSidebar = true)" class="button-main" v-if="!hasLocalFiles">
      <el-icon class="el-icon-circle-plus font-weight--extra margin-h--right-10"><CirclePlusFilled /></el-icon>
      Загрузить файлы {{ brokerName }}
    </el-button>
    <h3 v-else class="margin-v-s">Файлы {{ brokerName }} к загрузке</h3>
    <div>
      <AddedFiles
        :files="describer.localFiles.file_names"
        @delete-loaded-file="
          (index) => {
            describer.onRemoveLocalFile(index)
            emit('update')
          }
        "
      />
    </div>
  </div>

  <SidebarLoader
    v-model:show-sidebar="showSidebar"
    :loader-describer="activeLoader"
    :show-delete-button="true"
    @readed-file="
      (value) => {
        activeLoader.onUpdateFilesEvent(value)
        emit('update')
      }
    "
    @delete-local-file="
      (value) => {
        activeLoader.onRemoveLocalFile(value)
        emit('update')
      }
    "
    @delete-server-file="
      (value) => {
        activeLoader.onAddDeleteFile(value)
        emit('update')
      }
    "
    @undo-delete-file="
      () => {
        activeLoader.undoDeleteFiles()
        emit('update')
      }
    "
  />
  <!-- <ng-global-right-side-bar ref="globalRightSidebar" @click="closeSidebarAnyArea"> -->
  <!--   <ng-upload :active-loaders="activeLoaders" :sidebar="globalRightSidebar" /> -->
  <!--   <ib-download-file-instruction :broker="activeLoaders?.config?.serverTag" class="padding-v-l" /> -->
  <!-- </ng-global-right-side-bar> -->
</template>
<style lang="sass"></style>
