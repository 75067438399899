import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "el-overlay-message-box" }
const _hoisted_2 = { class: "el-message-box" }
const _hoisted_3 = { class: "el-message-box__header" }
const _hoisted_4 = { class: "el-message-box__content" }
const _hoisted_5 = { class: "el-message-box__input" }
const _hoisted_6 = { class: "flex-box flex-box--align-c flex-box--sb" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "el-message-box__btns" }

import { ref } from 'vue'
import onBoardingDescriber from '@/describers/OnBoardingDescriber'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import useWindowWidth from '@/use/useWindowWidth'
import useTextFilters from '@/plugins/TextFilters'

interface IProps {
  title: string
  labelTag: string
  valueTag: string
  value: Record<string, any>[]
}
interface IEmits {
  (e: 'select', value: string): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MessageBoxSelect',
  props: {
    title: {},
    labelTag: { default: 'label' },
    valueTag: { default: 'value' },
    value: {}
  },
  emits: ["select"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props
const emit = __emit
const visible = ref(false)
const selectValue = ref<string>('')
const { screenLessValue } = useWindowWidth()
const { subStringText } = useTextFilters()
const { onBoarding } = onBoardingDescriber()
function show() {
  visible.value = true
}
function close() {
  visible.value = false
}
function ok() {
  emit('select', selectValue.value)
  selectValue.value = ''
  close()
}
function closeNotArea(e: MouseEvent) {
  if (((e?.target as HTMLElement)?.firstChild as HTMLElement)?.className === 'el-message-box') visible.value = false
}
__expose({
  show,
  close,
})

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!

  return (visible.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "el-overlay is-message-box",
        onClick: closeNotArea
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
              _createElementVNode("button", {
                class: "el-message-box__headerbtn",
                onClick: close
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("i", { class: "el-icon el-message-box__close" }, [
                  _createElementVNode("svg", {
                    viewBox: "0 0 1024 1024",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("path", {
                      fill: "currentColor",
                      d: "M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
                    })
                  ])
                ], -1)
              ]))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_select, {
                    modelValue: selectValue.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectValue).value = $event)),
                    class: "el-input",
                    placeholder: "Выберите дополнительный доход"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item[_ctx.valueTag],
                          label: item[_ctx.labelTag],
                          value: item[_ctx.valueTag]
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("span", null, _toDisplayString(_unref(screenLessValue)(767) && item[_ctx.valueTag] === 'assignment_of_rights'
                        ? _unref(subStringText)(item[_ctx.labelTag], 3)
                        : item[_ctx.labelTag]), 1),
                              (!_unref(screenLessValue)(1200))
                                ? (_openBlock(), _createBlock(QuestionPopover, {
                                    key: 0,
                                    color: '#4b60cecc'
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(onBoarding), (desc, index) => {
                                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                                          _createElementVNode("div", {
                                            style: {"word-break":"break-word"},
                                            innerHTML: desc[item?.tag]
                                          }, null, 8, _hoisted_7)
                                        ]))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_el_button, { onClick: close }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Отменить")
                ])),
                _: 1
              }),
              (selectValue.value !== '')
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    type: "primary",
                    onClick: ok
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Добавить")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})