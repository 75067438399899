import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-weight--extra" }
const _hoisted_2 = {
  key: 0,
  class: "font-color--green"
}
const _hoisted_3 = {
  key: 1,
  class: "font-color--red"
}

import { computed } from 'vue'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import useWindowWidth from '@/use/useWindowWidth'
import { AdditionalIncome, AdditionalItem } from '@/06-Entities/IncomeAndRefund'

interface IProps {
  item: AdditionalItem<AdditionalIncome>
}
interface IEmits {
  (e: 'remove'): void
  (e: 'toggle-edit'): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCard',
  props: {
    item: {}
  },
  emits: ["remove", "toggle-edit"],
  setup(__props: any, { emit: __emit }) {

const { screenLessValue } = useWindowWidth()
const props = __props

const incomeSourceName = computed(() => {
  return (
    props.item.item?.other_attributes?.name_income ||
    props.item.item?.other_attributes?.name_organization ||
    props.item.item?.other_attributes?.name ||
    '-'
  )
})

const currency = computed(() => props.item.item?.currency || '-')

const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { align: "middle" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 7,
        md: 7,
        lg: 5,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Наименование", -1)),
          _createElementVNode("p", null, _toDisplayString(incomeSourceName.value), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        md: 4,
        lg: 4,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Дата", -1)),
          _createElementVNode("p", null, _toDisplayString(!_ctx.item.item.date || _ctx.item.item.date === 'null' ? '-' : _ctx.item.item.date), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        md: 4,
        lg: 3,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Валюта", -1)),
          _createElementVNode("p", null, _toDisplayString(currency.value || '-'), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 4,
        md: 4,
        lg: 4,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "font-color--deep-gray margin-v-xs" }, "Сумма", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.item.item?.amount || '-'), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: 5,
        md: 5,
        lg: 4,
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-m' : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, [
            (_ctx.item.valueFromServer && !_ctx.item.fieldWasEdited)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Доход сохранен"))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "Доход не сохранен"))
          ])
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_el_col, {
        xs: 24,
        sm: { offset: 0, span: 24 },
        md: { offset: 0, span: 24 },
        lg: { offset: 1, span: 3 },
        class: _normalizeClass(_unref(screenLessValue)(1200) ? 'margin-top-s' : '')
      }, {
        default: _withCtx(() => [
          _createVNode(EditSaveCardButtonGroup, {
            onToggle: _cache[0] || (_cache[0] = ($event: any) => (emit('toggle-edit'))),
            onRemove: _cache[1] || (_cache[1] = ($event: any) => (emit('remove'))),
            "mobile-screen": _unref(screenLessValue)(1200)
          }, null, 8, ["mobile-screen"])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}
}

})