<script setup lang="ts">
import { onUpdated, ref, watch } from 'vue'
interface IProps {
  label?: string
  value: boolean | undefined
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update:value', value: boolean): void
  (e: 'change', value: boolean): void
}
const emit = defineEmits<IEmits>()

const checked = ref(props.value || false)
watch(checked, (val) => {
  emit('update:value', val)
  emit('change', val)
})
onUpdated(() => {
  if (props.value === false) checked.value = false
  if (props.value === true) checked.value = true
})
</script>

<template>
  <div>
    <el-checkbox v-model="checked">
      <span>{{ label }}</span>
      <slot></slot>
    </el-checkbox>
  </div>
</template>

<style scoped lang="sass">
.red-checkbox
  color: red
</style>
