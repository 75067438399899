import { useRequestService } from '@/use/useRequestService'
import {IInvoice} from "@/entityes/invoice/invoice.type";
import { ITBankQuestionnaireRequest } from "@/entityes/invoice/TBankQuestionnaire/t_bank_questionnaire.type";

export default function useInvoiceRemoteAdminRequest() {
  const { fetch } = useRequestService()

  function recalculation(invoiceId: number) {
    return fetch(`/admin/invoices/${invoiceId}/recalculation`, {
      method: 'patch'
    })
  }
  function assignInvoice(invoiceId: number, body: Record<'email', string>) {
    return fetch<IInvoice>(`/admin/invoices/${invoiceId}/assign_invoice`, {
      method: 'patch',
      body
    })
  }
  function toggleBlockInvoice(invoiceId: number, body: Record<'blocked', boolean>) {
    return fetch<IInvoice>( `/admin/invoices/${invoiceId}`, {
      method: 'patch',
      body
    })
  }
  function changeExanteStatus(invoiceId: number) {
    return fetch(`/admin/invoices/${invoiceId}/exante_status_file_generated`, {
      method: 'patch'
    })
  }
  function getTBankPremiumStatus(invoiceId: number) {
    return fetch(`/admin/invoices/${invoiceId}/tinkoff_premium_state`)
  }
  function aliveChat(invoiceId: number) {
    return fetch(`/admin/invoices/${invoiceId}/tinkoff_chat_alive`, {
      method: 'patch'
    })
  }
  function setTBankPremiumStatus(invoiceId: number, body: Record<'method', string>) {
    return fetch(`/admin/invoices/${invoiceId}/tinkoff_premium_state_update`, {
      method: 'patch',
      body
    })
  }
  function getTBankForm(invoiceId: number) {
    return fetch<ITBankQuestionnaireRequest>(`/admin/invoices/${invoiceId}/tinkoff_data_form`)
  }
  function getAdditionalFile(fileId: number) {
    return fetch<Blob, 'blob'>(`/admin/additional_service_files/${fileId}`, {
      responseType: 'blob'
    })
  }
  function sendFilesToTBank(invoiceId: number) {
    return fetch(`/tinkoff/invoices/${invoiceId}/send_files`, {
      method: 'patch'
    })
  }
  function createTinkoffPaymentLink(invoiceId: number, body: { qty: number, price: number, name: string }) {
    return fetch<{ kassa_link: string }>(`/admin/invoices/${invoiceId}/tinkoff_kassa`, {
      method: 'post',
      body
    })
  }
  return {
    recalculation,
    assignInvoice,
    toggleBlockInvoice,
    changeExanteStatus,
    getTBankPremiumStatus,
    aliveChat,
    setTBankPremiumStatus,
    getTBankForm,
    getAdditionalFile,
    sendFilesToTBank,
    createTinkoffPaymentLink
  }
}
