// import CheckAdminRoleMiddleware from '@/router/middlewares/CheckAdminRoleMiddleware'
const oksmIndex = () => import('@/components/Admin/Oksm/OksmIndex.vue')

export default [
  {
    path: '/oksm',
    name: 'oksm index',
    component: oksmIndex,
    meta: {
      // middleware: [CheckAdminRoleMiddleware],
    },
  },
]
