<template>
  <el-tree :data="brokerName" accordion :props="{ class: 'broker-account-info' }" />
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'BrokerAccountInfo',
  props: {
    brokerAccount: {
      type: Object,
      require: true,
    },
  },
  setup(props) {
    const brokerName = computed(() => {
      return Object.keys(props.brokerAccount).map((key) => {
        return {
          label: key,
          children: props.brokerAccount[key].map((item) => ({ label: item })),
        }
      })
    })

    return {
      brokerName,
    }
  },
}
</script>

<style lang="sass">
.broker-account-info .el-tree-node__children .el-tree-node__content
  cursor: default
</style>
