<script lang="ts" setup>
import { IFile } from '../file.type'
import useTextFilters from '@/plugins/TextFilters'
interface IProps {
  files: string[]
}

const props = defineProps<IProps>()
const { subStringFileName } = useTextFilters()
interface IEmits {
  (e: 'deleteLoadedFile', index: number): void
}

const emit = defineEmits<IEmits>()
</script>

<template>
  <div v-for="(name, index) in props.files" :key="name" class="margin-h--left-m margin-v-xs">
    <div class="flex-box flex-box--align-c flex-box--sb">
      <div class="flex-box flex-box--align-c">
        <el-icon color="#43d2cc" size="large" class="margin-h--right-6">
          <CirclePlusFilled />
        </el-icon>
        <span class="">{{ subStringFileName(name, 30) }}</span>
      </div>
      <img
        class="button--text-hover"
        @click="emit('deleteLoadedFile', index)"
        src="@/assets/delete_icon.svg"
        alt="close_icon"
        width="20"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
