<script lang="ts" setup>
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useTextFilters from '@/plugins/TextFilters'
import { useBrokerName } from '@/use/useBrokerName'
import { computed, ref } from 'vue'

const invoiceStore = useInvoiceStore()
const warnings = computed(() => invoiceStore.invoice && invoiceStore.invoice?.warning_and_error.warnings)
const { subStringFileName } = useTextFilters()
const { brokerNames } = useBrokerName()

const showLog = ref(false)
const toggleLog = () => {
  showLog.value = !showLog.value
}
</script>

<template>
  <div class="mb-4 border-warning p-2" v-if="Object.keys(warnings).length > 0">
    <p class="mb-4 font-bold font-color--warning">Критические предупреждения</p>
    <div v-for="(accounts, broker) in warnings" :key="broker">
      <p class="mb-2 font-bold">{{ brokerNames[broker] }}</p>
      <div v-for="(events, account) in accounts" :key="account">
        <div class="flex items-center mb-4">
          <p class="font-weight--extra font-color--gray mr-2">имя файла:</p>
          <p>{{ subStringFileName(account, 30) }}</p>
        </div>
        <el-collapse>
          <el-collapse-item v-for="(warnings, keys) in events" :key="keys">
            <template #title>
              <WarningFilled
                style="font-weight: 700; width: 1em; height: 1em"
                class="p-2"
                color="#E6A23C"
              />
              <p class=" font-bold">{{ keys }}</p>
            </template>
            <div class="p-2">
              {{ warnings }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
  <div class="border-regular p-2" v-if="Object.keys(warnings).length > 0">
    <div class="flex items-center justify-between mb-4">
      <h3>Все предупреждения</h3>
      <el-button type="primary" @click="toggleLog">Развернуть</el-button>
    </div>
    <div v-if="showLog">
      <div v-for="(accounts, broker) in warnings" :key="broker">
        <h3 class="mb-2">{{ brokerNames[broker] }}</h3>
        <div v-for="(events, account) in accounts" :key="account">
          <div class="flex items-center mb-2">
            <p class="font-weight--extra font-color--gray mr-2">имя файла:</p>
            <p>
              {{ subStringFileName(account, 30) }}
            </p>
          </div>
          <el-collapse>
            <el-collapse-item v-for="(warnings, keys) in events" :key="keys">
              <template #title>
                <h3 class="p-2">{{ keys }}</h3>
              </template>
              <div class="p-2">
                {{ warnings }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
  <div v-if="Object.keys(warnings).length === 0">Предупреждений не выявлено</div>
</template>

<style scoped></style>
