<script lang="ts" setup>
import GoodsPreview from '@/04-Widgets/ResultStep/ui/GoodsPreview.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const invoiceId = computed(() => route.params.id as string)
</script>

<template>
  <GoodsPreview :id="invoiceId" />
</template>

<style scoped lang="sass"></style>
