<script setup lang="ts">
import { AdditionalIncomeWidget } from '@/04-Widgets/AdditionelIncome/'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
const route = useRoute()
const invoiceId = computed(() => route.params.id as string)
</script>

<template>
  <div>
    <AdditionalIncomeWidget :invoice-id="invoiceId" />
  </div>
</template>

<style lang="sass" scoped></style>
