import { IInvoice } from '@/entityes/invoice/invoice.type'
import { computed, ComputedRef } from 'vue'

type InvoiceInfoType = {
  title: string
  value: ComputedRef<string | number | boolean>
  adaptive: {
    sm: number
    md: number
    lg: number
  }
}
export const useInvoiceInfo = (invoice: IInvoice) => {
  const info: InvoiceInfoType[] = [
    {
      title: 'Блокировка',
      value: computed(() => (invoice.status?.blocked ? 'да' : 'нет')),
      adaptive: {
        sm: 2,
        md: 3,
        lg: 2,
      },
    },
    {
      title: 'Пользователь',
      value: computed(() => invoice.email),
      adaptive: {
        sm: 4,
        md: 4,
        lg: 5,
      },
    },
    {
      title: 'Стоимость',
      value: computed(() => invoice.outSum),
      adaptive: {
        sm: 3,
        md: 3,
        lg: 3,
      },
    },
    {
      title: 'Год',
      value: computed(() => invoice.year_to_calc),
      adaptive: {
        sm: 3,
        md: 3,
        lg: 3,
      },
    },
    {
      title: 'Статус',
      value: computed(() => invoice.status.async),
      adaptive: {
        sm: 3,
        md: 3,
        lg: 3,
      },
    },
  ]
  // const amountOperations = computed(() => invoice.amount_operations?.Declaration)
  // const brokerAccounts = computed(() => invoice.broker_accounts)

  return {
    info,
    // amountOperations,
    // brokerAccounts,
  }
}
