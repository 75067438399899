<script setup lang="ts">
import TaxPlanInfo from '@/04-Widgets/TaxPlanShow/ui/TaxPlanInfo.vue'
import { useTaxPlanRepository } from '@/entityes/invoice/tax_plan/tax_plan.repository'
import { useTaxPlanStore } from '@/entityes/invoice/tax_plan/tax_plan.store'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { IUser } from '@/entityes/user/user.type'
import { useUserStore } from '@/entityes/user/userStore'

interface IProps {
  id: number | string
}
const props = defineProps<IProps>()
const taxPlanStore = useTaxPlanStore()
const userStore = useUserStore()
const { getTaxPlan, getDataSources } = useTaxPlanRepository()
const router = useRouter()
const user = computed(() => userStore.user as IUser)
getTaxPlan(user.value.active_profile_id, props.id).then((res) => {
  if (res?.async) {
    setTimeout(() => {
      getTaxPlan(user.value.active_profile_id, props.id)
    }, 5000)
  }
  else {
    getDataSources(user.value.active_profile_id)
  }
})
</script>

<template>
  <div class="container-list">
    <tax-plan-info v-if="taxPlanStore.taxPlan" :profile-id="user.active_profile_id" :tax-plan="taxPlanStore.taxPlan" />
  </div>
</template>

<style scoped lang="sass">

</style>
