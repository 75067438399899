import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center mb-1" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex mb-1 items-center" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex items-center mb-1" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "px-4 mb-4" }
const _hoisted_8 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_9 = { class: "mb-4" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = {
  key: 1,
  class: "flex items-center flex-wrap"
}
const _hoisted_14 = { class: "mr-4" }
const _hoisted_15 = { class: "mr-4" }
const _hoisted_16 = { class: "mr-4" }
const _hoisted_17 = {
  key: 1,
  class: "flex items-center flex-wrap"
}

import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { watch, UnwrapNestedRefs } from 'vue'
import { setTouchedHelper } from '../lib/setTouchedHelper'
import { getFields } from '../model/formFields'
import { object as obj, string as str } from 'yup'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { cloneDeep } from 'lodash'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  // TODO need typing
  helpDescriber?: any
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AssignmentOfRights',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {}
  },
  emits: ["update-item", "delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

setTouchedHelper(props.item)
const { mainFields, otherAttributesField } = getFields(props.yearToCalc)

const schema = obj({
  ...mainFields,
  other_attributes: obj({
    ...otherAttributesField,
    amount_of_expenses: str().checkNumberString(),
  }),
})
const validationSchema = toTypedSchema(schema)

const { values, validate, errorBag } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: Object.assign(
    {
      rus: true,
      physical_person: true,
      currency: 'RUB',
    },
    {
      ...props.item.item,
    }
  ),
})
async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "px-4 pt-4 mb-4" }, [
      _createElementVNode("h4", null, "Доход получен")
    ], -1)),
    _createVNode(_component_el_row, {
      gutter: 20,
      class: "px-4 mb-4"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          sm: 8,
          lg: 6,
          xl: 5
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-semibold" }, "Сумма дохода", -1)),
              _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    style: {"word-break":"break-word"},
                    innerHTML: _ctx.helpDescriber?.amount
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              })
            ]),
            _createVNode(FormItem, { name: "amount" }, {
              default: _withCtx(({ field, value }) => [
                _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          sm: 8,
          lg: 6,
          xl: 5
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, [
                _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-semibold" }, "Дата получения дохода", -1)),
                _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      style: {"word-break":"break-word"},
                      innerHTML: _ctx.helpDescriber?.date
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "date" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_date_picker, _mergeProps({
                    type: "date",
                    placeholder: "Выберите дату",
                    format: "DD.MM.YYYY",
                    "value-format": "DD.MM.YYYY",
                    "model-value": value
                  }, field), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          sm: 8,
          lg: 6,
          xl: 5
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-semibold" }, "Сумма расходов на покупку", -1)),
              _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    style: {"word-break":"break-word"},
                    innerHTML: _ctx.helpDescriber?.amount_of_expenses
                  }, null, 8, _hoisted_6)
                ]),
                _: 1
              })
            ]),
            _createVNode(FormItem, { name: "other_attributes.amount_of_expenses" }, {
              default: _withCtx(({ field, value }) => [
                _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_7, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex items-center mb-4" }, [
          _createElementVNode("h4", null, "Другая сторона договора")
        ], -1)),
        (_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(FormItem, { name: "physical_person" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_radio_group, _mergeProps(field, { "model-value": value }), {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio, { value: false }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("Юридическое лицо")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_el_radio, { value: true }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("Физическое лицо")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          (_unref(values).rus)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_unref(values).physical_person)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-semibold mb-1" }, "ФИО", -1)),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(FormItem, { name: "other_attributes.name" }, {
                          default: _withCtx(({ field, value }) => [
                            _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                          ]),
                          _: 1
                        })
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Наименование организации", -1)),
                        _createVNode(FormItem, { name: "other_attributes.name_organization" }, {
                          default: _withCtx(({ field, value }) => [
                            _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "font-semibold mb-1" }, "ИНН", -1)),
                        _createVNode(FormItem, { name: "other_attributes.inn" }, {
                          default: _withCtx(({ field, value }) => [
                            _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "123456789012" }), null, 16, ["model-value"]), [
                              [_directive_mask, ['############']]
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "font-semibold mb-1" }, "КПП", -1)),
                        _createVNode(FormItem, { name: "other_attributes.kpp" }, {
                          default: _withCtx(({ field, value }) => [
                            _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "123456789" }), null, 16, ["model-value"]), [
                              [_directive_mask, ['#########']]
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", null, [
                        _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "font-semibold mb-1" }, "ОКТМО", -1)),
                        _createVNode(FormItem, { name: "other_attributes.oktmo" }, {
                          default: _withCtx(({ field, value }) => [
                            _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "12345678" }), null, 16, ["model-value"]), [
                              [_directive_mask, ['########']]
                            ])
                          ]),
                          _: 1
                        })
                      ])
                    ]))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", null, [
                  _cache[10] || (_cache[10] = _createElementVNode("h4", { class: "font-semibold mb-1" }, "Наименование", -1)),
                  _createVNode(FormItem, { name: "other_attributes.name_income" }, {
                    default: _withCtx(({ field, value }) => [
                      _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                    ]),
                    _: 1
                  })
                ])
              ]))
        ])
      ])
    ])
  ], 64))
}
}

})