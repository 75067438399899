import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-4 pt-4 mb-4" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "flex items-center mb-1 mr-1" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex items-center mb-1" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "mb-8" }
const _hoisted_10 = { class: "flex flex-wrap items-center" }
const _hoisted_11 = { class: "px-4" }
const _hoisted_12 = { class: "flex flex-wrap items-center mb-4" }
const _hoisted_13 = { class: "px-4" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "mb-4" }
const _hoisted_17 = { class: "flex items-center" }
const _hoisted_18 = { class: "margin-h--right-20" }
const _hoisted_19 = { key: 0 }

import { AdditionalItem, AdditionalItemValue, Deduction } from '@/06-Entities/IncomeAndRefund'
import Checkbox from '@/07-Shared/ui/Checkbox.vue'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { toTypedSchema } from '@vee-validate/yup'
import { ElMessageBox } from 'element-plus'
import { cloneDeep, repeat } from 'lodash'
import { useForm } from 'vee-validate'
import { ref, UnwrapNestedRefs, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { bool, number, object, string } from 'yup'
import { vMaska } from 'maska/vue'
import { MaskaDetail } from 'maska'
import { integerMask, moneyMask } from '@/07-Shared/lib/maskHelper'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
  yearToCalc: number
  helpDescriber: any
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'IIS',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {}
  },
  emits: ["update-item", "delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const schema = object({
  sum: number().required('Это поле обязательно для заполнения'),
  name: string().required('Это поле обязательно для заполнения'),
  other_attributes: object({
    date: string()
      .checkYearValue(props.yearToCalc)
      .when('iis_type', ([iis_type], schema) => {
        return iis_type === 'A' ? schema.required('Поле является обязательным') : schema
      }),
    inn: number()
      .len([10, 12])
      .when('iis_type', ([iis_type], schema) => {
        return iis_type === 'A' ? schema.required('Поле является обязательным') : schema
      }),
    kpp: number()
      .len(9)
      .when('iis_type', ([iis_type], schema) => {
        return iis_type === 'A' ? schema.required('Поле является обязательным') : schema
      }),
    contract_id: string().when('iis_type', ([iis_type], schema) => {
      return iis_type === 'A' ? schema.required('Поле является обязательным') : schema
    }),
    simplified: bool(),
    iis_type: string().oneOf(['A', 'B']).default('A'),
  }),
})
const validationSchema = toTypedSchema(schema)
const { setValues, values, validate, errorBag } = useForm({
  validateOnMount: true,
  validationSchema,
  initialValues: Object.assign({}, { ...props.item.item }),
})

async function prepareItem() {
  if (values.other_attributes?.iis_type === 'B') {
    setValues({
      sum: 100,
      name: 'name',
    })
  }
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

const iisBroker = ref(false)
function checkIisSum() {
  if (values.sum && values.sum > 400000) {
    ElMessageBox({
      title: 'Вычет по ИИС',
      message: 'Получить вычет по ИИС можно только с суммы не более чем 400 000 ₽',
      type: 'success',
      confirmButtonText: 'Понятно',
    }).then(() => {
      setValues({
        sum: 400000,
      })
    })
  }
}
watch(iisBroker, (val) => {
  if (val) {
    ElMessageBox.confirm('При получении вычета через брокера его нет необходимости указывать в декларации', {
      confirmButtonText: 'Удалить вычет',
      cancelButtonText: 'Не удалять',
    })
      .then(() => {
        emit('delete')
      })
      .catch(() => {
        iisBroker.value = false
      })
  }
})
const route = useRoute()
const router = useRouter()
function redirect() {
  const { id, good_id } = route.params
  router.push({
    name: 'add_incomes',
    params: { id, good_id },
  })
}
function unmasked(value: CustomEvent<MaskaDetail>) {
  console.log(value.detail)
}

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(FormItem, { name: "other_attributes.iis_type" }, {
        default: _withCtx(({ field, value }) => [
          _createVNode(_component_el_radio_group, _mergeProps({ "model-value": value }, field), {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_radio, { value: "A" }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Вычет тип А")
                    ])),
                    _: 1
                  }),
                  _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        style: {"word-break":"break-word"},
                        innerHTML: _ctx.helpDescriber?.a_type
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_radio, { value: "B" }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Вычет тип Б")
                    ])),
                    _: 1
                  }),
                  _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        style: {"word-break":"break-word"},
                        innerHTML: _ctx.helpDescriber?.b_type
                      }, null, 8, _hoisted_7)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 2
          }, 1040, ["model-value"])
        ]),
        _: 1
      })
    ]),
    (_unref(values).other_attributes?.iis_type === 'A')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "mb-4" }, "Данные брокера", -1)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", null, [
                _cache[4] || (_cache[4] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Название брокера, где открыт ИИС", -1)),
                _createVNode(FormItem, { name: "name" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-semibold mb-1" }, "ИНН брокера", -1)),
                _createVNode(FormItem, { name: "other_attributes.inn" }, {
                  default: _withCtx(({ field, value }) => [
                    _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "123456789012" }), null, 16, ["model-value"]), [
                      [_unref(vMaska), _unref(integerMask)]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, [
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-semibold mb-1" }, "КПП брокера", -1)),
                _createVNode(FormItem, { name: "other_attributes.kpp" }, {
                  default: _withCtx(({ field, value }) => [
                    _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "123456789" }), null, 16, ["model-value"]), [
                      [_unref(vMaska), _unref(integerMask)]
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("h3", { class: "mb-4" }, "Данные договора", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", null, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Номер договора", -1)),
              _createVNode(FormItem, { name: "other_attributes.contract_id" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[9] || (_cache[9] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Дата заключения договора", -1)),
              _createVNode(FormItem, { name: "other_attributes.date" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_date_picker, _mergeProps({ "model-value": value }, field, {
                    type: "date",
                    placeholder: "ДД.ММ.ГГГГ",
                    format: "DD.MM.YYYY",
                    "value-format": "DD.MM.YYYY"
                  }), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _cache[10] || (_cache[10] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Сумма внесенная на ИИС в течение года", -1)),
              _createVNode(FormItem, { name: "sum" }, {
                default: _withCtx(({ field, value }) => [
                  _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, {
                    placeholder: "не более 400 000 руб.",
                    onFocusout: checkIisSum
                  }), null, 16, ["model-value"]), [
                    [_unref(vMaska), _unref(moneyMask)]
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(FormItem, { name: "other_attributes.simplified" }, {
              default: _withCtx(({ field, value }) => [
                _createVNode(Checkbox, _mergeProps({ value: value }, field), {
                  default: _withCtx(() => [
                    _cache[11] || (_cache[11] = _createTextVNode(" Вычет был предоставлен в упрощенном порядке ")),
                    _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          style: {"word-break":"break-word"},
                          innerHTML: _ctx.helpDescriber?.simplified
                        }, null, 8, _hoisted_14)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1040, ["value"])
              ]),
              _: 1
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "mb-4" }, "Вычет был получен через брокера?", -1)),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_el_radio, {
                  modelValue: iisBroker.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((iisBroker).value = $event)),
                  label: false
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("Нет")
                  ])),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_el_radio, {
                  modelValue: iisBroker.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((iisBroker).value = $event)),
                  label: true
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("Да")
                  ])),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ])
          ]),
          (!iisBroker.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _cache[17] || (_cache[17] = _createElementVNode("p", { class: "mb-4" }, "Чтобы получить вычет, необходимо указать доходы.", -1)),
                _cache[18] || (_cache[18] = _createElementVNode("p", { class: "mb-8" }, " Для этого загрузите справку о доходах (2-НДФЛ), полученных в рамках данного ИИС, выбрав на странице Доп. доходов ситуацию \"Справки о доходах (ранее 2-НДФЛ)\". ", -1)),
                _createVNode(_component_el_button, { onClick: redirect }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode("Загрузить справки 2-НДФЛ")
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}
}

})