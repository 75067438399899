<template>
  <el-row align="middle">
    <el-col :lg="10" class="margin-v-xs">
      <h3 class="font-color--deep-gray margin-v-xs">Сообщение</h3>
      <p>{{ subStringText(item.item?.other_attributes?.message || '') }}</p>
    </el-col>
    <el-col :lg="6" class="margin-v-xs">
      <h3 class="font-color--deep-gray margin-v-xs">Телефон</h3>
      <p>{{ item.item?.other_attributes?.phone }}</p>
    </el-col>
    <el-col :lg="5" class="margin-v-xs">
      <p class="font-weight--extra">
        <span v-if="item?.valueFromServer && !item.fieldWasEdited" class="font-color--green">Доход сохранен</span>
        <span v-else class="font-color--red">Доход не сохранен</span>
      </p>
    </el-col>
    <el-col :lg="3" class="b-margin-top">
      <edit-save-card-button-group
        :mobile-screen="screenLessValue(1200)"
        @toggle="item.toggleEdit"
        @remove="$emit('remove')"
      />
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import useTextFilters from '@/plugins/TextFilters'
import useWindowWidth from '@/use/useWindowWidth'
import { UnwrapNestedRefs } from 'vue'
import { AdditionalIncome, AdditionalItem } from '@/06-Entities/IncomeAndRefund'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'remove'): void
}
const emit = defineEmits<IEmits>()
const { subStringText } = useTextFilters()
const { screenLessValue } = useWindowWidth()
</script>

<style lang="sass" scoped></style>
