<template>
  <el-row align="middle">
    <el-col :class="screenLessValue(1200) ? 'margin-top-s' : ''" :lg="10">
      <h3 class="font-color--gray margin-v-xs">Тип</h3>
      <p>{{ tag.tag === 'live_insure' ? contractType?.title : tag.label }}</p>
    </el-col>
    <el-col :class="screenLessValue(1200) ? 'margin-top-s' : ''" :lg="4">
      <h3 class="font-color--gray margin-v-xs">Сумма</h3>
      <p>{{ item.item?.sum || 0 }} руб.</p>
    </el-col>
    <el-col :class="screenLessValue(1200) ? 'margin-top-s' : ''" :lg="3">
      <p class="font-weight--extra">
        <span v-if="item?.valueFromServer && !item.fieldWasEdited" class="font-color--green"> Вычет сохранен</span>
        <span v-else class="font-color--red">Вычет не сохранен</span>
      </p>
    </el-col>
    <el-col
      :xs="24"
      :sm="{ offset: 0, span: 24 }"
      :md="{ offset: 0, span: 24 }"
      :lg="{ offset: 4, span: 3 }"
      :class="screenLessValue(1200) ? 'margin-top-m' : ''"
    >
      <edit-save-card-button-group
        @toggle="item.toggleEdit"
        @remove="$emit('remove')"
        :mobile-screen="screenLessValue(1200)"
      />
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { computed, UnwrapNestedRefs } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import { LiveInsureContractType } from '../../model/LiveInsureContractType'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { SocialRefundsDescriber } from '../../model/SocialRefundsDescriber'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
}
const props = defineProps<IProps>()

const { screenLessValue } = useWindowWidth()
const contracts = SocialRefundsDescriber().tagsList
const tag = computed(
  () =>
    contracts.find((item) => item.tag === props.item.item.tag) || {
      tag: 'other',
      label: 'Не выбрано',
    }
)
const contractType = computed(() => {
  return LiveInsureContractType.find((item) => item.id === props.item.item?.other_attributes?.contract_type)
})
</script>

<style lang="sass" scoped>
.underline
  border-bottom: 1px solid grey
</style>
