import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "alert bg-gray margin-v-s p-4 border" }
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "margin-v-s"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { class: "flex items-center mb-4" }
const _hoisted_12 = { class: "mb-4" }

import { computed, ref } from 'vue'
import { WarningFilled, Message, UserFilled, CircleCheckFilled } from '@element-plus/icons-vue'
import {usePDRepository} from '@/entityes/invoice/personal_data/pd.repository'

interface IProps {
  additional: boolean
  invoiceId: string | number
  goodId: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GoodsPreviewMsg',
  props: {
    additional: { type: Boolean },
    invoiceId: {},
    goodId: {}
  },
  setup(__props: any) {

const props = __props
const { getCurrentPersonalDataCard } = usePDRepository()
const hasAddIncomes = ref(false)
const hasDeductions = ref(false)
const hasPersonalData = ref(false)
const completed = computed(() => {
  if (props.additional) {
    if ((hasAddIncomes.value && hasDeductions.value) && hasPersonalData.value) {
      return true
    }
  }
  if (!props.additional) {
    if (hasPersonalData.value) return true
  }
  return false
})
getCurrentPersonalDataCard(props.invoiceId).then(() => {
  hasPersonalData.value = true
}).catch(() => {
  hasPersonalData.value = false
})

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!completed.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createVNode(_component_el_icon, {
            class: "mr-2",
            color: "#FF9A9D",
            style: {"vertical-align":"middle"}
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(WarningFilled))
            ]),
            _: 1
          }),
          _cache[0] || (_cache[0] = _createElementVNode("span", {
            class: "font-color--red font-weight--semi font-color--purple",
            style: {"vertical-align":"middle"}
          }, "Внимание!", -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.additional)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          ((!hasAddIncomes.value && !hasDeductions.value) || !hasPersonalData.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Для завершения формирования декларации: "))
            : _createCommentVNode("", true),
          (!hasAddIncomes.value && !hasDeductions.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "mb-4" }, " 1. Заполните Дополнительные доходы и Вычеты - для этого перейдите в соответствующие разделы, кликнув на них в меню слева. ", -1)),
                _createElementVNode("p", _hoisted_6, [
                  _cache[3] || (_cache[3] = _createTextVNode(" Либо нажмите ")),
                  _createVNode(_component_router_link, {
                    to: { name: 'add_incomes', params: { good_id: _ctx.goodId } }
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Дополнительные доходы")
                    ])),
                    _: 1
                  }, 8, ["to"]),
                  _cache[4] || (_cache[4] = _createTextVNode(" или ")),
                  _createVNode(_component_router_link, {
                    to: { name: 'deductions', params: { good_id: _ctx.goodId } }
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Вычеты")
                    ])),
                    _: 1
                  }, 8, ["to"]),
                  _cache[5] || (_cache[5] = _createTextVNode(". "))
                ])
              ]))
            : _createCommentVNode("", true),
          (!hasPersonalData.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mb-4" }, " 2. Заполните Персональные данные, для этого перейдите в соответствующий раздел, кликнув на него в меню слева. ", -1)),
                _createElementVNode("p", null, [
                  _cache[8] || (_cache[8] = _createTextVNode(" Перейти к разделу ")),
                  _createVNode(_component_router_link, {
                    to: { name: 'personal_data', params: { good_id: _ctx.goodId } }
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Персональные данные")
                    ])),
                    _: 1
                  }, 8, ["to"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.additional)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (!hasPersonalData.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _cache[12] || (_cache[12] = _createElementVNode("p", { class: "mb-4" }, " Для завершения формирования декларации заполните персональные данные в меню слева.", -1)),
                _cache[13] || (_cache[13] = _createElementVNode("p", { class: "mb-4" }, " Или поставьте там галочку “без персональных данных”. ", -1)),
                _createElementVNode("p", null, [
                  _cache[11] || (_cache[11] = _createTextVNode(" Перейти в раздел ")),
                  _createVNode(_component_router_link, {
                    to: { name: 'personal_data', params: { good_id: _ctx.goodId } }
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("Персональные данные")
                    ])),
                    _: 1
                  }, 8, ["to"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (completed.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_unref(CircleCheckFilled), {
              style: {"font-weight":"700","width":"1em","height":"1em"},
              color: "#43d2cc",
              class: "mr-2"
            }),
            _cache[14] || (_cache[14] = _createElementVNode("p", null, " Вы заполнили все необходимые разделы ", -1))
          ]),
          _createElementVNode("p", _hoisted_12, [
            _createVNode(_component_el_icon, {
              color: "#4b60cecc",
              style: {"vertical-align":"middle"},
              class: "mr-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(Message))
              ]),
              _: 1
            }),
            _cache[15] || (_cache[15] = _createElementVNode("span", { style: {"vertical-align":"middle"} }, "Мы проверим ваши документы и пришлем письмо с результатом.", -1))
          ]),
          _createElementVNode("p", null, [
            _createVNode(_component_el_icon, {
              color: "#4b60cecc",
              style: {"vertical-align":"middle"},
              class: "mr-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(UserFilled))
              ]),
              _: 1
            }),
            _cache[16] || (_cache[16] = _createElementVNode("span", { style: {"vertical-align":"middle"} }, "Обычно это занимает 3-7 дней.", -1))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})