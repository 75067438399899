<script lang="ts" setup>
import { LoadFileStepComponent } from '@/04-Widgets/LoadFileStep'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const invoiceId = computed(() => route.params.id as string)
</script>

<template>
  <LoadFileStepComponent :invoice-id="invoiceId" />
</template>

<style lang="sass" scoped></style>
