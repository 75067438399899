import { defineStore } from 'pinia'
import {computed, ref} from 'vue'
import {IProfile, IProfilesList} from '@/entityes/profiles/profiles.type'

export const useProfilesStore = defineStore('profiles store', () => {
  const userAdminProfiles = ref<IProfilesList | undefined | any>(undefined)
  const activeProfile = computed(() => {
      return userAdminProfiles.value?.find((profile: IProfile) => profile.active)
  })

  return {
    userAdminProfiles,
    activeProfile
  }
})
