import { AdditionalIncome, AdditionalItemValue, useCollectionWithSituation } from '@/06-Entities/IncomeAndRefund'
import { IncomeDescriber } from './IncomeDescriber'
import { ValidationAddError } from '@/components/Invoice/Declaration/support/Errors'
import { useRequestService } from '@/use/useRequestService'
import { computed } from 'vue'
import HeaderPreview from '../ui/HeaderPreview.vue'

interface IIncomeToServer extends AdditionalItemValue {
  situation: string
}
export function useIncomeCollection() {
  const describers = IncomeDescriber
  const { fetch } = useRequestService()
  const collectionObj = useCollectionWithSituation<AdditionalIncome>({
    describers,
    situationFieldName: 'situation',
  })
  const meta = {
    headerComponent: HeaderPreview,
  }
  const availableSituation = computed(() => {
    const check = collectionObj.showItemDescibersWithHaveElements.value.some((item) => item.tag === 'two_ndfl_files')
    if (check) {
      describers.filter((item) => item.tag !== 'two_ndfl_files')
    }
    return describers
  })
  function buildDataForServer() {
    return new Promise<IIncomeToServer[]>((resolve, reject) => {
      const result = collectionObj.collection
        .filter((i) => !['two_ndfl_rus', 'two_ndfl_source'].includes(i.situationName))
        .map((item) => {
          if (item.valueFromServer && !item.fieldWasEdited) {
            return {
              ...item.toJson(),
              valid: true,
            }
          }
          return item.toJson()
        })
      if (result.some((item) => !item.valid)) {
        reject(new ValidationAddError())
      } else resolve(result as IIncomeToServer[])
    })
  }
  function getFromServer(declarationId: number | string) {
    return new Promise<AdditionalIncome[]>((resolve, reject) => {
      fetch<{ additional_incomes: AdditionalIncome[] }>(`/declarations/${declarationId}/additional_incomes`)
        .then((res) => {
          collectionObj.mergeValueFromServer(res.additional_incomes)
          resolve(res.additional_incomes)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  function sendToServer(declarationId: number | string) {
    return new Promise<AdditionalIncome[]>((resolve, reject) => {
      buildDataForServer()
        .then((res) => {
          fetch<{ additional_incomes: AdditionalIncome[] }>(`/declarations/${declarationId}/additional_incomes`, {
            method: 'patch',
            body: {
              additional_incomes: res,
            },
          })
            .then((res) => {
              resolve(res.additional_incomes)
            })
            .catch((err) => reject(err))
        })
        .catch((err) => reject(err))
    })
  }
  return {
    ...collectionObj,
    meta,
    availableSituation,
    getFromServer,
    sendToServer,
  }
}
