<script lang="ts" setup>
import { ref } from 'vue'
import { useUpdateDeclarationVersion } from '@/05-Features/DeclarationVersionSet/api/updateDeclarationVersion.request'
import { IDeclaration } from '@/entityes/invoice/declaration/declaration.type'
import { ElNotification } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'

interface IProps {
  declaration: IDeclaration
}
const props = defineProps<IProps>()
const { updateDeclarationVersion } = useUpdateDeclarationVersion()
const showEditor = ref(false)
const isLoading = ref(false)
const correctionNumber = ref(props.declaration?.correction_number || 0)
function onSubmit() {
  isLoading.value = true
  const body = { correction_number: correctionNumber.value }
  // if(props.declaration?.id) {
    updateDeclarationVersion(props.declaration?.id, body).then(() => {
      isLoading.value = false
    }).catch((err) => {
      isLoading.value = false
      ElNotification({
        title: `Ошибка`,
        message: `обновления версии декларации ${err}`,
        type: 'error',
      })
    })
  // }
}
</script>

<template>
  <div class="mb-4">
    <p class="font-color--deep-gray font-weight--extra mb-2">
      Версия уточненной декларации: <span class="font-weight--semi">
      {{
        correctionNumber > 0 ? `уточненная (версия - ${correctionNumber})` : `первичная (версия - ${correctionNumber})`
      }}
    </span>
    </p>
    <div class="mb-4">
      <el-checkbox v-model="showEditor" type="primary" class="font-weight--extra">
        Подать уточненную декларацию
      </el-checkbox>
    </div>
    <div v-if="showEditor" class="bg-gray border p-2">
      <div class="flex items-center justify-between">
        <div class="flex">
          <p class="mr-2">Версия уточненной декларации</p>
          <el-input-number class="mr-6"
                           style="width: 86px !important; height: 26px"
                           size="small"
                           :min="0"
                           v-model="correctionNumber"
                           :disabled="isLoading"
          />
        </div>
        <el-button :icon="UploadFilled" size="small" type="primary" @click="onSubmit" :loading="isLoading">Сохранить</el-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
