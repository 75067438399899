import { defineStore } from 'pinia'
import { ref } from 'vue'
import { RouteLocationNormalized } from 'vue-router'

export const useServiceStore = defineStore('service store', () => {
  const toUrl = ref<RouteLocationNormalized | undefined>(undefined)
  const requestServiceInProcess = ref<boolean>(false)
  const settingOpen = ref<boolean>(false)
  return {
    toUrl,
    requestServiceInProcess,
    settingOpen,
  }
})
