<script setup lang="ts">
const slots = defineSlots<{
  left(): any
  right(): any
}>()
</script>

<template>
  <el-row class="mb-4" :gutter="20">
    <el-col :xs="24" :lg="12">
      <div class="step-box mb-4" style="min-height: 100%">
        <slot name="left"></slot>
      </div>
    </el-col>
    <!--  Корзина с файлами заказа  -->
    <el-col :xs="24" :lg="12">
      <div class="sticky-basket step-box mb-4" style="min-height: 100%">
        <slot name="right"></slot>
      </div>
    </el-col>
  </el-row>
</template>

<style lang="sass" scoped></style>
