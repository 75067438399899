import { useUserRepository } from '@/entityes/user/user.repository'
import { NavigationGuardWithThis } from 'vue-router'
import { ElNotification } from 'element-plus'

export const checkPasswordTokenMiddleware: NavigationGuardWithThis<void> = async (to) => {
  await useUserRepository()
    .checkPasswordToken(to?.params?.token)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `проверки токена. Попробуйте еще раз. ${err}`,
        type: 'error',
      })
      return false
    })
}
