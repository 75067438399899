import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "mb-4 flex flex-wrap items-center" }
const _hoisted_9 = { class: "mr-4" }
const _hoisted_10 = { class: "mr-2" }
const _hoisted_11 = { class: "flex flex-wrap items-center" }
const _hoisted_12 = { class: "mr-4" }
const _hoisted_13 = { class: "mr-4" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "w-full" }

import { number, object, string } from 'yup'
import { ContractIds, LiveInsureContractType, ContractType } from '../model/LiveInsureContractType'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { UnwrapNestedRefs, watch } from 'vue'
import { AdditionalItem, AdditionalItemValue, Deduction } from '@/06-Entities/IncomeAndRefund'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import useWindowWidth from '@/use/useWindowWidth'
import { SocialRefundsDescriber } from '../model/SocialRefundsDescriber'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { cloneDeep } from 'lodash'

interface IProps {
  yearToCalc: number
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
  helpDescriber: any
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'SocialDeduction',
  props: {
    yearToCalc: {},
    item: {},
    helpDescriber: {}
  },
  emits: ["update-item"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const schema = object({
  tag: string().required(),
  sum: number().required('Это поле обязательно для заполнения'),
  currency: string().length(3).required().default('RUB'),
  other_attributes: object({
    name: string().test('name-test-required', 'Поле обязательное к заполнению', (value, context) => {
      const tag = context.from?.[1].value.tag
      const contract_type = context.from?.[0].value.contract_type
      if (tag === 'live_insure' && contract_type !== '4') {
        return string().required().isValidSync(value)
      }
      return true
    }),
    inn: number().test('inn-test-required', 'Поле обязательное к заполнению', (value, context) => {
      const tag = context.from?.[1].value.tag
      const contract_type = context.from?.[0].value.contract_type
      if (tag === 'live_insure' && contract_type !== '4') {
        return number().required().isValidSync(value)
      }
      return true
    }),
    kpp: number().test('kpp-test-required', 'Поле обязательное к заполнению', (value, context) => {
      const tag = context.from?.[1].value.tag
      const contract_type = context.from?.[0].value.contract_type
      if (tag === 'live_insure' && contract_type !== '4') {
        return number().required().isValidSync(value)
      }
      return true
    }),
    contract_id: string().test('contract-test-required', 'Поле обязательное к заполнению', (value, context) => {
      const tag = context.from?.[1].value.tag
      const contract_type = context.from?.[0].value.contract_type
      if (tag === 'live_insure' && contract_type !== '4') {
        return string().required().isValidSync(value)
      }
      return true
    }),
    date: string()
      .checkYearValue(props.yearToCalc)
      .test('date-test-required', 'Поле обязательное к заполнению', (value, context) => {
        const tag = context.from?.[1].value.tag
        const contract_type = context.from?.[0].value.contract_type
        if (tag === 'live_insure' && contract_type !== '4') {
          return string().required().isValidSync(value)
        }
        return true
      }),
    contract_type: string()
      .oneOf<ContractIds>(LiveInsureContractType.map((item) => item.id))
      .test('contract_type-required', 'Поле обязательное к заполнению', (value, context) => {
        const tag = context.from?.[1].value.tag
        if (tag === 'live_insure') {
          return string().required().isValidSync(value)
        }
        return true
      }),
  }),
})
const validationSchema = toTypedSchema(schema)

const { values, validate, errorBag } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: {
    ...Object.assign({}, { ...props.item.item }),
  },
})
const { screenLessValue } = useWindowWidth()
const tags = SocialRefundsDescriber().tagsList
async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Тип вычета", -1)),
    _createVNode(_component_el_row, { class: "mb-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 12 }, {
          default: _withCtx(() => [
            _createVNode(FormItem, { name: "tag" }, {
              default: _withCtx(({ field, value }) => [
                _createVNode(_component_el_select, _mergeProps({ "model-value": value }, field, {
                  filterable: "",
                  placeholder: "Выберите тип вычета"
                }), {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tags), (key) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: key.tag,
                        value: key.tag,
                        label: key.label
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("span", null, _toDisplayString(key.label), 1),
                            (!_unref(screenLessValue)(1200))
                              ? (_openBlock(), _createBlock(QuestionPopover, {
                                  key: 0,
                                  color: '#4b60cecc'
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      style: {"word-break":"break-word"},
                                      innerHTML: _ctx.helpDescriber[key?.tag]
                                    }, null, 8, _hoisted_3)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1040, ["model-value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_unref(values).tag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              (_unref(values).tag === 'live_insure')
                ? (_openBlock(), _createBlock(_component_el_col, {
                    key: 0,
                    span: 12
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Вид договора", -1)),
                        _createVNode(FormItem, { name: "other_attributes.contract_type" }, {
                          default: _withCtx(({ field, value }) => [
                            _createVNode(_component_el_select, _mergeProps({ "model-value": value }, field, {
                              class: "margin-v-s width-100",
                              filterable: "",
                              placeholder: "Выберите тип контракта"
                            }), {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(LiveInsureContractType), (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.title,
                                    value: item.id,
                                    label: item.title
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_5, [
                                        _createElementVNode("span", null, _toDisplayString(item.title), 1),
                                        (!_unref(screenLessValue)(1200))
                                          ? (_openBlock(), _createBlock(QuestionPopover, {
                                              key: 0,
                                              color: '#4b60cecc'
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", {
                                                  style: {"word-break":"break-word"},
                                                  innerHTML: _ctx.helpDescriber[item?.tag]
                                                }, null, 8, _hoisted_6)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    _: 2
                                  }, 1032, ["value", "label"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1040, ["model-value"])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_unref(values).tag === 'live_insure' && _unref(values).other_attributes?.contract_type !== '4')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Наименование организации или фонда", -1)),
                    _createVNode(FormItem, { name: "other_attributes.name" }, {
                      default: _withCtx(({ field, value }) => [
                        _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-semibold mb-1" }, "ИНН", -1)),
                    _createVNode(FormItem, { name: "other_attributes.inn" }, {
                      default: _withCtx(({ field, value }) => [
                        _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("p", { class: "mb-1 font-semibold" }, "КПП", -1)),
                    _createVNode(FormItem, { name: "other_attributes.kpp" }, {
                      default: _withCtx(({ field, value }) => [
                        _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "mb-1 font-semibold" }, "Номер договора", -1)),
                    _createVNode(FormItem, { name: "other_attributes.contract_id" }, {
                      default: _withCtx(({ field, value }) => [
                        _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Сумма взноса", -1)),
                    _createVNode(FormItem, { name: "sum" }, {
                      default: _withCtx(({ field, value }) => [
                        _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Дата заключения договора", -1)),
                    _createVNode(FormItem, { name: "other_attributes.date" }, {
                      default: _withCtx(({ field, value }) => [
                        _createVNode(_component_el_date_picker, _mergeProps({ "model-value": value }, field, {
                          type: "date",
                          placeholder: "Выберите дату",
                          format: "DD.MM.YYYY",
                          "value-format": "DD.MM.YYYY"
                        }), null, 16, ["model-value"])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 10 }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[7] || (_cache[7] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Сумма", -1)),
                          _createVNode(FormItem, { name: "sum" }, {
                            default: _withCtx(({ field, value }) => [
                              _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})