import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "label font-weight--semi mb-1"
}
const _hoisted_2 = { class: "form-slot" }
const _hoisted_3 = { class: "form-warning" }


import { useField } from 'vee-validate'

interface IProps {
  label?: string
  name: string
  // todo добавить правильный тип для model
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FormItem',
  props: {
    label: {},
    name: {}
  },
  setup(__props: any) {

const props = __props
const field = useField(() => props.name)
function onBlur() {
  field.handleBlur()
  field.validate()
}

return (_ctx: any,_cache: any) => {
  const _component_WarningFilled = _resolveComponent("WarningFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {
        value: _unref(field).value.value as any,
        onBlur: onBlur,
        onInput: _unref(field).handleChange,
        meta: _unref(field).meta
      }),
      (!_unref(field).meta.valid && _unref(field).meta.touched)
        ? (_openBlock(), _createBlock(_component_el_popover, {
            key: 0,
            placement: "bottom",
            class: "margin-h--left-s",
            width: 300
          }, {
            reference: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, [
                _createVNode(_component_el_icon, {
                  color: "#FF9A9D",
                  size: "large",
                  class: "margin-h--left-s cursor-point"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_WarningFilled)
                  ]),
                  _: 1
                })
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("p", {
                class: _normalizeClass([{ 'rb-form-error-hide': _unref(field).meta.valid || !_unref(field).meta.touched }, "font-color--red"]),
                style: {"word-break":"break-word"}
              }, _toDisplayString(_unref(field).errorMessage || 'нет ошибок'), 3)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})