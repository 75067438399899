import { ComponentPublicInstance } from 'vue'
import {RouteLocationNormalized, NavigationGuardWithThis, RouteLocationRaw, NavigationGuardNext} from 'vue-router'

export interface MiddlewareNextFunction {
  (): void
}
export interface MiddlewareContext {
  to: RouteLocationNormalized
  from: RouteLocationNormalized
}
declare type NavigationGuardNextCallback = (vm: ComponentPublicInstance) => unknown
declare type NavigationGuardReturn = void | Error | RouteLocationRaw | boolean | NavigationGuardNextCallback
export interface IMiddleware {
  (to: RouteLocationNormalized, from: RouteLocationNormalized): NavigationGuardReturn | Promise<NavigationGuardReturn>
  // (context: MiddlewareContext, next: MiddlewareNextFunction): NavigotionGuardReturn
}

export function middlewarePipeline(...middlewares: NavigationGuardWithThis<any>[]): NavigationGuardWithThis<any> {
  return async (to, from) => {
    const res = await Promise.all(middlewares.map((item) => item(to, from, () => {})))
    const filtered = res.filter((item) => item != true || item != undefined)
    if (filtered.length != 0) {
      return filtered[0]
    }
  }
}
