<script lang="ts" setup>
import DeductionWidget from '@/04-Widgets/DeductionWidget/ui/DeductionWidget.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
const route = useRoute()
const invoiceId = computed(() => route.params.id as string)
</script>
<template>
  <DeductionWidget :invoice-id="invoiceId" />
</template>
<style lang="sass"></style>
