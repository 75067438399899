import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "mr-2" }

import {toTypedSchema} from '@vee-validate/yup'
import * as yup from 'yup'
import {useForm, useFormValues} from 'vee-validate'
import FormItem from '@/components/supportComponents/FormItem.vue'
import {useInvoiceStore} from '@/entityes/invoice/invoice.store'
import useInvoiceRemoteAdminRequest from '@/06-Entities/AdminInvoiceRemote/api/invoice_remote.admin.request'
import useCopyToClipboard from '@/07-Shared/model/useCopyToClipboard'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TBankKassa',
  setup(__props) {

const invoiceStore = useInvoiceStore()
const { copyValue } = useCopyToClipboard()
const { createTinkoffPaymentLink } = useInvoiceRemoteAdminRequest()
const kassaLink = ref('')

const schema = toTypedSchema(
  yup.object({
    name: yup.string().required(),
    qty: yup.number().required(),
    price: yup.number().required()
  })
)
const { handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    name: 'Лицензионный договор "Декларация". Предоставление права использования программы для ЭВМ и баз данных.'
  }
})
// const formValues = useFormValues()
const getTBankKassaLink = handleSubmit((form) => {
  console.log(form)
  createTinkoffPaymentLink(invoiceStore.invoice.id, form).then(( res: { kassa_link: string } ) => {
    kassaLink.value += res.kassa_link
  })
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CopyDocument = _resolveComponent("CopyDocument")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      class: "flex items-center mr-12",
      onSubmit: _withModifiers(_unref(getTBankKassaLink), ["prevent"]),
      "validation-schema": _unref(schema)
    }, {
      default: _withCtx(() => [
        _createVNode(FormItem, {
          class: "mr-4",
          name: "name",
          label: "Имя"
        }, {
          default: _withCtx(({ value, onBlur, onInput }) => [
            _createVNode(_component_el_input, {
              "model-value": value,
              type: "text",
              name: "name",
              size: "small",
              onBlur: onBlur,
              onInput: onInput,
              placeholder: "example@ya.ru"
            }, null, 8, ["model-value", "onBlur", "onInput"])
          ]),
          _: 1
        }),
        _createVNode(FormItem, {
          class: "mr-4",
          name: "qty",
          label: "Количество"
        }, {
          default: _withCtx(({ value, onBlur, onInput }) => [
            _createVNode(_component_el_input_number, {
              "model-value": value,
              type: "number",
              name: "qty",
              min: 1,
              size: "small",
              onBlur: onBlur,
              onInput: onInput,
              placeholder: "0"
            }, null, 8, ["model-value", "onBlur", "onInput"])
          ]),
          _: 1
        }),
        _createVNode(FormItem, {
          class: "mr-4",
          name: "price",
          label: "Цена"
        }, {
          default: _withCtx(({ value, onBlur, onInput }) => [
            _createVNode(_component_el_input, {
              "model-value": value,
              type: "text",
              name: "price",
              size: "small",
              onBlur: onBlur,
              onInput: onInput,
              placeholder: "123"
            }, null, 8, ["model-value", "onBlur", "onInput"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          class: "mt-6",
          "native-type": "submit",
          type: "primary",
          size: "small"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Получить ссылку")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onSubmit", "validation-schema"]),
    _createElementVNode("div", null, [
      _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Ссылка на оплату", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(kassaLink.value !== '' ? kassaLink.value : 'не сформирована'), 1),
        _createVNode(_component_el_tooltip, {
          effect: "dark",
          content: "Скопировать значение",
          placement: "top-start"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              (kassaLink.value !== '')
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(copyValue)(kassaLink.value))),
                    class: "button--text-hover",
                    color: "#4b60cecc",
                    size: "large"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CopyDocument)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})