import TaxPLan from '@/03-Pages/TaxPlanList/TaxPLanList.vue'
import TaxPlanShow from '@/03-Pages/TaxPlanShow/TaxPlanShow.vue'
import { CheckAdminRoleMiddleware } from '@/router/middlewares/CheckAdminRoleMiddleware'

export default [
  {
    name: 'tax plan index',
    path: 'tax_plans',
    component: TaxPLan,
    meta: {
      middleware: [CheckAdminRoleMiddleware],
    },
  },
  {
    name: 'tax plan show',
    path: 'tax_plan/:id',
    component: TaxPlanShow,
    props: true,
    meta: {
      middleware: [CheckAdminRoleMiddleware],
    },
  },
]
