import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center mb-4" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "mb-8" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_6 = { class: "tax_plan_info--data_sources mb-8 bg-gray p-4 border width-100" }

import { computed, ref } from 'vue'
import { ITaxPlan } from '@/entityes/invoice/tax_plan/tax_plan.type'
import { UploadFilled } from '@element-plus/icons-vue'
import FormItem from '@/components/supportComponents/FormItem.vue'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { useForm, useFormValues } from 'vee-validate'
import { useTaxPlanRepository } from '@/entityes/invoice/tax_plan/tax_plan.repository'
import { useTaxPlanStore } from '@/entityes/invoice/tax_plan/tax_plan.store'
import DataSourceCard from '@/07-Shared/ui/DataSourceCard.vue'
import { SidebarLoader } from '@/05-Features/SidebarLoader'
import { describers } from '@/06-Entities/File/model/LoaderDescriber'
import fileLoader from '@/06-Entities/File/model/FileLoader'
import { ILoaderDescriber } from '@/entityes/invoice/file/loader/loader.type'
import { ElNotification } from 'element-plus'
import DownloadFileCard from '@/06-Entities/File/ui/DownloadFileCard.vue'

interface IProps {
  taxPlan: ITaxPlan
  profileId: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TaxPlanInfo',
  props: {
    taxPlan: {},
    profileId: {}
  },
  setup(__props: any) {

const props = __props
const { updateTaxPlan, saveDataSource, getTaxPlan } = useTaxPlanRepository()
const taxPlanStore = useTaxPlanStore()
const loader = fileLoader(describers.find((loader) => loader.serverTag === 'interactive_brokers_files') as ILoaderDescriber)
const schema = toTypedSchema(
  yup.object({
    name: yup.string(),
  })
)
const form = useForm({
  validationSchema: schema,
  initialValues: {
    name: props.taxPlan.name || ''
  }
})
const formValues = useFormValues()
const timer = ref(0)
const showLoaderSideBar = ref(false)
const activeDataSources = computed(() => props.taxPlan.data_sources?.map(i => i.id))
const activeDataSourcesModel = ref([...activeDataSources.value])
const dataSourceProcessing = computed(() => ({
  data_ids: activeDataSourcesModel.value,
  name: formValues.value.name,
}))
const isLoading = ref(false)
function updateCurrentTaxPlan() {
  isLoading.value = true
  updateTaxPlan(props.profileId, props.taxPlan.id, {...dataSourceProcessing.value}).then((res) => {
    ElNotification({
      title: `Успешно`,
      message: `обновлен таксплан ${props.taxPlan.id}`,
      type:  'success',
    })
    if (res?.async) {
      timer.value = setTimeout(() => {
        getTaxPlan(props.profileId, props.taxPlan.id)
      }, 5000)
    }
  }).catch((err) => {
    ElNotification({
      title: `Ошибка`,
      message: `обновления таксплана ${props.taxPlan.id}. ${err}`,
      type:  'error',
    })
  })
}
function saveFilesToDataSource() {
  const body = {
    file: loader.localFiles.files[0],
    file_name: loader.localFiles.file_names[0],
    file_type: loader.config.serverTag,
  }
  saveDataSource(props.profileId, body).then(() => {
    ElNotification({
      title: `Файл успешно`,
      message: 'загружен',
      type:  'success',
    })
  }).catch((err) => {
    ElNotification({
      title: `Ошибка`,
      message: `загрузки файла. ${err}`,
      type:  'error',
    })
  })
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      class: "el-button mb-8 mt-4",
      to: { name: 'tax plan index' }
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode("К списку налоговых планов")
      ])),
      _: 1
    }),
    _createVNode(_component_el_row, {
      class: "flex items-center border mb-8",
      style: {"align-items":"stretch","border":"1px solid #d2d6de"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          lg: 12,
          class: "border p-2"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.taxPlan?.name === '' ? `${_ctx.taxPlan.id} TaxPlan` : _ctx.taxPlan?.name), 1)
              ]),
              _createVNode(_component_el_form, {
                onSubmit: _withModifiers(updateCurrentTaxPlan, ["prevent"]),
                "validation-schema": _unref(schema)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(FormItem, {
                      name: "name",
                      label: ''
                    }, {
                      default: _withCtx(({ value, onBlur, onInput }) => [
                        _createVNode(_component_el_input, {
                          "model-value": value,
                          type: "text",
                          name: "name",
                          size: "small",
                          onBlur: onBlur,
                          onInput: onInput,
                          placeholder: "Имя таксплана"
                        }, null, 8, ["model-value", "onBlur", "onInput"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_tooltip, {
                      effect: "dark",
                      content: "Изменить имя",
                      placement: "top"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          style: {"margin-left":"-30px","z-index":"1000","width":"30px"},
                          type: "primary",
                          icon: _unref(UploadFilled),
                          "native-type": "submit",
                          size: "small"
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["validation-schema"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_tooltip, {
                effect: "dark",
                content: "Открыть в инвойсе",
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: { name: 'invoice show', params: { id: _ctx.taxPlan?.invoice_id } },
                    target: "_blank",
                    class: "font-weight--extra"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.taxPlan?.invoice_id) + " инвойc ", 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_el_button_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  loading: _ctx.taxPlan.async,
                  type: "primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showLoaderSideBar.value = true))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Загрузить файл")
                  ])),
                  _: 1
                }, 8, ["loading"]),
                _createVNode(_component_el_button, {
                  icon: _unref(UploadFilled),
                  type: "success",
                  onClick: updateCurrentTaxPlan,
                  loading: _ctx.taxPlan.async
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Сохранить ")
                  ])),
                  _: 1
                }, 8, ["icon", "loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          lg: 12,
          class: "bg-gray p-2"
        }, {
          default: _withCtx(() => [
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-bold mb-8" }, "Итоговые файлы", -1)),
            (_ctx.taxPlan.result_files?.length > 0)
              ? (_openBlock(), _createBlock(_component_el_scrollbar, {
                  key: 0,
                  class: "pr-8",
                  "max-height": 100,
                  always: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taxPlan.result_files, (file) => {
                      return (_openBlock(), _createBlock(DownloadFileCard, {
                        file: file,
                        "is-result": ""
                      }, null, 8, ["file"]))
                    }), 256))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_unref(taxPlanStore).dataSources)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "margin-v-s" }, "Доступные Источники", -1)),
            _withDirectives((_openBlock(), _createBlock(_component_el_checkbox_group, {
              class: "tax_plan_info--datasource_list",
              modelValue: activeDataSourcesModel.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((activeDataSourcesModel).value = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(taxPlanStore).dataSources, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "flex"
                  }, [
                    _createVNode(_component_el_checkbox, {
                      value: item.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(DataSourceCard, { "data-source": item }, null, 8, ["data-source"])
                      ]),
                      _: 2
                    }, 1032, ["value"])
                  ]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])), [
              [_directive_loading, _ctx.taxPlan.async]
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(SidebarLoader), {
      "loader-describer": _unref(loader),
      "show-sidebar": showLoaderSideBar.value,
      "onUpdate:showSidebar": _cache[2] || (_cache[2] = ($event: any) => ((showLoaderSideBar).value = $event)),
      onReadedFile: _unref(loader).onUpdateFilesEvent,
      onDeleteLocalFile: _unref(loader).onRemoveLocalFile,
      onDeleteServerFile: _unref(loader).onAddDeleteFile,
      onUndoDeleteFile: _unref(loader).undoDeleteFiles,
      onSaveFiles: saveFilesToDataSource
    }, null, 8, ["loader-describer", "show-sidebar", "onReadedFile", "onDeleteLocalFile", "onDeleteServerFile", "onUndoDeleteFile"])
  ], 64))
}
}

})