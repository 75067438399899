import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { AdditionalIncomeWidget } from '@/04-Widgets/AdditionelIncome/'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'AdditionalIncomePage',
  setup(__props) {

const route = useRoute()
const invoiceId = computed(() => route.params.id as string)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(AdditionalIncomeWidget), { "invoice-id": invoiceId.value }, null, 8, ["invoice-id"])
  ]))
}
}

})