import { defineStore } from 'pinia'
import { IDeclaration, IMissingsList, IShortsList, ITransferList } from './declaration.type'
import { ref } from 'vue'

export const useDeclarationStore = defineStore('declaration store', () => {
  const declaration = ref<IDeclaration | null>(null)
  const tms = ref<any>({
    transfers: undefined,
    missings: undefined,
    unclosed_shorts: undefined,
  })
  return {
    declaration,
    tms,
  }
})
