import { defineStore } from 'pinia'
import { ref } from 'vue'
import {IDataSource, ITaxPlan} from '@/entityes/invoice/tax_plan/tax_plan.type'

export const useTaxPlanStore = defineStore('taxplan store', () => {
  const taxPlans = ref<ITaxPlan[] | undefined>(undefined)
  const taxPlan = ref<ITaxPlan | null>(null)
  const dataSources = ref<IDataSource[] | null>(null)
  return {
    taxPlans,
    taxPlan,
    dataSources
  }
})
