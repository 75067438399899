import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/undo_icon.svg'


const _hoisted_1 = { class: "bg-gray pb-2" }
const _hoisted_2 = { class: "flex-box flex-box--align-c padding-h-l margin-v-xs" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "margin-v-m padding-h-l" }
const _hoisted_7 = {
  key: 0,
  class: "px-4"
}
const _hoisted_8 = { class: "py-2" }
const _hoisted_9 = {
  key: 1,
  class: "p-4"
}
const _hoisted_10 = {
  key: 2,
  class: "my-4"
}
const _hoisted_11 = { class: "bg-gray padding--40 margin-v-s" }
const _hoisted_12 = {
  key: 0,
  class: "text-center"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "px-4" }
const _hoisted_15 = {
  key: 3,
  class: "padding-h-m margin-top-m flex-box flex-box--align-c margin-v-m"
}

import { PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'
import RightSideBar from '@/07-Shared/ui/RightSideBar.vue'
import { DragAndDrop, FileItem, FileFromServerItem, FileFromServerDeleted } from '@/06-Entities/File'
import { computed, defineAsyncComponent } from 'vue'
import { FileLoaderType } from '@/06-Entities/File'
interface IProps {
  loaderDescriber: FileLoaderType
  showDeleteButton?: boolean
}
interface IEmits {
  (e: 'readedFile', value: PreProcessingFiles): void
  (e: 'deleteLocalFile', index: number): void
  (e: 'deleteServerFile', value: number): void
  (e: 'undoDeleteFile'): void
  (e: 'saveFiles'): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarLoader',
  props: /*@__PURE__*/_mergeModels({
    loaderDescriber: {},
    showDeleteButton: { type: Boolean, default: false }
  }, {
    "showSidebar": { type: Boolean, ...{ required: true, default: false } },
    "showSidebarModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["readedFile", "deleteLocalFile", "deleteServerFile", "undoDeleteFile", "saveFiles"], ["update:showSidebar"]),
  setup(__props: any, { emit: __emit }) {

const IbDownloadFileInstruction = defineAsyncComponent(
  () => import('@/components/Instructions/IbDownloadFileInstruction')
)

const showSidebar = _useModel<boolean>(__props, 'showSidebar')

const props = __props
const isExanteLoaderDescriber = computed<boolean>(
  () => props.loaderDescriber.config.serverTag !== 'exante_virtual_files'
)
const emit = __emit

function dndReadedFiles(value: PreProcessingFiles) {
  emit('readedFile', value)
}
function fileItemDeleteLocalLoadedItem(index: number) {
  emit('deleteLocalFile', index)
} // props.loaderDescriber.onRemoveLocalFile(index)
function undoDeleteFile() {
  emit('undoDeleteFile')
}
const showFileFromServer = computed(() => isExanteLoaderDescriber && props.loaderDescriber.filesFromServer.length > 0)

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(RightSideBar, {
    modelValue: showSidebar.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((showSidebar).value = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: props.loaderDescriber.config.logo,
            alt: "plus_icon",
            class: "logo-img margin-h--right-6"
          }, null, 8, _hoisted_3),
          (props.loaderDescriber.config.serverTag === 'exante_virtual_files')
            ? (_openBlock(), _createElementBlock("h2", _hoisted_4, "Инструкция для клиентов Exante"))
            : (_openBlock(), _createElementBlock("h2", _hoisted_5, "Загрузить отчеты " + _toDisplayString(props.loaderDescriber.config.title), 1))
        ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(props.loaderDescriber.config.description), 1)
      ]),
      (isExanteLoaderDescriber.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_unref(DragAndDrop), {
              text: props.loaderDescriber.config.text,
              "file-format": props.loaderDescriber.config.fileFormat,
              onReadedFiles: dndReadedFiles
            }, null, 8, ["text", "file-format"]),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.loaderDescriber.localFiles.file_names, (item, index) => {
                return (_openBlock(), _createBlock(_unref(FileItem), {
                  name: item,
                  key: 'local_files_' + item,
                  onClose: 
            () => {
              fileItemDeleteLocalLoadedItem(index)
            }
          
                }, null, 8, ["name", "onClose"]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[3] || (_cache[3] = [
            _createElementVNode("iframe", {
              src: "https://www.youtube.com/embed/S2wMoKIKWfk?si=lwHLZJVjyba8nNBC",
              title: "YouTube video player",
              width: "100%",
              height: "300px",
              allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: "",
              loading: "lazy"
            }, null, -1)
          ]))),
      (showFileFromServer.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "margin-v-xs text-center" }, "В портфеле", -1)),
              (!props.showDeleteButton)
                ? (_openBlock(), _createElementBlock("p", _hoisted_12, " Вы можете удалить отчет, обратившись в службу поддержки. "))
                : (_openBlock(), _createElementBlock("p", _hoisted_13, " Вы можете удалить отчет, нажав на значок мусорной корзины, затем – на кнопку «Изменить состав файлов». Далее нажмите «Продолжить» для обновления информации по портфелю. "))
            ]),
            _createVNode(_unref(FileFromServerItem), {
              files: props.loaderDescriber.getFilesFromServer,
              "no-delete": !props.showDeleteButton,
              onDelete: _cache[0] || (_cache[0] = (index) => emit('deleteServerFile', index))
            }, null, 8, ["files", "no-delete"]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_unref(FileFromServerDeleted), {
                files: props.loaderDescriber.getFileToDelete
              }, null, 8, ["files"])
            ])
          ]))
        : _createCommentVNode("", true),
      (props.loaderDescriber.deleteFiles.length > 0 || props.loaderDescriber.localFiles.file_names.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_el_button, {
              onClick: _cache[1] || (_cache[1] = 
          () => {
            showSidebar.value = false
            emit('saveFiles')
          }
        ),
              class: "button-main",
              type: "primary",
              size: "large"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.loaderDescriber.getFilesFromServer.length > 0 || props.loaderDescriber.deleteFiles.length > 0
            ? 'Сохранить изменения файлов'
            : 'Сохранить файлы'), 1)
              ]),
              _: 1
            }),
            (props.loaderDescriber.deleteFiles.length > 0)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  onClick: undoDeleteFile,
                  type: "primary",
                  class: "button-main",
                  size: "large"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      width: "14",
                      alt: "undo_icon"
                    }, null, -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_unref(IbDownloadFileInstruction), {
        broker: props.loaderDescriber?.config?.serverTag,
        class: "padding-v-l"
      }, null, 8, ["broker"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})