<script setup lang="ts">
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useInvoiceRemoteAdminRequest from '@/06-Entities/AdminInvoiceRemote/api/invoice_remote.admin.request'
import { computed, ref } from 'vue'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import FormItem from '@/components/supportComponents/FormItem.vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { ElNotification } from 'element-plus'
import { useForm, useFormValues } from 'vee-validate'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import {useAsyncInvoiceReload} from "@/entityes/invoice/useAsyncInvoiceReload";
import { SuccessFilled, Refresh, CircleCloseFilled, Loading } from '@element-plus/icons-vue'

const invoiceStore = useInvoiceStore()
const { recalculation, assignInvoice, toggleBlockInvoice } = useInvoiceRemoteAdminRequest()
const { getInvoice } = useInvoiceRepository()
const asyncInvoice = computed(() => invoiceStore.invoice && invoiceStore.invoice.status.async)
const blocked = computed(() => invoiceStore.invoice && invoiceStore.invoice.status.blocked)
const schema = toTypedSchema(
  yup.object({
    email: yup.string().required().email(),
  })
)
const { handleSubmit } = useForm({
  validationSchema: schema,
})
const formValues = useFormValues()
const isLoading = ref(false)
const { initAsync } = useAsyncInvoiceReload()

function recalculateInvoice() {
  isLoading.value = true
  recalculation(invoiceStore.invoice?.id).then(() => {
    getInvoice(invoiceStore.invoice?.id).then(() => {
      ElNotification({
        title: 'Успешно',
        message: `отправлен на пересчет заказ ${invoiceStore.invoice.id}`,
        type: 'success'
      })
      initAsync(invoiceStore.invoice)
    })
  }).catch((err) => {
    ElNotification({
      title: 'Ошибка',
      message: `при пересчете. Попробуйте позже. ${err}`,
      type: 'error'
    })
  }).finally(() => isLoading.value = false)
}
function updateBlock(val: boolean) {
  toggleBlockInvoice(invoiceStore.invoice.id, { blocked: val }).then((res) => {
    getInvoice(res.id)
  })
}
const assignInvoiceToUser = handleSubmit((form) => {
  isLoading.value = true
  if (invoiceStore.invoice){
    assignInvoice(invoiceStore.invoice?.id, form).then((res: IInvoice) => {
      ElNotification({
        title: 'Успещно',
        message: `заказ ${res.id} привязан к пользователю ${res.email}`,
        type: 'success'
      })
      getInvoice(res.id)
    }).catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `во время привзяки заказа к пользователю ${formValues.value.email}. Попробуйте позже. ${err}`,
        type: 'error'
      })
    }).finally(() => isLoading.value = false)
  }
})
</script>

<template>
  <div class="flex">
    <div class="flex mr-12">
      <div>
        <h4 class="font-weight--extra font-color--deep-gray mb-2">Привязать</h4>
        <div class="flex flex-wrap">
          <div style="width: 200px" class="mr-4">
            <el-form @submit.prevent="assignInvoice" :validation-schema="schema">
              <FormItem name="email" v-slot="{ value, onBlur, onInput }" :label="''">
                <el-input :model-value="value"
                          type="text"
                          name="email"
                          size="small"
                          @blur="onBlur"
                          @input="onInput"
                          placeholder="example@ya.ru" />
              </FormItem>
            </el-form>
          </div>
          <div>
            <el-button
              size="small"
              type="primary"
              :loading="isLoading"
              :disabled="isLoading || asyncInvoice"
              @click="assignInvoiceToUser"
            >Привязать</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="mr-12">
      <h4 class="font-weight--extra mb-2 font-color--deep-gray">Пересчитать</h4>
      <div v-if="!asyncInvoice">
        <el-tooltip class="box-item" effect="dark" content="На основании загруженных файлов" placement="top">
          <el-button
            size="small"
            :loading="isLoading"
            type="primary"
            @click="recalculateInvoice"
            :disabled="isLoading || asyncInvoice"
            :icon="Refresh"
          >
            Пересчитать
          </el-button>
        </el-tooltip>
      </div>
      <div class="flex items-center" v-else>
        <el-icon color="#FF9A9D" class="is-loading mr-2" v-if="asyncInvoice"><Loading /></el-icon>
        <p class="font-color--red">расчет</p>
      </div>
    </div>
    <div>
      <h4 class="font-weight--extra mb-2 font-color--deep-gray">
        Блокировка:
        <span class="font-weight--extra" :class="blocked ? 'font-color--red' : 'font-color--green'">{{
            blocked ? 'да' : 'нет'
          }}</span>
      </h4>
      <el-tooltip
        v-if="!blocked"
        class="box-item"
        effect="dark"
        content="Закрыть скачивание документов"
        placement="top"
      >
        <el-button
          size="small"
          :loading="isLoading"
          type="danger"
          @click="updateBlock(true)"
          :disabled="isLoading || asyncInvoice"
          :icon="CircleCloseFilled"
        >
          Заблокировать
        </el-button>
      </el-tooltip>
      <el-tooltip v-else class="box-item" effect="dark" content="Открыть скачивание документов" placement="top">
        <el-button
          size="small"
          :loading="isLoading"
          type="success"
          :icon="SuccessFilled"
          @click="updateBlock(false)"
          :disabled="isLoading || asyncInvoice"
        >
          Разблокировать
        </el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<style scoped lang="sass">

</style>
