<template>
  <div class="margin-v-s">
    <el-button type="primary" size="small" @click="openVideoModal" class="subscribe-button" v-if="showButton">
      <el-icon class="el-icon-video-play font-weight--extra margin-h--right-10"><VideoPlay /></el-icon>
      Как это работает?
    </el-button>
  </div>
  <!-- TODO fix width and height -->
  <el-dialog
    v-model="videoModalIsOpen"
    :destroy-on-close="true"
    :width="dialogWidth?.dialogWidth"
    title="Как пользоваться сервисом НДФЛ Гуру"
  >
    <iframe
      :src="videoLink"
      title="YouTube video player"
      width="100%"
      :height="dialogWidth?.frameHeight"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      loading="lazy"
    >
    </iframe>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'

interface IProps {
  showButton?: boolean
  videoLink: string
}
const props = withDefaults(defineProps<IProps>(), {
  showButton: false,
})
const videoModalIsOpen = ref(false)
const openVideoModal = () => {
  videoModalIsOpen.value = true
}
const { screenLessValue } = useWindowWidth()
const dialogWidth = computed(() => {
  if (screenLessValue(767)) {
    return {
      frameHeight: 300,
      dialogWidth: '80%',
    }
  }
  if (screenLessValue(1780)) {
    return {
      frameHeight: 600,
      dialogWidth: '80%',
    }
  }
})
defineExpose({
  openVideoModal,
})
</script>

<style scoped lang="sass">
.modal-height
  height: 500px
@keyframes TransitioningBackground
  0%
    background-position: 1% 0
  50%
    background-position: 99% 100%
  100%
    background-position: 1% 0

.subscribe-button
  position: relative
  overflow: hidden
  // for background color shift
  background-image: (linear-gradient(270deg, #FF9A9D, #4b60cecc))
  background-size: 400% 400%
  animation: TransitioningBackground 4s ease infinite
  // to ease the button growth on hover
  transition: 0.6s
  border: none

  // psuedo-element shine animation left side
  &::before
    content: ''
    display: block
    position: absolute
    //background: rgba(255, 255, 255, 0.5)
    //width: 60px
    height: 100%
    top: 0
    filter: blur(30px)
    transform: translateX(-100px) skewX(-15deg)

  // psuedo-element shine animation right side
  &::after
    content: ''
    display: block
    position: absolute
    background: rgba(255, 255, 255, 0.2)
    width: 30px
    height: 100%
    top: 0
    filter: blur(5px)
    transform: translateX(-100px) skewX(-15deg)

  // grow button and change background gradient on hover
  &:hover
    background-image: (linear-gradient(to left, #2d8fe5, #d155b8))
    //transform: scale(1.2)
    cursor: pointer

    // psuedo-elements for right- and left-side shine animations
    &::before,
    &::after
      transform: translateX(300px) skewX(-15deg)
      transition: 2s
</style>
