<script lang="ts" setup>
import { IAdminFileInfo } from '@/entityes/invoice/file/file.type'
import useTypeWriter from '@/plugins/useTypeWriter'
import { toRefs } from 'vue'

interface IProps {
  file: IAdminFileInfo
}
const props = defineProps<IProps>()
const { file } = toRefs(props)
const { renderString } = useTypeWriter('загрузка...')
</script>

<template>
  <el-tag
    class="cursor-point card-hover"
    :class="file.is_active ? 'bg-green' : ''"
    :type="file.is_active ? undefined : 'info'"
    style="min-width: 200px; padding: 40px 20px"
  >
    <div v-if="file.isLoading" class="flex items-center">
      <el-icon class="el-icon-loading font-weight--extra mr-2" style="vertical-align: middle">
        <Loading />
      </el-icon>
      <p>{{ renderString }}</p>
    </div>
    <div v-else>
      <p class="mb-4 font-bold">{{ file.type.split('::')[1] }}</p>
      <p class="mb-4">{{ file.name }}</p>
      <p>{{ file.created_at.split('T')[0] }} {{ file.created_at.split('T')[1].split('.')[0] }}</p>
    </div>
  </el-tag>
</template>

<style scoped lang="sass"></style>
