import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "padding-h-l" }
const _hoisted_2 = { class: "flex-box margin-v-xs" }
const _hoisted_3 = { class: "margin-h--left-s text-strike opacity" }
const _hoisted_4 = { class: "margin-v-xs" }

import { FileFromServer, IAdminFileInfo } from '@/entityes/invoice/file/file.type'

interface IProps {
  files: FileFromServer[]
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FileFromServerDeleted',
  props: {
    files: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  const _component_el_divider = _resolveComponent("el-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.files, (file) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'file_to_delete_' + file.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "el-icon-close font-color--red font-weight--extra" }, null, -1)),
          _createElementVNode("span", _hoisted_3, _toDisplayString(file.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_divider, { class: "el-divider-80" })
        ])
      ]))
    }), 128))
  ]))
}
}

})