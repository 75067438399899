<script lang="ts" setup>
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useTextFilters from '@/plugins/TextFilters'
import { useBrokerName } from '@/use/useBrokerName'
import { computed } from 'vue'

const invoiceStore = useInvoiceStore()
const hasErrors = computed(
  () => invoiceStore.invoice && Object.keys(invoiceStore.invoice?.warning_and_error.errors).length !== 0
)
const errors = computed(() => invoiceStore.invoice?.warning_and_error.errors)
const { brokerNames } = useBrokerName()
const { subStringFileName } = useTextFilters()
</script>

<template>
  <div v-if="hasErrors">
    <div>
      <el-collapse>
        <el-collapse-item v-for="(accounts, broker) in errors" :key="broker">
          <template #title>
            <WarningFilled
              style="font-weight: 700; width: 1em; height: 1em"
              color="#FF9A9D"
              class="ml-2 mr-2"
            />
            <h3>
              {{ brokerNames[broker] || broker }}
            </h3>
          </template>
          <div v-for="(err, account) in accounts" :key="account" class="mb-2">
            <div class="flex items-center pl-2 flex-wrap">
              <div class="mr-2">
                <p class="font-bold font-color--gray">
                  {{ broker === 'workers' ? 'воркер: ' : 'имя файла: ' }}
                </p>
                <p>{{ subStringFileName(account, 30) }}</p>
              </div>
              <div>
                <p class="font-bold font-color--gray">ошибка:</p>
                <p>{{ err }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
  <div class="ml-2" v-else>
    <p>Ошибок не обнаружено</p>
  </div>
</template>

<style scoped></style>
