import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import GoodsPreview from '@/04-Widgets/ResultStep/ui/GoodsPreview.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'ResultFiles',
  setup(__props) {

const route = useRoute()
const invoiceId = computed(() => route.params.id as string)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GoodsPreview, { id: invoiceId.value }, null, 8, ["id"]))
}
}

})