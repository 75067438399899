<script setup lang="ts">
import { defineProps, inject, onUnmounted } from 'vue'
import { useField, FieldBindingObject } from 'vee-validate'
import { EventEmitter } from 'events'

interface IProps {
  label?: string
  name: string
  // todo добавить правильный тип для model
}
const props = defineProps<IProps>()
const { handleBlur, validate, handleChange, value, errors, meta, errorMessage, setTouched } = useField(() => props.name)
function onBlur() {
  handleBlur()
  validate()
}
// TODO Понаблюдать за производительностью, не повлияет-ли потенциально большое количество подписок
const emitter = inject<EventEmitter>('form-emitter')
function setTouch() {
  validate()
  setTouched(true)
}
emitter?.addListener('setFieldTouched', setTouch)
onUnmounted(() => {
  emitter?.removeListener('setFieldTouched', setTouch)
})
// TODO следить за поведением
const field: FieldBindingObject = {
  name: props.name,
  onBlur,
  onChange: handleChange,
  onInput: handleChange,
  'onUpdate:modelValue': handleChange,
}
</script>

<template>
  <div>
    <p v-if="label" class="label font-semibold mb-1">
      {{ label }}
    </p>
    <div class="form-slot">
      <slot
        :value="value as any"
        :onBlur="onBlur"
        :onInput="handleChange"
        :meta="meta"
        :field="field"
        :errors="errors"
        :errorMessage="errorMessage"
      />
      <el-popover placement="bottom" class="margin-h--left-s" :width="300" v-if="!meta.valid && meta.touched">
        <template #reference>
          <span class="form-warning">
            <el-icon color="#FF9A9D" size="large" class="margin-h--left-s cursor-point"><WarningFilled /></el-icon>
          </span>
        </template>
        <p
          :class="{ 'rb-form-error-hide': meta.valid || !meta.touched }"
          class="font-color--red"
          style="word-break: break-word"
        >
          {{ errorMessage || 'нет ошибок' }}
        </p>
      </el-popover>
    </div>
  </div>
</template>

<style scoped lang="sass">
//.label
//  text-align: right
//  vertical-align: middle
//  float: left
//  box-sizing: border-box
.rb-form-error
  line-height: 14px
  opacity: 1
  margin-bottom: 10px
  &-hide
    opacity: 0 !important
.form-slot
  position: relative
.form-warning
  position: absolute
  right: -5px
  top: -5px
</style>
