<script lang="ts" setup>
import { ref } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { ElNotification } from 'element-plus'

export interface IProps {
  id: string | number
}
const props = defineProps<IProps>()
const { sendDiscountRequest, dropDiscountRequest, getInvoice } = useInvoiceRepository()
const visible = ref(false)
const { width } = useWindowWidth()
const form = ref<{[key: string]: string | boolean}>({
  loss: false,
  portfolio_volume: false,
  relocate: false,
  other: false,
  comment: ''
})
const openModal = () => {
  visible.value = true
}
const closeModal = () => {
  visible.value = false
}
const dialogWidth = () => {
  if (width.value < 1200) {
    if (width.value > 991) {
      return '40%'
    }
  }
  if (width.value < 992) {
    if (width.value > 767) {
      return '60%'
    }
  }
  if (width.value < 767) {
    return '90%'
  }
  return '36%'
}
const clearForm = () => {
  Object.keys(form.value).filter((item) => {
    if (item === 'comment') {
      form.value[item] = ''
    } else {
      form.value[item] = false
    }
  })
}
const request = () => {
  const body = {
    form_id: 'ng_discount_request',
    host: process.env.VUE_APP_SERVER_HOST,
    invoice_id: props.id,
    ...form.value,
  }
  const validFields = Object.keys(form.value).reduce((acc: string[], key: string) => {
    if (form.value[key]) {
      acc.push(key)
    }
    return acc
  }, [])
  if (validFields.length > 0) {
    sendDiscountRequest(body).then(() => {
      clearForm()
      closeModal()
      ElNotification({
        title: 'Заявка принята',
        message: 'наши специалисты скоро свяжутся с вами',
        type: 'success',
      })
    }).catch((err) => {
      clearForm()
      closeModal()
      ElNotification({
        title: `Произошла ошибка`,
        message: 'Попробуйте отправить заявку еще раз.',
        type: 'error',
      })
    }).then(() => {
      dropDiscountRequest(
        props.id,
        { discount_request: true }
      ).then(() => {
        getInvoice(props.id)
      }).catch((err) => {
        ElNotification({
          title: `Ошибка`,
          message: `При сохранении статуса отправки сообщения о скидке ${err}`,
          type: 'error',
        })
      })
    })
  } else {
    ElNotification({
      title: `Ошибка`,
      message: 'Вы не выбрали ни одного пункта',
      type: 'error',
    })
  }
}
defineExpose({ openModal })
</script>

<template>
  <el-dialog v-model="visible" :width="dialogWidth()" center>
    <h2 class="mb-4">Дарим дополнительную скидку, если у вас:</h2>
    <div class="mb-4">
      <el-checkbox v-model="form.loss" label="Убыток в 2023 году" />
    </div>
    <div class="mb-4">
      <el-checkbox v-model="form.portfolio_volume" label="Размер портфеля до $50 000" />
    </div>
    <div class="mb-4">
      <el-checkbox v-model="form.relocate" label="Переезд в другую страну" />
    </div>
    <div class="mb-4">
      <el-checkbox v-model="form.other" label="Опишите вашу ситуацию" />
    </div>
    <div v-if="form.other" class="mb-4">
      <p class="font-bold font-color--deep-gray mb-4">Комментарий</p>
      <el-input
        size="large"
        v-model="form.comment"
        type="textarea"
        class="textarea-crunch"
        placeholder="Опишите вашу ситуацию"
        :autosize="{ minRows: 2, maxRows: 4 }"
      />
    </div>
    <div class="flex items-center justify-center">
      <el-button @click="request" size="large" type="primary" class="button-main">
        <el-icon class="mr-4"><UploadFilled /></el-icon>
        Хочу
      </el-button>
    </div>
  </el-dialog>
</template>

<style scoped>

</style>
