import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "good-card mb-6 card-hover" }
const _hoisted_2 = { class: "bg-gray border--top p-4" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "mr-4" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "mr-3" }
const _hoisted_7 = {
  key: 0,
  class: "flex justify-between font-bold p-4"
}
const _hoisted_8 = {
  key: 0,
  class: "font-weight--semi"
}
const _hoisted_9 = {
  key: 1,
  class: "p-4"
}
const _hoisted_10 = { class: "mb-2" }
const _hoisted_11 = { class: "flex justify-between" }
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 2,
  class: "flex-box"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 0,
  class: "font-weight--semi",
  style: {"margin-top":"6px"}
}
const _hoisted_17 = {
  key: 0,
  class: "flex justify-end mb-2"
}
const _hoisted_18 = {
  style: {"font-size":"12px"},
  class: "font-color--deep-gray"
}
const _hoisted_19 = {
  key: 1,
  class: "flex justify-between items-center mb-2"
}
const _hoisted_20 = { class: "flex items-center" }
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 0,
  class: "font-weight--semi"
}
const _hoisted_23 = {
  key: 1,
  class: "font-weight--semi"
}
const _hoisted_24 = {
  key: 2,
  class: "el-divider-purple"
}
const _hoisted_25 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_26 = { class: "flex items-center" }
const _hoisted_27 = ["src"]

import useWindowWidth from '@/use/useWindowWidth'
import { computed, ref, toRef, watch } from 'vue'
import { describers } from '@/06-Entities/File/model/LoaderDescriber'
import { useForm, useFormValues } from 'vee-validate'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { cloneDeep } from 'lodash'

export interface IProps {
  files: any
  inBasket: boolean
  incomesDeductions: { [key: string]: { qty: number; price: number } }
  year: string | number
  shitBasePrice: string | number
  hasBundle: boolean
  amountOperations: number
  good: any
  cfrBucketSum: string | number
}
export interface IEmits {
  (e: 'add_to_basket', value: number | string): void
  (e: 'remove_from_basket', value: number | string): void
  (e: 'count_bucket_cfr', value: number | string): void
  (e: 'count_add', value: { total: number; services: { [key: string]: number } }): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GoodCard',
  props: {
    files: {},
    inBasket: { type: Boolean },
    incomesDeductions: {},
    year: {},
    shitBasePrice: {},
    hasBundle: { type: Boolean },
    amountOperations: {},
    good: {},
    cfrBucketSum: {}
  },
  emits: ["add_to_basket", "remove_from_basket", "count_bucket_cfr", "count_add"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const { incomesDeductionsDescribers } = AddIncomesRefundsDescriber()
const tariffsRange = [[0], [1, 15], [16, 50], [51, 200], [201, 800], [801, 1600], [1601, 3000], [3001, 50000]]
const bucketForm = ref(1)
const addedToBasket = ref(props.inBasket)
const formConfig = computed(() => {
  return Object.keys(props.incomesDeductions).reduce((acc: { [tag: string]: number }, tag: string) => {
    Object.assign(acc, { [tag]: props.incomesDeductions[tag].qty })
    return acc
  }, {})
})
const { values } = useForm({
  initialValues: { ...formConfig.value },
})
const formValues = useFormValues()
const currentTariffRange = computed(() => {
  let res = null
  for (const range of tariffsRange) {
    if (props.amountOperations === 0) {
      res = `Тариф: ${props.amountOperations} доходных операций`
    } else {
      const [min, max] = range
      if (props.amountOperations >= min && props.amountOperations <= max) {
        res = `Тариф: ${range.join(' - ')} доходных операций`
      }
    }
  }
  return res
})
const addIncomesDeductionTotal = computed(() => {
  return Object.keys(props.incomesDeductions).reduce((acc, key) => {
    return acc + props.incomesDeductions[key]?.price * formValues.value[key]
  }, 0)
})
const incomesDeductionsObject = computed(() => {
  const items = Object.keys(formValues.value).reduce((acc: { [key: string]: number }, key) => {
    if (formValues.value[key] > 0) {
      acc[key] = formValues.value[key]
    }
    return acc
  }, {})
  return {
    total: addIncomesDeductionTotal.value,
    services: { ...items },
  }
})
const countPrice = computed(() => {
  const res = {} as { [key: string]: number | string }
  if (props.hasBundle) {
    if (props.good.name === 'Cfr') {
      res.basePrice = props.shitBasePrice
    }
    if (props.good.name === 'Declaration') {
      res.basePrice = 0
    }
  }
  if (!props.hasBundle) {
    if (props.inBasket) {
      if (props.good.name === 'Cfr') {
        res.basePrice = props.shitBasePrice
      }
      if (props.good.name === 'Declaration') {
        res.basePrice = props.shitBasePrice
      }
    }
    if (!props.inBasket) {
      res.basePrice = 0
    }
  }
  return res
})

function hasFiles(brokerName: string) {
  return props.files.map((i: { name: string }) => i.name).includes(brokerName)
}
function tooltipForShit(key: string) {
  return ['unknown', 'property_deduction_build', 'property_deduction_mortgage'].includes(key)
}
function sendTotalBucket(val: number) {
  emit('count_bucket_cfr', val)
}

emit('count_add', incomesDeductionsObject.value)
emit('count_bucket_cfr', bucketForm.value)

watch(addIncomesDeductionTotal, () => {
  emit('count_add', incomesDeductionsObject.value)
})
watch(addedToBasket, (val) => {
  if (props.good.name === 'Decalration') {
    emit('count_add', incomesDeductionsObject.value)
  }
  if (val) {
    emit('add_to_basket', props.good.id)
  }
  if (!val) {
    emit('remove_from_basket', props.good.id)
  }
})

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_checkbox, {
            modelValue: addedToBasket.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((addedToBasket).value = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.good.title) + " " + _toDisplayString(_ctx.year), 1)
        ])
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.good.description), 1)
    ]),
    (_ctx.good.name === 'declaration_pdf')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "Стоимость", -1)),
          (_ctx.good.name === 'declaration_pdf')
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, "₽ " + _toDisplayString(_ctx.good.basePrice), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.good.name === 'Declaration' || _ctx.good.name === 'Cfr')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "mb-2" }, "Брокерские отчеты", -1)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", null, [
                (hasFiles('interactive_brokers_files'))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["flex items-center", 
                hasFiles('freedom_finance_files') || hasFiles('tinkoff_files') || hasFiles('bucket_files') ? 'mb-2' : ''
              ])
                    }, [
                      _createElementVNode("img", {
                        src: _unref(describers).find((i) => i.serverTag === 'interactive_brokers_files')?.logo,
                        alt: "broker_icon",
                        class: "logo-img mr-2"
                      }, null, 8, _hoisted_12),
                      _createElementVNode("p", null, _toDisplayString(_unref(describers).find((i) => i.serverTag === 'interactive_brokers_files')?.title), 1)
                    ], 2))
                  : _createCommentVNode("", true),
                (hasFiles('freedom_finance_files'))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["flex items-center", hasFiles('tinkoff_files') || hasFiles('bucket_files') ? 'margin-v-xs' : ''])
                    }, [
                      _createElementVNode("img", {
                        src: _unref(describers).find((i) => i.serverTag === 'freedom_finance_files')?.logo,
                        alt: "broker_icon",
                        class: "logo-img mr-2"
                      }, null, 8, _hoisted_13),
                      _createElementVNode("p", null, _toDisplayString(_unref(describers).find((i) => i.serverTag === 'freedom_finance_files')?.title), 1)
                    ], 2))
                  : _createCommentVNode("", true),
                (hasFiles('tinkoff_files') && _ctx.good.name === 'Declaration')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("img", {
                        src: _unref(describers).find((i) => i.serverTag === 'tinkoff_files')?.logo,
                        alt: "broker_icon",
                        class: "logo-img mr-2"
                      }, null, 8, _hoisted_15),
                      _createElementVNode("p", null, _toDisplayString(_unref(describers).find((i) => i.serverTag === 'tinkoff_files')?.title), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                (
                hasFiles('interactive_brokers_files') ||
                hasFiles('freedom_finance_files') ||
                (hasFiles('tinkoff_files') && _ctx.good.name === 'Declaration')
              )
                  ? (_openBlock(), _createElementBlock("p", _hoisted_16, " ₽ " + _toDisplayString(_ctx.good.basePrice), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            (
            _ctx.good.name === 'Declaration' &&
            (hasFiles('interactive_brokers_files') || hasFiles('freedom_finance_files') || hasFiles('tinkoff_files'))
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("p", _hoisted_18, _toDisplayString(currentTariffRange.value), 1)
                ]))
              : _createCommentVNode("", true),
            (hasFiles('bucket_files'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("img", {
                      src: _unref(describers).find((i) => i.serverTag === 'bucket_files')?.logo,
                      alt: "broker_icon",
                      class: "logo-img mr-2"
                    }, null, 8, _hoisted_21),
                    _createElementVNode("p", null, _toDisplayString(_unref(describers).find((i) => i.serverTag === 'bucket_files')?.title), 1)
                  ]),
                  (_ctx.good.name === 'Declaration')
                    ? (_openBlock(), _createBlock(_component_el_popover, {
                        key: 0,
                        placement: "top",
                        trigger: "hover",
                        width: 300
                      }, {
                        reference: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createElementVNode("div", null, [
                            _createElementVNode("p", { class: "font-color--warning font-bold cursor-pointer button--text-hover" }, "предоплата")
                          ], -1)
                        ])),
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createElementVNode("div", null, [
                            _createElementVNode("p", { style: {"word-break":"break-word !important"} }, " Обратитесь в тех-поддержку для расчета полной стоимости заказа ")
                          ], -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.good.name === 'Cfr')
                    ? (_openBlock(), _createBlock(_component_el_input_number, {
                        key: 1,
                        style: {"width":"86px !important"},
                        min: 1,
                        size: "small",
                        modelValue: bucketForm.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((bucketForm).value = $event)),
                        onInput: sendTotalBucket
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", null, [
                    (_ctx.good.name === 'Declaration')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_22, "₽ " + _toDisplayString(countPrice.value.basePrice), 1))
                      : (_openBlock(), _createElementBlock("p", _hoisted_23, "₽ " + _toDisplayString(_ctx.cfrBucketSum), 1))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.good.name === 'Declaration' && Object.keys(_ctx.incomesDeductions).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_24))
              : _createCommentVNode("", true)
          ]),
          (Object.keys(_ctx.incomesDeductions).length > 0 && _ctx.good.name === 'Declaration')
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                _cache[8] || (_cache[8] = _createElementVNode("h4", null, "Доходы и вычеты", -1)),
                _createVNode(_component_el_row, { class: "margin-v-xs" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      md: 13,
                      lg: 13
                    }),
                    _createVNode(_component_el_col, {
                      md: 6,
                      lg: 7,
                      class: "flex justify-center"
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createElementVNode("p", {
                          class: "font-bold font-color--purple text-center",
                          style: {"font-size":"14px"}
                        }, [
                          _createTextVNode(" Поручить внесение"),
                          _createElementVNode("br"),
                          _createTextVNode(" НДФЛ Гуру ")
                        ], -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      md: 4,
                      lg: 4,
                      class: "flex justify-end"
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createElementVNode("p", {
                          class: "font-bold font-color--purple",
                          style: {"font-size":"14px"}
                        }, "Стоимость", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incomesDeductions, (val, key) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: key,
                    class: "mb-2"
                  }, [
                    _createVNode(_component_el_row, { class: "mb-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, {
                          md: 13,
                          lg: 13
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_26, [
                              _createElementVNode("img", {
                                src: _unref(incomesDeductionsDescribers).find((i) => i.tag === key)?.icon,
                                alt: "close_icon",
                                width: "20",
                                class: "mr-2"
                              }, null, 8, _hoisted_27),
                              _createTextVNode(" " + _toDisplayString(_unref(incomesDeductionsDescribers).find((i) => i.tag === key)?.name), 1)
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_el_col, {
                          md: 6,
                          lg: 7,
                          class: "flex justify-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_tooltip, {
                              content: 'нельзя внести самостоятельно',
                              placement: "top",
                              trigger: "hover",
                              disabled: !tooltipForShit(key as string)
                            }, {
                              default: _withCtx(() => [
                                _createVNode(FormItem, {
                                  name: key,
                                  label: ''
                                }, {
                                  default: _withCtx(({ value, onInput }) => [
                                    _createVNode(_component_el_input_number, {
                                      style: {"width":"86px !important"},
                                      min: 0,
                                      max: val.qty,
                                      size: "small",
                                      "model-value": value,
                                      type: "text",
                                      name: key,
                                      disabled: 
                      key === 'unknown' || key === 'property_deduction_build' || key === 'property_deduction_mortgage'
                    ,
                                      onInput: onInput
                                    }, null, 8, ["max", "model-value", "name", "disabled", "onInput"])
                                  ]),
                                  _: 2
                                }, 1032, ["name"])
                              ]),
                              _: 2
                            }, 1032, ["disabled"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_el_col, {
                          md: 4,
                          lg: 4,
                          class: "font-weight--semi flex justify-end"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createElementVNode("p", null, _toDisplayString(_unref(formValues)[key] > 0 ? `₽ ${_ctx.incomesDeductions[key]?.price * _unref(formValues)[key] || 0}` : 'внесу сам'), 1)
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})