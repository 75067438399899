<script setup lang="ts">
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceInfo } from '@/use/AdminInfoTabs/useInvoiceInfo'
import BrokerAccountInfo from './BrokerAccountInfo.vue'
import { computed } from 'vue'
import { Loading } from '@element-plus/icons-vue'

const invoiceStore = useInvoiceStore()
const { info } = useInvoiceInfo(invoiceStore.invoice!)
const asyncInvoice = computed(() => invoiceStore.invoice?.status.async)
const amountOperations = computed(() => invoiceStore.invoice?.amount_operations?.Declaration)
const brokerAccounts = computed(() => invoiceStore.invoice?.broker_accounts)
</script>

<template>
  <el-row>
    <el-col
      v-for="item in info"
      :key="item.title"
      :xs="24"
      :sm="item.adaptive.sm"
      :md="item.adaptive.md"
      :lg="item.adaptive.lg"
    >
      <h4 class="font-weight--extra font-color--deep-gray">{{ item.title }}</h4>
      <div v-if="item.title === 'Статус'" class="flex items-center">
        <el-icon color="#FF9A9D" class="is-loading mr-2" v-if="asyncInvoice"><Loading /></el-icon>
        <p
          class="font-weight--extra flex align-center font-color--green"
          :class="asyncInvoice ? 'font-color--red' : ''"
        >
          {{ asyncInvoice ? 'раcчeт' : 'Посчитан' }}
        </p>
      </div>
      <div v-else class="font-weight--extra">
        {{ item.value.value }}
      </div>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="4">
      <h4 class="font-weight--extra font-color--deep-gray">Кол-во операций</h4>
      <div v-for="(operations, years) in amountOperations" :key="years">
        <span class="font-weight--extra">{{ years }} год: </span>
        {{ operations }}
      </div>
    </el-col>
    <el-col :xs="24" :sm="3" :md="3" :lg="3">
      <h4 class="font-weight--extra font-color--deep-gray">Номера счетов</h4>
      <broker-account-info :broker-account="brokerAccounts" />
    </el-col>
  </el-row>
</template>

<style lang="sass" scoped></style>
