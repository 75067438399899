<script setup lang="ts">
import {shallowRef, ref, computed} from 'vue';
import {useInvoiceStore} from "@/entityes/invoice/invoice.store";
import useInvoiceRemoteAdminRequest from "@/06-Entities/AdminInvoiceRemote/api/invoice_remote.admin.request";
import {ElNotification} from "element-plus";
import {useDeclarationStore} from "@/entityes/invoice/declaration/declaration.store";
import TinkoffForm from "@/06-Entities/AdminInvoiceRemote/ui/tinkoffForm.vue";
import fileDownload from "js-file-download";
import { SuccessFilled, Refresh, UploadFilled } from '@element-plus/icons-vue'
import { ITBankQuestionnaireRequest } from "@/entityes/invoice/TBankQuestionnaire/t_bank_questionnaire.type";
import {JsonViewer} from "vue3-json-viewer";

const invoiceStore = useInvoiceStore()
const declarationStore = useDeclarationStore()
const { getTBankPremiumStatus, aliveChat, setTBankPremiumStatus, getTBankForm, getAdditionalFile, sendFilesToTBank } = useInvoiceRemoteAdminRequest()
const tinkoffPremiumState = ref('')
const tinkoffForm = shallowRef({})
const additionalFilesTinkoff = computed(() => invoiceStore.invoice && invoiceStore.invoice.additional_incomes_files)
const isLoading = ref(false)
const invoiceAsync = computed(() => invoiceStore.invoice && invoiceStore.invoice.status.async)
const showJson = ref(false)

const premiumStatuses = [
  {
    rus: 'Анализ',
    eng: 'Preprocess',
    method: 'start_review'
  },
  {
    rus: 'Требует оплаты',
    eng: 'PendingPayment',
    method: 'pending_payment'
  },
  {
    rus: 'Оплачен',
    eng: 'PaymentDone',
    method: 'payment_done'
  },
  {
    rus: 'Взят в работу',
    eng: 'PartnerGeneratingReportData',
    method: 'start_work'
  },
  {
    rus: 'Работа возобновлена',
    eng: 'PartnerInProgress',
    method: 'resume_work'
  },
  {
    rus: 'Уточнения',
    eng: 'Clarification',
    method: 'clarification'
  },
  {
    rus: 'Выполнена',
    eng: 'Acknowledge',
    method: 'acknowledge'
  },
  {
    rus: 'Завершена',
    eng: 'Ready',
    method: 'ready'
  },
  {
    rus: 'Отклонен',
    eng: 'Declined',
    method: 'decline'
  },
  {
    rus: 'Отменен',
    eng: 'Cancelled',
    method: 'cancel'
  },
]
function handleStatusRes(res: Record<string, string>) {
  if (Object.keys(res).includes('errorCode')) {
    ElNotification({
      title: 'Ошибка',
      message: `изменения статуса ${res.errorMessage}`,
      type: 'error'
    })
  } else {
    ElNotification({
      title: 'Успешное',
      message: `изменение статуса на ${premiumStatuses.find(i => i.eng === res.status)?.rus}`,
      type: 'success'
    })
  }
}
function setStatus(method: string) {
  setTBankPremiumStatus(invoiceStore.invoice.id, { method }).then((res: any) => {
    handleStatusRes(res)
    getTBankPremiumStatus(invoiceStore.invoice.id)
  })
}
function pingChat() {
  aliveChat(invoiceStore.invoice.id).then(() => {
    ElNotification({
      title: 'Успех',
      message: `чат жив!!!`,
      type: 'success'
    })
  }).catch((err) => {
    ElNotification({
      title: 'Ошибка',
      message: `произошла какая-то ошибка. Вот такая например ${err}`,
      type: 'error'
    })
  })
}
function downLoadFile (fileId: number, fileName: string) {
  getAdditionalFile(fileId)
    .then((res) => {
      fileDownload(res, fileName)
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `произошла ошибка скачивания файла. ${err}`,
        type: 'error'
      })
  })
}
function sendFiles() {
  sendFilesToTBank(invoiceStore.invoice.id).then(() => {
    ElNotification({
      title: 'Успешно',
      message: `отправлен запрос на передачу итоговых документов.`,
      type: 'success'
    })
  }).catch((err) => {
    ElNotification({
      title: 'Ошибка',
      message: `отправки запроса. ${err}`,
      type: 'success'
    })
  })
}

getTBankForm(invoiceStore.invoice.id)
  .then((res: ITBankQuestionnaireRequest) => {
    Object.keys(res?.form).forEach((key: string) => {
      tinkoffForm.value[key] = res?.form[key]
    })
  })
  .catch((err) => {
    ElNotification({
      title: 'Ошибка',
      message: `получения анкеты ${err}`,
      type: 'error'
    })
  })
getTBankPremiumStatus(invoiceStore.invoice.id)
  .then((res: any) => {
  tinkoffPremiumState.value += res?.status
})
  .catch((err) => {
    ElNotification({
      title: 'Ошибка',
      message: `получения статуса заявки TBank premium. ${err}`,
      type: 'error'
    })
})
</script>

<template>
  <el-row :gutter="40" :key="tinkoffPremiumState" class="flex">
    <el-col :lg="6" class="right-border">
      <div v-if="tinkoffPremiumState && declarationStore.declaration">
        <tinkoff-form
          :questionnaire="tinkoffForm"
          :key="tinkoffForm"
          :declaration="declarationStore.declaration"
        />
        <el-button round @click="showJson = !showJson">show json</el-button>
        <div v-if="showJson">
          <json-viewer :value="tinkoffForm" copyable :boxed="true" />
        </div>
      </div>
    </el-col>
    <el-col :lg="8" class="right-border">
      <div class="flex justify-between mb-4" v-if="tinkoffPremiumState !== ''">
        <p>
          Статус:
          <span class="font-weight--extra">
                  {{ premiumStatuses.find((i) => i.eng === tinkoffPremiumState)?.rus }}
                </span>
        </p>
        <el-popover placement="right" :width="400" trigger="click">
          <template #reference>
            <el-button size="small" round type="primary">
              Изменить
              <!--                    <el-icon><MoreFilled /></el-icon>-->
            </el-button>
          </template>
          <div
            v-for="status in premiumStatuses"
            @click="setStatus(status.method)"
            :key="status.eng"
            class="card-hover p-4 border cursor-point"
          >
            <el-button :type="status.eng === tinkoffPremiumState ? 'success' : ''" link>
              <el-icon class="mr-2" v-if="status.eng === tinkoffPremiumState">
                <SuccessFilled />
              </el-icon>
              <el-icon class="mr-2" v-else><SwitchButton /></el-icon>
              {{ status.rus }}
            </el-button>
          </div>
        </el-popover>
      </div>
      <div v-else>
        Этот заказ из категории Private. Анкету можно посмотреть в соседней вкладке Анкета Tinkoff JSON
      </div>
      <div class="el-divider-purple mb-4" />
      <div class="flex justify-between">
        <p class="mb-4">Отправить итоговые документы</p>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="если вы уже загрузили декларацию в pdf, то нажмите кнопку отправить"
          placement="top"
        >
          <el-button :loading="isLoading"
                     type="primary"
                     size="small"
                     @click="sendFiles"
                     :icon="UploadFilled"
                     round
                     :disabled="isLoading && invoiceAsync"
          >
            отправить
          </el-button>
        </el-tooltip>
      </div>
      <div class="el-divider-purple mb-4" />
      <div class="flex justify-between items-center">
        <p>Оживить чат:</p>
        <el-button :icon="Refresh"
                   type="danger"
                   size="small"
                   round
                   @click="pingChat"
        >
          встань и иди
        </el-button>
      </div>
    </el-col>
    <el-col :lg="10">
      <h4>Дополнительные файлы</h4>
      <div class="flex flex-wrap">
        <el-scrollbar :height="additionalFilesTinkoff.length > 16 ? '300px' : ''">
          <div class="flex flex-wrap">
            <div v-for="file in additionalFilesTinkoff" :key="file.id" class="mb-4 mr-2">
              <el-tooltip class="box-item" effect="dark" :content="`Скачать ${file.name}`" placement="top">
                <div
                  @click="downLoadFile(file.id, file.name)"
                  class="p-2 bg-purple cursor-point button--text-hover"
                >
                  {{ file.name }}
                </div>
              </el-tooltip>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div v-if="additionalFilesTinkoff.length === 0">
        <p>Дополнительные файлы отсутствуют</p>
      </div>
    </el-col>
  </el-row>
<!--  <json-viewer :value="tinkoffForm" copyable boxed sort />-->
<!--  <JsonViewer  />-->
</template>

<style scoped lang="sass">
.right-border
  border-right: 1px solid #909399
</style>
