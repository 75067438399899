<template>
  <div class="el-overlay is-message-box" v-if="visible" @click="closeNotArea">
    <div class="el-overlay-message-box">
      <div class="el-message-box">
        <div class="el-message-box__header">
          <h2>{{ title }}</h2>
          <button class="el-message-box__headerbtn" @click="close">
            <i class="el-icon el-message-box__close">
              <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
                ></path>
              </svg>
            </i>
          </button>
        </div>
        <div class="el-message-box__content">
          <div>
            <div class="el-message-box__input">
              <el-select v-model="selectValue" class="el-input" placeholder="Выберите дополнительный доход">
                <el-option v-for="item in value" :key="item[valueTag]" :label="item[labelTag]" :value="item[valueTag]">
                  <div class="flex-box flex-box--align-c flex-box--sb">
                    <span>{{
                      screenLessValue(767) && item[valueTag] === 'assignment_of_rights'
                        ? subStringText(item[labelTag], 3)
                        : item[labelTag]
                    }}</span>
                    <ng-global-question-popover :color="'#4b60cecc'" v-if="!screenLessValue(1200)">
                      <div v-for="(desc, index) in onBoarding" :key="index">
                        <div style="word-break: break-word" v-html="desc[item?.tag]"></div>
                      </div>
                    </ng-global-question-popover>
                  </div>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="el-message-box__btns">
          <el-button @click="close">Отменить</el-button>
          <el-button type="primary" @click="ok" v-if="selectValue !== ''">Добавить</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import onBoardingDescriber from '@/describers/OnBoardingDescriber'
import NgGlobalQuestionPopover from '@/components/NgGlobalQuestionPopover'
import useWindowWidth from '@/use/useWindowWidth'
import useTextFilters from '@/plugins/TextFilters'

export default {
  name: 'MessageBoxSelect',
  components: { NgGlobalQuestionPopover },
  props: {
    title: String,
    message: String,
    value: {
      type: Array,
      default: () => [],
    },
    labelTag: {
      type: String,
      default: 'label',
    },
    valueTag: {
      type: String,
      default: 'value',
    },
  },
  emits: ['select'],
  setup(_, { emit }) {
    const onBoarding = onBoardingDescriber().onBoarding
    const { subStringText } = useTextFilters()
    const { screenLessValue } = useWindowWidth()
    const visible = ref(false)
    const selectValue = ref('')
    const show = () => (visible.value = true)
    const closeNotArea = (e) => {
      if (e?.target?.firstChild?.className === 'el-message-box') visible.value = false
    }
    const close = () => (visible.value = false)
    const ok = () => {
      emit('select', selectValue.value)
      selectValue.value = ''
      close()
    }
    return {
      visible,
      show,
      closeNotArea,
      close,
      selectValue,
      ok,
      onBoarding,
      screenLessValue,
      subStringText,
    }
  },
}
</script>

<style lang="sass" scoped>
//@media(max-width: 767px)
//  .el-message-box
//    max-width: 300px
</style>
