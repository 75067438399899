import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/delete_icon.svg'


const _hoisted_1 = { class: "flex-box flex-box--align-c flex-box--sb" }
const _hoisted_2 = { class: "flex-box flex-box--align-c flex-box--sb" }
const _hoisted_3 = { class: "text-strike" }
const _hoisted_4 = ["onClick"]

import useTextFilters from '@/plugins/TextFilters'
import { FileFromServer } from '../file.type'
interface IProps {
  files: FileFromServer[]
}

interface IEmits {
  (e: 'deleteFile', index: number): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DeletedFiles',
  props: {
    files: {}
  },
  emits: ["deleteFile"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const { subStringFileName } = useTextFilters()
const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_CircleCloseFilled = _resolveComponent("CircleCloseFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.files, (file) => {
    return (_openBlock(), _createElementBlock("div", {
      key: 'file_' + file,
      class: "margin-h--left-m margin-v-xs"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_icon, {
            color: "#FF9A9D",
            size: "large",
            class: "margin-h--right-6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CircleCloseFilled)
            ]),
            _: 1
          }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(file.name), 1)
        ]),
        _createElementVNode("img", {
          class: "button--text-hover",
          onClick: ($event: any) => (emit('deleteFile', file?.id)),
          src: _imports_0,
          alt: "close_icon",
          width: "20"
        }, null, 8, _hoisted_4)
      ])
    ]))
  }), 128))
}
}

})