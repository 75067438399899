import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/doc_icon.svg'


const _hoisted_1 = { class: "mr-2 my-2 font-weight--semi" }
const _hoisted_2 = { class: "flex items-center" }

import useTextFilters from '@/plugins/TextFilters'

interface IProps {
  name: string
}
interface IEmits {
  (e: 'close'): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FileItem',
  props: {
    name: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const { subStringFileName } = useTextFilters()

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tooltip, {
      effect: "dark",
      content: _ctx.name,
      disabled: _ctx.name.length > 20,
      placement: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tag, {
          closable: "",
          onClose: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("img", {
                src: _imports_0,
                alt: "doc_icon",
                class: "mr-2"
              }, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_unref(subStringFileName)(props.name, 20)), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["content", "disabled"])
  ]))
}
}

})