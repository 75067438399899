<script setup lang="ts">
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { computed } from 'vue'
import { WarningFilled } from '@element-plus/icons-vue'

const invoiceStore = useInvoiceStore()
const errors = computed<string[]>(() => {
  if (invoiceStore.invoice) {
    return invoiceStore.invoice.warning_and_error.errors
  }
  return []
})
</script>

<template>
  <div v-for="(_item, key) in errors" :key="key" class="err-box mb-4 p-4 bg-red border">
    <div class="line-height">
      <div class="flex items-center my-4">
        <el-icon color="#FF9A9D" size="large" class="mr-2">
          <WarningFilled />
        </el-icon>
        <h3>Возникла ошибка</h3>
      </div>
      <p class="my-4">Проблема в загруженных файлах.</p>
      <div class="my-4">
        <ul>
          <li>Повторно скачайте отчеты брокера, строго следуя инструкции.</li>
          <li>Удалите загруженные файлы.</li>
          <li>Загрузите новые отчеты брокера в этот заказ.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
