<template>
  <el-row align="middle">
    <el-col :xs="24" :sm="7" :md="7" :lg="5" :class="screenLessValue(1200) ? 'margin-top-s' : ''">
      <h3 class="font-color--deep-gray margin-v-xs">Наименование</h3>
      <p>{{ item.item.other_attributes.name_organization }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="3" :class="screenLessValue(1200) ? 'margin-top-s' : ''">
      <h3 class="font-color--deep-gray margin-v-xs">Дата</h3>
      <p>
        {{ !item.item.date || item.item.date === 'null' ? '-' : item.item.date }}
      </p>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="3" :class="screenLessValue(1200) ? 'margin-top-s' : ''">
      <h3 class="font-color--deep-gray margin-v-xs">Валюта</h3>
      <p>{{ item.item.currency || '-' }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="3" :class="screenLessValue(1200) ? 'margin-top-s' : ''">
      <h3 class="font-color--deep-gray margin-v-xs">Сумма</h3>
      <p>{{ item.item?.amount || '-' }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="3" :class="screenLessValue(1200) ? 'margin-top-s' : ''">
      <h3 class="font-color--deep-gray margin-v-xs">Код дохода</h3>
      <p>{{ item.item?.other_attributes.income_code || '-' }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="3" :class="screenLessValue(1200) ? 'margin-top-s' : ''">
      <h3 class="font-color--deep-gray margin-v-xs">Код вычета</h3>
      <p>{{ item.item?.other_attributes.expense_type || '-' }}</p>
    </el-col>
    <el-col :xs="24" :sm="5" :md="5" :lg="3" :class="screenLessValue(1200) ? 'margin-top-m' : ''">
      <p class="font-weight--extra">
        <span v-if="item?.valueFromServer && !item.fieldWasEdited" class="font-color--green">Доход сохранен</span>
        <span v-else class="font-color--red">Доход не сохранен</span>
      </p>
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { AdditionalIncome, AdditionalItem } from '@/06-Entities/IncomeAndRefund'
import useWindowWidth from '@/use/useWindowWidth'
import { UnwrapNestedRefs } from 'vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
}
defineProps<IProps>()

const { screenLessValue } = useWindowWidth()
</script>

<style scoped></style>
