import socialIcon from '@/assets/pre_add_incomes_icons/social_icon.svg'
export const LiveInsureContractType = [
  {
    id: '4',
    tag: 'contributions_for_pension',
    title: 'Дополнительные страховые взносы на накопительную пенсию',
    icon: socialIcon,
  },
  {
    id: '1',
    tag: 'non_civil_pension_provide',
    title: 'Негосударственное пенсионное обеспечение',
    icon: socialIcon,
  },
  {
    id: '3',
    tag: 'voluntary_live_insure',
    title: 'Добровольное страхование жизни',
    icon: socialIcon,
  },
  {
    id: '2',
    tag: 'voluntary_pension_insurance',
    title: 'Добровольное пенсионное страхование',
    icon: socialIcon,
  },
] as const

export type ContractType = (typeof LiveInsureContractType)[number]['tag']
export type ContractIds = (typeof LiveInsureContractType)[number]['id']
