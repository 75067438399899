/* eslint-disable */
import { reactive, readonly, Plugin } from 'vue'
const Vue3GoogleOauth = reactive({
  instance: null,
  isInit: false,
  isAuthorized: false,
})
type loadfn = () => void
interface gapiType {
  load: (name: string, fn: loadfn) => void
  auth2: {
    init: (confin: any) => Promise<any>
    getAuthInstance: () => any
  }
}

declare global {
  interface Window {
    gapi: gapiType
  }
}

class Auth {
  instance: any
  prompt: any
  constructor() {
    this.instance = null
  }
  load(config: { prompt: any }) {
    installClient()
      .then(() => {
        return initClient(config)
      })
      .then((gapi: gapiType) => {
        this.instance = gapi.auth2.getAuthInstance()
        this.prompt = config.prompt
        Vue3GoogleOauth.instance = gapi.auth2.getAuthInstance()
        Vue3GoogleOauth.isInit = true
        Vue3GoogleOauth.isAuthorized = this.instance.isSignedIn.get()
      })
      .catch((error) => {
        console.error(error)
      })
  }
  signIn() {
    return new Promise((resolve, reject) => {
      if (!this.instance) {
        reject(false)
        return
      }
      this.instance
        .signIn()
        .then((googleUser: {}) => {
          Vue3GoogleOauth.isAuthorized = this.instance.isSignedIn.get()
          resolve(googleUser)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }
  getAuthCode() {
    return new Promise<string>((res, rej) => {
      if (!this.instance) {
        rej(false)
        return
      }
      this.instance
        .grantOfflineAccess({ prompt: this.prompt })
        .then((resp: { code: string }) => {
          res(resp.code)
        })
        .catch((err: any) => {
          console.error(err)
        })
    })
  }
  signOut() {
    return new Promise((res, rej) => {
      if (!this.instance) {
        rej(false)
        return
      }
      this.instance
        .signOut()
        .then(() => {
          Vue3GoogleOauth.isAuthorized = false
          res(true)
        })
        .catch((err: any) => {
          rej(err)
        })
    })
  }
}
function initClient(config: any): Promise<gapiType> {
  return new Promise((resolve, reject) => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init(config)
        .then(() => {
          resolve(window.gapi)
        })
        .catch((error) => {
          reject(error)
        })
    })
  })
}
function installClient() {
  const apiUrl = 'https://apis.google.com/js/api.js'
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = apiUrl
    document.onreadystatechange = script.onload = function () {
      if (!document.readyState || /loaded|complete/.test(document.readyState)) {
        setTimeout(function () {
          resolve(true)
        }, 500)
      }
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}

const googleAuth = (function () {
  return new Auth()
})()

const plugin: Plugin = {
  install: (app, options) => {
    /* eslint-disable */
    //set config
    let config = null
    let defaultConfig = { scope: 'profile email', prompt: 'select_account' }
    if (typeof options === 'object') {
      config = Object.assign(defaultConfig, options)
      if (!options.clientId) {
        throw new Error('clientId is require')
      }
    } else {
      throw new TypeError('invalid option type. Object type accepted only')
    }

    //Install Vue plugin
    googleAuth.load(config)
    app.config.globalProperties.$gAuth = googleAuth
    app.provide('GoogleAuth', googleAuth)
    app.provide('Vue3GoogleOauth', readonly(Vue3GoogleOauth))
  },
}
export function useGAuth(): Auth {
  return googleAuth
}
export default plugin
/* eslint-disable */
