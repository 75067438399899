import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex-box flex-box--align-c flex-box--sb margin-v-s" }
const _hoisted_2 = { class: "flex-box flex-box--align-c margin-v-xs" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "font-weight--extra" }
const _hoisted_5 = { class: "flex-box flex-box--align-c" }

import { toRef } from 'vue'
import LoaderDescriber from '@/06-Entities/File/model/LoaderDescriber'
import useTextFilters from '@/plugins/TextFilters'
import { useRouter } from 'vue-router'
import { BrokerFileType } from '@/entityes/invoice/file/file.type'

interface IProps {
  brokerFiles: Partial<Record<BrokerFileType, { id: number; name: string }[]>>
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ShowOnlyLoadedFiles',
  props: {
    brokerFiles: {}
  },
  setup(__props: any) {

const props = __props
const { subStringFileName } = useTextFilters()
const loaders = LoaderDescriber().describers
const router = useRouter()

const filesInInvoice = toRef(props.brokerFiles)

const onlyLoadedFiles = Object.keys(filesInInvoice.value).reduce((acc, key) => {
  if (filesInInvoice.value[key].length > 0) {
    const loader = loaders.find((i) => i.config.serverTag === key)
    loader.loadedFiles = filesInInvoice.value[key]
    acc.push(loader)
  }
  return acc
}, [])

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_Files = _resolveComponent("Files")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", null, [
        _createElementVNode("h3", null, "Загруженные отчеты")
      ], -1)),
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'file_in_invoice' }))),
          link: "",
          type: "primary"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" редактировать отчеты брокеров ")
          ])),
          _: 1
        })
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(onlyLoadedFiles), (loader, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "margin-v-xs"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: loader.config.logo,
            class: "margin-h--right-6",
            width: 24
          }, null, 8, _hoisted_3),
          _createElementVNode("p", _hoisted_4, _toDisplayString(loader.config.title), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(loader.loadedFiles, (file, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "margin-h--left-m"
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_icon, {
                color: "#4b60cecc",
                size: "large",
                class: "margin-h--right-6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Files)
                ]),
                _: 1
              }),
              _createElementVNode("p", null, _toDisplayString(_unref(subStringFileName)(file.name, 30)), 1)
            ])
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}
}

})