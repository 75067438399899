import { string as str, bool, object as obj } from 'yup'
// 'rus', 'physical_person', 'currency', 'date', 'amount'

export function getFields(yearToCalc: number) {
  const rus = bool().required()
  const physical_person = bool().required('Это поле обязательно для заполнения')
  const currency = str().required('Это поле обязательно для заполнения').length(3)
  const date = str().checkYearValue(yearToCalc).required('Это поле обязательно для заполнения')
  const amount = str().checkNumberString().required('Это поле обязательно для заполнения')
  const otherAttributesField = {
    name: str().test('name-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      const physical_person = context.from?.[1].value.physical_person
      if (rus === true && physical_person === true) return str().required().isValidSync(value)
      return true
    }), // ФИОИн
    // rus organization
    name_organization: str().test('name_organization-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      const physical_person = context.from?.[1].value.physical_person
      if (rus === true && physical_person === false) return str().required().isValidSync(value)
      return true
    }), // Наим
    inn: str()
      .checkNumberString()
      .length(10)
      .test('inn-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) return str().required().isValidSync(value)
        return true
      }),
    kpp: str()
      .checkNumberString()
      .length(9)
      .test('kpp-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) return str().required().isValidSync(value)
        return true
      }),
    oktmo: str()
      .checkNumberString()
      .length(8)
      .test('oktmo-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) return str().required().isValidSync(value)
        return true
      }),

    // all outh country
    name_income: str().test('name_income-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      if (rus === false) return str().required().isValidSync(value)
      return true
    }), // НаимИстДох
    oksm: str()
      .checkNumberString()
      .test('oksm-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) return str().required().isValidSync(value)
        return true
      }), // ОКСМ ИСТ
    oksm_broker: str()
      .checkNumberString()
      .test('oksm_broker-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) return str().required().isValidSync(value)
        return true
      }), // ОКСМ ЗАЧ
    // paid tax
    paid_tax: str().checkNumberString(),
    paid_tax_date: str().test('check_tax_date_validation', function (value) {
      const { createError, path } = this
      if (!!this.parent.paid_tax) {
        const reverse = value?.split('.').reverse().join('/') || ''
        const d = new Date(reverse)
        if (!value) return createError({ path, message: 'Поле является обязательным' })
        return d.getFullYear() === yearToCalc || createError({ path, message: 'Год не соответсвует отчетному периоду' })
      }
      return true
    }),
  }
  return {
    mainFields: { rus, physical_person, currency, date, amount },
    otherAttributesField,
  }
}
