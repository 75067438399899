<template>
  <div>
    <div v-for="item in loader.describers" :key="item.config.name" class="padding--20 border">
      <h3 class="margin-v-xs" v-if="item?.getFilesFromServer?.length > 0">Справки {{ item.config.name }}</h3>
      <div v-for="file in item.getFilesFromServer" :key="file.id" class="margin-v-s margin-h--left-s">
        <el-tooltip effect="dark" :content="file.serverName" placement="top">
          <span>{{ file.name }}</span>
        </el-tooltip>
        <a @click.prevent="item.downloadFile(file, id)" class="clickable-text-link">
          <el-icon
            class="el-icon-loading font-weight--extra margin-h--left-s"
            v-if="file.isLoading"
            style="vertical-align: middle"
          >
            <Loading />
          </el-icon>
          <el-icon
            class="el-icon-download font-weight--extra margin-h--left-s cursor-point"
            style="vertical-align: middle"
            v-else
          >
            <Download />
          </el-icon>
        </a>
      </div>
      <div class="margin-v-xs" :class="{ flex: item.localFiles.files.length === 0 }">
        <SingleFileLoader :describer="item" :broker-name="item.config.name || ''" @update="updateValue" />
        <div
          class=""
          v-if="errors['other_attributes.two_ndfl_files.file_names'] && props.item.item.two_ndfl_files?.length === 0"
        >
          <el-popover placement="bottom" class="margin-h--left-s" :width="300">
            <template #reference>
              <span class="form-warning">
                <el-icon color="#FF9A9D" size="large" class="margin-h--left-s cursor-point"><WarningFilled /></el-icon>
              </span>
            </template>
            <p class="font-color--red" style="word-break: break-word">
              {{ errors['other_attributes.two_ndfl_files.file_names'] || 'нет ошибок' }}
            </p>
          </el-popover>
        </div>
      </div>
      <div>
        <!--      <deleted-files :files="item.getFileToDelete.value || []" @remove-file="item.onRemoveDeleteFile" type="delete" />-->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import img from '@/07-Shared/ui/ImgDescriber'
import { useRouter } from 'vue-router'
import { computed, UnwrapNestedRefs, watch } from 'vue'
import GuruLogo from '@/assets/guru_logo.svg'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { loaderDescriber } from '@/06-Entities/File'
import { array, number, bool, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import SingleFileLoader from '@/06-Entities/File/ui/SingleFileLoader.vue'
import { cloneDeep } from 'lodash'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber?: any
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}
const emit = defineEmits<IEmits>()

const router = useRouter()
const id = computed(() => router.currentRoute.value.params.id as string)

const LoaderDescriber = [
  {
    title: '2-ндфл',
    text: 'Нажмите или перетащите файлы для загрузки отчетов 2-ндфл',
    serverTag: 'two_ndfl_files',
    name: '2-НДФЛ',
    logo: GuruLogo,
    fileFormat: /.*\.(xml|pdf)$/,
    img: img.csv,
  },
]
const loader = loaderDescriber(LoaderDescriber)
loader.mergeFile({
  two_ndfl_files: props.item.item.two_ndfl_files || [],
})

const schema = object({
  rus: bool().default(true),
  physical_person: bool().default(true),
  currency: string().default('RUB'),
  date: string().default(`01.01.2023`),
  amount: string().default('0'),
  other_attributes: object({
    two_ndfl_files: object({
      file_names: array().of(string()).ensure().default([]).min(1, 'Неабходимо загрузить минимум один файл').required(),
      files: array().of(string()).ensure().default([]).min(1).required(),
    }),
  }),
  two_ndfl_files: array().of(
    object({
      id: number(),
      name: string(),
    })
  ),
})
const validationSchema = toTypedSchema(schema)
const { values, validate, errorBag, errors, setValues } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: Object.assign(
    {},
    {
      ...props.item.item,
    }
  ),
})
function updateValue() {
  setValues({
    other_attributes: {
      two_ndfl_files: loader.toJson().two_ndfl_files,
    },
  })
}

async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid: props.item.item.two_ndfl_files?.length !== 0 || valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  { immediate: true }
)
</script>

<style lang="sass" scoped></style>
