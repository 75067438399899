import { onUnmounted, ref } from 'vue'

export default function useWindowWidth() {
  const width = ref(window.innerWidth)
  const updateWidth = () => {
    width.value = window.innerWidth
  }
  window.addEventListener('resize', updateWidth)
  function screenLessValue(val: number) {
    return width.value < val
  }
  onUnmounted(() => {
    window.removeEventListener('resize', updateWidth)
  })

  return {
    width,
    screenLessValue,
  }
}
