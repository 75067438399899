import { useRequestService } from '@/use/useRequestService'
import { ICfr } from '@/entityes/invoice/cfr/cfr.type'

export function useCfr() {
  const { fetch } = useRequestService()

  function getCfr(goodId: string | number): Promise<ICfr> {
    return fetch(`/cfrs/${goodId}`)
  }
  return {
    getCfr
  }
}
