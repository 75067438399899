<script lang="ts" setup>
import useTextFilters from '@/plugins/TextFilters'
import { FileFromServer } from '../file.type'
interface IProps {
  files: FileFromServer[]
}

const props = defineProps<IProps>()
const { subStringFileName } = useTextFilters()
interface IEmits {
  (e: 'deleteFile', index: number): void
}

const emit = defineEmits<IEmits>()
</script>

<template>
  <div v-for="file in props.files" :key="'file_' + file" class="margin-h--left-m margin-v-xs">
    <div class="flex-box flex-box--align-c flex-box--sb">
      <div class="flex-box flex-box--align-c flex-box--sb">
        <el-icon color="#FF9A9D" size="large" class="margin-h--right-6">
          <CircleCloseFilled />
        </el-icon>
        <span class="text-strike">{{ file.name }}</span>
      </div>
      <img
        class="button--text-hover"
        @click="emit('deleteFile', file?.id)"
        src="@/assets/delete_icon.svg"
        alt="close_icon"
        width="20"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
