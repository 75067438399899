import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/loader.gif'


const _hoisted_1 = { class: "flex flex-col justify-center items-center mx-auto max-w-7xl" }

const msg = 'Магические операции, подождите пожалуйста'

export default /*@__PURE__*/_defineComponent({
  __name: 'ClearLoader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: "load_gif",
      width: "200"
    }, null, -1)),
    _createElementVNode("h4", null, _toDisplayString(msg))
  ]))
}
}

})