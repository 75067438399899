import { FileTagType, loaderDescriber as LoaderDescriber } from '@/06-Entities/File'
import { computed, ref } from 'vue'

export function useLoaderDescriber() {
  const loaderDescriber = LoaderDescriber()
  const selectedActiveLoader = ref<FileTagType | null>(null)
  const activeLoader = computed(() =>
    loaderDescriber.describers.find((item) => item.serverTag === selectedActiveLoader.value)
  )
  function selectActiveLoader(tag: FileTagType) {
    selectedActiveLoader.value = tag
  }
  return {
    loaderDescriber,
    activeLoader,
    selectActiveLoader,
    selectedActiveLoader,
  }
}
