import { ElNotification } from 'element-plus'

export default function useCopyToClipboard() {
  const copyValue = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        ElNotification({
          title: 'Значение скопировано',
          message: 'в буфер обмена',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          title: 'Ошибка копирования',
          message: 'в буфер обмена',
          type: 'error',
        })
      })
  }
  return {
    copyValue,
  }
}
