<script setup lang="ts">
import useTextFilters from '@/plugins/TextFilters'

interface IProps {
  name: string
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'close'): void
}

const emit = defineEmits<IEmits>()

const { subStringFileName } = useTextFilters()
</script>

<template>
  <div class="mr-2 my-2 font-weight--semi">
    <el-tooltip effect="dark" :content="name" :disabled="name.length > 20" placement="top">
      <el-tag closable @close="emit('close')">
        <span class="flex items-center">
          <img src="~@/assets/doc_icon.svg" alt="doc_icon" class="mr-2" />
          <span>{{ subStringFileName(props.name, 20) }}</span>
        </span>
      </el-tag>
    </el-tooltip>
  </div>
</template>

<style lang="sass" scoped></style>
