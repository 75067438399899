<script lang="ts" setup>
// import newIncomeForm from '@/04-Widgets/AdditionelIncome/ui/incomeForm'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { UnwrapNestedRefs, watch } from 'vue'
import { string as str, object as obj, bool } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { cloneDeep } from 'lodash'
import { setTouchedHelper } from '../lib/setTouchedHelper'
import FormItem from '@/07-Shared/ui/FormItem.vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber?: any
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}
const emit = defineEmits<IEmits>()

setTouchedHelper(props.item)
const schema = obj({
  rus: bool().default(true),
  physical_person: bool().default(true),
  currency: str().default('RUB'),
  amount: str().default('0'),
  other_attributes: obj({
    message: str().required('Это поле обязательно для заполнения'),
    phone: str().required('Это поле обязательно для заполнения'),
  }),
})
const validationSchema = toTypedSchema(schema)
const { values, validate } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: Object.assign({
    ...props.item.item,
  }),
})

async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div class="px-4">
    <el-row class="mb-4">
      <el-col :lg="17">
        <h4 class="mb-1">Напишите нам сообщение</h4>
        <form-item name="other_attributes.message" v-slot="{ field, value }">
          <el-input
            :model-value="value"
            v-bind="field"
            type="textarea"
            class="textarea-crunch"
            placeholder="Опишите вашу ситуацию"
            :autosize="{ minRows: 2, maxRows: 4 }"
          />
        </form-item>
      </el-col>
      <el-col :lg="1"></el-col>
      <el-col :lg="6">
        <h4 class="mb-1">Номер телефона</h4>
        <form-item name="other_attributes.phone" v-slot="{ field, value }">
          <el-input :model-value="value" v-bind="field" placeholder="89998887766" v-mask="['8##########']" />
        </form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="sass"></style>
