import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-4 font-bold" }

import useWindowWidth from '@/use/useWindowWidth'
import { defineAsyncComponent, reactive, ref } from 'vue'
import { useGetOnlineCfrInfo } from '@/05-Features/CfrOnlineResults/api/getOnlineCfrInfo.request'
import { ElNotification } from 'element-plus'
import { ICfrOnline } from '@/entityes/invoice/cfr/cfr.type'
import { IFile } from '@/06-Entities/File'
import DownloadFileCard from '@/06-Entities/File/ui/DownloadFileCard.vue'
interface IProps {
  goodId: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CfrOnlineResults',
  props: {
    goodId: {}
  },
  setup(__props: any) {

const CfrOnlineAccCard = defineAsyncComponent(() => import('@/06-Entities/ResultFiles/ui/CfrOnlineAccCard.vue'))
const props = __props
const { getCfrOnlineInfo } = useGetOnlineCfrInfo()
const cfrAccounts = ref<ICfrOnline[]>([])
const files = reactive<IFile[]>([])
const { screenLessValue } = useWindowWidth()

getCfrOnlineInfo(props.goodId)
  .then((res) => {
    cfrAccounts.value = [...res.data]
    files.splice(0, files.length, ...res.result_files)
  })
  .catch((err) => {
    ElNotification({
      title: `Ошибка`,
      message: `получения данных для онлайн ОДС ${err}`,
      type: 'error',
    })
  })

return (_ctx: any,_cache: any) => {
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "mb-4" }, "Данные для заполнения ОДС на сайте налоговой", -1)),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-8" }, [
      _createElementVNode("i", { class: "el-icon-link font-weight--extra font-color--purple" }),
      _createElementVNode("a", {
        href: "https://help.ndfl.guru/i8",
        class: "clickable-text-link",
        target: "_blank"
      }, "Инструкция для подачи ОДС онлайн через личный кабинет ФНС")
    ], -1)),
    (files.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_collapse, { class: "mb-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_collapse_item, null, {
                title: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("p", { class: "p-4 font-bold" }, [
                    _createElementVNode("span", null, "Итоговые файлы")
                  ], -1)
                ])),
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files, (file) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: file?.id
                      }, [
                        _createVNode(DownloadFileCard, {
                          file: file,
                          "is-result": ""
                        }, null, 8, ["file"])
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cfrAccounts.value, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id
      }, [
        _createVNode(_component_el_collapse, { class: "mb-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_collapse_item, null, {
              title: _withCtx(() => [
                _createElementVNode("p", _hoisted_2, [
                  _cache[1] || (_cache[1] = _createElementVNode("span", null, "Счет ", -1)),
                  _createElementVNode("span", null, _toDisplayString(item.account_number), 1)
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(_unref(screenLessValue)(767) ? '' : 'padding--20')
                }, [
                  _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "mb-8" }, "Информация о движении средств и стоимости иных финансовых активов", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item?.broker_accounts, (accountInfo) => {
                    return (_openBlock(), _createBlock(_unref(CfrOnlineAccCard), {
                      key: accountInfo.id,
                      "broker-account": accountInfo
                    }, null, 8, ["broker-account"]))
                  }), 128))
                ], 2)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ], 64))
}
}

})