import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "padding--40" }

import { AdditionalItem, AdditionalItemValue, Deduction } from '@/06-Entities/IncomeAndRefund'
import { UnwrapNestedRefs } from 'vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
  yearToCalc: number
  helpDescriber: any
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PropertyDeduction',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {}
  },
  emits: ["update-item"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h2", null, "Скоро тут появится интерфейс", -1)
  ])))
}
}

})