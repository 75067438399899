<template>
  <el-row align="middle">
    <el-col :sm="6" :md="6" :lg="6" class="margin-v-xs">
      <h3 class="font-color--gray margin-v-xs">ЦБ</h3>
      <p>{{ props.item.item?.other_attributes?.cb || 0 }} руб.</p>
    </el-col>
    <el-col :sm="4" :md="4" :lg="4" class="margin-v-xs">
      <h3 class="font-color--gray margin-v-xs">ПФИ</h3>
      <p>{{ props.item.item?.other_attributes?.pfi || 0 }} руб.</p>
    </el-col>
    <el-col :sm="8" :md="8" :lg="8" class="margin-v-xs">
      <div class="flex-box flex-box--align-c flex-box--end">
        <p class="font-weight--extra">
          <span v-if="props.item?.valueFromServer && !props.item.fieldWasEdited" class="font-color--green"
            >Вычет сохранен</span
          >
          <span v-else class="font-color--red">Вычет не сохранен</span>
        </p>
      </div>
    </el-col>
    <el-col :sm="4" :md="4" :lg="4" class="b-margin-top">
      <div class="flex-box flex-box--align-c flex-box--end">
        <edit-save-card-button-group @toggle="item.toggleEdit" @remove="$emit('remove')" />
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { AdditionalItem, Deduction } from '@/06-Entities/IncomeAndRefund'
import EditSaveCardButtonGroup from '@/07-Shared/ui/EditSaveCardButtonGroup.vue'
import { UnwrapNestedRefs } from 'vue'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'remove'): void
}
const emit = defineEmits<IEmits>()
</script>

<style lang="sass" scoped></style>
