import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-list" }

import TaxPlanInfo from '@/04-Widgets/TaxPlanShow/ui/TaxPlanInfo.vue'
import { useTaxPlanRepository } from '@/entityes/invoice/tax_plan/tax_plan.repository'
import { useTaxPlanStore } from '@/entityes/invoice/tax_plan/tax_plan.store'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { IUser } from '@/entityes/user/user.type'
import { useUserStore } from '@/entityes/user/userStore'

interface IProps {
  id: number | string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TaxPlanShow',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props
const taxPlanStore = useTaxPlanStore()
const userStore = useUserStore()
const { getTaxPlan, getDataSources } = useTaxPlanRepository()
const router = useRouter()
const user = computed(() => userStore.user as IUser)
getTaxPlan(user.value.active_profile_id, props.id).then((res) => {
  if (res?.async) {
    setTimeout(() => {
      getTaxPlan(user.value.active_profile_id, props.id)
    }, 5000)
  }
  else {
    getDataSources(user.value.active_profile_id)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(taxPlanStore).taxPlan)
      ? (_openBlock(), _createBlock(TaxPlanInfo, {
          key: 0,
          "profile-id": user.value.active_profile_id,
          "tax-plan": _unref(taxPlanStore).taxPlan
        }, null, 8, ["profile-id", "tax-plan"]))
      : _createCommentVNode("", true)
  ]))
}
}

})