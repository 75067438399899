import { useGtag } from 'vue-gtag-next'
declare global {
  function ym(id: number, eventName: string, name: string, config: Record<string, any>): void
}

export default function useGtagManager() {
  const { event: googleEvent } = useGtag()
  const event = (name: string, config?: Record<string, any>) => {
    if (process.env.VUE_APP_SERVER_HOST === 'https://dev.ndfl.guru') {
      console.log(`GtagEvent: ${name}`, config)
    } else {
      googleEvent(name, config)
      // eslint-disable-next-line
      ym(54601003, 'reachGoal', name, config || {})
      switch (name) {
        case 'completeRegistration':
          // eslint-disable-next-line
          break
        case 'purchase':
          // eslint-disable-next-line
          break
        case 'lead':
          // eslint-disable-next-line
          break
        case 'initiateCheckout':
          // eslint-disable-next-line
          break
      }
    }
  }

  return {
    event,
  }
}
