import { useSlots as _useSlots, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "step-box mb-4",
  style: {"min-height":"100%"}
}
const _hoisted_2 = {
  class: "sticky-basket step-box mb-4",
  style: {"min-height":"100%"}
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SplitWindow',
  setup(__props) {

const slots = _useSlots()

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, {
    class: "mb-4",
    gutter: 20
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        xs: 24,
        lg: 12
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "left")
          ])
        ]),
        _: 3
      }),
      _createVNode(_component_el_col, {
        xs: 24,
        lg: 12
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "right")
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})