<script lang="ts" setup>
import { cloneDeep } from 'lodash'
import axios from '@/plugins/axios'
import { onBeforeUnmount, ref } from 'vue'
import useTypeWriter from '@/plugins/useTypeWriter'
import {ITaxPlan} from "@/entityes/invoice/tax_plan/tax_plan.type";

// export default {
//   name: 'TaxPlanCard',
//   props: {
//     plan: Object,
//     profile_id: [String, Number],
//   },
//   setup(props) {
interface IProps {
  plan: ITaxPlan
}
const props = defineProps<IProps>()
const localPlan = ref(cloneDeep(props.plan))
const timer = ref(null)
const { renderString } = useTypeWriter(['расчет...', 'минуточку...', 'еще чуть...:)'])

    // const async = () => {
    //   axios({
    //     url: `/profiles/${props.profile_id}/tax_plans/${localPlan.value.id}`,
    //     method: 'GET',
    //   }).then((res) => {
    //     Object.assign(localPlan.value, res.data)
    //     if (res.data.async) {
    //       timer.value = setTimeout(() => {
    //         async()
    //       }, 5000)
    //     }
    //   })
    // }
    // if (localPlan.value.async) {
    //   async()
    // }
    //
    // onBeforeUnmount(() => {
    //   clearTimeout(timer.value)
    //   timer.value = null
    // })

//     return {
//       localPlan,
//       renderString,
//     }
//   },
// }
</script>

<template>
  <el-row align="middle" class="step-box--small padding--20">
    <el-col :xs="24" :sm="2" :lg="2">
      <h3 class="font-color--gray margin-v-xs">id:</h3>
      <p>{{ localPlan.id }}</p>
    </el-col>
    <el-col :xs="24" :sm="6" :lg="6">
      <h3 class="font-color--gray margin-v-xs">name:</h3>
      <p>{{ localPlan.name }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :lg="4">
      <h3 class="font-color--gray margin-v-xs">Статус:</h3>
      <div v-if="localPlan.async" class="flex-box flex-box--align-c">
        <el-icon class="el-icon-loading margin-h--right-6" color="#FF9A9D">
          <Loading />
        </el-icon>
        <p class="font-color--red">{{ renderString }}</p>
      </div>
      <div class="font-color--green font-weight--extra" v-else>
        <p>Посчитан</p>
      </div>
    </el-col>
    <el-col :xs="24" :sm="4" :lg="4">
      <h3 class="font-color--gray margin-v-xs">Старт:</h3>
      <p>{{ localPlan.start_date }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :lg="4">
      <h3 class="font-color--gray margin-v-xs">Финиш:</h3>
      <p>{{ localPlan.finish_date }}</p>
    </el-col>
    <el-col :xs="24" :sm="4" :lg="4">
      <div class="flex-box flex-box--end">
        <router-link :to="{ name: 'tax plan show', params: { id: plan.id } }" class="el-button">
          Подробнее
        </router-link>
      </div>
    </el-col>
  </el-row>
</template>

<style lang="sass" scoped>
.padding-10
  padding: 10px
</style>
