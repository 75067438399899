<script lang="ts" setup>
// import newIncomeForm from '@/04-Widgets/AdditionelIncome/ui/incomeForm'
import currency from '@/describers/CurrencyDescriber'
import useCountryDescriber from '@/describers/useCountryDescriber'
import { ref, watch, nextTick, computed, UnwrapNestedRefs } from 'vue'
import useWindowWidth from '@/use/useWindowWidth'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { string as str, object as obj, number as numb } from 'yup'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import Checkbox from '@/components/supportComponents/Checkbox.vue'
import { cloneDeep } from 'lodash'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { setTouchedHelper } from '../lib/setTouchedHelper'

const { screenLessValue } = useWindowWidth()
interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber: any
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
}
const emit = defineEmits<IEmits>()

setTouchedHelper(props.item)
const mainField = {
  currency: str().length(3).required('Это поле обязательно для заполнения'),
  date: str().required('Это поле обязательно для заполнения').checkYearValue(props.yearToCalc),
  amount: str().required('Это поле обязательно для заполнения'),
}
const paid_tax = ref(props.item.item?.other_attributes?.paid_tax || '')
const other_attributes = computed(() => {
  const res = {
    name_income: str().required('Это поле обязательно для заполнения'),
    oksm: str().required('Это поле обязательно для заполнения'),
    oksm_broker: str().required('Это поле обязательно для заполнения'),
    paid_tax: str(),
    paid_tax_date: str(),
  }
  if (paid_tax.value !== '') {
    res.paid_tax = str().required('Это поле обязательно для заполнения')
    res.paid_tax_date = str().required('Это поле обязательно для заполнения').checkYearValue(props.yearToCalc)
  }
  return res
})

const schema = computed(() => {
  return toTypedSchema(obj({ ...mainField, other_attributes: obj({ ...other_attributes.value }) }))
})
const sameDateTax = ref(false)

const { values, setFieldTouched, validate, setFieldValue } = useForm({
  validationSchema: schema,
  validateOnMount: true,
  initialValues: {
    ...props.item.item,
  },
})

if (props.item.valueFromServer) {
  if (values.other_attributes?.paid_tax_date === values.date) sameDateTax.value = true
}
watch(paid_tax, (val) => {
  if (val !== '') setFieldTouched('other_attributes.paid_tax_date', true)
})
watch(sameDateTax, (val) => {
  if (val) {
    setFieldValue('other_attributes.paid_tax_date', values.date)
  } else {
    setFieldValue('other_attributes.paid_tax_date', '')
  }
})

async function mergeIncomeResult() {
  const res = {
    ...cloneDeep(values),
    valid: (await validate()).valid,
  }
  emit('update-item', res)
}
watch(values, () => {
  mergeIncomeResult()
})
mergeIncomeResult()
</script>

<template>
  <div class="px-4 pt-4">
    <div class="flex mb-4">
      <div class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold">Тикер</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber.name_income"></div>
          </question-popover>
        </div>
        <form-item class="rb-margin-m" name="other_attributes.name_income" v-slot="{ value, onInput, onBlur }">
          <el-input :model-value="value" @update:model-value="onInput" @blur="onBlur" />
        </form-item>
      </div>
      <div class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold">Страна источника выплаты</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber.oksm"></div>
          </question-popover>
        </div>
        <form-item class="rb-margin-m" name="other_attributes.oksm" v-slot="{ value, onInput, onBlur }">
          <el-select
            :model-value="value"
            @update:model-value="onInput"
            @blur="onBlur"
            filterable
            fit-input-width
            placeholder="Выберите страну"
          >
            <el-option
              v-for="item in useCountryDescriber()"
              :key="item.code"
              :value="item.code"
              :label="item.shortname"
            />
          </el-select>
        </form-item>
      </div>
      <div>
        <div class="flex mb-1 items-center">
          <p class="font-semibold">Страна зачисления выплаты</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber.oksm_broker"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.oksm_broker" v-slot="{ value, onBlur, onInput }">
          <el-select
            :model-value="value"
            @update:model-value="onInput"
            @blur="onBlur"
            filterable
            fit-input-width
            placeholder="Выберите страну"
          >
            <el-option
              v-for="item in useCountryDescriber()"
              :key="item.code"
              :value="item.code"
              :label="item.shortname"
            />
          </el-select>
        </form-item>
      </div>
    </div>
    <div class="flex items-center flex-wrap mb-4">
      <div class="mr-4">
        <div class="flex items-center mb-1">
          <p class="font-semibold">Сумма</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber.amount"></div>
          </question-popover>
        </div>
        <form-item class="rb-margin-m" name="amount" v-slot="{ value, onInput, onBlur }">
          <el-input :model-value="value" @blur="onBlur" @input="onInput" />
        </form-item>
      </div>
      <div class="mr-4">
        <div class="flex items-center mb-1">
          <h4 class="font-semibold">Валюта</h4>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber.currency"></div>
          </question-popover>
        </div>
        <form-item name="currency" v-slot="{ value, onBlur, onInput }">
          <el-select class="min-w-[220px]" :model-value="value" @update:model-value="onInput" @blur="onBlur" filterable>
            <el-option v-for="item in currency" :key="item.code" :value="item.code" :label="item.code" />
          </el-select>
        </form-item>
      </div>
      <div>
        <div class="flex items-center mb-1">
          <h4 class="font-semibold">Дата выплаты дивидендов</h4>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber.date"></div>
          </question-popover>
        </div>
        <form-item class="rb-margin-m" name="date" v-slot="{ value, onInput, onBlur }">
          <el-date-picker
            :model-value="value"
            @update:model-value="onInput"
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
            @blur="onBlur"
          />
        </form-item>
      </div>
    </div>
    <div class="flex items-center flex-wrap mb-4">
      <div class="mr-8">
        <div class="flex items-center mb-1">
          <h4 class="font-semibold">Сумма налога в валюте</h4>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber.paid_tax_dividend"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.paid_tax" v-slot="{ value, onInput, onBlur }">
          <el-input
            :model-value="value"
            @update:model-value="(val: string) => {onInput(val); paid_tax = val}"
            @blur="onBlur"
          />
        </form-item>
      </div>
      <div>
        <div class="flex items-center mb-1">
          <p class="font-semibold">Дата выплаты налога</p>
          <question-popover color="#021c1a4d">
            <div style="word-break: break-word" v-html="helpDescriber.paid_tax_dividend_date"></div>
          </question-popover>
        </div>
        <form-item name="other_attributes.paid_tax_date" v-slot="{ value, onInput, onBlur }">
          <el-date-picker
            :model-value="value"
            @update:model-value="onInput"
            @blur="onBlur"
            type="date"
            placeholder="Выберите дату"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
          />
        </form-item>
      </div>
    </div>
    <checkbox v-model:value="sameDateTax" class="mb-4">
      Налог уплачен в дату<br v-if="screenLessValue(469)" />
      получения дивидендов
    </checkbox>
  </div>
</template>

<style scoped></style>
