import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { LoadFileStepComponent } from '@/04-Widgets/LoadFileStep'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'LoadFile',
  setup(__props) {

const route = useRoute()
const invoiceId = computed(() => route.params.id as string)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(LoadFileStepComponent), { "invoice-id": invoiceId.value }, null, 8, ["invoice-id"]))
}
}

})