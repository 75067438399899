<script lang="ts" setup>
interface IProps {
  dataSource: any
}
const props = defineProps<IProps>()
const fileTypeFilter = (type: string) => {
  return type?.split('::')[1]
}
</script>

<template>
  <el-row class="p-2">
    <el-col :span="4">
      <p>
        id: <span>{{ dataSource.id }}</span>
      </p>
    </el-col>
    <el-col :span="10">
      <p>Number: {{ dataSource.account_number }}</p>
    </el-col>
    <el-col :span="7">
      <p>FileType: {{ fileTypeFilter(dataSource?.datable_type) }}</p>
    </el-col>
  </el-row>
</template>

<style lang="sass" scoped></style>
