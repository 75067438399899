import { MaskOptions } from 'maska'

export const moneyMask: MaskOptions = {
  mask: '0.99',
  tokens: {
    9: { pattern: /[0-9]/, optional: true },
    0: { pattern: /[0-9]/, multiple: true },
  },
}

export const integerMask: MaskOptions = {
  mask: '0',
  tokens: {
    0: { pattern: /[0-9]/, multiple: true },
  },
}
