import { computed, onUnmounted, reactive, ref } from 'vue'
import ImgDescriber from '@/07-Shared/ui/ImgDescriber'
import { EventEmitter } from 'events'
import useFileDownload from '@/use/useFileDownload'
import usePolitic from '@/use/usePolitic'
import { ILoaderDescriber, PreProcessingFields, PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'
import { FileFromServer } from '@/entityes/invoice/file/file.type'

export default function fileLoader(initConfig: ILoaderDescriber) {
  class Emitter extends EventEmitter {}
  const emitter = new Emitter()
  const { downloadFileRequest } = useFileDownload()
  const politic = usePolitic()

  const defaultConfig: ILoaderDescriber = {
    title: 'Загрузчик файлов',
    text: 'Нажмите или перетащите файлы для загрузки',
    instructionLink: 'https://google.com',
    serverTag: 'bucket_files',
    name: 'Other',
    fileFormat: /.*\.(csv)$/,
    instructionText: 'Инструкция',
    mimeType: undefined,
    img: ImgDescriber.csv,
    roles: ['isAllRole'],
  }

  const config = Object.assign(defaultConfig, initConfig) // Мердж исхдного конфига с дефолтным
  // Объект для загрузки файлов
  const localFiles = reactive<PreProcessingFiles>({
    files: [],
    file_names: [],
  })
  // Метод удаления файла из загруки
  const onRemoveLocalFile = (index: number) => {
    localFiles.files.splice(index, 1)
    localFiles.file_names.splice(index, 1)
  }

  // Файлы с сервера
  // Объект для файлов с сервера
  const filesFromServer = reactive<FileFromServer[]>([])
  // Метод для обновления файлов с сервера
  const setFilesFromServer = (val: FileFromServer[]) => {
    filesFromServer.splice(0, 1000000)
    val.forEach((i) => filesFromServer.push(i)) // TODO потенциальная проблема
  }

  // Удаляемые файлы
  // Массив идентификаторов файлов которые необходимо удалить с сервера
  const deleteFiles = reactive<number[]>([])
  const onAddDeleteFile = (id: number) => {
    deleteFiles.push(id)
    emitter.emit('local_files_update')
  }
  const onRemoveDeleteFile = (id: number) => {
    const file = deleteFiles.findIndex((i) => i === id)
    deleteFiles.splice(file, 1)
  }
  const undoDeleteFiles = () => {
    deleteFiles.splice(0, deleteFiles.length)
  }

  // Геттер файлов с сервера за исключением удаляемых файлов
  const getFilesFromServer = computed(() => filesFromServer.filter((i) => !deleteFiles.includes(i.id)))
  // Геттер удаляемых файлов с сервера
  const getFileToDelete = computed(() => filesFromServer.filter((i) => deleteFiles.includes(i.id)))

  // Метод обновления загружаемых файлов с учетом дупликатов
  const onUpdateFilesEvent = (files: PreProcessingFiles) => {
    if (files.files.length !== files.file_names.length) throw new Error('Incorrect array lengths when reading files')
    // дубликаты новых файлов в localFiles
    const doubleFilesInLoader = files.file_names.map((item) => localFiles.file_names.includes(item))
    // дубликаты файлов в инвойсе (данные с сервера)
    const doubleFilesFromServer = files.file_names.map((item) => {
      return !!getFilesFromServer.value.find((i) => i.name === item)
    })
    // мердж дубликатов в единый массив
    const doubleFilesMerge = doubleFilesInLoader.map((item, index) => item || doubleFilesFromServer[index])
    for (const [key, value] of Object.entries(files)) {
      // все новозагруженные (files), не дублирующиеся файлы
      const filesReadyToLoad = value.filter((item, index) => !doubleFilesMerge[index])
      localFiles[key as PreProcessingFields].push(...filesReadyToLoad)
    }
    // все новозагруженные (files) дублирующиеся файлы
    const doubleFilesInFiles = files.file_names.filter((item, index) => doubleFilesMerge[index])
    // Если есть дубликаты то вызывается событие
    if (doubleFilesInFiles.length > 0) {
      emitter.emit('double_files', doubleFilesInFiles)
    }
    emitter.emit('local_files_update')
  }

  const downloadFile = (file: FileFromServer, invoiceId: number | string) => {
    file.isLoading = true
    downloadFileRequest({
      invoiceId,
      fileType: config.serverTag,
      fileId: file.id,
      name: file.serverName || file.name,
    })
  }

  onUnmounted(() => {
    localFiles.files.splice(0, localFiles.files.length)
    localFiles.file_names.splice(0, localFiles.file_names.length)
    filesFromServer.splice(0, filesFromServer.length)
    deleteFiles.splice(0, deleteFiles.length)
  })

  const allClear = () => {
    localFiles.files = []
    localFiles.file_names = []
    deleteFiles.splice(0, deleteFiles.length)
    filesFromServer.splice(0, filesFromServer.length)
  }

  return reactive({
    serverTag: config.serverTag,
    config,
    // Загружаемые файлы
    localFiles, // field
    onUpdateFilesEvent, // method
    onRemoveLocalFile, // method
    // Удаляемые файлы
    deleteFiles, // field
    getFileToDelete, // getter
    onAddDeleteFile, // method
    onRemoveDeleteFile, // method
    undoDeleteFiles, // method
    // файлы с сервера
    filesFromServer,
    setFilesFromServer, // method
    getFilesFromServer, // getter
    // emitter
    emitter, // field
    tabsField: ref('files'),
    isShow: computed(() => config.roles?.every((role) => politic[role])),
    // methods
    downloadFile, // method
    allClear, // method
  })
}

export type FileLoaderType = ReturnType<typeof fileLoader>
