import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/shop_icon.svg'
import _imports_1 from '@/assets/basket_icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "mb-8"
}
const _hoisted_2 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_3 = {
  key: 1,
  class: "mb-4"
}
const _hoisted_4 = {
  key: 2,
  class: "mb-4"
}
const _hoisted_5 = { class: "mt-6" }
const _hoisted_6 = {
  key: 0,
  class: "margin-v-s"
}
const _hoisted_7 = { class: "step-box" }
const _hoisted_8 = { class: "selectors justify-end" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "p-4"
}
const _hoisted_11 = { class: "step-box sticky-basket" }
const _hoisted_12 = {
  key: 0,
  class: "p-4"
}
const _hoisted_13 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_14 = { class: "flex justify-center" }
const _hoisted_15 = { class: "flex-box justify-center" }
const _hoisted_16 = { class: "flex justify-center" }
const _hoisted_17 = {
  key: 1,
  class: "el-divider-purple mb-4"
}
const _hoisted_18 = { class: "text-center" }
const _hoisted_19 = { class: "flex justify-end" }
const _hoisted_20 = { class: "flex justify-end" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "flex" }
const _hoisted_23 = { class: "text-center" }
const _hoisted_24 = { class: "flex justify-end" }
const _hoisted_25 = {
  key: 7,
  class: "mb-4"
}
const _hoisted_26 = {
  key: 8,
  class: "el-divider-purple margin-v-s"
}
const _hoisted_27 = {
  key: 0,
  class: "flex items-center justify-between mb-4"
}
const _hoisted_28 = { class: "font-bold" }
const _hoisted_29 = { class: "mb-8" }
const _hoisted_30 = { class: "flex justify-end items-center mb-2" }
const _hoisted_31 = { class: "el-divider--30 justify-end opacity" }
const _hoisted_32 = { class: "font-bold" }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = {
  key: 1,
  class: "font-color--purple"
}
const _hoisted_35 = {
  key: 9,
  class: "text-center font-color--deep-gray font-bold",
  style: {"font-size":"12px"}
}

import GoodCard from '../../components/GoodCard'
import BasketCard from '../../components/BasketCard'
import GoodsDescriber, { IGood } from '../../describers/GoodsDescriber'
import { computed, reactive, ref, watch } from 'vue'
import usePolitic from '@/use/usePolitic'
import FinishPaymentDialog from '@/components/Admin/FinishPaymentDialog'
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'
import useWindowWidth from '@/use/useWindowWidth'
import useGtagManager from '@/use/useGtagManager'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import FormItem from '@/components/supportComponents/FormItem.vue'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { UploadFilled } from '@element-plus/icons-vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { ElNotification } from 'element-plus'
import DiscountRequest from '@/components/DiscountRequest.vue'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import { useStepper } from '@/entityes/invoice/useStepper'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'

// Init
export interface IProps {
  id: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GoodsSelection',
  props: {
    id: {}
  },
  setup(__props: any) {

// TODO Mitrich тут надо доделывать
const props = __props
const { isAdmin, isPartner, isCustomer } = usePolitic()
const router = useRouter()
const invoiceStore = useInvoiceStore()
const { initAsync } = useAsyncInvoiceReload()
const { goodSelect, addPromocode, getInvoice, surchargePayment } = useInvoiceRepository()
const { goodsList } = GoodsDescriber()
const { screenLessValue } = useWindowWidth()
const { event } = useGtagManager()
const country = ref('ru')
const yearToCalc = ref(2024)
const baseYearsArray = [2018, 2019, 2020, 2021, 2022, 2023, 2024]
const years = reactive([...baseYearsArray])
const showYearSelect = ref(false)
const addDeductionsSum = ref(0)
const bucketCountCfr = ref(0)
const addDeductionsObject = ref({})

// Tariff
const tariff = ref(invoiceStore.invoice?.tariff || 'base')
const minTariff = ref(false)
const baseTariff = ref(true)
const maxTariff = ref(false)

// modal components
const discountRequest = ref<any | null>(null)
const invoiceVideo = ref(null)

// Invoice
const invoicePayed = invoiceStore.invoice?.pay_status
const amountOperations = computed(() => invoiceStore.invoice?.amount_operations?.Declaration?.[yearToCalc.value] || 0)
const shitPrice = computed(() => invoiceStore.invoice?.all_price?.[yearToCalc.value]?.[tariff.value]?.bucket || 0)
const shitBasePrice = computed(
  () => invoiceStore.invoice?.all_price?.[yearToCalc.value]?.[tariff.value]?.base_bucket || 0
)
const personalPrice = computed(() =>
  !!invoiceStore.invoice ? invoiceStore.invoice?.all_price[yearToCalc.value]?.personal?.personal : 0
)
const surcharge = computed(() => invoiceStore.invoice?.surcharge || 0)
const hasSurcharge = computed(() => surcharge.value !== 0)
const hasErrors = computed(
  () => !!invoiceStore.invoice && Object.keys(invoiceStore.invoice?.warning_and_error?.errors).length !== 0
)
const tinkoffPremiumInvoice = computed(() => invoiceStore.invoice?.email === 'external_premium@tinkoff.tinkoff')
const tinkoffPremiumLink = computed(() => invoiceStore.invoice?.payments?.link)
const promo = computed(() => {
  if (!!invoiceStore.invoice && Object.keys(invoiceStore.invoice?.promo).length > 0) {
    return {
      percent: `${invoiceStore.invoice?.promo?.discount_percent}%*`,
      expirationDate: invoiceStore.invoice?.promo?.active_to?.split('T')[0].split('-').join('-'),
    }
  } else {
    return {
      percent: '',
      expirationDate: '',
    }
  }
})

// Files
const filterHaveFile = computed(() => {
  if (!invoiceStore.invoice) return []
  if (!invoiceStore.invoice.broker_files) return []
  return Object.entries(invoiceStore.invoice.broker_files)
    .map((value) => {
      return {
        have: value[1].length > 0,
        name: value[0],
      }
    })
    .filter((item) => item.have)
})

const hasTinkoffOnly = computed<boolean>(() => {
  if (!invoiceStore.invoice) return false
  if (!invoiceStore.invoice.broker_files) return false
  return Object.entries(invoiceStore.invoice.broker_files).some(
    (value) => value[0] === 'tinkoff_files' && value[1].length > 0
  )
})
const hasShitOnly = computed<boolean>(() => {
  if (!invoiceStore.invoice) return false
  if (!invoiceStore.invoice.broker_files) return false
  return Object.entries(invoiceStore.invoice.broker_files).some(
    (value) => value[0] === 'bucket_files' && value[1].length > 0
  )
})
const hasTinkoffAndShitOnly = computed(() => {
  const names = filterHaveFile.value.map((i) => i.name)
  if (names.includes('bucket_files') && names.includes('tinkoff_files')) {
    return names.length === 2
  }
  return false
})
const haveShitFiles = computed(
  () => invoiceStore.invoice?.broker_files?.bucket_files && invoiceStore.invoice?.broker_files?.bucket_files?.length > 0
)

// OTO
const timerOTO = computed(() => invoiceStore.invoice?.oto_timer || 0)
const endOto = computed(() => {
  const date = new Date(Date.now() + timerOTO.value * 1000)
  const day = Number(date.getDate()) > 9 ? date.getDate() : `0${date.getDate()}`
  return `${day}-${date.getMonth() + 1}-${date.getFullYear()}`
})

// PromoForm
const schema = toTypedSchema(
  yup.object({
    promocode: yup.string(),
  })
)
const { handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    promocode: invoiceStore.invoice?.promo?.code || '',
  },
})

// Lists && Calc
const list = computed(() => {
  if (hasTinkoffOnly.value) {
    return goodsList.filter((i) => i.showGood.value).filter((i) => i.id !== 4)
  } else {
    return goodsList.filter((i) => i.showGood.value)
  }
})
const basketList = computed(() => {
  const names = goodsList.filter((i) => i.inBasket.value).map((i) => i.name)
  if (names.includes('Declaration') && names.includes('Cfr')) {
    goodsList[0].inBasket.value = true
    goodsList[1].inBasket.value = false
    goodsList[3].inBasket.value = false
    return goodsList.filter((i) => i.inBasket.value)
  } else {
    return goodsList.filter((i) => i.inBasket.value)
  }
})
const emptyBasket = computed(() => basketList.value?.every((i) => !i.inBasket.value))
const hasBundle = computed(() => goodsList?.find((i) => i.name === 'bundle')?.inBasket.value)
const cfrBucketSum = computed(() => bucketCountCfr.value * shitPrice.value)
const cfrBucketNoDiscount = computed(() => (shitBasePrice.value ? bucketCountCfr.value * shitBasePrice.value : 0))
const additionalTotal = computed(() => {
  if (hasBundle.value) {
    return addDeductionsSum.value
  } else if (goodsList[1].checked.value) {
    return addDeductionsSum.value
  } else {
    return 0
  }
})
const invoiceTotalPremiumTinkoff = computed(() =>
  invoiceStore.invoice?.payments ? invoiceStore.invoice?.payments.price : 0
)
const noPrices = computed(
  () =>
    !!invoiceStore.invoice &&
    !!invoiceStore.invoice?.all_price &&
    Object.keys(invoiceStore.invoice?.all_price).length === 0
)
const bundleDiscount = computed(() => {
  const prices = invoiceStore.invoice?.all_price
  const bundleTotal = prices?.[yearToCalc.value]?.[tariff.value]?.base_bundle || 0
  const decPlusCfr =
    (prices?.[yearToCalc.value]?.[tariff.value]?.base_declaration || 0) +
    (prices?.[yearToCalc.value]?.[tariff.value]?.base_cfr || 0)
  return decPlusCfr - bundleTotal
})
const invoiceTotal = computed(() => {
  const personal = tariff.value === 'personal' ? personalPrice.value : 0
  const hasDeclarationPdf = basketList.value.map((i) => i.id).includes(3) ? goodsList[2].price.value : 0
  const selectedTotal = basketList.value.reduce((acc, item) => {
    if (hasShitOnly.value) {
      if (item.name === 'bundle') {
        item.price.value = cfrBucketSum.value
      }
      if (item.name === 'Cfr') {
        item.price.value = cfrBucketSum.value
      }
    }
    return acc + item.price.value
  }, 0)
  return hasShitOnly.value
    ? (personal || 0) + selectedTotal + additionalTotal.value + (cfrBucketSum.value - selectedTotal) + hasDeclarationPdf
    : selectedTotal + cfrBucketSum.value + (personal || 0) + additionalTotal.value
})
const createGoodsBody = computed(() => {
  const countGoods = basketList.value
    .map((i) => i.name)
    .reduce((acc: string[], goodName: string) => {
      if (goodName === 'bundle') {
        acc.push(...['Declaration', 'Cfr'])
      }
      if (goodName === 'Declaration') {
        acc.push('Declaration')
      }
      if (goodName === 'Cfr') {
        acc.push('Cfr')
      }
      return acc
    }, [])
  const countServices = basketList.value.reduce(
    (acc: any, service: Record<string, number | string | any>, index: number) => {
      if (!['Declaration', 'Cfr', 'bundle'].includes(service.name)) {
        acc[service.name] = 1
      }
      if (index === basketList.value.length - 1) {
        Object.assign(acc, addDeductionsObject.value)
      }
      return acc
    },
    {}
  )
  return {
    goods: countGoods,
    year_to_calc: yearToCalc.value,
    country: country.value,
    price: invoiceTotal.value,
    tariff: tariff.value,
    services: { ...countServices },
  }
})
const totalText = computed(() => {
  if (hasSurcharge.value) {
    return 'Итого к доплате: '
  } else if (haveShitFiles.value) {
    return 'Предоплата: '
  } else {
    return 'Итого: '
  }
})

function totalAddDeductions(val: { total: number; services: Record<string, number> }) {
  console.log(val.total)
  addDeductionsSum.value = val.total
  addDeductionsObject.value = val.services
}
function totalBucketCfr(val: number) {
  bucketCountCfr.value = val
}
function addGood(id: number) {
  const item = goodsList.find((i) => i.id === id)
  if (item && id === 2 && goodsList[3].inBasket.value && item) {
    goodsList[0].inBasket.value = true
    goodsList[3].inBasket.value = false
    goodsList[3].checked.value = true
    item.inBasket.value = false
    item.checked.value = true
  } else if (id === 4 && goodsList[2].inBasket.value && item) {
    goodsList[0].inBasket.value = true
    goodsList[2].inBasket.value = false
    goodsList[2].checked.value = true
    item.inBasket.value = false
    item.checked.value = true
  } else if (item) {
    item.inBasket.value = true
    item.checked.value = true
  }
}
function removeGood(id: number) {
  const item = goodsList.find((i) => i.id === id)
  if (id === 2 && item) {
    if (hasBundle.value) {
      item.inBasket.value = false
      item.checked.value = false
      goodsList[0].inBasket.value = false
      goodsList[0].checked.value = false
      goodsList[3].inBasket.value = true
      goodsList[3].checked.value = true
    }
    item.inBasket.value = false
    item.checked.value = false
  } else if (id === 4 && item) {
    if (hasBundle.value) {
      item.inBasket.value = false
      item.checked.value = false
      goodsList[0].inBasket.value = false
      goodsList[0].checked.value = false
      goodsList[1].inBasket.value = true
      goodsList[1].checked.value = true
    }
    item.inBasket.value = false
    item.checked.value = false
  } else if (item) {
    item.inBasket.value = false
    item.checked.value = false
  }
}
function sendGoodsArray(callbackConfig?: Record<string, (link: string) => Promise<void>>) {
  event('initiateCheckout')
  if (hasSurcharge.value) {
    surchargePayment(props.id)
      .then((res: { [key: string]: string }) => {
        if (isCustomer.value) {
          if (res?.link) window.location.assign(res.link)
        } else {
          callbackConfig?.method?.(res.link)
        }
      })
      .catch((err) => {
        console.log(err)
        ElNotification({
          title: `Ошибка`,
          message: `перехода на страницу оплаты ${err}`,
          type: 'error',
        })
      })
  } else {
    goodSelect(props.id, createGoodsBody.value)
      .then((res: Record<string, string>) => {
        if (isCustomer.value) {
          if (res?.link) window.location.assign(res.link)
        } else {
          callbackConfig?.method?.(res.link).then(() => {
            if (!invoiceStore.invoice) return
            getInvoice(props.id)
            router.push({ name: 'invoice show' })
          })
        }
      })
      .catch((err) => {
        console.log(err)
        ElNotification({
          title: `Ошибка`,
          message: `перехода на страницу оплаты ${err}`,
          type: 'error',
        })
      })
  }
}
function getPaymentLink(callbackConfig: Record<string, (link: string) => void>) {
  goodSelect(props.id, createGoodsBody.value).then((res: Record<string, string>) => {
    callbackConfig?.method?.(res?.link)
  })
}
function setupPrices(invoice: IInvoice | null) {
  if (invoice) {
    const prices = invoice?.all_price
    goodsList.forEach((item: IGood) => {
      if (prices && Object.keys(prices).length === 0) {
        item.price.value = 0
        item.basePrice.value = 0
      }
      if (invoice?.surcharge) {
        item.price.value = invoice?.surcharge
      } else {
        if (prices && item.id !== 3 && item.priceTag && item.priceBaseTag) {
          const price = prices[yearToCalc.value][tariff.value][item.priceTag]
          const basePrice = prices[yearToCalc.value][tariff.value][item.priceBaseTag]
          if (price && basePrice) {
            item.price.value = price
            item.basePrice.value = basePrice
          }
        }
        if (item.id === 5) {
          item.price.value = 0
        }
      }
    })
  }
}
const incomesDeductionsWithPrices = computed(() => {
  if (!!invoiceStore?.invoice && !!invoiceStore?.invoice?.pre_selected_add_incomes) {
    return Object.keys(invoiceStore?.invoice?.pre_selected_add_incomes).reduce(
      (acc: Record<string, Record<string, number | undefined>>, key: string) => {
        acc[key] = {
          qty: invoiceStore?.invoice?.all_price?.[yearToCalc.value]?.[tariff.value]?.add_services[key]?.qty,
          price: invoiceStore?.invoice?.all_price?.[yearToCalc.value]?.[tariff.value]?.add_services[key]?.price,
        }
        return acc
      },
      {}
    )
  } else {
    return {}
  }
})
function mergeYears(invoice: IInvoice | null) {
  const allYears = invoice?.all_price
  if (allYears) {
    const yearsKeys = Object.keys(allYears).map((year) => parseInt(year, 10))
    years.splice(0, years.length, ...yearsKeys)
    showYearSelect.value = years.length > 1
  }
}
function mergeTariff() {
  if (tariff.value === 'minimal') {
    minTariff.value = true
    baseTariff.value = false
    maxTariff.value = false
  }
  if (tariff.value === 'base') {
    minTariff.value = false
    baseTariff.value = true
    maxTariff.value = false
  }
  if (tariff.value === 'personal') {
    minTariff.value = false
    baseTariff.value = false
    maxTariff.value = true
  }
}
function saveSelectedGoods(invoice: IInvoice | null) {
  if (invoice?.goods) {
    if (Object.keys(invoice?.goods).length > 0) {
      Object.keys(invoice?.goods).forEach((name) => {
        const goodId = goodsList.find((i) => i.name === name)?.id
        if (goodId) addGood(goodId)
      })
    }
  }
}
const sendPromocode = handleSubmit((promo) => {
  goodSelect(props.id, createGoodsBody.value).then(() => {
    addPromocode(props.id, { ...promo })
      .then(() => {
        ElNotification({
          title: `Промокод`,
          message: 'успешно активирован',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          title: `Ошибка`,
          message: 'при применении промокода',
          type: 'error',
        })
      })
      .finally(() => {
        getInvoice(props.id).then((res) => {
          initAsync(res)
        })
      })
  })
})
function showDiscountRequest() {
  discountRequest.value.openModal()
}

if (hasTinkoffOnly.value) {
  goodsList[3].inBasket.value = false
  goodsList[4].showGood.value = false
}
// Уведомление о скидочной политике при загрузке страницы GoodsSelection
// if (!hasErrors.value && !politic.isAdmin.value) DiscountModal()
if (hasErrors.value) router.push({ name: 'file_in_invoice' })

watch(yearToCalc, () => {
  if (invoiceStore.invoice) setupPrices(invoiceStore.invoice)
})
watch(tariff, () => {
  if (invoiceStore.invoice) setupPrices(invoiceStore.invoice)
})
watch(minTariff, (val) => {
  if (val) {
    tariff.value = 'minimal'
    baseTariff.value = false
    maxTariff.value = false
  } else {
    if (!maxTariff.value) {
      baseTariff.value = true
    }
  }
})
watch(baseTariff, (val) => {
  if (val) {
    tariff.value = 'base'
    minTariff.value = false
    maxTariff.value = false
  } else {
    if (!maxTariff.value && !minTariff.value) {
      baseTariff.value = true
    }
  }
})
watch(maxTariff, (val) => {
  if (val) {
    tariff.value = 'personal'
    minTariff.value = false
    baseTariff.value = false
  } else {
    if (!minTariff.value) {
      baseTariff.value = true
    }
  }
})
function setupYearToCalc(res: IInvoice | null) {
  if (!res || !res.all_price) return 2024
  const years = Object.keys(res.all_price).map((year) => parseInt(year, 10))
  if (years.includes(res.year_to_calc)) {
    return res.year_to_calc
  } else {
    return years[years.length - 1]
  }
}

// mergeYears()
// setupPrices()
// mergeTariff()

// getInvoice(props.id).then((res) => {
//   initAsync(res).then((res) => {
yearToCalc.value = setupYearToCalc(invoiceStore.invoice)
saveSelectedGoods(invoiceStore.invoice)
mergeYears(invoiceStore.invoice)
setupPrices(invoiceStore.invoice)
mergeTariff()
// })
// })
event('uploadedFile')

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_Discount = _resolveComponent("Discount")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_CreditCard = _resolveComponent("CreditCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!hasErrors.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!hasSurcharge.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "mb-4 mr-3" }, "Шаг 3. Выберите товары и узнайте их стоимость", -1)),
                _createVNode(NgInvoiceVideo, {
                  ref_key: "invoiceVideo",
                  ref: invoiceVideo,
                  "show-button": true,
                  "video-link": 'https://www.youtube.com/embed/8bocQ9Uhkik?si=U5G1gVBB4hn-xpIq'
                }, null, 512)
              ]))
            : _createCommentVNode("", true),
          (hasSurcharge.value)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_3, "Требуется доплата"))
            : _createCommentVNode("", true),
          (!hasSurcharge.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Отметьте нужные позиции и тариф, после чего перейдите к оплате."))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (hasSurcharge.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[7] || (_cache[7] = [
                  _createElementVNode("p", null, "Так как число операций выросло, то для продолжения оформления документов необходима доплата", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_el_row, { gutter: 20 }, {
            default: _withCtx(() => [
              (!hasSurcharge.value)
                ? (_openBlock(), _createBlock(_component_el_col, {
                    key: 0,
                    xs: 24,
                    sm: 24,
                    lg: 12,
                    class: _normalizeClass([_unref(screenLessValue)(1200) ? 'mb-8' : ''])
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["pr-4 pl-4 bg-gray border--top flex items-center justify-between", [_unref(screenLessValue)(1250) ? 'flex-box--wrap' : '']])
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["flex items-center", [_unref(screenLessValue)(1250) ? 'mb-2 text-start' : '']])
                          }, _cache[8] || (_cache[8] = [
                            _createElementVNode("img", {
                              class: "mr-4",
                              src: _imports_0,
                              alt: "shop_icon"
                            }, null, -1),
                            _createElementVNode("h2", null, "Портфель", -1)
                          ]), 2),
                          _createElementVNode("div", _hoisted_8, [
                            (showYearSelect.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  _cache[9] || (_cache[9] = _createElementVNode("p", { class: "font-weight--extra text-start mb-1" }, "выберите отчетный год", -1)),
                                  _createVNode(_component_el_select, {
                                    modelValue: yearToCalc.value,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((yearToCalc).value = $event)),
                                    placeholder: "Выберите год",
                                    class: "mb-2 min-w-[100px]"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(years, (year) => {
                                        return (_openBlock(), _createBlock(_component_el_option, {
                                          key: year,
                                          label: year,
                                          value: year
                                        }, null, 8, ["label", "value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"])
                                ]))
                              : _createCommentVNode("", true),
                            _cache[10] || (_cache[10] = _createElementVNode("p", { class: "font-weight--extra text-start mb-1" }, "выберите страну расчета", -1)),
                            (_unref(isAdmin))
                              ? (_openBlock(), _createBlock(_component_el_select, {
                                  key: 1,
                                  modelValue: country.value,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((country).value = $event)),
                                  class: "mb-2 min-w-[100px]"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['ru', 'uk', 'kz', 'se', 'eu'], (i) => {
                                      return _createVNode(_component_el_option, {
                                        key: i,
                                        label: i,
                                        value: i
                                      }, null, 8, ["label", "value"])
                                    }), 64))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"]))
                              : _createCommentVNode("", true)
                          ])
                        ], 2),
                        (!!_unref(invoiceStore).invoice)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (i) => {
                                return (_openBlock(), _createBlock(_unref(GoodCard), {
                                  key: i.id,
                                  "in-basket": i.checked.value,
                                  good: i,
                                  files: filterHaveFile.value,
                                  "incomes-deductions": incomesDeductionsWithPrices.value,
                                  onAdd_to_basket: addGood,
                                  onRemove_from_basket: removeGood,
                                  onCount_add: totalAddDeductions,
                                  onCount_bucket_cfr: totalBucketCfr,
                                  year: yearToCalc.value,
                                  "shit-base-price": shitBasePrice.value,
                                  "has-bundle": hasBundle.value,
                                  "amount-operations": amountOperations.value,
                                  "cfr-bucket-sum": cfrBucketNoDiscount.value
                                }, null, 8, ["in-basket", "good", "files", "incomes-deductions", "year", "shit-base-price", "has-bundle", "amount-operations", "cfr-bucket-sum"]))
                              }), 128)),
                              (list.value.length === 0)
                                ? (_openBlock(), _createBlock(_component_el_empty, {
                                    key: 0,
                                    description: "Вы добавили все товары"
                                  }))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }, 8, ["class"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 24,
                lg: 12
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, [
                    _cache[30] || (_cache[30] = _createElementVNode("div", { class: "flex items-center p-4 bg-gray border--top" }, [
                      _createElementVNode("img", {
                        class: "mr-4",
                        src: _imports_1,
                        alt: "basket_icon"
                      }),
                      _createElementVNode("h2", null, "Стоимость услуг")
                    ], -1)),
                    (!emptyBasket.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          (!hasSurcharge.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _cache[17] || (_cache[17] = _createElementVNode("h3", { class: "font-bold mb-4" }, "Тарифы", -1)),
                                _createVNode(_component_el_row, {
                                  gutter: 20,
                                  style: {"font-size":"14px","hyphens":"auto !important","word-break":"break-word"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_col, {
                                      xs: 24,
                                      sm: 8,
                                      md: 8,
                                      lg: 8
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", {
                                          class: _normalizeClass(["flex flex-col justify-between text-center tariff-box", minTariff.value ? 'tariff-box-min' : ''])
                                        }, [
                                          _cache[11] || (_cache[11] = _createElementVNode("p", { class: "font-bold" }, "Минимальный", -1)),
                                          _cache[12] || (_cache[12] = _createElementVNode("p", { style: {"line-height":"18px"} }, "Максимальная экономия, без тех-поддержки и сопровождения", -1)),
                                          _createElementVNode("div", _hoisted_14, [
                                            _createVNode(_component_el_checkbox, {
                                              modelValue: minTariff.value,
                                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((minTariff).value = $event))
                                            }, null, 8, ["modelValue"])
                                          ])
                                        ], 2)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      xs: 24,
                                      sm: 8,
                                      md: 8,
                                      lg: 8
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", {
                                          class: _normalizeClass(["flex flex-col justify-between text-center tariff-box", baseTariff.value ? 'tariff-box-base' : ''])
                                        }, [
                                          _cache[13] || (_cache[13] = _createElementVNode("p", { class: "font-weight--extra" }, "Базовый", -1)),
                                          _cache[14] || (_cache[14] = _createElementVNode("p", { style: {"line-height":"18px"} }, "Помощь тех-поддержки, сопровождение при камеральной проверке", -1)),
                                          _createElementVNode("div", _hoisted_15, [
                                            _createVNode(_component_el_checkbox, {
                                              modelValue: baseTariff.value,
                                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((baseTariff).value = $event))
                                            }, null, 8, ["modelValue"])
                                          ])
                                        ], 2)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_col, {
                                      xs: 24,
                                      sm: 8,
                                      md: 8,
                                      lg: 8
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", {
                                          class: _normalizeClass(["flex flex-col justify-between text-center tariff-box", maxTariff.value ? 'tariff-box-max' : ''])
                                        }, [
                                          _cache[15] || (_cache[15] = _createElementVNode("p", { class: "font-bold" }, "Персональный", -1)),
                                          _cache[16] || (_cache[16] = _createElementVNode("p", { style: {"line-height":"18px"} }, "Оформление заказа за вас, полное сопровождение", -1)),
                                          _createElementVNode("div", _hoisted_16, [
                                            _createVNode(_component_el_checkbox, {
                                              modelValue: maxTariff.value,
                                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((maxTariff).value = $event))
                                            }, null, 8, ["modelValue"])
                                          ])
                                        ], 2)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true),
                          (!hasSurcharge.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17))
                            : _createCommentVNode("", true),
                          (!hasSurcharge.value)
                            ? (_openBlock(), _createBlock(_component_el_row, {
                                key: 2,
                                class: "mb-2",
                                align: "middle"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, {
                                    md: 12,
                                    lg: 12
                                  }, {
                                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                                      _createElementVNode("h3", { class: "mb-2" }, "Действующие скидки", -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 4,
                                    lg: 4
                                  }, {
                                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                                      _createElementVNode("h3", { class: "mb-2 text-center" }, "Размер", -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 8,
                                    lg: 8
                                  }, {
                                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                                      _createElementVNode("div", { class: "flex justify-end" }, [
                                        _createElementVNode("h3", { class: "mb-2" }, "Срок истечения")
                                      ], -1)
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (!hasSurcharge.value && hasBundle.value && !hasShitOnly.value && !hasTinkoffAndShitOnly.value && !hasTinkoffOnly.value)
                            ? (_openBlock(), _createBlock(_component_el_row, {
                                key: 3,
                                class: "mb-4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, {
                                    md: 12,
                                    lg: 12
                                  }, {
                                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                                      _createElementVNode("p", null, "Скидка Декларация + ОДС", -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 4,
                                    lg: 4
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", _hoisted_18, "₽ " + _toDisplayString(bundleDiscount.value), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 8,
                                    lg: 8
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (timerOTO.value !== 0 && timerOTO.value !== null)
                            ? (_openBlock(), _createBlock(_component_el_row, {
                                key: 4,
                                class: "mb-4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, {
                                    md: 12,
                                    lg: 12
                                  }, {
                                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                                      _createElementVNode("p", null, "Специальное предложение", -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 4,
                                    lg: 4
                                  }, {
                                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                                      _createElementVNode("p", { class: "text-center" }, "5%*", -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 8,
                                    lg: 8
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_19, [
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_20, [
                                            _createElementVNode("p", {
                                              class: _normalizeClass(timerOTO.value * 1000 < 24 * 3600 * 1000 ? 'font-color--red' : 0)
                                            }, _toDisplayString(endOto.value), 3)
                                          ])
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (tariff.value === 'minimal' && !hasSurcharge.value)
                            ? (_openBlock(), _createBlock(_component_el_row, {
                                key: 5,
                                class: "mb-4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, {
                                    md: 12,
                                    lg: 12
                                  }, {
                                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                                      _createElementVNode("p", null, "Скидка по тарифу", -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 4,
                                    lg: 4
                                  }, {
                                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                                      _createElementVNode("p", { class: "text-center" }, "25%*", -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 8,
                                    lg: 8
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (tariff.value !== 'minimal')
                            ? (_openBlock(), _createBlock(_component_el_row, {
                                key: 6,
                                class: "mb-4",
                                align: "middle"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, {
                                    md: 12,
                                    lg: 12
                                  }, {
                                    default: _withCtx(() => [
                                      (!hasSurcharge.value && !_unref(isPartner))
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                            _createVNode(_component_el_form, {
                                              onSubmit: _withModifiers(_unref(sendPromocode), ["prevent"]),
                                              "validation-schema": _unref(schema)
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_22, [
                                                  _createVNode(FormItem, {
                                                    name: "promocode",
                                                    label: ''
                                                  }, {
                                                    default: _withCtx(({ value, onBlur, onInput }) => [
                                                      _createVNode(_component_el_input, {
                                                        "model-value": value,
                                                        type: "text",
                                                        name: "promocode",
                                                        onBlur: onBlur,
                                                        onInput: onInput,
                                                        placeholder: "Промокод"
                                                      }, null, 8, ["model-value", "onBlur", "onInput"])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_el_tooltip, {
                                                    effect: "dark",
                                                    content: "Активировать промокод",
                                                    placement: "top"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_el_button, {
                                                        style: {"margin-left":"-32px","z-index":"1000","width":"30px"},
                                                        type: "primary",
                                                        icon: _unref(UploadFilled),
                                                        "native-type": "submit",
                                                        disabled: Object.keys(_unref(invoiceStore).invoice?.promo || {}).length > 0
                                                      }, null, 8, ["icon", "disabled"])
                                                    ]),
                                                    _: 1
                                                  })
                                                ])
                                              ]),
                                              _: 1
                                            }, 8, ["onSubmit", "validation-schema"])
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 4,
                                    lg: 4
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", _hoisted_23, _toDisplayString(promo.value.percent), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    md: 8,
                                    lg: 8
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_24, [
                                        _createElementVNode("p", null, _toDisplayString(promo.value.expirationDate.split('T')[0]), 1)
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (tariff.value !== 'minimal' && !_unref(isPartner) && !hasTinkoffOnly.value && !hasSurcharge.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                _createElementVNode("div", {
                                  class: "font-color--purple cursor-point button--text-hover flex items-center",
                                  onClick: showDiscountRequest
                                }, [
                                  _createVNode(_component_el_icon, { class: "mr-2" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Discount)
                                    ]),
                                    _: 1
                                  }),
                                  _cache[26] || (_cache[26] = _createElementVNode("span", null, "хочу промокод", -1))
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          (!hasSurcharge.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_26))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", null, [
                            _cache[28] || (_cache[28] = _createElementVNode("h3", { class: "font-weight--extra mb-2" }, "Товары", -1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(basketList.value, (i) => {
                              return (_openBlock(), _createBlock(_unref(BasketCard), {
                                key: i.name,
                                good: i,
                                "add-deductions": addDeductionsSum.value,
                                "has-shit-only": hasShitOnly.value,
                                "has-tinkoff-only": hasTinkoffOnly.value,
                                "cfr-bucket-sum": cfrBucketSum.value,
                                "cfr-bucket-base-sum": cfrBucketNoDiscount.value,
                                "base-bundle-discount": bundleDiscount.value
                              }, null, 8, ["good", "add-deductions", "has-shit-only", "has-tinkoff-only", "cfr-bucket-sum", "cfr-bucket-base-sum", "base-bundle-discount"]))
                            }), 128)),
                            (tariff.value === 'personal')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                  _cache[27] || (_cache[27] = _createElementVNode("p", null, "Персональное обслуживание", -1)),
                                  _createElementVNode("p", _hoisted_28, "₽ " + _toDisplayString(personalPrice.value), 1)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("div", _hoisted_30, [
                              _createElementVNode("div", _hoisted_31, [
                                _createVNode(_component_el_divider, { class: "el-divider-30" }),
                                _createElementVNode("h2", _hoisted_32, [
                                  _createElementVNode("span", null, _toDisplayString(totalText.value), 1),
                                  (tinkoffPremiumInvoice.value)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_33, "₽ " + _toDisplayString(invoiceTotalPremiumTinkoff.value), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_34, "₽ " + _toDisplayString(hasSurcharge.value ? surcharge.value : invoiceTotal.value), 1))
                                ])
                              ])
                            ])
                          ]),
                          _createVNode(_component_el_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_col, {
                                sm: 24,
                                md: 24,
                                lg: 24,
                                class: "flex justify-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_tooltip, {
                                    class: "item",
                                    effect: "dark",
                                    content: "Переход на оплату",
                                    placement: "top-start"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_button, {
                                        disabled: noPrices.value || _unref(invoicePayed),
                                        class: "button-main",
                                        type: "success",
                                        onClick: _cache[5] || (_cache[5] = 
                        () => {
                          sendGoodsArray()
                        }
                      ),
                                        style: {"height":"50px","font-size":"18px","width":"90%"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_icon, {
                                            class: "el-icon-credit-card font-weight--extra mr-2",
                                            size: 22
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_CreditCard)
                                            ]),
                                            _: 1
                                          }),
                                          _createTextVNode(" " + _toDisplayString(hasSurcharge.value ? 'Доплатить' : 'Перейти к оплате') + " " + _toDisplayString(`₽ ` + invoiceTotal.value), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["disabled"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (!hasSurcharge.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[29] || (_cache[29] = [
                                _createElementVNode("p", null, "*Скидка распространяется на расчёт декларации и ОДС для брокеров и банков", -1)
                              ])))
                            : _createCommentVNode("", true),
                          (!_unref(isCustomer))
                            ? (_openBlock(), _createBlock(_unref(FinishPaymentDialog), {
                                key: 10,
                                class: "margin-v-s",
                                id: _ctx.id,
                                onSend: sendGoodsArray,
                                onGetLink: getPaymentLink,
                                "tinkoff-link": tinkoffPremiumLink.value,
                                "is-tinkoff-premium": tinkoffPremiumInvoice.value,
                                "is-admin": _unref(isAdmin)
                              }, null, 8, ["id", "tinkoff-link", "is-tinkoff-premium", "is-admin"]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (emptyBasket.value)
                      ? (_openBlock(), _createBlock(_component_el_empty, {
                          key: 1,
                          description: "В корзине нет товаров"
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(DiscountRequest, {
      id: _ctx.id,
      ref_key: "discountRequest",
      ref: discountRequest
    }, null, 8, ["id"])
  ]))
}
}

})