import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/delete_icon.svg'


const _hoisted_1 = { class: "" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mb-4 bg-gray p-4" }
const _hoisted_4 = { class: "mb-2 flex items-center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]

import usePolitic from '@/use/usePolitic'
import { FileTagType, LoaderDescriberType } from '@/06-Entities/File'
import { Download, Loading } from '@element-plus/icons-vue'

interface IEmit {
  (e: 'selectLoader', value: FileTagType): void
  (
    e: 'deleteFileFromServer',
    value: {
      serverTag: FileTagType
      index: number
    }
  ): void
}
interface IProps {
  loaderDescriber: LoaderDescriberType
  invoiceId: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BrokersAndLoadFiles',
  props: {
    loaderDescriber: {},
    invoiceId: {}
  },
  emits: ["selectLoader", "deleteFileFromServer"],
  setup(__props: any, { emit: __emit }) {

const politics = usePolitic()
const emit = __emit

const props = __props

function selectActiveLoader(brokerServerTag: FileTagType) {
  emit('selectLoader', brokerServerTag)
}

const autoBrokers = props.loaderDescriber.describers.filter((i) => i?.config?.type === 'auto')
const rusBrokers = props.loaderDescriber.describers.filter((i) => i?.config?.type === 'rus')
const manual = props.loaderDescriber.describers.filter((i) => i?.config?.type === 'manual')
const guru = props.loaderDescriber.describers.filter((i) => i?.config?.serverTag === 'guru_files')

const FileBrokerLoaders = [
  {
    title: 'Автоматические иностранные брокеры',
    loaders: [...autoBrokers],
    show: true,
  },
  {
    title: 'Иные иностранные организации',
    loaders: [...manual],
    show: true,
  },
  {
    title: 'Российские брокеры',
    loaders: [...rusBrokers],
    show: true,
  },
  {
    title: 'Гуру',
    loaders: [...guru],
    show: politics.isAdmin.value,
  },
]

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(FileBrokerLoaders, (item) => {
      return _createElementVNode("div", {
        key: item.title
      }, [
        (item.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h3", _hoisted_3, _toDisplayString(item.title), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.loaders, (desc) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "mb-4 px-4",
                  key: 'show_' + desc.config.serverTag
                }, [
                  (desc.isShow)
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 0,
                        content: `Загрузить ${desc.config.title}`,
                        effect: "dark",
                        placement: "right"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", {
                              onClick: ($event: any) => (selectActiveLoader(desc.config.serverTag as FileTagType)),
                              class: "clickable-broker cursor-point width-100 flex items-center p-2"
                            }, [
                              _createElementVNode("img", {
                                src: desc.config.logo,
                                alt: "broker_icon",
                                class: "logo-img mr-4"
                              }, null, 8, _hoisted_6),
                              _createElementVNode("span", null, _toDisplayString(desc.config.title), 1)
                            ], 8, _hoisted_5)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["content"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(desc.getFilesFromServer, (file) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: file.id,
                      class: "margin-v-xs margin-h--left-l flex"
                    }, [
                      _createVNode(_component_el_tooltip, {
                        effect: "dark",
                        content: `Скачать ${file.serverName}`,
                        placement: "right"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("a", {
                            onClick: _withModifiers(($event: any) => (desc.downloadFile(file, _ctx.invoiceId)), ["prevent"]),
                            class: "clickable-text-link cursor-point mr-2"
                          }, [
                            _createElementVNode("span", null, _toDisplayString(file.name), 1),
                            (file.isLoading)
                              ? (_openBlock(), _createBlock(_component_el_icon, {
                                  key: 0,
                                  class: "el-icon-loading font-weight--extra margin-h--left-s",
                                  style: {"vertical-align":"middle"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Loading))
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_el_icon, {
                                  key: 1,
                                  class: "el-icon-download font-weight--extra margin-h--left-s",
                                  style: {"vertical-align":"middle"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Download))
                                  ]),
                                  _: 1
                                }))
                          ], 8, _hoisted_7)
                        ]),
                        _: 2
                      }, 1032, ["content"]),
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "delete_icon",
                        width: "16",
                        class: "cursor-point button--text-hover",
                        onClick: 
                () => {
                  emit('deleteFileFromServer', {
                    index: file.id,
                    serverTag: desc.serverTag as FileTagType,
                  })
                }
              
                      }, null, 8, _hoisted_8)
                    ]))
                  }), 128))
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    }), 64))
  ]))
}
}

})