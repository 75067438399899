import { useRequestService } from '@/use/useRequestService'
import { IFile } from '@/entityes/invoice/file/file.type'
import { ref } from 'vue'
import { useProfilesStore } from '@/entityes/profiles/profiles.store'
import { useProfilesRepository } from '@/entityes/profiles/profiles.repository'
import { useUserStore } from '@/entityes/user/userStore'
import { IUser } from '@/entityes/user/user.type'

export function useGetResultFilesRequest() {
  const { fetch } = useRequestService()
  const profilesStore = useProfilesStore()
  const { getUserProfiles } = useProfilesRepository()
  const userStore = useUserStore()
  function getDeclarationResultFiles(goodId: string | number) {
    getDeclarationResultFiles.indicator.value = true
    return new Promise<IFile[]>((resolve, reject) => {
      fetch<{ result_files: IFile[] }>(`/declarations/${goodId}/result_files`)
        .then((res) => {
          resolve(res.result_files)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => (getDeclarationResultFiles.indicator.value = false))
    })
  }
  getDeclarationResultFiles.indicator = ref(false)
  function getCfrResulFiles(goodId: string | number) {
    getCfrResulFiles.indicator.value = true
    return new Promise<IFile[]>((resolve, reject) => {
      fetch<{ result_files: IFile[] }>(`/cfrs/${goodId}`)
        .then((res) => {
          resolve(res.result_files)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => (getCfrResulFiles.indicator.value = false))
    })
  }
  getCfrResulFiles.indicator = ref(false)
  function getTaxPlanResultFiles(goodId: number | string) {
    getTaxPlanResultFiles.indicator.value = true
    return new Promise<IFile[]>((resolve, reject) => {
      fetch<{ result_files: IFile[] }>(`/profiles/${userStore.user?.active_profile_id}/tax_plans/${goodId}`)
        .then((res) => {
          resolve(res.result_files)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => (getTaxPlanResultFiles.indicator.value = false))
    })
  }
  getTaxPlanResultFiles.indicator = ref(false)

  return {
    getDeclarationResultFiles,
    getCfrResulFiles,
    getTaxPlanResultFiles,
  }
}
