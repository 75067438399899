<script lang="ts" setup>
import { computed } from 'vue'

export interface IProps {
  good: { [key: string]: number | string | any }
  addDeductions: number
  hasShitOnly: boolean
  hasTinkoffOnly: boolean
  cfrBucketSum: number
  cfrBucketBaseSum: number
  baseBundleDiscount: number
}
const props = defineProps<IProps>()
const cfrPrice = computed(() => (props.hasShitOnly ? 0 : props.cfrBucketSum))
const cfrBasePrice = computed(() => (props.hasShitOnly ? 0 : props.cfrBucketBaseSum))
const price = computed(() => {
  if (props.good.name === 'bundle') {
    return props.good.price.value + props.addDeductions + cfrPrice.value
  } else if (props.good.name === 'declaration_pdf' || props.good.name === 'tax_plan') {
    return props.good.price.value
  } else if (props.hasShitOnly && props.good.name === 'Declaration') {
    return 5700 + props.addDeductions
  } else {
    return props.good.price.value + cfrPrice.value + props.addDeductions
  }
})
const basePrice = computed(() => {
  const shitOnlyNoDiscounts =
    props.hasShitOnly || props.hasTinkoffOnly ? 0 : cfrBasePrice.value + props.baseBundleDiscount
  if (props.good.name === 'bundle') {
    return props.hasShitOnly
      ? props.cfrBucketBaseSum
      : props.good.basePrice.value + props.addDeductions + shitOnlyNoDiscounts
  } else if (props.good.name === 'Declaration') {
    return props.good.basePrice.value + props.addDeductions + shitOnlyNoDiscounts
  } else if (props.hasShitOnly && props.good.name === 'Declaration') {
    return 6000
  } else {
    return props.hasShitOnly ? props.cfrBucketBaseSum : props.good.basePrice.value + cfrBasePrice.value
  }
})
const showBasePrice = computed(() => props.good.name !== 'declaration_pdf' && props.good.name !== 'tax_plan')
</script>

<template>
  <div class="flex justify-between mb-4">
    <p>{{ good.title }}</p>
    <div>
      <p class="font-bold" v-if="good.name !== 'tax_plan'">₽ {{ price }}</p>
      <p v-if="showBasePrice" class="text-strike font-color--deep-gray" style="font-size: 12px">₽ {{ basePrice }}</p>
    </div>
  </div>
</template>

<style scoped></style>
