<script setup lang="ts">
import usePolitic from '@/use/usePolitic'
import { FileTagType, LoaderDescriberType } from '@/06-Entities/File'
import { Download, Loading } from '@element-plus/icons-vue'

const politics = usePolitic()
interface IEmit {
  (e: 'selectLoader', value: FileTagType): void
  (
    e: 'deleteFileFromServer',
    value: {
      serverTag: FileTagType
      index: number
    }
  ): void
}
const emit = defineEmits<IEmit>()

interface IProps {
  loaderDescriber: LoaderDescriberType
  invoiceId: string | number
}
const props = defineProps<IProps>()

function selectActiveLoader(brokerServerTag: FileTagType) {
  emit('selectLoader', brokerServerTag)
}

const autoBrokers = props.loaderDescriber.describers.filter((i) => i?.config?.type === 'auto')
const rusBrokers = props.loaderDescriber.describers.filter((i) => i?.config?.type === 'rus')
const manual = props.loaderDescriber.describers.filter((i) => i?.config?.type === 'manual')
const guru = props.loaderDescriber.describers.filter((i) => i?.config?.serverTag === 'guru_files')

const FileBrokerLoaders = [
  {
    title: 'Автоматические иностранные брокеры',
    loaders: [...autoBrokers],
    show: true,
  },
  {
    title: 'Иные иностранные организации',
    loaders: [...manual],
    show: true,
  },
  {
    title: 'Российские брокеры',
    loaders: [...rusBrokers],
    show: true,
  },
  {
    title: 'Гуру',
    loaders: [...guru],
    show: politics.isAdmin.value,
  },
]
</script>

<template>
  <div class="">
    <div v-for="item in FileBrokerLoaders" :key="item.title">
      <div v-if="item.show">
        <h3 class="mb-4 bg-gray p-4">{{ item.title }}</h3>
        <div class="mb-4 px-4" v-for="desc in item.loaders" :key="'show_' + desc.config.serverTag">
          <el-tooltip :content="`Загрузить ${desc.config.title}`" effect="dark" placement="right" v-if="desc.isShow">
            <div class="mb-2 flex items-center">
              <div
                @click="selectActiveLoader(desc.config.serverTag as FileTagType)"
                class="clickable-broker cursor-point width-100 flex items-center p-2"
              >
                <img :src="desc.config.logo" alt="broker_icon" class="logo-img mr-4" />
                <span>{{ desc.config.title }}</span>
              </div>
            </div>
          </el-tooltip>
          <div v-for="file in desc.getFilesFromServer" :key="file.id" class="margin-v-xs margin-h--left-l flex">
            <el-tooltip effect="dark" :content="`Скачать ${file.serverName}`" placement="right">
              <a @click.prevent="desc.downloadFile(file, invoiceId)" class="clickable-text-link cursor-point mr-2">
                <span>{{ file.name }}</span>
                <el-icon
                  class="el-icon-loading font-weight--extra margin-h--left-s"
                  v-if="file.isLoading"
                  style="vertical-align: middle"
                >
                  <Loading />
                </el-icon>
                <el-icon
                  class="el-icon-download font-weight--extra margin-h--left-s"
                  style="vertical-align: middle"
                  v-else
                >
                  <Download />
                </el-icon>
              </a>
            </el-tooltip>
            <img
              src="@/assets/delete_icon.svg"
              alt="delete_icon"
              width="16"
              class="cursor-point button--text-hover"
              @click="
                () => {
                  emit('deleteFileFromServer', {
                    index: file.id,
                    serverTag: desc.serverTag as FileTagType,
                  })
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
