import { useRequestService } from '@/use/useRequestService'
import { useUserStore } from './userStore'
import { NotFoundException } from '@/errors/FetchErrors'
import { IUser } from '@/entityes/user/user.type'
import { FetchError } from 'ofetch'
import { ref } from 'vue'
import {ElMessageBox, ElNotification} from 'element-plus'
import { useRouter } from 'vue-router'

export function useUserRepository() {
  const userStore = useUserStore()
  const { fetch, fullFetch } = useRequestService()
  const router = useRouter()

  function sendRequestChangePassword(body: { email: string }) {
    return fullFetch('/users/init_change_password', {
      method: 'post',
      body,
    }).then(() => {
      userStore.signOut()
      router.push({ name: 'authorization' }).then(() => {
        ElMessageBox({
          message: 'На вашу почту отправлено письмо с инструкциями по восстановлению пароля',
          title: 'Запрос отправлен',
          type: 'info',
          confirmButtonText: 'Понятно',
        })
      })
    }).catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `Не удалось отправить запрос на восстановление пароля. Попробуйте позже. ${err}`,
      })
    })
  }
  function resetPassword( body: { password: string, token: string | string[] }) {
    return fullFetch('users/change_password', {
      method: 'put',
      body
    })
  }
  function checkPasswordToken(token: string | string[]) {
    return fullFetch(`users/check_reset_token?token=${token}`)
  }
  async function findUser(email: string): Promise<number | string> {
    return fetch<{ users: IUser[] }>(`/admin/users?q[email_eq]=${email}`).then((res) => {
      if (res.users.length === 1) {
        userStore.userShowForAdmin = res.users[0]
        return res.users[0].user_id
      } else {
        throw new NotFoundException()
      }
    })
  }

  async function getUserInfo(userId: string | number): Promise<void> {
    getUserInfo.indicator.value = true
    return fetch<IUser>(`/admin/users/${userId}`)
      .then((user) => {
        userStore.userShowForAdmin = user
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        getUserInfo.indicator.value = false
      })
  }
  getUserInfo.indicator = ref(false)
  async function updateUserInfo(userId: string | number, body: { role: string; group: string | null }) {
    return fetch<IUser>(`/admin/users/${userId}`, {
      method: 'patch',
      body,
    })
      .then((user: IUser) => {
        getUserInfo(userId)
        console.log(user)
      })
      .catch((err: FetchError) => {
        console.log(err)
      })
  }

  async function updateCustomerInfo(userId: string | number, body: { phone: string }) {
    return fetch<IUser>(`/users/${userId}`, {
      method: 'patch',
      body,
    }).then((res) => {
      // @ts-ignore
      userStore.user.phone = res.phone
    })
  }

  return {
    sendRequestChangePassword,
    findUser,
    getUserInfo,
    updateUserInfo,
    updateCustomerInfo,
    resetPassword,
    checkPasswordToken
  }
}
