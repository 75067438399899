import { computed, onBeforeUnmount, ref } from 'vue'
import { useInvoiceRepository } from './invoice.repository'
import { IInvoice } from './invoice.type'

export function useAsyncInvoiceReload() {
  const timer = ref<number | undefined>(0)
  const hasTimer = computed(() => timer.value !== undefined)
  const invoiceRepository = useInvoiceRepository()

  function initAsync(invoice: IInvoice): Promise<IInvoice> {
    return new Promise<IInvoice>((resolve, _) => {
      if (!invoice.status.async) {
        clearTimeout(timer.value)
        timer.value = undefined
        resolve(invoice)
      } else {
        timer.value = setTimeout(() => {
          invoiceRepository.getInvoice(invoice.id).then((res) => {
            initAsync(res).then((r) => {
              resolve(r)
            })
          })
        }, 2500)
      }
    })
  }
  onBeforeUnmount(() => {
    clearTimeout(timer.value)
  })
  return {
    hasTimer,
    initAsync,
  }
}
