import { useUserStore } from '@/entityes/user/userStore'
import { NavigationGuardWithThis } from 'vue-router'

// Дает доступ на страницу только если пользователь залогинен
export const isAuth: NavigationGuardWithThis<void> = () => {
  const userStore = useUserStore()
  if (!userStore.checkTokenActuality()) {
    userStore.signOut()
  }
  if (!userStore.isAuth)
    return {
      name: 'authorization',
      replace: true,
    }
}
