import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import DeductionWidget from '@/04-Widgets/DeductionWidget/ui/DeductionWidget.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'DeductionPage',
  setup(__props) {

const route = useRoute()
const invoiceId = computed(() => route.params.id as string)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DeductionWidget, { "invoice-id": invoiceId.value }, null, 8, ["invoice-id"]))
}
}

})