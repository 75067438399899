import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "margin-v-s"
}

import SidebarLoader from '@/05-Features/SidebarLoader/ui/SidebarLoader.vue'
import { ILoaderDescriber } from '@/entityes/invoice/file/loader/loader.type'
import { computed, ref } from 'vue'
import { FileLoaderType, LoaderDescriberType } from '../model'
import AddedFiles from './AddedFiles.vue'

interface IProps {
  brokerName: string
  describer: FileLoaderType
}
interface IEmits {
  (e: 'update'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'SingleFileLoader',
  props: {
    brokerName: {},
    describer: {}
  },
  emits: ["update"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit
const props = __props
const activeLoader = computed(() => props.describer)
const showSidebar = ref<boolean>(false)
const hasLocalFiles = computed<boolean>(() => props.describer.localFiles.files.length > 0 || false)

return (_ctx: any,_cache: any) => {
  const _component_CirclePlusFilled = _resolveComponent("CirclePlusFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (!hasLocalFiles.value)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "primary",
            onClick: _cache[0] || (_cache[0] = () => (showSidebar.value = true)),
            class: "button-main"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "el-icon-circle-plus font-weight--extra margin-h--right-10" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CirclePlusFilled)
                ]),
                _: 1
              }),
              _createTextVNode(" Загрузить файлы " + _toDisplayString(_ctx.brokerName), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("h3", _hoisted_1, "Файлы " + _toDisplayString(_ctx.brokerName) + " к загрузке", 1)),
      _createElementVNode("div", null, [
        _createVNode(AddedFiles, {
          files: _ctx.describer.localFiles.file_names,
          onDeleteLoadedFile: _cache[1] || (_cache[1] = 
          (index) => {
            _ctx.describer.onRemoveLocalFile(index)
            emit('update')
          }
        )
        }, null, 8, ["files"])
      ])
    ]),
    _createVNode(SidebarLoader, {
      "show-sidebar": showSidebar.value,
      "onUpdate:showSidebar": _cache[2] || (_cache[2] = ($event: any) => ((showSidebar).value = $event)),
      "loader-describer": activeLoader.value,
      "show-delete-button": true,
      onReadedFile: _cache[3] || (_cache[3] = 
      (value) => {
        activeLoader.value.onUpdateFilesEvent(value)
        emit('update')
      }
    ),
      onDeleteLocalFile: _cache[4] || (_cache[4] = 
      (value) => {
        activeLoader.value.onRemoveLocalFile(value)
        emit('update')
      }
    ),
      onDeleteServerFile: _cache[5] || (_cache[5] = 
      (value) => {
        activeLoader.value.onAddDeleteFile(value)
        emit('update')
      }
    ),
      onUndoDeleteFile: _cache[6] || (_cache[6] = 
      () => {
        activeLoader.value.undoDeleteFiles()
        emit('update')
      }
    )
    }, null, 8, ["show-sidebar", "loader-describer"])
  ], 64))
}
}

})