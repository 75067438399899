import { computed, reactive, ref } from 'vue'
import { IAddItem } from './IAddItem.type'
import { generatorId } from '@/07-Shared/lib/generatorId'

export type AdditionalItemType = 'type' | 'situation'
export interface AdditionalItemConfig {
  baseField: AdditionalItemType
  situationName: string // перечислить возможные ситуации
  fieldWasEdited?: boolean
  isEdit?: boolean
  valueFromServer?: boolean
  describer: IAddItem[]
}
export interface AdditionalItemValue extends Record<string, any> {
  valid?: boolean
}

export function newAdditionalItem<T extends AdditionalItemValue>(inputAdValue: T, config: AdditionalItemConfig) {
  const item = reactive<T>(inputAdValue)
  const localUniqueId = generatorId()
  const fieldWasEdited = ref(config?.fieldWasEdited || false)
  const valueFromServer = ref(config?.valueFromServer || false)
  const isEdit = ref(config?.isEdit || false)
  const situationName = ref(config.situationName || '')

  const getActiveComponent = computed(() => {
    const activeItem = config.describer.find((i) => i.tag === config.situationName)
    return activeItem || null
  })

  function _cleanAllField() {
    Object.keys(item).forEach((key) => {
      delete item[key]
    })
  }

  function onUpdateEvent(value: T) {
    _cleanAllField()
    Object.assign(item, value)
  }

  function toggleEdit() {
    isEdit.value = !isEdit.value
    fieldWasEdited.value = true
  }
  function toJson() {
    return Object.assign(item, { [config.baseField]: config.situationName })
  }
  const touched = ref<boolean>(false)

  return {
    item,
    localUniqueId,
    fieldWasEdited,
    isEdit,
    valueFromServer,
    getActiveComponent,
    onUpdateEvent,
    toggleEdit,
    toJson,
    situationName,
    touched,
    baseField: config.baseField,
  }
}

export type AdditionalItem<T extends AdditionalItemValue> = ReturnType<typeof newAdditionalItem<T>>
