<template>
  <h1 class="margin-v-xs">Налоговые вычеты</h1>
  <p class="margin-v-xs">
    Налоговые вычеты — это сумма, которая уменьшает размер дохода, с которого уплачивается налог.
  </p>
  <p class="margin-v-xs">Либо это возврат части ранее уплаченного налога на доходы физического лица,</p>
  <p class="margin-v-xs">например, в связи с покупкой квартиры, расходами на лечение, обучение и т.д.</p>
  <p class="margin-v-xs font-weight--semi">
    Чтобы получить налоговые вычеты (социальные, ИИС по типу А, имущественные) необходимо иметь Доходы по Основной
    налоговой базе (по заработной плате, от продажи имущества и/или сдачи имущества в аренду и т.п.).
  </p>
  <p class="margin-v-xs font-weight--semi">Если таких доходов нет, то вычеты получить нельзя.</p>
  <div class="margin-v-s">
    <el-popover placement="top" trigger="hover" :width="300">
      <template #reference>
        <div class="flex-box flex-box--align-c cursor-point" style="width: 200px">
          <el-icon color="#4b60cecc" class="margin-h--right-10">
            <QuestionFilled />
          </el-icon>
          <p class="font-color--purple font-weight--semi">Как получить вычеты</p>
        </div>
      </template>
      <template #default>
        <div style="word-break: break-word">
          <p class="margin-v-xs">Загрузить справку о доходах (ранее 2-НДФЛ) на странице Дополнительные доходы;</p>
          <p class="margin-v-xs">Либо заполнить на странице Дополнительные доходы данные по ситуациям:</p>
          <p>
            Аренда недвижимого имущества, Продажа транспортного средства, Продажа недвижимого имущества, Переуступка
            права требования по договору долевого участия.
          </p>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<script>
import useWindowWidth from '@/use/useWindowWidth'

export default {
  name: 'RefundHeaderPreview',
  setup() {
    const { screenLessValue } = useWindowWidth()
    return {
      screenLessValue,
    }
  },
}
</script>

<style lang="sass" scoped></style>
